import { useMutation, useQuery } from "@apollo/client";
import { useDisclosure } from "@chakra-ui/react";
import FilterList from "components/FilterList";
import { ModalConfirmation } from "components/ModalConfirmation";
import Pagination from "components/Pagination";
import { PanelHeader } from "components/PanelHeader";
import Search from "components/Search";
import Table from "components/Table";
import { format, parseISO } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { launchToast } from "shared/utils/launchToast";
import { ReactComponent as CancelButton } from "../../assets/svg/generic/cancelbutton.svg";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { columnHeaders } from "./constants/columns";
import { APIFilters, EventFilterEnglishTranslationEnum, EventFilterPortugueseKey, IEvent, IFilterFormData } from "./constants/types";
import { DEACTIVATE_EVENT, LIST_EVENTS } from "./graphql/EventQuery";
import CreateEventModal from "./modals/CreateEventModal";
import FilterModal from "./modals/FilterModal";
import UpdateEventModal from "./modals/UpdateEventModal";
import MainContainer from "components/MainContainer";

const Events: React.FC = () => {
  const [paginationLimit, setPaginationLimit] = useState<number>(10);
  const [searchInput, setSearchInput] = useState<string>("");
  const [eventSelected, setEventSelected] = useState<IEvent | null>(null);
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [idToDeactivate, setIdToDeactivate] = useState<number | null>(null)
  
  const createEventModalControl = useDisclosure();
  const updateEventModalControl = useDisclosure();
  const filterModalControl = useDisclosure();
  const deactivateEventModalControl = useDisclosure();

  const handleWordSearch = (event: string ) => {
    setCurrentPage(1)
    setSearchInput(event)
  };

  const [removeEvent, { loading: removeEventLoading }] = useMutation(DEACTIVATE_EVENT, {
    refetchQueries: [
      LIST_EVENTS,
      'ListEvents',
    ],
  });

  const handleEventDeactivation = useCallback(
    async () => {
      try {
        await removeEvent({
          variables: {
            removeEventId: idToDeactivate,
          }
        });
        launchToast('Evento desativado com sucesso!', "success");
      } catch (error: any) {
        launchToast(error.message, "error");
      }
    }, [removeEvent, idToDeactivate]
  );

  const handleDeactivationConfirmation = (eventId: number) => {
    setIdToDeactivate(eventId);
    deactivateEventModalControl.onOpen();
  }

  const handleEventUpdate = useCallback(
    async (event: IEvent) => {
      setEventSelected(event);
      updateEventModalControl.onOpen();
    },
    [updateEventModalControl]
  )

  const { data, loading, refetch } = useQuery(LIST_EVENTS, {
    variables: {
      filterParams: {
        titleOrCupon: searchInput,
      },
      paginationParams: {
        limit: paginationLimit,
        page: currentPage,
      },
      orderParams: {
        field: "event_end_date",
        order: "desc",
      },
    },
  });

  useEffect(() => {
    try {
      const filterParams = filters.reduce((acc, filter: { key: string, value: string }) => {
        acc[filter.key as keyof IFilterFormData] = filter.value;
        return acc;
      }, {} as IFilterFormData);
  
      refetch({
        filterParams: {
          ...filterParams,
          titleOrCupon: ''
        },
        paginationParams: {
          limit: paginationLimit,
          page: currentPage,
        },
        orderParams: {
          field: "event_end_date",
          order: "desc",
        },
      });
    } catch (error: any) {
      launchToast(error.message, "error");
    }
}, [paginationLimit, currentPage])
  

  const handleRemoveFilter = (key: string) => {
    try {
      const newFilters = filters.filter(
        (filter: {
          key: EventFilterPortugueseKey,
          value: string | boolean,
        }) => filter.key !== key
      );
      setFilters(newFilters);
      const filterParams = newFilters.reduce(
        (acc, filter: {
          key: EventFilterPortugueseKey,
          value: string | boolean,
        }) => {
        if (
          (filter.key === 'Data de início' || filter.key === 'Data de término')
          && typeof filter.value === 'string'
        ) {
          const [comparison, date] = filter.value.split(' a ');
          acc[EventFilterEnglishTranslationEnum[filter.key]] = {
            date,
            filter: comparison === 'Menor ou igual'
              ? 'lte'
              : 'gte',
          };
        } else {
          acc[EventFilterEnglishTranslationEnum[filter.key]] = filter.value as any;
        }
        return acc;
        },
        {} as APIFilters
      );
  
      setCurrentPage(1);
  
      refetch({
        filterParams: {
          ...filterParams,
          titleOrCupon: ''
        }
      });
    } catch (error: any) {
      launchToast(error.message, "error");
    }
  };

  const handleAddFilter = (params: any) => {
    setCurrentPage(1);
    refetch(params);
  };

  return (
    <>
      <CreateEventModal
        isOpen={createEventModalControl.isOpen}
        onClose={createEventModalControl.onClose}
      />
      {eventSelected && (
        <UpdateEventModal
          isOpen={updateEventModalControl.isOpen}
          onClose={updateEventModalControl.onClose}
          event={eventSelected}
        />
      )}
      <FilterModal
        isOpen={filterModalControl.isOpen}
        onClose={filterModalControl.onClose}
        handleAddFilter={handleAddFilter}
        onFilterSubmit={setFilters}
      />
      <ModalConfirmation
        isOpen={deactivateEventModalControl.isOpen}
        onClose={deactivateEventModalControl.onClose}
        nameAction="desativar esse evento"
        handleConfirm={handleEventDeactivation}
        loading={removeEventLoading}
      />
      <MainContainer>
        <PanelHeader
          title="Eventos"
          isFilterModalVisible
          isNew
          onClick={filterModalControl.onOpen}
          onClickNew={createEventModalControl.onOpen}
        />
        <Search
          wordSearch={searchInput}
          setSearch={handleWordSearch}
          isFilterForRegister
          limit={paginationLimit}
          onSetPageSize={(limit) => {
            setCurrentPage(1)
            setPaginationLimit(limit)
          }}
          placeholder="Pesquise pelo título ou nome do cupom"
        />
        {
          filters.length
            ? (
              <FilterList filters={filters} removeFilter={handleRemoveFilter} />
            )
            : ''
        }
        {
          !loading && (
            <Table
              headers={columnHeaders}
              data={data.listEvents.data.map((event: IEvent) => ({
                id: event.id,
                street: event.address.street,
                coupon: event.cupon.name,
                is_active: event.is_active? 'Ativo': 'Desativado',
                event_start_date: format(parseISO(event.event_start_date), "dd/MM/yyyy - HH:mm"),
                event_end_date: format(parseISO(event.event_end_date), "dd/MM/yyyy - HH:mm"),
                title: event.title,
                subtitle: event.subtitle,
                actions: [
                  {
                    name: "Editar",
                    icon: <EditButton />,
                    action: () => handleEventUpdate(event),
                  },
                  {
                    name: "Desativar",
                    icon: <CancelButton />,
                    action: handleDeactivationConfirmation,
                  },
                ],
              }))}
            />
          )
        }
        {
          !loading && (
            <Pagination
              totalPage={data.listEvents.totalPages}
              pageIndex={currentPage}
              setPage={setCurrentPage}
            />
          )
        }
      </MainContainer>
    </>
  );
};

export default Events;
