import * as Yup from 'yup';

export const UpdateCepFormValidationSchema = Yup.object().shape({
    start_cep: Yup.string()
        .required('Cep inicial obrigatório')
        .length(5, 'O complemento do cep inicial deve ter 5 caracteres'),
    end_cep: Yup.string()
        .required('Cep final obrigatório')
        .length(5, 'O complemento do cep final ter 5 caracteres'),
});
