import React, { useEffect, useState } from "react";
import { loadTypes, createPartnerDiscount } from "../graphQL";
import {
  Box,
  Input,
  Select,
  Button,
  Heading,
  FormControl,
  FormLabel,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { DiscountsDropdown } from "./DiscountsDropdown";
import IPartnerDiscount from "types/IPartnerDiscount";
import colors from "../../../shared/utils/constants/colors";

interface IProps {
  partnerId: string;
  discounts: IPartnerDiscount[];
  setDiscounts: (discounts: IPartnerDiscount[]) => void;
  partnerLoad: () => void;
}

interface IDiscountType {
  id: string;
  name: string;
}

export const Discount: React.FC<IProps> = ({
  partnerId,
  discounts,
  setDiscounts,
  partnerLoad,
}) => {
  const [categories, setCategories] = useState<IDiscountType[]>([]);
  const [services, setServices] = useState<IDiscountType[]>([]);
  const [typeDiscount, setTypeDiscount] = useState<string>("categories");
  const [discountType, setDiscountType] = useState<string>("PERCENTAGE");
  const [amount, setAmount] = useState<number>(0);
  const [partnerPercentagePaid, setPartnerPercentagePaid] = useState<number>(0);
  const [typeValue, setTypeValue] = useState<string | null>(null);
  const [type, setType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadTypesDiscount = async () => {
    try {
      const data = await loadTypes();
      setCategories([
        { id: "", name: "Selecione uma categoria" },
        ...data.categories,
      ]);
      setServices([{ id: "", name: "Selecione um serviço" }, ...data.services]);
    } catch (error) {
      console.error(error);
    }
  };

  const createDiscount = async () => {
    try {
      setIsLoading(true);
      await createPartnerDiscount(
        partnerId,
        type,
        discountType,
        amount,
        partnerPercentagePaid,
        typeValue
      );
      toast.success("Desconto criado com sucesso!");
      partnerLoad();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao criar desconto!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTypesDiscount();
  }, []);

  useEffect(() => {
    const mapping: { [key: string]: string } = {
      categories: "CATEGORY_ID",
      services: "SERVICE_ID",
      PACK_BIWEEKLY: "PACK_BIWEEKLY",
      PACK_WEEKLY: "PACK_WEEKLY",
    };

    setType(mapping[typeDiscount]);
  }, [typeDiscount]);

  return (
    <>
      <Box mt="20px" mb="20px">
        <FormControl>
          <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="type">
            Tipo
          </FormLabel>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Select
              id="type"
              onChange={(e) => {
                const selectedType = e.target.value;
                setTypeDiscount(selectedType);
              }}
              width="100%"
              h={"16"}
              fontSize="1.2rem"
              fontWeight="semibold"
            >
              <option value="categories">CATEGORIA</option>
              <option value="services">SERVIÇO</option>
              <option value="PACK_BIWEEKLY">PACOTE QUINZENAL</option>
              <option value="PACK_WEEKLY">PACOTE SEMANAL</option>
            </Select>
            {typeDiscount === "categories" && (
              <Select
                id="typeValue"
                onChange={(e) => setTypeValue(e.target.value)}
                width="100%"
                h={"16"}
                fontSize="1.2rem"
                fontWeight="semibold"
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name.toUpperCase()}
                  </option>
                ))}
              </Select>
            )}
            {typeDiscount === "services" && (
              <Select
                id="typeValue"
                onChange={(e) => setTypeValue(e.target.value)}
                width="100%"
                h={"16"}
                fontSize="1.2rem"
                fontWeight="semibold"
              >
                {services.map((service) => (
                  <option key={service.id} value={service.id}>
                    {service.name}
                  </option>
                ))}
              </Select>
            )}
          </Box>
        </FormControl>
      </Box>
      <Box mt="20px" mb="20px">
        <FormControl>
          <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="discountType">
            Tipo de Desconto
          </FormLabel>
          <Select
            id="discountType"
            onChange={(e) => setDiscountType(e.target.value)}
            width="100%"
            h={"16"}
            fontSize="1.2rem"
            fontWeight="semibold"
          >
            <option value="PERCENTAGE">PORCENTAGEM</option>
            <option value="ABSOLUTE">VALOR FIXO</option>
          </Select>
        </FormControl>
      </Box>
      <Box mt="20px" mb="20px">
        <FormControl>
          <FormLabel fontSize="1.5rem" fontWeight="bold" htmlFor="amount">
            Valor
          </FormLabel>
          <InputGroup>
            <InputLeftAddon children="R$" h={"16"} fontSize="1.2rem" />
            <Input
              id="amount"
              type="number"
              onChange={(e) => setAmount(Number(e.target.value))}
              width="100%"
              h={"16"}
              fontSize="1.2rem"
            />
          </InputGroup>
        </FormControl>
      </Box>
      <Box mt="20px" mb="20px">
        <FormControl>
          <FormLabel
            fontSize="1.5rem"
            fontWeight="bold"
            htmlFor="partnerPercentagePaid"
          >
            Porcentagem Paga pelo Parceiro
          </FormLabel>
          <InputGroup>
            <InputLeftAddon children="%" h={"16"} fontSize="1.2rem" />
            <Input
              id="partnerPercentagePaid"
              type="number"
              onChange={(e) => setPartnerPercentagePaid(Number(e.target.value))}
              width="100%"
              h={"16"}
              fontSize="1.2rem"
            />
          </InputGroup>
        </FormControl>
      </Box>
      <Box display="flex" justifyContent="center" mt="20px" mb="20px">
        <Button
          colorScheme="green"
          variant="solid"
          isLoading={isLoading}
          onClick={createDiscount}
          h={"16"}
          fontSize="1.5rem"
          width="100%"
        >
          Salvar Desconto
        </Button>
      </Box>
      <DiscountsDropdown discounts={discounts} setDiscounts={setDiscounts} />
    </>
  );
};
