import { gql } from "@apollo/client";

export const GET_ALL_USERS = gql`
  query Users($filters: UsersFiltersInputType!) {
    users(filters: $filters) {
      users {
        id
        name
        email
        cpf
        phone
        birthday
        status
        address_main_id
        roles {
          id
          name
        }
        addresses {
          id
          cep
          number
          city
          neighborhood
          street
          state
          surname
          complement
        }
        customer {
          meet_us
          id
        }
        professional {
          id
          truck_id
          payment_percent
          cnh_id
          crmv
          crmv_uf
          report_visibility
          cnh {
            number
            validity
            category
          }
          truck_rent
          outsourced
          truck {
            id
            license_plate
          }
        }
      }
      totalPages
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUserByAdmin(
    $roles: [RolesInputType!]!
    $user: UserByAdminInputType!
  ) {
    createUserByAdmin(roles: $roles, user: $user) {
      id
      name
      email
      cpf
      phone
      birthday
      status
      address_main_id
      roles {
        id
        name
      }
      addresses {
        id
        cep
        number
        city
        neighborhood
        street
        state
        surname
        complement
      }
      customer {
        meet_us
        id
      }
      professional {
        id
        truck_id
        payment_percent
        cnh_id
        crmv
        crmv_uf
        cnh {
          number
          validity
          category
        }
        outsourced
        truck_rent
        truck {
          id
          license_plate
        }
      }
    }
  }
`;

export const UPDATE_PROFESSIONAL = gql`
  mutation UpdateProfessionalByAdmin(
    $professional: UpdateProfessionalInputType!
    $professionalId: String!
  ) {
    updateProfessionalByAdmin(
      professional: $professional
      professional_id: $professionalId
    ) {
      id
    }
  }
`;

export const CREATE_CUSTOMMER = gql`
  mutation CreateCustomerByAdmin($meetUs: Float!, $userId: String!) {
    createCustomerByAdmin(meet_us: $meetUs, user_id: $userId) {
      id
    }
  }
`;

export const CREATE_GROOMER = gql`
  mutation CreateTosadorByAdmin(
    $userId: String!
    $validity: String!
    $category: String!
    $cnhNumber: String!
    $truckId: String!
  ) {
    createTosadorByAdmin(
      user_id: $userId
      validity: $validity
      category: $category
      cnh_number: $cnhNumber
      truck_id: $truckId
    ) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserByAdmin($user: UpdateUserByAdminInputType!) {
    updateUserByAdmin(user: $user) {
      id
      name
      email
      cpf
      phone
      birthday
      status
      address_main_id
      roles {
        id
        name
      }
      addresses {
        id
        cep
        number
        city
        neighborhood
        street
        state
        surname
        complement
      }
      customer {
        meet_us
        id
      }
      professional {
        id
        truck_id
        payment_percent
        cnh_id
        crmv
        crmv_uf
        outsourced
        truck_rent
        cnh {
          number
          validity
          category
        }
        truck {
          id
          license_plate
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMMER = gql`
  mutation UpdateCustomerByAdmin(
    $customer: UpdateCustomerByAdminInputType!
    $customerId: String!
  ) {
    updateCustomerByAdmin(customer: $customer, customer_id: $customerId) {
      id
    }
  }
`;
