import { normalizePlatformEnum } from "shared/enums/PlatformEnum";
import stringOfEnum from "shared/utils/StringOfEnum";
import convertToCurrencyFormat from "shared/utils/convertToCurrencyFormat";
import AppointmentStatusEnum from "shared/utils/enums/AppointmentStatusEnum";
import formatDate from "shared/utils/formatDate";
import formatDateWithMiliseconds from "shared/utils/formatDateWithMiliseconds";
import { calcServiceValue, calculateAppointmentPaidPrice } from "shared/utils/normalizeAppointments";
import IAppointment from "types/IAppointment";

export function formatAppointment(appointment: IAppointment) {
  const appointmentTotalPrice = calcServiceValue(appointment);
  const appointmentPaidPrice = calculateAppointmentPaidPrice(appointment, appointmentTotalPrice);

  return {
    ...appointment,
    date_formated: formatDate(appointment.date),
    customer: appointment.customer.user.name,
    pet_name: appointment.pet.name,
    professional: appointment.professional?.user.name || "Sem profissional",
    platform: !!appointment.platform ? normalizePlatformEnum(appointment.platform) : "--",
    service_name: `${appointment.service.name} ${
      !!appointment.pack_frequency
        ? ` - Pacote ${appointment.pack_frequency.toLowerCase()}`
        : ""
    }`,
    appointment_price_total: convertToCurrencyFormat(appointmentTotalPrice),
    appointment_price_paided: convertToCurrencyFormat(appointmentPaidPrice),
    created_at: formatDateWithMiliseconds(appointment.created_at),
    status_description: stringOfEnum(AppointmentStatusEnum, appointment.status),
    category: appointment.service.category,
  };
}

export type IFormattedAppointment = ReturnType<typeof formatAppointment>;
