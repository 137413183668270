import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { CREATE_CEP, GET_SECTION_CEPS, LIST_SECTIONS_CEP_CATEGORIES } from '../graphql/CepsCategoriesQuery';
import { ICreateCepFormData, ISectorCepsCategories } from '../constants/types';
import CreateCepForm, { CreateCepFormId } from '../forms/CreateCep/createCepForm';


export type ICreateSectionModalProps = {
    isOpen: boolean;
    onClose: () => void;
    section: ISectorCepsCategories
};

const CreateCepsModal: React.FC<ICreateSectionModalProps> = ({ isOpen, onClose, section }) => {


    const [createCep, { loading }] = useMutation(CREATE_CEP, {
        refetchQueries: [
            GET_SECTION_CEPS,
            'GetCepSectionCeps',
            LIST_SECTIONS_CEP_CATEGORIES,
            'listCepsCategoriesSections'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: ICreateCepFormData,
            { reset }
        ) => {
            try {
                await createCep({
                    variables: {
                        startCep: payload.startCep,
                        endCep: payload.endCep,
                        regionId: payload.regionId
                    }
                });
                reset();
                onClose();
                launchToast('Cep criado com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createCep],
    );

    return (
        <>            
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Novo Cep"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <CreateCepForm onSubmit={handleSubmit} section={section} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={CreateCepFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
        </>
    )
}

export default CreateCepsModal