import { gql } from "@apollo/client";
import client from "services/api-graphql";
import ICep from "types/ICep";
import IRegion from "types/IRegion";

const getRegion = async (region_id: string): Promise<IRegion> => {
  const response = await client.query({
    query: gql`
      query($regionId: String!) {
        region(id: $regionId) {
          id
          ceps {
            id
            status
            start_cep
            end_cep
          }
          categories {
            id
            name
          }
        }
      }
    `,
    variables: {
      regionId: region_id
    }
  });

  return response.data.region;
};

const updateCep = async ({ id, status }: { id: string; status: boolean; }): Promise<ICep> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($id: String!, $status: Boolean!) {
        updateCepStatus(status: $status, id: $id) {
          id
          status
        }
      }
    `,
    variables: {
      id: id,
      status: status
    }
  });

  return response.data.updateCepStatus;
};

export { getRegion, updateCep };
