import React, { ReactNode } from "react";
import { Main } from "./styles";

type IBaseMainProps = {
  children: ReactNode;
}

function MainContainer({ children }: IBaseMainProps) {
  return (
    <Main>
      {children}
    </Main>
  );
}

export default MainContainer;
