
import React, { useCallback, useState } from 'react';
import * as Styles from '../constants/styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from 'components/Button';
import CreateMovimentForm, { CreateMovimentFormId } from '../forms/CreateMoviment/CreateMovimentForm';
import Table from "components/Table";
import { columnAddMovimentHeaders } from '../constants/conlumns';
import { launchToast } from 'shared/utils/launchToast';
import { ICreateMovimentFormData } from '../constants/types';
import { useMutation } from '@apollo/client';
import { CREATE_MOVIMENT, LIST_MOVIMENT } from '../graphql/MovimentQuery';

export type ICreateMovimentModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const CreateMovimentModal: React.FC<ICreateMovimentModalProps> = ({ isOpen, onClose }) => {

    const [createMovimentList, setCreateMovimentList] = useState([] as any)
    
    const handleAddMoviment = async (moviment: ICreateMovimentFormData) => {
        setCreateMovimentList([...createMovimentList, moviment])
        launchToast('Movimentação adicionada', 'success');
    } 


    
    const [CreateMoviment, { loading }] = useMutation(CREATE_MOVIMENT, {
        refetchQueries: [
            LIST_MOVIMENT,
            'stockActionLogs'
        ],
    });

    const handleSubmit = async () => {
            try {
                const moviments = createMovimentList.map((moviment: ICreateMovimentFormData ) => (
                    {
                        numberOfProducts: moviment.numberOfProducts,
                        productId: moviment.product.id,
                        reason: moviment.reason.value,
                        stockAction: moviment.stockAction,
                        recipientId: moviment.recipient.value,
                    }))
                await CreateMoviment({
                    variables: {
                       input: moviments
                    }
                    ,
                });
                onClose();
                setCreateMovimentList([] as any)
                launchToast('Movimentações feitas com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        }

    return (
        <Modal visible={isOpen} closeModal={onClose}>
        {/* <AddProductsToReceiptsModal
                onSubmit={handleAddProduct}
                isOpen={addProductsToReceiptsModalControl.isOpen}
                onClose={addProductsToReceiptsModalControl.onClose}
                productsReceipt={productsReceipt}
                handleRemoveProduct={handleRemoveProduct}
         /> */}
            <Styles.header>
                <Text
                    text="Movimentar Produto"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <CreateMovimentForm 
                    onSubmit={handleAddMoviment} listMoviments={createMovimentList}
                />
            </Styles.FormDiv>
            <Styles.row>
            <Button 
                    text="Adicionar movimentação" type="submit" form={CreateMovimentFormId} 
                />
            </Styles.row>
            {createMovimentList.length > 0 && <Table
                    headers={columnAddMovimentHeaders}
                    data={ 
                        createMovimentList.map((moviment: ICreateMovimentFormData) => ({
                        recipient: moviment.recipient.label,
                        numberOfProducts: moviment.numberOfProducts,
                        product: moviment.product.name,
                        reason: moviment.reason.label,
                        stockAction: moviment.stockAction,
                        // actions: [
                        // {
                        //     name: "remove",
                        //     icon: <CancelButton/>,
                        //     action: () => handleRemoveProduct(product.id),
                        // },
                        // ],
                    }))
                }
                />}
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" onClick={handleSubmit} loading={ loading }/>
            </Styles.rowButton>
        </Modal>
    )
}

export default CreateMovimentModal