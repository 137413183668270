import { useMutation } from "@apollo/client";
import Button from "components/Button";
import Modal from "components/Modal";
import Text from 'components/Text';
import React, { useCallback } from 'react';
import colors from 'shared/utils/constants/colors';
import { launchToast } from "shared/utils/launchToast";
import * as Styles from '../constants/styles';
import { IEvent, IUpdateEventFormData, IUpdateEventVariables } from "../constants/types";
import UpdateEventForm, { UpdateEventFormId } from "../forms/UpdateEvent/UpdateEventForm";
import { LIST_EVENTS, UPDATE_EVENT } from "../graphql/EventQuery";

export type ICreateEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
  event: IEvent;
};

function UpdateEventModal({
  isOpen,
  onClose,
  event
}: ICreateEventModalProps) {
  const [updateEvent, { loading }] = useMutation(UPDATE_EVENT, {
    refetchQueries: [
      LIST_EVENTS,
      'ListEvents'
    ],
  });

  const handleSubmit = useCallback(
    async (payload: IUpdateEventFormData, { reset }) => {
      try {
        const serializedCep = payload.cep.replace('-', '');
        const variables: IUpdateEventVariables = {
          input: {
            name: payload.name,
            title: payload.title,
            subtitle: payload.subtitle,
            content: payload.content,
            coupon_id: payload.coupon_id,
            event_start_date: payload.event_start_date,
            event_end_date: payload.event_end_date,
            is_active: payload.is_active === 'Sim',
            addresses: {
              id: event.address.id,
              cep: serializedCep,
              city: payload.city,
              state: payload.state,
              street: payload.street,
              number: payload.number,
              neighborhood: payload.neighborhood,
              complement: payload.complement,
              surname: payload.surname,
            },
          },
          updateEventId: event.id,
        }
  
        if (payload.image_url) {
          variables['file'] = payload.image_url;
        }
  
        await updateEvent({
          variables,
        });
        reset();
        onClose();
        launchToast('Evento atualizado com sucesso!', 'success');
      } catch (error: any) {
        launchToast(error.message, "error");
      }
    },
    [updateEvent],
  );

  return (
    <Modal visible={isOpen} closeModal={onClose}>
      <Styles.header>
        <Text
          text="Novo endereço"
          color={colors.argon.darkBlue}
          fontFamily="Open Sans"
          size={17}
          weight="600"
        />
      </Styles.header>
      <Styles.FormDiv>
        <UpdateEventForm onSubmit={handleSubmit} event={event} />
      </Styles.FormDiv>
      <Styles.rowButton>
        <Button text="Cancelar" onClick={onClose} />
        <Button text="Salvar" type="submit" form={UpdateEventFormId} loading={loading} />
      </Styles.rowButton>
    </Modal>
  );
};

export default UpdateEventModal;
