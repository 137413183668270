import React, { useEffect } from "react";
import {
  Redirect,
  Route as ReactDomRoute,
  RouteProps as ReactDomRouterProps,
  useLocation,
} from "react-router-dom";
import Sidebar from "../components/layout/Sidebar";
import { useAuth } from "../hooks/Auth";

interface RouteProps extends ReactDomRouterProps {
  isPrivate?: boolean;
  component: any;
  sidebar?: boolean;
  notFounded?: boolean;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  sidebar = false,
  notFounded = false,
  ...rest
}) => {
  const { user } = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return (
    <ReactDomRoute
      {...rest}
      render={({ location }) => {
        if (notFounded) return <Component />;
        if (isPrivate === !!user) {
          return (
            <>
              {sidebar ? (
                <Sidebar>
                  <Component />
                </Sidebar>
              ) : (
                <Component />
              )}
            </>
          );
        }

        const search =
          location.pathname === "/checkout" && location.search
            ? location.search
            : undefined;

        if (search) {
          localStorage.setItem("@zazuu:admin:service_id", search.split("=")[1]);
        }

        return (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : "/home",
            }}
          />
        );
      }}
    />
  );
};

export default Route;
