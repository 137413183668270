import styled from "styled-components";

import colors from "../../../shared/utils/constants/colors";

export const BackgroundPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PanelHeader = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${colors.primary.default};
  color: ${colors.argon.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelItens = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
`;

export const TablePanel = styled.div`
  background: ${colors.argon.white};
  border-radius: 5px;
  width: calc(90%-32px);
  margin-top: -40px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const FilterDisplay = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${colors.gray.light02};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TabHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const ContentHeaderDateInfo = styled.div`
  padding: 8px 20px;
  width: 100%;
`;

export const WhiteButton = styled.button`
  margin: 5%;
  background-color: ${colors.argon.white};
  border: 2px ${colors.argon.default} solid;
  border-radius: 5px;
  width: 100px;
  font-family: Open Sans;
  color: ${colors.argon.default};
  height: 40px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchText = styled.input`
  margin: 0 8px;
  padding: 2px 4px;
  color: ${colors.argon.textColorGray};
  border-radius: 5px;
  border: 1px solid ${colors.argon.textColorLight02};
`;

export const Option = styled.button<{ selected: boolean }>`
  padding: 6px 12px;
  color: ${colors.argon.white};
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-width: 120px;
  margin-right: 16px;
  opacity: ${props => props.disabled ? 0.3 : 1};
  background: ${props => props.selected ? '#669d8c' : `${colors.argon.default}`};
  transform: ${props => props.selected ? 'scale(1.05)' : 'scale(1)'};

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px ${colors.argon.textColorGray};
    background: #669d8c;
  }
`;

export const ContentTable = styled.div`
  flex-grow: 1;
`;

export const ButtonExport = styled.button`
  color: ${colors.suport.alert};
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #669d8c;
  margin: 0 16px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px ${colors.argon.textColorGray};
  }
`;
