import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import { ICreateCepFormData, ISectorCepsCategories } from 'pages/Ceps/constants/types';
import { CreateCepFormValidationSchema } from './CreateCepFormValidations';


export type ICreateCepFormProps = {
    onSubmit: (payload: ICreateCepFormData, options: { reset: () => void }) => Promise<void>, section: ISectorCepsCategories
}

export const CreateCepFormId = 'create-cep-form';

const CreateCepForm: React.FC<ICreateCepFormProps> = ({
    onSubmit,
    section
}) => {

    const createCepFormRef = useRef<FormHandles>(null);

    const validateFields = async (
        payload: ICreateCepFormData,
        options: { reset: () => void }
    ) => {
        try {
            verifyInterval(payload.startCep, payload.endCep, section.start_cep, section.end_cep)
            await CreateCepFormValidationSchema.validate({ startCep: `${payload.startCep}`, endCep: `${payload.endCep}`}, { abortEarly: false });
            onSubmit({ startCep: `${payload.startCep}`, endCep: `${payload.endCep}`, regionId: section.region_id}, options);
        } catch (error: any) {
            if(error.message.startsWith('Cep inicial e cep final devem estar entre')) {
                launchToast(`${error.message}`, "error");
            } else {
                const errors = getValidationErrors(error as ValidationError);
                createCepFormRef.current?.setErrors(errors);
                launchToast("Verifique o preenchimento dos dados", "error");
            }
        }
    };

    const verifyInterval = (start_cep: string, end_cep: string, start_section: string, end_section: string): void => {
        const startSectionToCompare = Number(start_section) * 100
        const endSectionToCompare = ((Number(end_section) + 1) * 100) - 1
        const startCepToCompare = Number(start_cep)
        const endCepToCompare = Number(end_cep)

        if(startCepToCompare < startSectionToCompare || startCepToCompare > endSectionToCompare || endCepToCompare < startSectionToCompare || endCepToCompare > endSectionToCompare){
            throw new Error(`Cep inicial e cep final devem estar entre ${start_section}00 e ${end_section}99`)
        }
    }

    return (
        <>
            <Form onSubmit={validateFields} ref={createCepFormRef} id={CreateCepFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>{`Cep inicial`}</Label>
                        <InputRef
                            name="startCep"
                            type='number'
                            placeholder="Cep inicial"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>{`Cep Final`}</Label>
                        <InputRef
                            name="endCep"
                            type='number'
                            placeholder="Cep final"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default CreateCepForm