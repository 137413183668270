import AppointmentStatusEnum from "shared/enums/AppointmentStatusEnum";
import { normalizePlatformEnum } from "shared/enums/PlatformEnum";
import stringOfEnum from "shared/utils/StringOfEnum";
import convertToCurrencyFormat from "shared/utils/convertToCurrencyFormat";
import PeriodsEnum from "shared/utils/enums/PeriodsEnum";
import formatDate from "shared/utils/formatDate";
import formatDateWithMiliseconds from "shared/utils/formatDateWithMiliseconds";
import IAppointment from "types/IAppointment";
import ICategory from "types/ICategory";
import { ISalesOrder } from "types/IOrder";

export type IFormattedAppointment = {
  category: ICategory;
  date_formated: string;
  period: PeriodsEnum;
  customer: string;
  pet_name: string;
  service_name: string;
  professional: string;
  appointment_price_total: string;
  appointment_price_paided: string;
  status_description: string | null;
  created_at: string;
  platform: string;
};

export const calcServiceValue = (appointment: IAppointment) => {
  const { service_price, appointments_items, price_fix } = appointment;
  const additionalsPrice = appointments_items.reduce(
    (sum: number, item: any) => sum + parseInt(item.item_price),
    0
  );

  const total =
    additionalsPrice * 100 +
    Number(service_price) +
    (price_fix >= 0 ? Number(price_fix) : 0);

  return total / 100;
};

export function calculateAppointmentPaidPrice(
  appointment: IAppointment,
  totalAppointmentPrice: number
) {
  const needToCalculate =
    Number(appointment.order.discount_by_admin) > 0 &&
    appointment.service_price / 100 === Number(appointment.paid_price);

  if (needToCalculate) {
    return (
      totalAppointmentPrice -
      Number(appointment.order.discount_by_admin) +
      appointment.appointments_items.reduce(
        (total, additional) => Number(total + Number(additional.paid_price)),
        0
      )
    ).toFixed(2);
  } else {
    const servicePaidPrice = Number(appointment.paid_price);
    const priceFix = Number(appointment.price_fix / 100);
    const additionalsPaidPrice = appointment.appointments_items.reduce(
      (total, additional) => Number(total + Number(additional.paid_price)),
      0
    );
    return (servicePaidPrice + priceFix + additionalsPaidPrice).toFixed(2);
  }
}

export function normalizeAppointments(appointment: IAppointment) {
  const appointmentTotalPrice = calcServiceValue(appointment);
  const appointmentPaidPrice = calculateAppointmentPaidPrice(
    appointment,
    appointmentTotalPrice
  );

  return {
    category: appointment.service.category,
    date_formated: formatDate(appointment.date),
    period: appointment.period,
    customer: appointment.customer.user.name,
    pet_name: appointment.pet.name,
    service_name: `${appointment.service.name} ${
      !!appointment.pack_frequency
        ? ` - Pacote ${appointment.pack_frequency.toLowerCase()}`
        : ""
    }`,
    professional: appointment.professional?.user.name || "Sem profissional",
    appointment_price_total: convertToCurrencyFormat(appointmentTotalPrice),
    appointment_price_paided: convertToCurrencyFormat(appointmentPaidPrice),
    status_description: stringOfEnum(AppointmentStatusEnum, appointment.status),
    created_at: formatDateWithMiliseconds(appointment.created_at),
    platform: !!appointment.platform
      ? normalizePlatformEnum(appointment.platform)
      : "--",
    appointment_discount: convertToCurrencyFormat(
      appointmentTotalPrice - Number(appointmentPaidPrice)
    ),
  };
}

export function normalizeSales(sales: ISalesOrder) {
  if (!sales.salesProducts) {
    return [];
  }

  const address = `Rua ${sales.saleAddress?.street?.split("-")[0]}, Nº:  ${
    sales.saleAddress?.number
  }`;

  const cepStr = `${sales.saleAddress?.cep}`;
  const cep = cepStr.slice(0, 5) + "-" + cepStr.slice(5);

  return sales.salesProducts.map((sale) => {
    const normalizedSale = {
      name: sale.product?.name || "",
      quantity: sale.quantity || 0,
      price: convertToCurrencyFormat((sale.price_per_product || 0) / 100),
      totalPrice: convertToCurrencyFormat(
        ((sale.quantity || 0) * (sale.price_per_product || 0)) / 100
      ),
      address,
      cep,
    };

    return normalizedSale;
  });
}
