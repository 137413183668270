import { Form } from "@unform/web";
import { IFilterCamapaignFormData } from "pages/Campaigns/constants/types";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Styles from '../../constants/styles';
import { Label } from "components/Label/styles";
import InputRef from "components/InputRef";
import Select from "components/Select";
import { IFilterReceiptsFormData } from "pages/Receipts/constants/types";
import { getSuppliers } from "services/stock/graphQL";
import { format, parseISO } from "date-fns";

export type IFilterReceiptsFormProps = {
    onSubmit: (payload: IFilterReceiptsFormData, { reset }: any) => void;
}

export const FilterReceiptsFormId = 'filter-receipts-form';


const FilterReceiptsForm = ({ onSubmit }: IFilterReceiptsFormProps) => {
    const formRef = useRef<FormHandles>(null);

    const [suppliers, setSuppliers] = useState([] as any[]);
    const [selectSupplier, setSelectSupplier] = useState([] as any)
    const [cnpj, setCnpj] = useState<string>('');

    const fetchSuppliers = async () => {
        try {
          const results = await getSuppliers();
          setSuppliers(results.map( (supplier: {name: string, id: string}) => ({label: supplier.name, id: supplier.id})))
        } catch (error: any) {
          console.log(error.message);
        }
      };

    useEffect(() => {
        const fetchData = async () => {
          await Promise.all([
            fetchSuppliers(),
          ]);
        };
        fetchData();
      }, []);
    
    const normalizeDate = (date: string) => {
        const originalDate = new Date(date);
        const newDate = new Date(originalDate.getTime() + 3 * 60 * 60 * 1000);
    
     return newDate.toISOString();
    }

    const handleCnpjChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.replace(/\D/g, '');
        value = value.substring(0, 14); 
        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        setCnpj(value);
      };

      const extractNumbersFromCnpj = (cnpj: string): string => {
        return cnpj.replace(/\D/g, '');
      };
      

    const validateFields = async (
        payload: IFilterReceiptsFormData,
        options: { reset: () => void }
    ) => {
        onSubmit({
            ...payload,
            supplier: selectSupplier.label,
            startDate: payload.startDate && format(parseISO(normalizeDate(payload.startDate)), "yyyy-MM-dd"),
            endDate: payload.endDate && format(parseISO(normalizeDate(payload.endDate)), "yyyy-MM-dd"),
            cnpj: extractNumbersFromCnpj(cnpj)
        }, options);
        setSelectSupplier([] as any)
    };

    return (
        <Form onSubmit={validateFields} ref={formRef} id={FilterReceiptsFormId} >
            <Styles.row>
                <Styles.field>
                    <Label>CNPJ</Label>
                    <InputRef
                        name="cnpj"
                        placeholder="CNPJ do emitente"
                        containerStyle={{ width: "33rem" }}
                        type="text"
                        value={cnpj}
                        onChange={handleCnpjChange}
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Fornecedor</Label>
                    <Select
                        name="supplier"
                        containerStyle={{ width: "33rem" }}
                        placeholder="Empresa emitente"
                        options={suppliers}
                        value={selectSupplier}
                        onChange={(e: any) => setSelectSupplier(e)}
                    />
                </Styles.field>
            </Styles.row>
            <Styles.row>
                <Styles.field>
                    <Label>Data Inicial</Label>
                    <InputRef
                        name="startDate"
                        type="date"
                        containerStyle={{ width: "33rem" }}
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Data Final</Label>
                    <InputRef
                        name="endDate"
                        type="date"
                        containerStyle={{ width: "33rem" }}
                    />
                </Styles.field>
            </Styles.row>
        </Form>
    )
}

export default FilterReceiptsForm