import React, { useEffect, useState } from "react";
import { Container, Row, ButtonTab, ButtonRemove } from "./styles";
import { gql } from "@apollo/client";
import client from "services/api-graphql";
import { toast } from "react-toastify";
import Text from "components/Text";
import colors from "shared/utils/constants/colors";
import Modal from "components/Modal";
import Input from "components/Input";
import Button from "components/Button";
import IPetFiles from "types/IPetFiles";

interface IProps {
  pet_id: string;
}

const PetFiles: React.FC<IProps> = ({ pet_id }) => {
  const [files, setFiles] = useState<IPetFiles[]>([]);
  const [filesFiltered, setFilesFiltered] = useState<IPetFiles[]>([]);
  const [buttonSelected, setButtonSelected] = useState("EXAM");
  const [fileSelected, setFileSelected] = useState<IPetFiles | undefined>(
    undefined
  );
  const [showModalNewFile, setShowModalNewFile] = useState(false);
  const [removeFileId, setRemoveFileId] = useState<string | undefined>("");
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);
  const [fileToUploadName, setFileToUploadName] = useState("");
  const [inputFileErrorMessage, setInputFileErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const loadPetFiles = async (): Promise<IPetFiles[]> => {
    const results = await client.query<{ petFiles: IPetFiles[] }>({
      query: gql`
        query ($petId: String!) {
          petFiles(pet_id: $petId) {
            id
            file_url
            name
            type
            created_at
          }
        }
      `,
      variables: {
        petId: pet_id,
      },
    });
    return results.data.petFiles;
  };

  const onSelectImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileSelected(undefined);
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const file = e.target.files[0];

    if (!file.type.endsWith('png') && !file.type.endsWith('pdf')) {
      e.target.value = "";
      setInputFileErrorMessage('Escolha um arquivo png ou pdf');
      return;
    }

    const extension = file.name.slice(-4);

    const [fileName] = file.name.split(extension);

    if (fileName.includes('.')) {
      e.target.value = "";
      setInputFileErrorMessage("Não é possível carregar arquivo com caractere '.' no nome");
      return;
    }

    setFileToUpload(file);
    toast.success("Arquivo carregado");
  };

  const handleUploadFile = async () => {
    if (!fileToUpload) {
      toast.error({
        text1: "Upload arquivo",
        text2: "Erro ao realizar upload",
        type: "error",
      });
      return;
    }

    try {
      setLoading(true);
      const result = await client.mutate({
        mutation: gql`
          mutation ($file: PetFileInputType!, $petId: String!) {
            uploadFilePet(file: $file, pet_id: $petId) {
              id
              file_url
              name
              type
              created_at
            }
          }
        `,
        variables: {
          file: {
            name: fileToUploadName,
            type: buttonSelected,
            file: fileToUpload,
          },
          petId: pet_id,
        },
      });
      setFiles((old) => [...old, result.data.uploadFilePet]);

      setShowModalNewFile(false);
      setFileToUpload(undefined);
      setFileToUploadName("");
      setLoading(false);
    } catch (error) {
      toast.error({
        text1: "Upload arquivo",
        text2: "Erro ao realizar upload",
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleDeleteFile = async (pet_file_id: string) => {
    try {
      await client.mutate({
        mutation: gql`
          mutation ($petFileId: String!, $petId: String!) {
            removeFilePet(pet_file_id: $petFileId, pet_id: $petId)
          }
        `,
        variables: {
          petFileId: pet_file_id,
          petId: pet_id,
        },
      });
      setFiles((old) => old.filter((o) => o.id !== pet_file_id));
      setRemoveFileId(undefined);
    } catch (error) {
      toast.error({
        text1: "Deletar arquivo",
        text2: "Erro ao deletar o arquivo",
        type: "error",
      });
    }
  };

  useEffect(() => {
    setFilesFiltered(files.filter((f) => f.type === buttonSelected));
  }, [buttonSelected, files]);

  useEffect(() => {
    loadPetFiles().then((examsResults) => {
      setFiles(examsResults);
    });
  }, [pet_id]);

  return (
    <Container>
      <Row style={{ marginBottom: 8, display: "flex", alignItems: "center" }}>
        <ButtonTab
          selected={buttonSelected === "EXAM"}
          onClick={() => setButtonSelected("EXAM")}
          style={{ marginRight: 8 }}
        >
          <Text
            text="Exames"
            weight="600"
            size={16}
            color={
              buttonSelected === "EXAM" ? colors.gray.white : colors.gray.dark01
            }
          />
        </ButtonTab>
        <ButtonTab
          style={{ marginRight: 8 }}
          selected={buttonSelected === "PRESCRIPTION"}
          onClick={() => setButtonSelected("PRESCRIPTION")}
        >
          <Text
            text="Prescrições"
            weight="600"
            size={16}
            color={
              buttonSelected === "PRESCRIPTION"
                ? colors.gray.white
                : colors.gray.dark01
            }
          />
        </ButtonTab>
        <ButtonTab
          selected={buttonSelected === "CERTIFICATE"}
          onClick={() => setButtonSelected("CERTIFICATE")}
        >
          <Text
            text="Atestados"
            weight="600"
            size={16}
            color={
              buttonSelected === "CERTIFICATE"
                ? colors.gray.white
                : colors.gray.dark01
            }
          />
        </ButtonTab>
      </Row>

      {filesFiltered.map((file) => {
        if (file.file_url.includes(".png")) {
          return (
            <Row
              key={file.id}
            >
              <Text
                text={file.name}
                weight="600"
                size={16}
                onTextClick={() => {
                  if (file.file_url.includes(".png")) {
                    setFileSelected(file);
                    return;
                  }
                }}
              />
              <ButtonRemove onClick={() => setRemoveFileId(file.id)}>
                <Text text="remover" color={colors.gray.white} />
              </ButtonRemove>
            </Row>
          );
        }
        if (file.file_url.includes(".pdf") || file.file_url.includes(".PDF")) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                paddingTop: 8,
                paddingBottom: 8,
                justifyContent: "space-between",
              }}
              >
              <a
                href={`https://docs.google.com/gview?url=${file.file_url}`}
                target="_blank"
                rel="noopener noreferrer"
                key={file.id}
              >
                <Text text={file.name} weight="600" size={16} />
              </a>
              <ButtonRemove onClick={() => setRemoveFileId(file.id)}>
                <Text text="remover" color={colors.gray.white} />
              </ButtonRemove>
            </div>
          );
        }
      })}
      {filesFiltered.length === 0 && <Text text="Nenhum arquivo encontrado" />}
      <Row
        style={{ justifyContent: "center" }}
        onClick={() => setShowModalNewFile(true)}
      >
        <Text
          text="+ adicionar arquivo"
          align="center"
          weight="600"
          size={16}
          color={colors.primary.default}
        />
      </Row>
      <Modal
        visible={!!fileSelected}
        closeModal={() => setFileSelected(undefined)}
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            text={fileSelected?.name || ""}
            color={colors.gray.dark01}
            size={24}
            weight="600"
            marginBottom={16}
          />
          {fileSelected?.file_url &&
            (fileSelected.file_url.includes(".png") ? (
              <img
                src={fileSelected.file_url}
                alt="file"
                style={{
                  flex: 1,
                  width: "80vw",
                  height: "80vh",
                }}
              />
            ) : null)}
        </div>
      </Modal>
      <Modal
        visible={showModalNewFile}
        closeModal={() => {
          setShowModalNewFile(false);
          setFileToUpload(undefined);
          setFileToUploadName("");
        }}
      >
        <Text text="Adicionar novo arquivo" weight="600" size={16} />
        <Input
          name="nome"
          placeholder="nome"
          onChange={(e) => setFileToUploadName(e.target.value)}
        />
        <input
          style={{ marginTop: 16, marginBottom: 16 }}
          id="avatar"
          type="file"
          accept="image/png, application/pdf"
          onChange={onSelectImage}
        />
        {inputFileErrorMessage && (
          <Text
            text={inputFileErrorMessage}
            color="#FF6340"
            fontFamily="Open Sans"
            align="left"
            weight="400"
            size={12}
            marginBottom={16}
          />
        )}
        <Button
          disabled={!fileToUpload || !fileToUploadName}
          loading={loading}
          onClick={() => handleUploadFile()}
          text="adicionar"
        ></Button>
      </Modal>
      <Modal
        visible={!!removeFileId}
        closeModal={() => {
          setRemoveFileId(undefined);
        }}
      >
        <Text
          text="Tem certeza que deseja remover esse arquivo?"
          weight="600"
          size={16}
        />
        <Button
          onClick={() => {
            if (removeFileId) {
              handleDeleteFile(removeFileId);
            }
          }}
          style={{ marginTop: 16 }}
          text="SIM"
        ></Button>
        <Button
          onClick={() => setRemoveFileId(undefined)}
          style={{ marginTop: 16 }}
          text="CANCELAR"
        ></Button>
      </Modal>
    </Container>
  );
};

export default PetFiles;
