import { useMutation } from "@apollo/client";
import Button from "components/Button";
import Modal from "components/Modal";
import Text from 'components/Text';
import React, { useCallback } from 'react';
import colors from 'shared/utils/constants/colors';
import { launchToast } from "shared/utils/launchToast";
import * as Styles from '../constants/styles';
import { ICreateEventFormData } from "../constants/types";
import CreateEventForm, { CreateEventFormId } from '../forms/CreateEvent/CreateEventForm';
import { CREATE_EVENT, LIST_EVENTS } from "../graphql/EventQuery";

export type ICreateEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function CreateEventModal({
  isOpen,
  onClose,
}: ICreateEventModalProps) {
  const [createEvent, { loading }] = useMutation(CREATE_EVENT, {
    refetchQueries: [
      LIST_EVENTS,
      'ListEvents'
    ],
  });

  const handleSubmit = useCallback(
    async (
      payload: ICreateEventFormData,
      { reset }
    ) => {
      const serializedCep = payload.cep.replace('-', '');

      try {
        await createEvent({
          variables: {
            input: {
              name: payload.name,
              title: payload.title,
              subtitle: payload.subtitle,
              content: payload.content,
              coupon_id: payload.coupon_id,
              event_start_date: payload.event_start_date,
              event_end_date: payload.event_end_date,
              addresses: {
                cep: serializedCep,
                city: payload.city,
                state: payload.state,
                street: payload.street,
                number: payload.number,
                neighborhood: payload.neighborhood,
                complement: payload.complement,
                surname: payload.surname,
              },
            },
            file: payload.image_url
          },
        });
        reset();
        onClose();
        launchToast('Evento criado com sucesso!', 'success');
      } catch (error: any) {
        launchToast(error.message, "error");
      }
    },
    [createEvent],
  );

  return (
    <Modal visible={isOpen} closeModal={onClose}>
      <Styles.header>
        <Text
          text="Novo endereço"
          color={colors.argon.darkBlue}
          fontFamily="Open Sans"
          size={17}
          weight="600"
        />
      </Styles.header>
      <Styles.FormDiv>
        <CreateEventForm onSubmit={handleSubmit} />
      </Styles.FormDiv>
      <Styles.rowButton>
        <Button text="Cancelar" onClick={onClose} />
        <Button text="Salvar" type="submit" form={CreateEventFormId} loading={loading} />
      </Styles.rowButton>
    </Modal>
  );
};

export default CreateEventModal;
