export const columnAddMovimentHeaders = [
    { key: "product", name: "Produto" },
    { key: "recipient", name: "Destinatario" },
    { key: "stockAction", name: "Ação" },
    { key: "numberOfProducts", name: "Quantidade" },
];

export const columnMovimentssHeaders = [
    { key: "id", name: "Id" },
    { key: "createdAt", name: "Data" },
    { key: "product", name: "Produto" },
    { key: "recipient", name: "Destinatario" },
    { key: "action_type", name: "Ação" },
    { key: "stock_unit", name: "Unidade de medida" },
    { key: "quantity", name: "Quantidade" },
    { key: "moved_by", name: "Movido por" },
    // { key: "actions", name: "ações" },
];