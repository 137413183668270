import styled from "styled-components";

import colors from "../../shared/utils/constants/colors";

export const BackgroundPanel = styled.div`
  width: 100%;
`;

export const PanelHeader = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${colors.primary.default};
  color: ${colors.argon.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelItens = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
`;

export const Container = styled.div`
  background: ${colors.argon.white};
  border-radius: 5px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  padding: 20px;
`;

export const Content = styled.div`
  margin-top: 24px;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.argon.textColorLight02};
  margin-bottom: 24px;
`;

export const FormDiv = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 650px) {
    align-items: flex-end;
    margin-left: 14px;  
    margin-bottom: 24px;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: -10px;
`;

export const RowButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  @media (min-width: 650px) {
    flex-direction: row;
    align-items: flex-end ;
    width: 100%;
  }
`;