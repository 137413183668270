import { gql } from "@apollo/client";
import client from "../../services/api-graphql";
import TypePaymentEnum from "../../shared/enums/TypePaymentEnum";
import ISubscription from "../../types/ISubscription";
import ISubscriptionPayment from "../../types/ISubscriptionPayment";

type ICreatePlanMonthlyPayment = {
  subscription_payment_id: string;
  type: TypePaymentEnum;
}

const getSubscriptions = async (): Promise<ISubscription[]> => {
  const results = await client.query({
    query: gql`
      query {
        subscriptions {
          id
          day_to_renew
          price
          status
          ends_in
          user {
            name
          }
          pet {
            name
          }
          plan {
            id
            name
          }
          payments {
            id
            date_to_renew
            date_renewed
            transaction {
              id
              amount
              status
              type
              transaction_pagseguro_id
              payload
            }
          }
        }
      }
    `
  })
  return results.data.subscriptions;
};

const updatePlanMonthlyPayment = async (payload: ICreatePlanMonthlyPayment): Promise<ISubscriptionPayment> => {
  const results = await client.mutate({
    mutation: gql`
    mutation($type: Int!, $subscriptionPaymentId: String!) {
      updatePlanMonthlyPayment(
        type: $type
        subscription_payment_id: $subscriptionPaymentId
      ) {
        id
        date_to_renew
        date_renewed
        transaction {
          id
          amount
          transaction_pagseguro_id
          payload
          status
          type
        }
      }
    } 
    `,
    variables: {
      subscriptionPaymentId: payload.subscription_payment_id,
      type: payload.type
    }
  })
  return results.data.updatePlanMonthlyPayment;
};

export { getSubscriptions, updatePlanMonthlyPayment };
