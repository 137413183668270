import { useField } from "@unform/core";
import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { Container, Input } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: { value: string; label: string }[];
  containerStyle?: object;
  onChangeOption?: (value: string) => void;
}

const Switch: React.FC<InputProps> = ({
  name,
  options,
  onChangeOption,
  containerStyle,
  disabled = false,
}) => {
  const inputRefs = useRef<HTMLInputElement>(null);

  const { fieldName, registerField } = useField(name);

  const [optionSelected, setOptionSelected] = useState(options[0].value);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (ref: HTMLInputElement) => {
        return optionSelected;
      },
      setValue: (ref: HTMLInputElement, value: string) => {
        setOptionSelected(value);
      },
    });
  }, [fieldName, registerField, optionSelected]);

  return (
    <Container style={containerStyle}>
      {options.map((option, index) => (
        <Input
          key={option.label}
          type="button"
          value={option.value}
          checked={optionSelected === option.value}
          onChange={() => {}}
          onClick={() => {
            if (!disabled) {
              setOptionSelected(option.value);
              onChangeOption && onChangeOption(option.value);
            }
          }}
        />
      ))}
    </Container>
  );
};

export default Switch;
