
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from 'components/Button';
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { IRegisterSupplierFormData } from 'pages/Stock/constants/types';
import { CREATE_SUPPLIER } from 'pages/Stock/graphql/StockQuery';
import RegisterSupplierForm, { RegisterSupplierFormId } from '../forms/registerSupplier/RegisterSupplierForm';

export type ICreateSupplierModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const CreateSupplierModal: React.FC<ICreateSupplierModalProps> = ({ isOpen, onClose }) => {
    
    const [createSupplier, { loading }] = useMutation(CREATE_SUPPLIER, {});

    const handleSubmit = useCallback(
        async (
            payload: IRegisterSupplierFormData,
            { reset }
        ) => {
            try {
                await createSupplier({
                    variables: {
                        input: {
                            name: payload.name,
                            cnpj: payload.cnpj,
                        }
                    },
                });
                reset();
                launchToast('Fornecedor registrado com sucesso!', 'success');
                onClose()
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createSupplier],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Cadastrar Fornecedor"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <RegisterSupplierForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={RegisterSupplierFormId} loading={loading} 
                />
            </Styles.rowButton>
        </Modal>
    )
}

export default CreateSupplierModal