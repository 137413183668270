import { gql } from "@apollo/client";
import client from "../../services/api-graphql";
import IPartner, { IPartnerCreate, IPartnerUpdate } from "../../types/IPartner";
import IUser from "types/IUser";
import IPartnerDiscount from "types/IPartnerDiscount";

interface QueryResponseUsersNotAssociated {
  users: {
    users: IUser[];
  };
}

export const createPartner = async (
  partner: IPartnerCreate
): Promise<IPartner> => {
  const { name, partnership_start, partnership_end, banner_file, logo_file } =
    partner;
  const results = await client.mutate({
    mutation: gql`
      mutation Mutation($files: [Upload!]!, $input: CreatePartnersInputType!) {
        createPartner(files: $files, input: $input) {
          name
          id
        }
      }
    `,
    variables: {
      files: [banner_file, logo_file],
      input: {
        name,
        partnership_start,
        partnership_end,
      },
    },
    fetchPolicy: "network-only",
  });

  return results.data.createPartner;
};

export const loadPartners = async (): Promise<IPartner[]> => {
  const results = await client.query<{ partners: IPartner[] }>({
    query: gql`
      query {
        partners {
          id
          name
          partnership_start
          partnership_end
          banner_url
          logo_url
          userCount
          created_at
          updated_at
        }
      }
    `,
    fetchPolicy: "network-only",
  });

  return results.data.partners;
};

export const loadPartnerById = async (partnerId: string): Promise<IPartner> => {
  const results = await client.query<{ partner: IPartner }>({
    query: gql`
      query Query($partnerId: String!) {
        partner(partnerId: $partnerId) {
          id
          name
          users {
            name
            id
          }
          discounts {
            id
            partner_percentage_paid
            amount
            discount_type
            status
            type
            type_value
            discountType {
              id
              name
            }
          }
          cards {
            id
            card_hash
            first_digits
            last_digits
            holder
            brand
          }
          partnership_start
          partnership_end
          banner_url
          logo_url
          userCount
          created_at
          updated_at
        }
      }
    `,
    variables: {
      partnerId,
    },
    fetchPolicy: "network-only",
  });

  return results.data.partner;
};

export const createUserPartner = async (
  partnerId: string,
  usersIds: string[]
): Promise<void> => {
  const results = await client.mutate({
    mutation: gql`
      mutation Mutation($inputs: CreateUserPartnerInputType!) {
        createUserPartner(inputs: $inputs)
      }
    `,
    variables: {
      inputs: {
        partnerId,
        usersIds,
      },
    },
    fetchPolicy: "network-only",
  });

  return results.data.createUserPartner;
};

export const removeUserPartner = async (
  partnerId: string,
  userId: string
): Promise<void> => {
  const results = await client.mutate({
    mutation: gql`
      mutation Mutation($inputs: RemoveUserPartnerInputType!) {
        removeUserPartner(inputs: $inputs)
      }
    `,
    variables: {
      inputs: {
        partnerId,
        userId,
      },
    },
    fetchPolicy: "network-only",
  });

  return results.data.removeUserPartner;
};

export const updatePartnerFile = async (
  field: string,
  file: File,
  updatePartnerFileId: string
): Promise<any> => {
  const results = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $field: String!
        $file: Upload!
        $updatePartnerFileId: String!
      ) {
        updatePartnerFile(
          field: $field
          file: $file
          id: $updatePartnerFileId
        ) {
          logo_file
          banner_file
        }
      }
    `,
    variables: {
      field,
      file,
      updatePartnerFileId,
    },
    fetchPolicy: "network-only",
  });

  return results.data.updatePartnerFile;
};

export const updatePartner = async (
  partner: IPartnerUpdate
): Promise<IPartnerUpdate> => {
  const { id, name, partnership_start, partnership_end } = partner;
  let input = { id };
  if (name) Object.assign(input, { name });
  if (partnership_start) Object.assign(input, { partnership_start });
  if (partnership_end) Object.assign(input, { partnership_end });

  const results = await client.mutate({
    mutation: gql`
      mutation Mutation($input: UpdatePartnerInputType!) {
        updatePartner(input: $input) {
          name
          partnership_start
          partnership_end
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: "network-only",
  });

  return results.data.updatePartner;
};

export const deletePartner = async (partnerId: string): Promise<void> => {
  const results = await client.mutate({
    mutation: gql`
      mutation Mutation($partnerId: String!) {
        removePartner(partnerId: $partnerId)
      }
    `,
    variables: {
      partnerId,
    },
    fetchPolicy: "network-only",
  });

  return results.data.removePartner;
};

export const loadUsersNotAssociated = async (
  partnerId: string,
  search: string = ""
): Promise<IUser[]> => {
  const results = await client.query<QueryResponseUsersNotAssociated>({
    query: gql`
      query Query($filters: UsersFiltersInputType!) {
        users(filters: $filters) {
          users {
            name
            id
          }
        }
      }
    `,
    variables: {
      filters: {
        where: {
          notPartnerId: partnerId,
          name: search,
        },
        pagination: {
        limit: 30,
        page: 0
        }
      },
    },
    fetchPolicy: "no-cache",
  });

  return results.data.users.users;
};

export const loadTypes = async (): Promise<any> => {
  const results = await client.query({
    query: gql`
      query {
        categories {
          name
          id
        }
        services {
          name
          id
        }
      }
    `,
    fetchPolicy: "network-only",
  });

  return results.data;
};

export const createPartnerDiscount = async (
  partnerId: string,
  type: string,
  discount_type: string,
  amount: number,
  partner_percentage_paid: number,
  type_value: string | null
): Promise<IPartnerDiscount> => {
  const results = await client.mutate({
    mutation: gql`
      mutation Mutation($input: CreatePartnerDiscountsInputType!) {
        createPartnerDiscount(input: $input) {
          partner_percentage_paid
          amount
          discount_type
          status
          type
          type_value
        }
      }
    `,
    variables: {
      input: {
        partner_id: partnerId,
        type,
        discount_type,
        amount,
        partner_percentage_paid,
        type_value,
      },
    },
    fetchPolicy: "network-only",
  });

  return results.data.createPartnerDiscount;
};

export const removePartnerDiscount = async (
  partnerDiscountId: string
): Promise<void> => {
  const results = await client.mutate({
    mutation: gql`
      mutation Mutation($partnerDiscountId: String!) {
        removePartnerDiscount(partnerDiscountId: $partnerDiscountId)
      }
    `,
    variables: {
      partnerDiscountId,
    },
    fetchPolicy: "network-only",
  });

  return results.data.removePartnerDiscount;
};

export const updatePartnerPreferedPayment = async (
  partnerId: string,
  payment_method: string,
  provider: string,
  card_input: any
): Promise<any> => {
  let input = { partnerId, payment_method, provider };
  if (payment_method === "CARD") Object.assign(input, { card_input });

  const results = await client.mutate({
    mutation: gql`
      mutation Mutation($input: UpdatePartnerPreferedPaymentInputType!) {
        updatePartnerPreferedPayment(input: $input) {
          cards {
            id
            card_hash
            first_digits
            last_digits
            holder
            brand
          }
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: "network-only",
  });

  return results.data.updatePartnerPreferedPayment;
};
