import * as Yup from "yup";

export const CreateAddressFormValidationSchema = Yup.object().shape({
  cep: Yup.string().required('CEP é obrigatório'),
  city: Yup.string().required('Cidade é obrigatória'),
  state: Yup.string()
    .required('Estado é obrigatório')
    .max(2, 'Coloque a sigla do estado em 2 caracteres'),
  street: Yup.string().required('Endereço é obrigatório'),
  number: Yup.string().required('Número é obrigatório'),
  neighborhood: Yup.string().required('Bairro é obrigatório'),
  complement: Yup.string(),
  surname: Yup.string(),
});

export const CreateEventFormValidationSchema = CreateAddressFormValidationSchema.shape({
  name: Yup.string()
    .required('Nome é obrigatório')
    .max(255, 'O nome pode ter no máximo 255 caracteres'),
  title: Yup.string()
    .required('Título é obrigatório')
    .max(255, 'O título pode ter no máximo 255 caracteres'),
  subtitle: Yup.string()
    .required('Subtitulo é obrigatório')
    .max(255, 'O subtitulo pode ter no máximo 255 caracteres'),
  content: Yup.string()
    .required('Conteúdo é obrigatório')
    .max(255, 'O conteúdo pode ter no máximo 500 caracteres'),
  coupon_id: Yup.string()
    .required('Selecione um cupom'),
  event_start_date: Yup
    .date()
    .required('Data de início é obrigatória'),
  event_end_date: Yup
    .date()
    .min(
      Yup.ref("event_start_date"),
      "Data final deve ser maior que a incial"
    )
    .required('Data do término é obrigatória'),
});