import styled from "styled-components";
import colors from "../../../shared/utils/constants/colors";

export const Container = styled.div<{
  opened: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary.dark};
  height: 50px;
  width: 100%;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
`;

export const Name = styled.div`
  color: white;
  font-weight: 600;
  margin: 8px;
  margin-right: 3%;
`;
