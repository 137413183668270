import styled from "styled-components";
import colors from "../../shared/utils/constants/colors";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const FormDiv = styled.div`
  width: 100%;
`;

export const rowButton = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: space-between;
  margin-top: 70px;

  @media (min-width: 650px) {
    flex-direction: row;
    align-items: flex-end;
    width: 30%;
    margin-left: ${(props) => `${props?.margin}%` || "70%"};
  }
`;

export const line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.primary.light};
  margin-bottom: 24px;
  position: relative;
  top: -1px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Option = styled.button`
  padding: 12px 24px;
  background: ${colors.argon.default};
  box-shadow: 0 4px 6px ${colors.argon.textColorGray};
  color: ${colors.argon.white};
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  min-width: 120px;
  margin-right: 16px;

  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px ${colors.argon.textColorGray};
    background: #669d8c;
  }
`;
