import Modal from 'components/Modal';
import React, { useCallback, useRef, useState } from 'react';
import * as Styles from '../styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { gql, useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { GET_OBSERVATIONS } from '..';
import { IFormattedAppointment } from "../../../../utils/formatAppointment";
import { FormHandles } from '@unform/core';
import { Form } from "@unform/web";
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import { newAppointmentObservationFormValidations } from './newAppointmentObservationFormValidations';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';

export type INewAppointmentObservationPayload = {
    userOrPetId: string
    observation: string
    showToCustomer: boolean
    showToProfessional: boolean
    observationTo: string
};

type INewObservationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    appointment?: IFormattedAppointment;
};

const NEW_OBSERVATION = gql`
    mutation CreatePermanentObservation($input: CreatePermanentObservationInput!) {
        createPermanentObservation(input: $input) {
            petOrCustomerId
            id
            description
            added_by
            show_to_customer
            show_to_professional
            created_at
            updated_at
            deleted_at
            deleted_by
        }
    }
`;

const NewObservationModal: React.FC<INewObservationModalProps> = ({ isOpen, onClose, appointment}) => {

    const [showToCustomer, setShowToCustomer] = useState([] as any)
    const [showToCustomerError, setShowToCustomerError] = useState("");
    const [showToProfessional, setShowToProfessional] = useState([] as any)
    const [showToProfessionalError, setShowToProfessionalError] = useState("");
    const [observationText, setObservationText] = useState("")
    const [observationTo, setObservationTo] = useState([] as any)
    const [observationToError, setObservationToError] = useState("");

    const newAppointmentObservationFormRef = useRef<FormHandles>(null);

    const showToOptions = [{label: "Mostrar", value: true}, {label: "Não mostrar", value: false}]

    const observationToOptions =[{label: 'Cliente', value: 'customer'}, {label: 'Pet', value: 'pet'}]

    const [createPermanentObservation, { loading }] = useMutation(NEW_OBSERVATION, {
        refetchQueries: [
            GET_OBSERVATIONS,
            'GetPermanetObservations'
        ],
    });

    const handleSubmit = useCallback(
        async () => {
            try {
                await createPermanentObservation({
                    variables: {
                        input: {
                            userOrPetId: observationTo?.value === 'pet' ? appointment?.pet_id : (observationTo?.value === 'customer' ? appointment?.customer_id : undefined), 
                            observation: observationText, 
                            showToCustomer: showToCustomer.value, 
                            showToProfessional: showToProfessional.value, 
                            observationTo: observationTo?.value
                        }
                    },
                });
                onClose();
                launchToast('Observação criada com sucesso!', 'success');
                resetForm()
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createPermanentObservation, observationTo, observationText, showToCustomer, showToProfessional],
    );

    const validateFields = async () => {
        try {
            await newAppointmentObservationFormValidations.validate({userOrPetId: observationTo?.value === 'pet' ? appointment?.pet_id : (observationTo?.value === 'customer' ? appointment?.customer_id : undefined), observation: observationText, showToCustomer: showToCustomer.value, observationTo: observationTo?.value}, { abortEarly: false });
            await handleSubmit();
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            newAppointmentObservationFormRef.current?.setErrors(errors);
            if(!showToCustomer?.label){setShowToCustomerError("Selecione uma opção")}
            if(!showToProfessional?.label){setShowToProfessionalError("Selecione uma opção")}
            if(!observationTo?.label){setObservationToError("Selecione uma opção")}
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    const resetForm = () => {
        setShowToCustomerError("")
        setShowToProfessionalError("")
        setObservationToError("")
        newAppointmentObservationFormRef.current?.setErrors({})
        setShowToCustomer([] as any)
        setShowToProfessional([] as any)
        setObservationTo([] as any)
        setObservationText('')
    }

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text={'Nova observação'}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
            <Form onSubmit={() => validateFields()} ref={newAppointmentObservationFormRef}>
                <Styles.row>
                <Styles.field>
                        <Label>Observação para</Label>
                        <Select
                            name="observationTo"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a ação"
                            options={observationToOptions}
                            value={observationTo}
                            error={observationToError}
                            onChange={(e: any) => setObservationTo(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Exibir para o cliente</Label>
                        <Select
                            name="showToCustomer"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a ação"
                            options={showToOptions}
                            value={showToCustomer}
                            error={showToCustomerError}
                            onChange={(e: any) => setShowToCustomer(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Exibir para o profissional</Label>
                        <Select
                            name="showToProfessional"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a ação"
                            options={showToOptions}
                            value={showToProfessional}
                            error={showToProfessionalError}
                            onChange={(e: any) => setShowToProfessional(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Observação</Label>
                        <TextArea
                            containerStyle={{ width: "40rem" }}
                            value={observationText}
                            onChange={(event) => setObservationText(event.target.value)}
                            name="observation"
                            placeholder="Digite a observação"
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" onClick={() => validateFields()} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default NewObservationModal