export const columnCampaignHeaders = [
  { key: "name", name: "Nome" },
  { key: "start_date", name: "Inicio" },
  { key: "end_date", name: "Término" },
  { key: "type", name: "Modelo" },
  { key: "is_active", name: "Status" },
  { key: "actions", name: "ações" },
];

export const columnCampaignReportHeaders = [
  { key: "id", name: "id" },
  { key: "user", name: "Doador" },
  { key: "donate", name: "Doação" },
  { key: "paid_price", name: "Valor Total" },
];
