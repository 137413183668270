export const columnMovimentsReportHeaders = [
    { key: "createdAt", label: "Data" },
    { key: "professional", label: "Profissional" },
    { key: "truck", label: "Truck" },
    { key: "productId", label: "Produto Id" },
    { key: "brand", label: "Marca" },
    { key: "product", label: "Produto" },
    { key: "stock_unit", label: "Unidade de Medida" },
    { key: "costPrice", label: "Preço de Custo" },
    { key: "salePrice", label: "Preço de Venda" },
    { key: "quantity", label: "quantidade" },
    { key: "totalPrice", label: "Preço total" },
    { key: "stockQuantity", label: "Quantidade em Estoque" },
    { key: "movedBy", label: "Movido por" },
    { key: "reason", label: "Motivo" },
];