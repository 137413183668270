import React, { FC } from "react";
import { Flex, Button, Text } from "@chakra-ui/react";
import { AiFillHome } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";

import colors from "../../shared/utils/constants/colors";

interface IPanelHeaderProps {
  onClick?: () => void;
  title: string;
  isFilterModalVisible?: boolean;
  isNew?: boolean;
  onClickNew?: () => void;
}

export const PanelHeader: FC<IPanelHeaderProps> = ({
  onClick,
  title,
  isFilterModalVisible = true,
  isNew = false,
  onClickNew,
}) => {
  return (
    <Flex
      width="100%"
      height="150px"
      backgroundColor={colors.primary.default}
      color={colors.argon.white}
      justifyContent="space-between"
      alignItems="center"
      paddingX={6}
    >
      <Flex justifyContent="space-between" alignItems="center" zIndex={2}>
        <AiFillHome size={20} color={colors.primary.dark} />
        <Text
          color={colors.gray.white}
          fontFamily="Open Sans"
          fontSize={20}
          fontWeight={600}
          marginRight={2}
          marginTop={0}
          ml={2}
        >
          {title}
        </Text>
      </Flex>
      <Flex>
        {isFilterModalVisible && (
          <Button
            onClick={onClick}
            backgroundColor={colors.argon.white}
            color={colors.argon.default}
            border={`2px solid ${colors.argon.default}`}
            borderRadius="5px"
            width="100px"
            fontFamily="Open Sans"
            fontSize={14}
            height="40px"
            marginRight={4}
            cursor="pointer"
            _hover={{
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
            leftIcon={<BiFilterAlt size={20} />}
          >
            Filtrar
          </Button>
        )}
        {isNew && (
          <Button
            onClick={isNew && isFilterModalVisible ? onClickNew : onClick}
            backgroundColor={colors.argon.white}
            color={colors.argon.default}
            border={`2px solid ${colors.argon.default}`}
            borderRadius="5px"
            width="100px"
            fontFamily="Open Sans"
            height="40px"
            fontSize={14}
            cursor="pointer"
            _hover={{
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            Novo
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
