import colors from 'shared/utils/constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.button.attrs({
  activeOpacity: 0.7
})`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
  justify-content: space-between;
`;

export const ButtonTab = styled.button.attrs({
  activeOpacity: 0.7
}) <{ selected: boolean }>`
  flex: 1;
  border-radius: 8px;
  border: 1px solid ${props => props.selected ? colors.secondary.default : colors.gray.light01};
  background-color: ${props => props.selected ? colors.secondary.default : colors.gray.white};
  padding: 8px;
`;

export const ButtonUploadFile = styled.button.attrs({
  activeOpacity: 0.7
})`
  width: 100%;
  padding: 16px 8px;
`;

export const ButtonRemove = styled.button.attrs({
  activeOpacity: 0.7
})`
  background-color: ${colors.suport.alert};
  border-radius: 15px;
  padding: 0 8px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;
