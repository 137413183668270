import React, { useCallback, useEffect, useState } from "react";
import { headers } from "./utils/columns";

import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { ReactComponent as CancelButton } from "../../assets/svg/generic/cancelbutton.svg";
import Pagination from "../../components/Pagination";

import Loading from "../../components/Loading";
import FilterModal from "./FilterModal";
import FilterList from "../../components/FilterList";
import formatDate from "../../shared/utils/formatDate";

import { getActualDate } from "../../shared/utils/getActualDate";
import CouponModal from "./CouponModal";
import {
  cancelCoupon,
  searchCoupons,
} from "../../services/coupon/couponService";
import { launchToast } from "../../shared/utils/launchToast";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "components/Search";
import Table from "components/Table";
import MainContainer from "components/MainContainer";

const actualDate = getActualDate();

const Coupon: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isFilterModalVisible, setisFilterModalVisible] = useState(false);
  const [isEditModalVisible, setisEditModalVisible] = useState(false);
  const [filters, setFilters] = useState([
    { key: "created", searchValue: actualDate },
  ] as any);
  const [selectedItem, setSelectedItem] = useState({} as any);
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sort, setSort] = useState({} as any);
  const [wordSearch, setWordSearch] = useState("");
  const [timer, setTimer] = useState(null as any);

  const loadCoupons = useCallback(
    async (f: any, wordSearch: string) => {
      setLoading(true);
      let orderBy = [];

      let where = {
        wordSearch,
      } as any;
      for (let filter of f) {
        const { key, searchValue } = filter;
        switch (key) {
          case "Descrição":
            where.description = searchValue;
            break;
          case "Código":
            where.code = searchValue;
            break;
          case "Data Início":
            where.from = searchValue;
            orderBy.push({ created_at: "desc" });
            break;
          case "Data Fim":
            where.to = searchValue;
            break;
        }
      }

      if (!!sort.field) {
        const { field, direction } = sort;
        switch (field) {
          case "name":
            orderBy.push({ name: direction });
            break;
          case "code":
            orderBy.push({ code: direction });
            break;
        }
      }
      try {
        const results = (await searchCoupons({
          pagination: { limit, page: currentPage },
          where,
          orderBy,
        })) as any;

        const { coupons, totalPages } = results.data.coupons;
        setTotalPage(totalPages);

        let data = coupons.map((r: any) => {
          const {
            id,
            created_at,
            name,
            code,
            type,
            starts_in,
            ends_in,
            campaign,
            status,
            amount,
          } = r;
          return {
            id,
            created_at: formatDate(created_at),
            name,
            code,
            type: type === "A" ? "$" : "%",
            starts_in: formatDate(starts_in),
            ends_in: formatDate(ends_in),
            campaign: campaign ? "Sim" : "Não",
            status: status ? "Ativo" : "Inativo",
            amount,
            coupon: r,
            actions: [
              {
                name: "Editar",
                action: handleEdit,
                icon: <EditButton />,
              },
              {
                name: "Cancelar",
                action: handleCancel,
                icon: <CancelButton />,
              },
            ],
          };
        });

        setData(data);
      } catch (error) {
        launchToast("Houve erro na requisição de cupons", "error");
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [limit, currentPage, sort]
  );

  const onSetPageSize = (pageSize: number) => {
    setLimit(pageSize);
  };

  const onSetPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleApplyFilter = (f: any) => {
    setCurrentPage(1);
    setFilters(f);
  };

  const handleRemoveFilter = (key: string) => {
    let newFilters = filters.filter((f: any) => f.key !== key);
    setFilters(newFilters);
    setCurrentPage(1);
    loadCoupons(newFilters, wordSearch);
  };

  const handleNew = () => {
    setSelectedItem({});
    setisEditModalVisible(true);
  };

  const handleCloseEditModal = () => {
    if (isEditModalVisible) {
      setData([]);
      setisEditModalVisible(false);
      loadCoupons(filters, wordSearch);
    }
  };

  const handleEdit = (id: string, processedData: any) => {
    let selectedItem = processedData.find((d: any) => d.id === id);
    setSelectedItem(selectedItem.coupon);
    setisEditModalVisible(true);
  };

  const handleCancel = async (id: string, processedData: any) => {
    setLoading(true);
    let coupon = processedData.find((d: any) => d.id === id);
    await cancelCoupon(coupon.code);
    loadCoupons(filters, wordSearch);
  };

  useEffect(() => {
    setTimer(
      setTimeout(function () {
        loadCoupons(filters, wordSearch);
      }, 500)
    );

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [loadCoupons, wordSearch, currentPage, limit, filters, sort]);

  // eslint-disable-next-line
  const handleSort = (field: string) => {
    let direction = "desc";
    if (!!sort.field && sort.field === field && sort.direction === "desc") {
      direction = "asc";
    }

    setSort({ field, direction });
  };

  return (
    <>
      {loading && <Loading />}
      <MainContainer>
        <PanelHeader
          title="Cupons"
          isNew={true}
          isFilterModalVisible={true}
          onClick={() => setisFilterModalVisible(true)}
          onClickNew={handleNew}
        />
        <Search
          wordSearch={wordSearch}
          setSearch={(event: string ) => {
            setCurrentPage(1)
            setWordSearch(event)
          }}
          isFilterForRegister={true}
          limit={limit}
          onSetPageSize={onSetPageSize}
          placeholder="Pesquisar por nome ou código"
        />
        {filters.length >= 1 && filters[0].key !== "created" && (
          <FilterList filters={filters} removeFilter={handleRemoveFilter} />
        )}
        <Table headers={headers} data={data} />
        <Pagination
          totalPage={totalPage}
          pageIndex={currentPage}
          setPage={setCurrentPage}
        />
      </MainContainer>
      <FilterModal
        visible={isFilterModalVisible}
        onCloseModal={() => setisFilterModalVisible(false)}
        applyFilters={handleApplyFilter}
        reciviedFilter={filters}
      ></FilterModal>
      <CouponModal
        visible={isEditModalVisible}
        reciviedCoupon={selectedItem}
        onCloseModal={() => handleCloseEditModal()}
      />
    </>
  );
};
export default Coupon;
