import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import {  LIST_SECTIONS_CEP_CATEGORIES, UPDATE_CEP } from '../graphql/CepsCategoriesQuery';
import {  ISectorCepsCategories, IUpdateSectionFormData } from '../constants/types';
import UpdateCepSectionForm, { UpdateCepSectionFormId } from '../forms/updateCep/updateSectionCepForm';
import { useDisclosure } from '@chakra-ui/react';


export type IUpdateCepModalProps = {
    isOpen: boolean;
    onClose: () => void;
    section: ISectorCepsCategories
};


const UpdateSectionModal: React.FC<IUpdateCepModalProps> = ({ isOpen, onClose, section }) => {

    const createRegionModalControl = useDisclosure();

    const [updateCep, { loading }] = useMutation(UPDATE_CEP, {
        refetchQueries: [
            LIST_SECTIONS_CEP_CATEGORIES,
            'ListCepsCategoriesSections'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: IUpdateSectionFormData,
            { reset }
        ) => {
            try {
                await updateCep({
                    variables: {
                        updateCepId: section.cep_id,
                        input: {
                            start_cep: payload.start_cep,
                            end_cep: payload.end_cep,
                            region_id: payload.region_id
                        }
                    },
                });
                reset();
                onClose();
                launchToast('Setor atualizado com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [updateCep],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Atualizar setor"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <Button text="Nova Região"  onClick={createRegionModalControl.onOpen} />
            </Styles.header>
            <Styles.FormDiv>
                <UpdateCepSectionForm onSubmit={handleSubmit} section={section} createRegionModalControl={createRegionModalControl}  />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={UpdateCepSectionFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default UpdateSectionModal