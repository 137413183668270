import { Divider, Heading, Stack } from "@chakra-ui/react";
import Modal from "components/Modal";
import { searchAppointments, searchOrder } from "pages/Appointments/graphQL";
import React, { useEffect, useState } from "react";
import {
  IFormattedAppointment,
  normalizeAppointments,
  normalizeSales,
} from "shared/utils/normalizeAppointments";
import Table from "../../../components/Table";
import { headers } from "../utils/columns";
import { appointmentsHeader, salesHeader } from "../constants/columns";
import { IFormattedOrder } from "shared/utils/formatOrder";
import IAppointment from "types/IAppointment";
import formatDate from "shared/utils/formatDate";
import convertToCurrencyFormat from "shared/utils/convertToCurrencyFormat";
import stringOfEnum from "shared/utils/StringOfEnum";
import AppointmentStatusEnum from "shared/enums/AppointmentStatusEnum";
import formatDateWithMiliseconds from "shared/utils/formatDateWithMiliseconds";
import { normalizePlatformEnum } from "shared/enums/PlatformEnum";

export type IOrderDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  order: IFormattedOrder;
};

type INormalizedSales = {
  name: string;
  quantity: number;
  price: string;
  totalPrice: string;
  address: string;
  cep: string;
};

const calcServiceValue = (appointment: IAppointment) => {
  const { service_price, appointments_items, price_fix } = appointment;
  const additionalsPrice = appointments_items.reduce(
    (sum: number, item: any) => sum + parseInt(item.item_price),
    0
  );

  const total =
    additionalsPrice * 100 +
    Number(service_price) +
    (price_fix >= 0 ? Number(price_fix) : 0);

  return total / 100;
};

function calculateAppointmentPaidPrice(
  appointment: IAppointment,
  totalAppointmentPrice: number
) {
  const needToCalculate =
    Number(appointment.order.discount_by_admin) > 0 &&
    appointment.service_price / 100 === Number(appointment.paid_price);

  if (needToCalculate) {
    const totalOrderPrice =
      Number(appointment.order.total_discount) +
      Number(appointment.order.paid_price);
    const discountPercentage =
      Number(appointment.order.total_discount) / totalOrderPrice;
    const discountPerAppointment = totalAppointmentPrice * discountPercentage;

    return totalAppointmentPrice - discountPerAppointment;
  } else {
    const servicePaidPrice = Number(appointment.paid_price);
    const priceFix = Number(appointment.price_fix / 100);
    const additionalsPaidPrice = appointment.appointments_items.reduce(
      (total, additional) => Number(total + Number(additional.paid_price)),
      0
    );

    return servicePaidPrice + priceFix + additionalsPaidPrice;
  }
}

function normalizeAppointment(appointment: IAppointment) {
  const appointmentTotalPrice = calcServiceValue(appointment);
  const appointmentPaidPrice = calculateAppointmentPaidPrice(
    appointment,
    appointmentTotalPrice
  );

  const discountValue = appointmentTotalPrice - appointmentPaidPrice;

  return {
    category: appointment.service.category,
    date_formated: formatDate(appointment.date),
    period: appointment.period,
    customer: appointment.customer.user.name,
    pet_name: appointment.pet.name,
    service_name: `${appointment.service.name} ${!!appointment.pack_frequency
      ? ` - Pacote ${appointment.pack_frequency.toLowerCase()}`
      : ""
      }`,
    professional: appointment.professional?.user.name || "Sem profissional",
    appointment_price_total: convertToCurrencyFormat(appointmentTotalPrice),
    appointment_discount: convertToCurrencyFormat(discountValue),
    appointment_price_paided: convertToCurrencyFormat(appointmentPaidPrice),
    status_description: stringOfEnum(AppointmentStatusEnum, appointment.status),
    created_at: formatDateWithMiliseconds(appointment.created_at),
    platform: !!appointment.platform
      ? normalizePlatformEnum(appointment.platform)
      : "--",
  };
}

function OrderDetailsModal({
  isOpen,
  onClose,
  order,
}: IOrderDetailsModalProps) {
  const [appointments, setAppointments] = useState<IFormattedAppointment[]>();
  const [sales, setSales] = useState<INormalizedSales[]>();

  const fetchAppointments = async () => {
    const responseAppintment = await searchAppointments({
      where: {
        order_id: order.id,
      },
    });

    const responseOrder = await searchOrder(order.id);

    if (responseAppintment) {
      const formattedAppointment = responseAppintment.appointments.map(
        (appointment) => {
          return normalizeAppointments(appointment);
        }
      );
      setAppointments(formattedAppointment);
    }

    if (responseOrder && responseOrder.sales_order) {
      const normalizedSales = normalizeSales(responseOrder.sales_order);
      setSales(normalizedSales);
    }
  };

  useEffect(() => {
    setSales([]);
    fetchAppointments();
  }, [order]);

  return (
    <Modal closeModal={onClose} visible={isOpen}>
      <Stack mt={4} width="100%" alignItems="center">
        <Heading>Pedido</Heading>
        <Table
          data={[order]}
          headers={headers.filter((header) => header.key !== "actions")}
        />
      </Stack>
      <Divider m={12} />
      {appointments && appointments?.length > 0 && (
        <Stack width="100%" alignItems="center">
          <Heading>Agendamentos</Heading>
          <Table data={appointments} headers={appointmentsHeader} />
        </Stack>
      )}

      {sales && sales?.length > 0 && (
        <Stack width="100%" alignItems="center">
          <Heading>Produtos</Heading>
          <Table data={sales} headers={salesHeader} />
        </Stack>
      )}
    </Modal>
  );
}

export default OrderDetailsModal;
