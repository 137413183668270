import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../../UserModal/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { gql, useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { GET_OBSERVATIONS } from '..';
import IUser from 'types/IUser';
import NewUserObservationForm, { INewUserObservationPayload, newCustomerObservationFormId } from './form/newUserObservationForm';


type INewObservationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    user: IUser
};

const NEW_USER_OBSERVATION = gql`
    mutation CreatePermanentObservation($input: CreatePermanentObservationInput!) {
    createPermanentObservation(input: $input) {
        petOrCustomerId
        id
        description
        added_by
        show_to_customer
        created_at
        updated_at
        deleted_at
        deleted_by
    }
    }
`;

const NewObservationModal: React.FC<INewObservationModalProps> = ({ isOpen, onClose, user}) => {

    const [createPermanentObservation, { loading }] = useMutation(NEW_USER_OBSERVATION, {
        refetchQueries: [
            {
                query: GET_OBSERVATIONS,
                variables: {
                    input: {
                        user_id: user.id
                    },
                },
            },
            'GetPermanetObservations'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: INewUserObservationPayload,
            { reset }
        ) => {
            try {
                await createPermanentObservation({
                    variables: {
                        input: payload
                    },
                });
                reset();
                onClose();
                launchToast('Observação criada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createPermanentObservation],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text={'Nova observação'}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <NewUserObservationForm onSubmit={handleSubmit}  userId={user.id}/>
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={newCustomerObservationFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default NewObservationModal