import React from 'react';
import * as Styles from './styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from 'components/Button';
import { launchToast } from 'shared/utils/launchToast';


interface ILinkModalProps {
    isOpen: boolean;
    onClose: () => void;
    link: string
  }

const LinkModal: React.FC<ILinkModalProps> = ({
    isOpen,
    onClose,
    link
  })  => {

    const handleCopyPayload = () => {
        navigator.clipboard.writeText(link);
        launchToast("Link copiado com sucesso", "success");
      };

    return (
        <Modal visible={isOpen} closeModal={onClose} width={850}>
            <Styles.header>
                <Text
                    text={'Link de pagamento'}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={24}
                    weight="600"
                />
            </Styles.header>
            <Styles.line></Styles.line>
            <Styles.TextResult onClick={() => handleCopyPayload()}>
                {link}
            </Styles.TextResult>
            <Styles.rowButton>
                <Button text="fechar"   onClick={onClose} />
                <Button text="Copiar"  onClick={() => { handleCopyPayload() }} />
            </Styles.rowButton>
        </Modal>
    )
}

export default LinkModal