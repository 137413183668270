export const walletBalanceColumns = [
    { key: "service", name: "Serviço" },
    { key: "amount", name: "Saldo" },
    { key: "actions", name: "ações" },
];

export const walletLogsColumns = [
    { key: "alteredBy", name: "Responsavel" },
    { key: "alteredReason", name: "Motivo" },
    { key: "categories", name: "serviços" },
    { key: "amount", name: "Quantidade" },
    { key: "type", name: "Alteção" },
    { key: "date", name: "Data" },
];