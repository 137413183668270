export const ordersErrorsColumnsHeader = [
  { key: "customer", name: "Cliente" },
  { key: "error", name: "Erro" },
  { key: "attemptDate", name: "Data da tentativa" },
  { key: "actions", name: "Ações" },
]

export type IOrderService = {
  id: string
  addressId: string
  petId: string
  date: string
  period: string
  additionalsId: string[]
  executionTime: number
}

export type ICreateOrderDto = {
  cart: {
    services: IOrderService[]
    packs: {
      id: string
      frequency: string
      autoRenew: boolean
      services: IOrderService[]
    }[]
    products: {
      addressId: string
      productList: {
        id: number
        quantity: number
      }[]
    }
  }
  paymentMethod: {
    type: number
    cardId: string | null
  }
  platform: number
  wallet: {
    id: number
    useIt: boolean
  },
  donated: number | null
  adminChange: {
    type: "increase" | "decrease"
    amount: number
    } | null
  canCreateOnSunday: boolean
}

export type IOrderErrorLog = {
  id: number
  type: string
  created_at: string
  customer_name: string
  customer_id: string
  error: string
  order_value: string
  dto: ICreateOrderDto
}

export type IOrderError = {
  listOrdersErrors: {
    logsList: IOrderErrorLog[]
    totalPages: number
  }
}
