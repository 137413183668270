import StatusTransactionEnum from "shared/enums/StatusTransactionEnum";
import TypePaymentEnum from "shared/enums/TypePaymentEnum";
import IOrder from "types/IOrder";
import convertToCurrencyFormat from "./convertToCurrencyFormat";
import { format, parseISO } from "date-fns";
import stringOfEnum from "./StringOfEnum";
import PlatformEnum from "shared/enums/PlatformEnum";
import { applyMask } from "./masks";

export type IFormattedOrder = {
  id: string;
  date: string;
  platform: string | null;
  customer: string;
  cpf: string;
  itens: number;
  paymentMethod: string;
  total: string;
  total_discount: string;
  finalOrderValue: string;
  status: string;
  order: IOrder;
}

export const formatOrder = (order: IOrder) => {
  const {
    id,
    created_at,
    platform,
    customer,
    appointments,
    transactions,
    status,
    total_discount,
  } = order;

  const { user } = customer;

  const pending_price = order.pending_price || 0;
  const paid_price = transactions.reduce(
    (total, transaction) =>
      total + (transaction.status !== "C" ? transaction.amount / 100 : 0),
    0
  );

  const originalPrice = Number(paid_price) + Number(pending_price);

  const total = convertToCurrencyFormat(originalPrice);
  const totalDiscount = convertToCurrencyFormat(Number(total_discount) <= 0 ? 0 : total_discount)
  const finalOrderValue = convertToCurrencyFormat(Number(originalPrice) + Number((total_discount <= 0 ? 0 : total_discount)))

  return {
    id,
    date: format(parseISO(created_at), "dd/MM/yyyy 'as' HH:mm:ss"),
    platform: stringOfEnum(PlatformEnum, platform),
    customer: user.name,
    cpf: applyMask(user.cpf, "cpf"),
    itens: appointments.length,
    paymentMethod:
      transactions.length > 0
        ? TypePaymentEnum[transactions[0].type]
        : "sem transação",
    total,
    total_discount: totalDiscount,
    finalOrderValue,
    status,
    order,
  };
};
