import React from 'react';
import * as Styles from './styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from 'components/Button';


interface IModalConfirmationProps {
    isOpen: boolean;
    onClose: () => void;
    handleConfirm: () => void;
    text: string;
    loading?: boolean;
  }

const ConfirmationModal: React.FC<IModalConfirmationProps> = ({
    isOpen,
    onClose,
    handleConfirm,
    text,
    loading
  })  => {

    return (
        <Modal visible={isOpen} closeModal={onClose} width={700}>
            <Styles.header>
                <Text
                    text={text}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={24}
                    weight="600"
                />
            </Styles.header>
            <Styles.rowButton>
                <Button text="Cancelar"   onClick={onClose} />
                <Button text="Confrimar"  onClick={() => { handleConfirm(); onClose() }} />
            </Styles.rowButton>
        </Modal>
    )
}

export default ConfirmationModal