import styled, { css } from "styled-components";

import colors from "../../shared/utils/constants/colors";

export const BackgroundPanel = styled.div`
  width: 100%;
`;

export const PanelHeader = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${colors.primary.default};
  color: ${colors.argon.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelItens = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
`;

export const Container = styled.div`
  background: ${colors.argon.white};
  border-radius: 5px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
  padding: 20px;
`;

export const Content = styled.div``;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.argon.textColorLight02};
  margin-bottom: 24px;
`;

export const Regions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  row-gap: 16px;
  column-gap: 16px;
  width: 100%;
  align-items: center;
  margin: auto;
`;

export const RegionCard = styled.button<{ status: boolean }>`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray.white};
  color: ${colors.gray.white};
  border: 1px solid ${colors.gray.dark02};
  padding: 8px;
  text-align: center;
  border-radius: 8px;

  transition: all 300ms ease;

  cursor: pointer;
  white-space: nowrap;

  ${(props) =>
    props.status &&
    css`
      background-color: ${colors.secondary.default};
      border: 1px solid ${colors.secondary.default};
    `}
  ${(props) =>
    !props.status &&
    css`
      background-color: ${colors.suport.alert};
      border: 1px solid ${colors.suport.alert};
    `}

  :hover {
    background-color: ${colors.gray.white};
    color: ${colors.gray.dark02};
  }
`;
