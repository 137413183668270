import { Stack } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import InputRef from 'components/InputRef';
import { Label } from 'components/Label/styles';
import Switch from 'components/Switch';
import React, { useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { IFilterFormData } from '../../constants/types';

export type IFilterFormProps = {
  onSubmit: (payload: IFilterFormData, { reset }: any) => void;
}

export const FilterFormId = 'filter-form';

function FilterForm({
  onSubmit,
}: IFilterFormProps) {
  const formRef = useRef<FormHandles>(null);

  const [startDateFilterValue, setStartDateFilterValue] = useState<string>('lte');
  const [endDateFilterValue, setEndDateFilterValue] = useState<string>('lte');
  
  const validateRadioInput = (
      payload: IFilterFormData,
      submitOptions: any
    ) => {
      onSubmit({
        ...payload,
        start_filter: startDateFilterValue,
        end_filter: endDateFilterValue,
      },
      submitOptions
      );
  }

  return (
    <Form onSubmit={validateRadioInput} ref={formRef} id={FilterFormId} >
      <Styles.row>
        <Styles.field>
          <Label>Título
            <InputRef
              name="title"
            />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Nome
            <InputRef
              name="name"
            />
          </Label>
        </Styles.field>
      </Styles.row>
      <Styles.row>
        <Styles.field>
          <Label>Subtítulo
            <InputRef
              name="subtitle"
            />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Conteúdo
            <InputRef
              name="content"
            />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Ativo</Label>
          <Switch
            name="is_active"
            options={[
              {value: 'Sim', label: 'Ativo'},
              {value: 'Não', label: 'Não ativo'},
            ]}
          />
        </Styles.field>
      </Styles.row>
      <Styles.row>
        <Stack>
          <Styles.fieldRadio>  
            <InputRef
              name="start_filter"
              type="radio"
              value="gte"
              onChange={(e) => setStartDateFilterValue(e.target.value)}
              style={{
                width: '20px',
              }}
              containerStyle={{
                width: '40px',
                justifyContent: 'center',
                boxShadow: 'none',
              }}
              />
            <Label>Maior ou igual a</Label>
          </Styles.fieldRadio>
          <Styles.fieldRadio>  
            <InputRef
              name="start_filter"
              type="radio"
              value="lte"
              onChange={(e) => setStartDateFilterValue(e.target.value)}
              style={{
                width: '20px',
              }}
              containerStyle={{
                width: '40px',
                justifyContent: 'center',
                boxShadow: 'none',
              }}
            />
            <Label>Menor ou igual a</Label>
          </Styles.fieldRadio>
        </Stack>
        <Styles.field>
          <Label>Data de início
            <InputRef
              name="event_start_date"
              type="date"
              containerStyle={{
                width: '250px'
              }}
            />
          </Label>
        </Styles.field>
        <Stack>
          <Styles.fieldRadio>
            <InputRef
              name="end_filter"
              type="radio"
              value="gte"
              onChange={(e) => setEndDateFilterValue(e.target.value)}
              style={{
                width: '20px',
              }}
              containerStyle={{
                width: '40px',
                justifyContent: 'center',
                boxShadow: 'none',
              }}
              />
            <Label>Maior ou igual a</Label>
          </Styles.fieldRadio>
          <Styles.fieldRadio>
            <InputRef
              name="end_filter"
              type="radio"
              value="lte"
              onChange={(e) => setEndDateFilterValue(e.target.value)}
              style={{
                width: '20px',
              }}
              containerStyle={{
                width: '40px',
                justifyContent: 'center',
                boxShadow: 'none',
              }}
              />
            <Label>Menor ou igual a</Label>
          </Styles.fieldRadio>
        </Stack>
        <Styles.field>
          <Label>Data do término
            <InputRef
              name="event_end_date"
              type="date"
              containerStyle={{
                width: '250px'
              }}
            />
          </Label>
        </Styles.field>
      </Styles.row>
    </Form>
  );
};

export default FilterForm;
