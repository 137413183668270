import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import Select from 'components/Select';
import { IAnamnesis, IAppoitnmentToAnamnesis, ICreateAnamnesisFormData} from 'pages/Anamnesis/constants/types';
import TextArea from 'components/TextArea';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import { listOptionAppointmentsToAnamnesisById } from 'pages/Anamnesis/graphql/AnamnesesQuery';

export type IUpdateAnamnesis = { onSubmit: (payload: ICreateAnamnesisFormData, { reset }: any) => Promise<void>, anamnesis: IAnamnesis}

type ISelectTypeBoolean = {
    value: boolean
    label: string
}

type ISelectTypeString = {
    value: string
    label: string
}

export const UpdateAnamnesisFormId = 'update-anamnesis-form';

const UpdateAnamnesisForm: React.FC<IUpdateAnamnesis> = ({
    onSubmit,
    anamnesis
}) => {

    const updateAnamnesisFormRef = useRef<FormHandles>(null);

    const [selectedAppointment, setSelectedAppointment] = useState<ISelectTypeString | null>(null)
    const [allergy, setAllergy] = useState<ISelectTypeBoolean | null>(null)
    const [feedType, setFeedtype] = useState<ISelectTypeString | null>(null)
    const [feedPrescribedByNutritionist, setFeedPrescribedByNutritionist] = useState<ISelectTypeBoolean | null>(null)
    const [respirationSecretion, setRespirationSecretion] = useState<ISelectTypeBoolean | null>(null)
    const [respirationCough, setRespirationCough] = useState<ISelectTypeBoolean | null>(null)
    const [respirationSneeze, setRespirationSneeze] = useState<ISelectTypeBoolean | null>(null)
    const [neurologicSyncope, setNeurologicSyncope] = useState<ISelectTypeBoolean | null>(null)
    const [heartCyanosis, setHeartCyanosis] = useState<ISelectTypeBoolean | null>(null)
    const [digestiveDepravedAppetite, setDigestiveDepravedAppetite] = useState<ISelectTypeBoolean | null>(null)
    const [digestiveVomit, setDigestiveVomit] = useState<ISelectTypeBoolean | null>(null)
    const [digestiveDiarrhea, setDigestiveDiarrhea] = useState<ISelectTypeBoolean | null>(null)
    const [locomotorTrauma, setLocomotorTrauma] = useState<ISelectTypeBoolean | null>(null)
    const [locomotorVolume, setLocomotorVolume] = useState<ISelectTypeBoolean | null>(null)
    const [locomotorImpotence, setLocomotorImpotence] = useState<ISelectTypeBoolean | null>(null)
    const [castrated, setCastrated] = useState<ISelectTypeBoolean | null>(null)
    const [eyesRed, setEyesRed] = useState<ISelectTypeBoolean | null>(null)
    const [eyesWhitish, setEyesWhitish] = useState<ISelectTypeBoolean | null>(null)
    const [eyesSecretion, setEyesSecretion] = useState<ISelectTypeBoolean | null>(null)
    const [eyesVisualDefict, setEyesVisualDefict] = useState<ISelectTypeBoolean | null>(null)
    const [heartExerciseIntolerance, setHeartExerciseIntolerance] = useState<ISelectTypeBoolean | null>(null)
    const [reproductiveSecretion, setReproductiveSecretion] = useState<ISelectTypeBoolean | null>(null)
    const [heartTiredness, setHeartTiredness] = useState<ISelectTypeBoolean | null>(null)
    const [reproductiveContraceptive, setReproductiveContraceptive] = useState<ISelectTypeBoolean | null>(null)
    const [reproductiveHeat, setReproductiveHeat] = useState<ISelectTypeBoolean | null>(null)
    const [locomotorLameness, setLocomotorLameness] = useState<ISelectTypeBoolean | null>(null)
    const [neurologicConculsion, setNeurologicConculsion] = useState<ISelectTypeBoolean | null>(null)
    const [neurologicDefict, setNeurologicDefict] = useState<ISelectTypeBoolean | null>(null)

    const booleanOptions = [
        {
            label: 'Sim',
            value: true
        },
        {
            label: 'Não',
            value: false
        }
    ]

    const normalizeLabel = (value: string) => {
        const normalizationMap: Record<string, string> = {
            "Alimentacao_natural": "Alimentação natural",
            "Racao_seca": "Ração seca",
            "Alimento_umido": "Alimento úmido",
        };
        return normalizationMap[value] || value;
    };

    const normalizeLabelBoolean = (value: boolean) => {
        return value ? "Sim" : "Não"
    };


    useEffect(() => {
        const getAppintmentId = async () => {
            const appointmentList = await listOptionAppointmentsToAnamnesisById(anamnesis.appointment_id)
            const appointment: IAppoitnmentToAnamnesis = appointmentList[0]
            setSelectedAppointment({label: `${appointment.date} - ${appointment.service.name} - ${appointment.professional.user.name}`, value: appointment.id })
            setAllergy({label: normalizeLabelBoolean(anamnesis.allergy) , value: anamnesis.allergy})
            setFeedtype({label: normalizeLabel(anamnesis.feed_type), value: anamnesis.feed_type})
            setFeedPrescribedByNutritionist({label: normalizeLabelBoolean(anamnesis.feed_prescribed_by_nutritionist) , value: anamnesis.feed_prescribed_by_nutritionist})
            setRespirationSecretion({label: normalizeLabelBoolean(anamnesis.respiration_secretion) , value: anamnesis.respiration_secretion})
            setRespirationCough({label: normalizeLabelBoolean(anamnesis.respiration_cough) , value: anamnesis.respiration_cough})
            setRespirationSneeze({label: normalizeLabelBoolean(anamnesis.respiration_sneeze) , value: anamnesis.respiration_sneeze})
            setNeurologicSyncope({label: normalizeLabelBoolean(anamnesis.neurologic_syncope) , value: anamnesis.neurologic_syncope})
            setHeartCyanosis({label: normalizeLabelBoolean(anamnesis.heart_cyanosis) , value: anamnesis.heart_cyanosis})
            setDigestiveDepravedAppetite({label: normalizeLabelBoolean(anamnesis.digestive_depraved_appetite) , value: anamnesis.digestive_depraved_appetite})
            setDigestiveVomit({label: normalizeLabelBoolean(anamnesis.digestive_vomit) , value: anamnesis.digestive_vomit})
            setDigestiveDiarrhea({label: normalizeLabelBoolean(anamnesis.digestive_diarrhea) , value: anamnesis.digestive_diarrhea})
            setLocomotorTrauma({label: normalizeLabelBoolean(anamnesis.locomotor_trauma) , value: anamnesis.locomotor_trauma})
            setLocomotorVolume({label: normalizeLabelBoolean(anamnesis.locomotor_volume) , value: anamnesis.locomotor_volume})
            setLocomotorImpotence({label: normalizeLabelBoolean(anamnesis.locomotor_impotence) , value: anamnesis.locomotor_impotence})
            setCastrated({label:normalizeLabelBoolean(anamnesis.castrated) , value: anamnesis.castrated})
            setEyesRed({label: normalizeLabelBoolean(anamnesis.eyes_red) , value: anamnesis.eyes_red})
            setEyesWhitish({label: normalizeLabelBoolean(anamnesis.eyes_whitish) , value: anamnesis.eyes_whitish})
            setEyesSecretion({label: normalizeLabelBoolean(anamnesis.eyes_secretion) , value: anamnesis.eyes_secretion})
            setEyesVisualDefict({label: normalizeLabelBoolean(anamnesis.eyes_visual_deficit) , value: anamnesis.eyes_visual_deficit})
            setHeartExerciseIntolerance({label: normalizeLabelBoolean(anamnesis.heart_exercise_intolerance) , value: anamnesis.heart_exercise_intolerance})
            setReproductiveSecretion({label: normalizeLabelBoolean(anamnesis.reproductive_secretion) , value: anamnesis.reproductive_secretion})
            setHeartTiredness({label: normalizeLabelBoolean(anamnesis.heart_tiredness) , value: anamnesis.heart_tiredness})
            setReproductiveContraceptive({label: normalizeLabelBoolean(anamnesis.reproductive_contraceptive) , value: anamnesis.reproductive_contraceptive})
            setReproductiveHeat({label: normalizeLabelBoolean(anamnesis.reproductive_heat) , value: anamnesis.reproductive_heat})
            setLocomotorLameness({label:normalizeLabelBoolean(anamnesis.locomotor_lameness) , value: anamnesis.locomotor_lameness})
            setNeurologicConculsion({label: normalizeLabelBoolean(anamnesis.neurologic_convulsion) , value: anamnesis.neurologic_convulsion})
            setNeurologicDefict({label: normalizeLabelBoolean(anamnesis.neurologic_deficit) , value: anamnesis.neurologic_deficit})
            updateAnamnesisFormRef.current?.setErrors({});
            updateAnamnesisFormRef.current?.setData({
                comments: anamnesis.comments,
                avaliation: anamnesis.avaliation,
                temperament: anamnesis.temperament,
                castrated: {label: anamnesis.castrated ? "Sim" : 'Não', value: anamnesis.castrated},
                allergy_detail: anamnesis.allergy_detail,
                feed: anamnesis.feed,
                feed_diary: anamnesis.feed_diary,
                feed_prescribed_by_nutritionist_detail: anamnesis.feed_prescribed_by_nutritionist_detail,
                skin_hygiene: anamnesis.skin_hygiene,
                skin_secretion: anamnesis.skin_secretion,
                skin_peeling: anamnesis.skin_peeling,
                skin_itching: anamnesis.skin_itching,
                ears_canals: anamnesis.ears_canals,
                eyes_red: anamnesis.eyes_red,
                eyes_whitish: anamnesis.eyes_whitish,
                eyes_secretion: anamnesis.eyes_secretion,
                eyes_visual_deficit: anamnesis.eyes_visual_deficit,
                respiration_cough_detail: anamnesis.respiration_cough_detail,
                respiration_sneeze_detail: anamnesis.respiration_sneeze_detail,
                respiration_secretion_detail: anamnesis.respiration_secretion_detail,
                respiration_noise: anamnesis.respiration_noise,
                heart_exercise_intolerance: anamnesis.heart_exercise_intolerance,
                heart_tiredness: anamnesis.heart_tiredness,
                syncope_details: anamnesis.syncope_details,
                heart_cyanosis_detail: anamnesis.heart_cyanosis_detail,
                digestive_appetite: anamnesis.digestive_appetite,
                digestive_depraved_appetite_detail: anamnesis.digestive_depraved_appetite_detail,
                digestive_vomit_detail: anamnesis.digestive_vomit_detail,
                digestive_diarrhea_detail: anamnesis.digestive_diarrhea_detail,
                urinary_water: anamnesis.urinary_water,
                urinary_aspect: anamnesis.urinary_aspect,
                urinary_frequency: anamnesis.urinary_frequency,
                urinary_volume: anamnesis.urinary_volume,
                reproductive_secretion: anamnesis.reproductive_secretion,
                reproductive_heat: anamnesis.reproductive_heat,
                reproductive_contraceptive: anamnesis.reproductive_contraceptive,
                reproductive_puppies: anamnesis.reproductive_puppies,
                locomotor_lameness: anamnesis.locomotor_lameness,
                locomotor_trauma_detail: anamnesis.locomotor_trauma_detail,
                locomotor_volume_detail: anamnesis.locomotor_volume_detail,
                locomotor_impotence_detail: anamnesis.locomotor_impotence_detail,
                neurologic_convulsion: anamnesis.neurologic_convulsion,
                neurologic_deficit: anamnesis.neurologic_deficit,
                breath_rate: anamnesis.breath_rate,
                heart_rate: anamnesis.heart_rate,
                ecc_condition_score: anamnesis.ecc_condition_score,
                muscle_mass_score: anamnesis.muscle_mass_score,
                hydration: anamnesis.hydration,
                temperature: anamnesis.temperature,
                lymph_nodes: anamnesis.lymph_nodes,
                dentition: anamnesis.dentition,
                customer_orientations: anamnesis.customer_orientations,
            });

        }
        getAppintmentId()
        
    }, [anamnesis])

    const validateFields = async (
        payload: ICreateAnamnesisFormData,
        options: { reset: () => void }
    ) => {
        try {
            onSubmit(
                { 
                    pet_id: anamnesis.pet_id,
                    comments: payload.comments || null,
                    avaliation: payload.avaliation || null,
                    temperament: payload.temperament || null,
                    castrated: payload.castrated || false,
                    allergy: payload?.allergy || false,
                    allergy_detail: payload.allergy_detail || null,
                    feed_type: payload.feed_type || null,
                    feed: payload.feed || null,
                    feed_diary: payload.feed_diary || null,
                    feed_prescribed_by_nutritionist: payload.feed_prescribed_by_nutritionist || false,
                    feed_prescribed_by_nutritionist_detail: payload.feed_prescribed_by_nutritionist_detail || null,
                    skin_hygiene: payload.skin_hygiene || null,
                    skin_secretion: payload.skin_secretion || null,
                    skin_peeling: payload.skin_peeling || null,
                    skin_itching: payload.skin_itching || null,
                    ears_canals: payload.ears_canals || null,
                    eyes_red: payload.eyes_red || false,
                    eyes_whitish: payload.eyes_whitish || false,
                    eyes_secretion: payload.eyes_secretion || false,
                    eyes_visual_deficit: payload.eyes_visual_deficit || false,
                    respiration_cough: respirationCough?.value || false,
                    respiration_sneeze: respirationSneeze?.value  || false,
                    respiration_cough_detail: payload.respiration_cough_detail || null,
                    respiration_sneeze_detail: payload.respiration_sneeze_detail || null,
                    respiration_secretion: respirationSecretion?.value  || false,
                    respiration_secretion_detail: payload.respiration_secretion_detail || null,
                    respiration_noise: payload.respiration_noise || false,
                    heart_exercise_intolerance: payload.heart_exercise_intolerance || false,
                    heart_tiredness: payload.heart_tiredness || false,
                    syncope_details: payload.syncope_details || null,
                    heart_cyanosis: heartCyanosis?.value  || false,
                    heart_cyanosis_detail: payload.heart_cyanosis_detail || null,
                    digestive_appetite: payload.digestive_appetite || null,
                    digestive_depraved_appetite: digestiveDepravedAppetite?.value  || false,
                    digestive_depraved_appetite_detail: payload.digestive_depraved_appetite_detail || null,
                    digestive_vomit: digestiveVomit?.value  || false,
                    digestive_vomit_detail: payload.digestive_vomit_detail || null,
                    digestive_diarrhea: digestiveDiarrhea?.value  || false,
                    digestive_diarrhea_detail: payload.digestive_diarrhea_detail || null,
                    urinary_water: payload.urinary_water || null,
                    urinary_aspect: payload.urinary_aspect || null,
                    urinary_frequency: payload.urinary_frequency || null,
                    urinary_volume: payload.urinary_volume || null,
                    reproductive_secretion: payload.reproductive_secretion || false,
                    reproductive_heat: payload.reproductive_heat || false,
                    reproductive_contraceptive: payload.reproductive_contraceptive || false,
                    reproductive_puppies: payload.reproductive_puppies || null,
                    locomotor_lameness: payload.locomotor_lameness || false,
                    locomotor_trauma: locomotorTrauma?.value || false,
                    locomotor_trauma_detail: payload.locomotor_trauma_detail || null,
                    locomotor_volume: payload.locomotor_volume || false,
                    locomotor_volume_detail: payload.locomotor_volume_detail || null,
                    locomotor_impotence: locomotorImpotence?.value || false,
                    locomotor_impotence_detail: payload.locomotor_impotence_detail || null,
                    neurologic_convulsion: payload.neurologic_convulsion || false,
                    neurologic_syncope: neurologicSyncope?.value || false,
                    neurologic_deficit: payload.neurologic_deficit || false,
                    appointment_id: anamnesis.appointment_id,
                    breath_rate: payload.breath_rate || null,
                    heart_rate: payload.heart_rate || null,
                    ecc_condition_score: payload.ecc_condition_score || null,
                    muscle_mass_score: payload.muscle_mass_score || null,
                    hydration: payload.hydration || null,
                    temperature: payload.temperature || null,
                    lymph_nodes: payload.lymph_nodes || null,
                    dentition: payload.dentition || null,
                    customer_orientations: payload.customer_orientations || null
                }, 
                options);
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            updateAnamnesisFormRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form onSubmit={validateFields} ref={updateAnamnesisFormRef} id={UpdateAnamnesisFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Pet</Label>
                        <Select
                            name="pet_id"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione um pet"
                            value={{label: anamnesis.pet.name, value: anamnesis.pet.id}}
                            isDisabled
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Agendamento</Label>
                        <Select
                            name="appointment_id"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione um agendamento"
                            value={selectedAppointment}
                            isDisabled
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Geral"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Observações da consulta</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="comments"
                            placeholder="Relate as suas observações sobre o atendimento"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Queixa principal</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="avaliation"
                            placeholder="Quadro, tratamentos, resultados"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Castrado</Label>
                        <Select
                            name="castrated"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={castrated}
                            onChange={(e: any) => setCastrated(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Temperamento</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="temperament"
                            placeholder="Digite sobre o temperamento do pet"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Alergia</Label>
                        <Select
                            name="allergy"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={allergy}
                            onChange={(e: any) => setAllergy(e)}
                        />
                    </Styles.field>
                    { allergy?.value && <Styles.field>
                        <Label>Sobre a alergia</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="allergy_detail"
                            placeholder="Descrever a alergia"
                        />
                    </Styles.field> }
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Alimentação"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Alimento utilizado</Label>
                        <Select
                            name="feed_type"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={[
                                {label: 'Alimentação natural', value: 'Alimentacao_natural'},
                                {label:'Ração seca', value: 'Racao_seca'},
                                {label: 'Alimento úmido', value: 'Alimento_umido'}
                              ]}
                            value={feedType}
                            onChange={(e: any) => setFeedtype(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Marca</Label>
                        <InputRef
                            name="feed"
                            type='feed'
                            placeholder="Digite a marca/tipo"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Quantidade em gramas</Label>
                        <InputRef
                            name="feed_diary"
                            type='number'
                            placeholder="Digite a quantidade"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                { feedType?.value === 'Alimentacao_natural' && <Styles.row>
                    <Styles.field>
                        <Label>Alimentação prescrita por nutrólogo</Label>
                        <Select
                            name="feed_prescribed_by_nutritionist"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={feedPrescribedByNutritionist}
                            onChange={(e: any) => setFeedPrescribedByNutritionist(e)}
                        />
                    </Styles.field>
                  { feedPrescribedByNutritionist?.value && <Styles.field>
                        <Label>Alimentação prescrita por nutrólogo</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="feed_prescribed_by_nutritionist_detail"
                            placeholder="Descrever a alimentação"
                        />
                    </Styles.field>}
                </Styles.row>}
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Pele"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Higiene</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="skin_hygiene"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Secreção</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="skin_secretion"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Descamação</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="skin_peeling"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Prurido (coceira)</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="skin_itching"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Orelhas"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Condutos auditivos</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="ears_canals"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Olhos"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Olhos vermelhos</Label>
                        <Select
                            name="eyes_red"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={eyesRed}
                            onChange={(e: any) => setEyesRed(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Olhos esbranquiçados</Label>
                        <Select
                            name="eyes_whitish"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={eyesWhitish}
                            onChange={(e: any) => setEyesWhitish(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Secreção</Label>
                        <Select
                            name="eyes_secretion"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={eyesSecretion}
                            onChange={(e: any) => setEyesSecretion(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Déficit Visual</Label>
                        <Select
                            name="eyes_visual_deficit"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={eyesVisualDefict}
                            onChange={(e: any) => setEyesVisualDefict(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema respiratório"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Secreção</Label>
                        <Select
                            name="respiration_secretion"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={respirationSecretion}
                            onChange={(e: any) => setRespirationSecretion(e)}
                        />
                    </Styles.field>
                { respirationSecretion?.value && <Styles.field>
                        <Label>Sobre a secreção</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="respiration_secretion_detail"
                            placeholder="Descrever a secreção"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Tosse</Label>
                        <Select
                            name="respiration_cough"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={respirationCough}
                            onChange={(e: any) => setRespirationCough(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    { respirationCough?.value && <Styles.field>
                        <Label>Sobre a tosse</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="respiration_cough_detail"
                            placeholder="Descrever a tosse"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Espirro</Label>
                        <Select
                            name="respiration_sneeze"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={respirationSneeze}
                            onChange={(e: any) => setRespirationSneeze(e)}
                        />
                    </Styles.field>
                    { respirationSneeze?.value && <Styles.field>
                        <Label>Sobre os espirros</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="respiration_sneeze_detail"
                            placeholder="Descrever o espirro"
                        />
                    </Styles.field>}
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Ruído</Label>
                        <Select
                            name="respiration_noise"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={respirationCough}
                            onChange={(e: any) => setRespirationCough(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Cardiovascular"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Intolerância a exercícios</Label>
                        <Select
                            name="heart_exercise_intolerance"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={heartExerciseIntolerance}
                            onChange={(e: any) => setHeartExerciseIntolerance(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Cansaço</Label>
                        <Select
                            name="heart_tiredness"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={heartTiredness}
                            onChange={(e: any) => setHeartTiredness(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Síncope</Label>
                        <Select
                            name="neurologic_syncope"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={neurologicSyncope}
                            onChange={(e: any) => setNeurologicSyncope(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    { neurologicSyncope?.value && <Styles.field>
                        <Label>Sobre a síncope</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="syncope_details"
                            placeholder="Descrever a sincope"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Cianose</Label>
                        <Select
                            name="heart_cyanosis"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={heartCyanosis}
                            onChange={(e: any) => setHeartCyanosis(e)}
                        />
                    </Styles.field>
                   { heartCyanosis?.value && <Styles.field>
                        <Label>Sobre a cianose</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="heart_cyanosis_detail"
                            placeholder="Descrever a cianose"
                        />
                    </Styles.field>}
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Digestório"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Apetite</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="digestive_appetite"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Apetite depravado</Label>
                        <Select
                            name="digestive_depraved_appetite"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={digestiveDepravedAppetite}
                            onChange={(e: any) => setDigestiveDepravedAppetite(e)}
                        />
                    </Styles.field>
                    { digestiveDepravedAppetite?.value && <Styles.field>
                        <Label>Sobreo apetite depravado</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="digestive_depraved_appetite_detail"
                            placeholder="Descrever o apetite depravado"
                        />
                    </Styles.field>}
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Vômito</Label>
                        <Select
                            name="digestive_vomit"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={digestiveVomit}
                            onChange={(e: any) => setDigestiveVomit(e)}
                        />
                    </Styles.field>
                    { digestiveVomit?.value && <Styles.field>
                        <Label>Sobre os vômitos</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="digestive_vomit_detail"
                            placeholder="Descrever o vômito"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Diarréia</Label>
                        <Select
                            name="digestive_diarrhea"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={digestiveDiarrhea}
                            onChange={(e: any) => setDigestiveDiarrhea(e)}
                        />
                    </Styles.field>
                </Styles.row>
                {digestiveDiarrhea?.value  &&  <Styles.row>
                    <Styles.field>
                        <Label>Sobre a diarréia</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="digestive_diarrhea_detail"
                            placeholder="Descrever o diarréia"
                        />
                    </Styles.field>
                </Styles.row>}
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Urinário"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Ingestão de água</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="urinary_water"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Aspecto</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="urinary_aspect"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Volume</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="urinary_volume"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Frequência</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="urinary_frequency"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Reprodutor"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Secreção</Label>
                        <Select
                            name="reproductive_secretion"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={reproductiveSecretion}
                            onChange={(e: any) => setReproductiveSecretion(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Cio</Label>
                        <Select
                            name="reproductive_heat"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={reproductiveHeat}
                            onChange={(e: any) => setReproductiveHeat(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Anticoncepcional</Label>
                        <Select
                            name="reproductive_contraceptive"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={reproductiveContraceptive}
                            onChange={(e: any) => setReproductiveContraceptive(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Número de filhotes</Label>
                        <InputRef
                            name="reproductive_puppies"
                            type='number'
                            placeholder="Digite a quantidade"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Ortopédico"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Claudicação</Label>
                        <Select
                            name="locomotor_lameness"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={locomotorLameness}
                            onChange={(e: any) => setLocomotorLameness(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Trauma</Label>
                        <Select
                            name="locomotor_trauma"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={locomotorTrauma}
                            onChange={(e: any) => setLocomotorTrauma(e)}
                        />
                    </Styles.field>
                    { locomotorTrauma?.value && <Styles.field>
                        <Label>Sobre traumas</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="locomotor_trauma_detail"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>}
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Aumento de volume</Label>
                        <Select
                            name="locomotor_volume"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={locomotorVolume}
                            onChange={(e: any) => setLocomotorVolume(e)}
                        />
                    </Styles.field>
                    { locomotorVolume?.value && <Styles.field>
                        <Label>Sobre o aumento de volume</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="locomotor_volume_detail"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Impotência de membro</Label>
                        <Select
                            name="locomotor_impotence"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={locomotorImpotence}
                            onChange={(e: any) => setLocomotorImpotence(e)}
                        />
                    </Styles.field>
                </Styles.row>
                { locomotorImpotence?.value && <Styles.row>
                    <Styles.field>
                        <Label>Sobre a impotência de membro</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="locomotor_impotence_detail"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>}
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Neurológico"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Convulsão</Label>
                        <Select
                            name="neurologic_convulsion"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={neurologicConculsion}
                            onChange={(e: any) => setNeurologicConculsion(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Déficit neurológico</Label>
                        <Select
                            name="neurologic_deficit"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={neurologicDefict}
                            onChange={(e: any) => setNeurologicDefict(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Exame Físico"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Frequência respiratória</Label>
                        <InputRef
                            name="breath_rate"
                            placeholder="Digite a frequência respiratória"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Frequência cardiaca</Label>
                        <InputRef
                            name="heart_rate"
                            placeholder="Digite a frequência cardiaca"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Escore de condição corporal (ECC)</Label>
                        <InputRef
                            name="ecc_condition_score"
                            placeholder="Digite o escore"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Escore de massa muscular</Label>
                        <InputRef
                            name="muscle_mass_score"
                            placeholder="Digite o escore"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Hidratação</Label>
                        <InputRef
                            name="hydration"
                            placeholder="Descreva sobre a hidratação"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Temperatura em graus C</Label>
                        <InputRef
                            name="temperature"
                            placeholder="Digite a temperatura"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Linfonos</Label>
                        <InputRef
                            name="lymph_nodes"
                            placeholder="Descreva sobre"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Dentição</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="dentition"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Mais detalhes"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Orientações para o cliente / Tratamento</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="customer_orientations"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default UpdateAnamnesisForm