import React, { FC, useState, useEffect } from "react";
import { loadPartnerById, loadUsersNotAssociated } from "../graphQL";
import IPartner from "types/IPartner";
import { toast } from "react-toastify";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Text,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { Editar } from "./Editar";
import { AddPeople } from "./AddPeople";
import { Discount } from "./Discount";
import IUser from "types/IUser";
import Loading from "components/Loading";
import { debounce } from "lodash";
import { format } from "date-fns";
import {
  AiOutlineUsergroupAdd,
  AiOutlineEdit,
  AiFillCreditCard,
} from "react-icons/ai";
import { BsFillPersonFill, BsFillGiftFill } from "react-icons/bs";
import { DiscountsDropdown } from "./DiscountsDropdown";
import { CgDetailsMore } from "react-icons/cg";
import { Payment } from "./Payment";

interface IPartnerModalProps {
  id: string;
  onClose: () => void;
}

export const PartnerModal: FC<IPartnerModalProps> = ({ id, onClose }) => {
  const [partner, setPartner] = useState<IPartner>({} as IPartner);
  const [partnerUsers, setPartnerUsers] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notAssociatedUsers, setNotAssociatedUsers] = useState<IUser[]>([]);
  const [search, setSearch] = useState<string>("");
  const [discounts, setDiscounts] = useState<any[]>([]);
  const [cards, setCards] = useState<any>([]);

  const partnerLoad = async () => {
    try {
      setIsLoading(true);
      const results = await loadPartnerById(id);
      setPartner(results);
      setPartnerUsers(results.users);
      setDiscounts(results.discounts);
      const ListUsersNotAssociated = await loadUsersNotAssociated(id, search);
      setNotAssociatedUsers(ListUsersNotAssociated);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao carregar parceiro!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search.trim() !== "") {
      const debounceSearch = debounce(() => {
        partnerLoad();
      }, 500);

      debounceSearch();

      return () => {
        debounceSearch.cancel();
      };
    } else {
      partnerLoad();
    }
    // eslint-disable-next-line
  }, [search]);

  return (
    <Box width="800px" height="600px">
      {isLoading && <Loading />}
      <Tabs isLazy>
        <TabList>
          <Tab
            fontSize="2xl"
            _selected={{
              color: "green.600",
              bg: "green.50",
              borderRadius: "0.5rem 0.5rem 0 0",
              borderBottom: "2px solid green",
            }}
          >
            <CgDetailsMore size={18} style={{ marginRight: "0.5rem" }} />
            Detalhes
          </Tab>
          <Tab
            fontSize="2xl"
            _selected={{
              color: "green.600",
              bg: "green.50",
              borderRadius: "0.5rem 0.5rem 0 0",
              borderBottom: "2px solid green",
            }}
          >
            <AiOutlineEdit size={18} style={{ marginRight: "0.5rem" }} />
            Editar parceiro
          </Tab>
          <Tab
            fontSize="2xl"
            _selected={{
              color: "green.600",
              bg: "green.50",
              borderRadius: "0.5rem 0.5rem 0 0",
              borderBottom: "2px solid green",
            }}
          >
            <BsFillGiftFill size={16} style={{ marginRight: "0.5rem" }} />
            Adicionar descontos
          </Tab>
          <Tab
            fontSize="2xl"
            _selected={{
              color: "green.600",
              bg: "green.50",
              borderRadius: "0.5rem 0.5rem 0 0",
              borderBottom: "2px solid green",
            }}
          >
            <AiOutlineUsergroupAdd
              size={18}
              style={{ marginRight: "0.5rem" }}
            />
            Adicionar pessoas
          </Tab>
          <Tab
            fontSize="2xl"
            _selected={{
              color: "green.600",
              bg: "green.50",
              borderRadius: "0.5rem 0.5rem 0 0",
              borderBottom: "2px solid green",
            }}
          >
            <AiFillCreditCard size={18} style={{ marginRight: "0.5rem" }} />
            Forma de pagamento
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box
              marginBottom="1rem"
              w={"100%"}
              boxShadow={"md"}
              rounded={"md"}
              overflow={"hidden"}
              bg={"white"}
              padding="1rem"
              mt={"20px"}
            >
              <Text
                fontSize="2xl"
                marginBottom="0.5rem"
                borderBottom="1px solid #f5f5f5"
                bg="gray.50"
                padding="0.5rem"
              >
                Detalhes do parceiro:
              </Text>
              <Box padding={"1rem"}>
                <Text fontSize="2xl" marginBottom="1rem">
                  <Text as="span" fontSize="2xl" fontWeight="bold">
                    Nome:
                  </Text>{" "}
                  {partner.name}
                </Text>
                <Text fontSize="2xl" marginBottom="1rem">
                  <Text as="span" fontSize="2xl" fontWeight="bold">
                    Data de início:
                  </Text>{" "}
                  {partner.partnership_start
                    ? format(new Date(partner.partnership_start), "dd/MM/yyyy")
                    : "Não informado"}
                </Text>
                <Text fontSize="2xl">
                  <Text as="span" fontSize="2xl" fontWeight="bold">
                    Data de término:
                  </Text>{" "}
                  {partner.partnership_end
                    ? format(new Date(partner.partnership_end), "dd/MM/yyyy")
                    : "Não informado"}
                </Text>
              </Box>
            </Box>
            <Box
              marginBottom="1rem"
              w={"100%"}
              boxShadow={"md"}
              rounded={"md"}
              overflow={"hidden"}
              bg={"white"}
              padding="1rem"
            >
              <Text
                fontSize="2xl"
                marginBottom="0.5rem"
                borderBottom="1px solid #f5f5f5"
                bg="gray.50"
                padding="0.5rem"
              >
                {partnerUsers.length > 0
                  ? "Pessoas associadas:"
                  : "Nenhuma pessoa associada"}
              </Text>
              <HStack spacing="1rem" flexWrap="wrap" padding="1rem">
                {partnerUsers.map((user) => (
                  <Flex alignItems="center" key={user.id}>
                    <BsFillPersonFill
                      size={20}
                      style={{ marginRight: "0.5rem", color: "green" }}
                    />
                    <Text fontSize="2xl">{user.name}</Text>
                  </Flex>
                ))}
              </HStack>
            </Box>
            <Box
              marginBottom="1rem"
              w={"100%"}
              boxShadow={"md"}
              rounded={"md"}
              bg={"white"}
              padding="1rem"
            >
              <DiscountsDropdown
                discounts={discounts}
                setDiscounts={setDiscounts}
                isOpenDefault={true}
                isNotEditable={true}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Editar onClose={onClose} partner={partner} />
          </TabPanel>
          <TabPanel>
            <Discount
              partnerId={id}
              discounts={discounts}
              setDiscounts={setDiscounts}
              partnerLoad={partnerLoad}
            />
          </TabPanel>
          <TabPanel>
            <AddPeople
              usersFromPartner={partnerUsers}
              setUsersFromPartner={setPartnerUsers}
              partnerId={id}
              notAssociatedUsers={notAssociatedUsers}
              setSearch={setSearch}
              search={search}
            />
          </TabPanel>
          <TabPanel>
            <Payment partnerId={id} cards={cards} setCards={setCards} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
