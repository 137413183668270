import { useQuery } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';
import Loading from 'components/Loading';
import MainContainer from 'components/MainContainer';
import Pagination from 'components/Pagination';
import { PanelHeader } from "components/PanelHeader";
import Table from "components/Table";
import Text from "components/Text";
import { format, parseISO } from 'date-fns';
import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from "../../../assets/svg/generic/searchicon.svg";
import OrderErrorDetailsModal from '../modals/OrderErrorDetails';
import { GET_ORDERS_ERRORS } from './GraphQL';
import { IOrderError, IOrderErrorLog, ordersErrorsColumnsHeader } from './types';

const OrdersErrors: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginationLimit] = useState<number>(10);
  const [selectedLog, setSelectedLog] = useState<IOrderErrorLog | undefined>(undefined);

  const OrderErrorDetailsModalControl = useDisclosure();

  const { data, loading, error } = useQuery<IOrderError>(GET_ORDERS_ERRORS, {
    variables: {
      pagination: {
        page: currentPage,
        limit: paginationLimit
      }
    }
  });

  const handleSeeOrderErrorDetail = (log: IOrderErrorLog) => {
    setSelectedLog(log);
    OrderErrorDetailsModalControl.onOpen();
  }

  return (
    <>
      {selectedLog && (
        <OrderErrorDetailsModal
          isOpen={OrderErrorDetailsModalControl.isOpen}
          onClose={OrderErrorDetailsModalControl.onClose}
          orderError={selectedLog}
          key={selectedLog.id}
        />
      )}
      <MainContainer>
        <PanelHeader
          title="Pedidos não finalizados"
          isFilterModalVisible={false}
        />
          {loading && <Loading />}
          {data && (
            <>
              <Table
                headers={ordersErrorsColumnsHeader}
                data={data?.listOrdersErrors.logsList.map((log) => ({
                  customer: log.customer_name,
                  error: log.error === 'unableToPay' ? 'Erro de pagamento' : log.error,
                  attemptDate: format(parseISO(log.created_at), "dd/MM/yyyy - HH:mm"),
                  actions: [
                    {
                      name: "Ver detalhes",
                      icon: <SearchIcon />,
                      action: () => handleSeeOrderErrorDetail(log),
                    }
                  ]
                }))}
              />
              <Pagination
                totalPage={data?.listOrdersErrors.totalPages}
                pageIndex={currentPage}
                setPage={setCurrentPage}
              />
            </>
          )}
          {error && <Text text={error.message} />}
      </MainContainer>
    </>
  );
}

export default OrdersErrors;
