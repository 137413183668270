const formatDateWithMiliseconds = (dateString: string) => {
  try {
    if (!dateString) {
      return '';
    }

    const dateObj = new Date(dateString);

    if (isNaN(dateObj.getTime())) {
      return '';
    }

    const pad = (num: number) => (num < 10 ? "0" + num : num);

    const day = pad(dateObj.getDate());
    const month = pad(dateObj.getMonth() + 1);
    const year = dateObj.getFullYear();
    const hours = pad(dateObj.getHours());
    const minutes = pad(dateObj.getMinutes());
    const seconds = pad(dateObj.getSeconds());
    const milliseconds = dateObj.getMilliseconds().toString().padStart(3, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}.${milliseconds}`;
  } catch (e: unknown) {
    return "";
  }
};

export default formatDateWithMiliseconds;
