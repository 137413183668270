import React, { useState, useCallback, useEffect } from "react";
import * as Styles from "./styles";
import { IconContext } from "react-icons/lib";
import Modal from "../../Modal";
import Option from "./OptionComponent";
import { ReactComponent as Logo } from "../../../assets/svg/sidebar/logo.svg";
import { ReactComponent as CloseButton } from "../../../assets/svg/generic/closebutton.svg";
import { ReactComponent as SearchIcon } from "../../../assets/svg/generic/searchicon.svg";
import { Logout } from "../Logout";

const Sidebar: React.FC = ({ children }) => {
  const [sidebar, setSidebar] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  const showSidebar = useCallback(() => {
    setSidebar(!sidebar);
  }, [sidebar]);

  const handleOpenModal = useCallback(() => {
    setVisible(true);
  }, []);
  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, [currentUrl]);

  return (
    <>
      <IconContext.Provider value={{ color: "#0B261D" }}>
        <Styles.ContainerWeb>
          <Styles.Nav opened={sidebar}>
            <Styles.Header opened={sidebar}>
              <Styles.IconZazuu opened={sidebar}>
                <Logo />
              </Styles.IconZazuu>
              <Styles.NavIcon onClick={showSidebar} opened={sidebar}>
                <Styles.HamburgerDiv>
                  <Styles.HamburgerBarVar opened={sidebar} />
                  <Styles.HamburgerBarFix />
                  <Styles.HamburgerBarVar opened={sidebar} />
                </Styles.HamburgerDiv>
              </Styles.NavIcon>
            </Styles.Header>
            <Styles.Content>
              <Option opened={sidebar} />
            </Styles.Content>
            <Logout opened={sidebar} />
          </Styles.Nav>
        </Styles.ContainerWeb>

        <Styles.ContainerMobile>
          <Styles.HamburgerDiv onClick={handleOpenModal}>
            <Styles.HamburgerBarVar />
            <Styles.HamburgerBarFix />
            <Styles.HamburgerBarVar />
          </Styles.HamburgerDiv>

          <Modal visible={visible} closeModal={handleCloseModal}>
            <Styles.ModalHeaderDiv>
              <Logo />
              <Styles.CloseButtom onClick={handleCloseModal}>
                <CloseButton />
              </Styles.CloseButtom>
            </Styles.ModalHeaderDiv>
            <Styles.SearchInputDiv>
              <Styles.SearchInput placeholder="Pesquisar" />
              <SearchIcon />
            </Styles.SearchInputDiv>
            <Option opened={sidebar} />
            <Styles.SpaceBottom />
            <Logout opened={sidebar} />
          </Modal>
        </Styles.ContainerMobile>
      </IconContext.Provider>

      <>{children}</>
    </>
  );
};

export default Sidebar;
