import React, { useCallback, useState, useEffect, useMemo } from "react"
import { useDisclosure } from "@chakra-ui/react";
import { PanelHeader } from "components/PanelHeader";
import CreateCampaignModal from "./modals/CreateCampaignModal";
import { APIFiltersCampaign, ICampaign, IFilterCamapaignFormData, IToggleCampaign } from "./constants/types";
import { LIST_CAMPAIGNS, TOGGLE_CAMPAIGN } from "./graphql/CampaignQuery";
import { useMutation, useQuery } from "@apollo/client";
import { launchToast } from "shared/utils/launchToast";
import UpdateCampaignModal from "./modals/updateCampaignModal";
import Table from "components/Table";
import { columnCampaignHeaders } from "./constants/conlumns";
import { format, parseISO } from "date-fns";
import { ReactComponent as CancelButton } from "../../assets/svg/generic/cancelbutton.svg";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { ReactComponent as ReportButton } from "../../assets/svg/generic/searchicon.svg";
import { ModalConfirmation } from "components/ModalConfirmation";
import Search from "components/Search";
import FilterCampaingModal from "./modals/FilterCampaignModal";
import FilterList from "components/FilterList";
import Pagination from "components/Pagination";
import ReportCampaignModal from "./modals/reportCampaignModal";
import MainContainer from "components/MainContainer";

const Campaigns: React.FC = () => {

    const [paginationLimit, setPaginationLimit] = useState<number>(10);
    const [searchInput, setSearchInput] = useState<string>("");
    const [campaingSelected, setCampaignSelected] = useState<ICampaign | null>(null);
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [idToToggleCampaign, setIdToToggleCampaign] = useState<number>(1)

    const createCampaignModalControl = useDisclosure();
    const filterModalControl = useDisclosure();
    const updateCampaignModalControl = useDisclosure();
    const toggleCampaignModalControl = useDisclosure();
    const reportCampaignModalControl = useDisclosure();

    const handleWordSearch = (campaign: string) => {
        setCurrentPage(1)
        setSearchInput(campaign)
    };

    const normalizeLabel = (roleLabel: string) => {
        const normalizationMap: Record<string, string> = {
            "DONATE": "Doação",
        };
        return normalizationMap[roleLabel] || roleLabel;
    };

    const handleCampaignUpdate = useCallback(
        async (campaign: ICampaign) => {
            setCampaignSelected(campaign);
            updateCampaignModalControl.onOpen();
        },
        [updateCampaignModalControl]
    )

    const handleCampaignReport = useCallback(
        async (campaign: ICampaign) => {
            setCampaignSelected(campaign);
            reportCampaignModalControl.onOpen();
        },
        [reportCampaignModalControl]
    )

    const [toggleCampaign, { loading: toggleCampaingLoad }] = useMutation(TOGGLE_CAMPAIGN, {
        refetchQueries: [
            LIST_CAMPAIGNS,
            'ListCampaign',
        ],
    });

    const handleCampaignToggle = useCallback(
        async () => {
            try {
                const variables: IToggleCampaign = {
                    id: idToToggleCampaign,
                }
                await toggleCampaign({
                    variables,
                });
                launchToast('Status alterado com sucesso!', "success");
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        }, [toggleCampaign, idToToggleCampaign]
    );

    const handleToggleConfirmation = (campaignId: number) => {
        setIdToToggleCampaign(campaignId);
        toggleCampaignModalControl.onOpen();
    }


    const { data, loading, refetch } = useQuery(LIST_CAMPAIGNS, {
        variables: {
            filterParams: {
                name: searchInput,
            },
            paginationParams: {
                limit: paginationLimit,
                page: currentPage,
            },
            orderParams: {
                field: "end_date",
                order: "desc",
            },
        },
    });

    useEffect(() => {
        try {
            const filterParams = filters.reduce((acc, filter: { key: string, value: string }) => {
                acc[filter.key as keyof IFilterCamapaignFormData] = filter.value;
                return acc;
            }, {} as IFilterCamapaignFormData);

            refetch({
                filterParams: {
                    ...filterParams,
                    name: ''
                },
                paginationParams: {
                    limit: paginationLimit,
                    page: currentPage,
                },
                orderParams: {
                    field: "end_date",
                    order: "desc",
                },
            });
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    }, [paginationLimit, currentPage])

    const handleAddFilter = (params: any) => {
        setCurrentPage(1);
        refetch(params);
    };

    const normalizeKeyFilter = (roleLabel: string) => {
        const normalizationMap: Record<string, string> = {
            "Modelo": "type",
            "Nome": "name",
            "Data de início": "start_date",
            "Data de término": "end_date",
            "Ativo": "is_active"
        };
        return normalizationMap[roleLabel] || roleLabel;
    };

    const handleRemoveFilter = (key: string) => {
        try {
            const newFilters = filters.filter((filter: any) => filter.key !== key);
            setFilters(newFilters);
            const filterParams = newFilters.reduce((acc, filter: { key: string, value: string }) => {
                const normalizeKey = normalizeKeyFilter(filter.key)
                if (normalizeKey === 'start_date' || normalizeKey === 'end_date') {
                    const [comparison, date] = filter.value.split(' de ');
                    acc[normalizeKey as keyof APIFiltersCampaign] = {
                        date,
                        filter: comparison === 'Antes'
                            ? 'lte'
                            : 'gte',
                    } as any; normalizeKey
                } else {
                    acc[normalizeKey as keyof APIFiltersCampaign] = filter.value as any;
                }
                return acc;
            }, {} as APIFiltersCampaign);


            setCurrentPage(1);

            refetch({
                filterParams: {
                    ...filterParams,
                    name: ''
                }
            });
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    };

    return (
        <>
            <CreateCampaignModal
                isOpen={createCampaignModalControl.isOpen}
                onClose={createCampaignModalControl.onClose}
            />
            {campaingSelected && (
                <UpdateCampaignModal
                    isOpen={updateCampaignModalControl.isOpen}
                    onClose={updateCampaignModalControl.onClose}
                    campaign={campaingSelected}
                />
            )}
            {campaingSelected && (
                <ReportCampaignModal
                    isOpen={reportCampaignModalControl.isOpen}
                    onClose={reportCampaignModalControl.onClose}
                    campaign={campaingSelected}
                />
            )}
            <FilterCampaingModal
                isOpen={filterModalControl.isOpen}
                onClose={filterModalControl.onClose}
                handleAddFilter={handleAddFilter}
                onFilterSubmit={setFilters}
            />
            <ModalConfirmation
                isOpen={toggleCampaignModalControl.isOpen}
                onClose={toggleCampaignModalControl.onClose}
                nameAction="ativar/desativar essa campanha"
                handleConfirm={handleCampaignToggle}
                loading={toggleCampaingLoad}
            />
            <MainContainer>
                <PanelHeader
                    title="Campanhas"
                    isFilterModalVisible
                    isNew
                    onClick={filterModalControl.onOpen}
                    onClickNew={createCampaignModalControl.onOpen}
                />
                <Search
                    wordSearch={searchInput}
                    setSearch={handleWordSearch}
                    isFilterForRegister
                    limit={paginationLimit}
                    onSetPageSize={(limit) => {
                        setCurrentPage(1)
                        setPaginationLimit(limit)
                    }}
                    placeholder="Pesquise pelo nome"
                />
                {
                    filters.length
                        ? (
                            <FilterList filters={filters} removeFilter={handleRemoveFilter} />
                        )
                        : ''
                }
                {
                    !loading && (
                        <Table
                            headers={columnCampaignHeaders}
                            data={data.listCampaigns.data.map((campaign: ICampaign) => ({
                                id: campaign.id,
                                name: campaign.name,
                                type: normalizeLabel(campaign.type),
                                is_active: campaign.is_active ? 'Ativo' : 'Desativado',
                                start_date: format(parseISO(campaign.start_date), "dd/MM/yyyy"),
                                end_date: format(parseISO(campaign.end_date), "dd/MM/yyyy"),
                                actions: [
                                    {
                                        name: "Relatoirio",
                                        icon: <ReportButton />,
                                        action: () => handleCampaignReport(campaign),
                                    },
                                    {
                                        name: "Editar",
                                        icon: <EditButton />,
                                        action: () => handleCampaignUpdate(campaign),
                                    },
                                    {
                                        name: "Desativar",
                                        icon: <CancelButton/>,
                                        action: handleToggleConfirmation,
                                    },
                                ],
                            }))}
                        />
                    )
                }
                {
                    !loading && (
                        <Pagination
                            totalPage={data.listCampaigns.totalPages}
                            pageIndex={currentPage}
                            setPage={setCurrentPage}
                        />
                    )
                }
            </MainContainer>
        </>
    )
}

export default Campaigns