import React from "react";
import * as S from "./styles";
import { random } from "lodash";
import { IMoviemntsReport } from "../constants/types";
import { format, parseISO } from "date-fns";

interface IProps {
  data: IMoviemntsReport[];
}

const formatarMoney = (valor: number): string => {
  const formatoMoeda = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formatoMoeda.format(valor / 100)
};

const normalizeTextFilter = (text: string) => {
  const normalizationMap: Record<string, string> = {
      "LITER": "Litros",
      "MILLILITRE": "Mililitros",
      "GRAM": "Gramas",
      "KILOGRAM": "Quilogramas",
      "MILIGRAM": "Miligramas",
      "UNITY": "Unidade"
  };
  return normalizationMap[text] || text;
};

const TableRow = ({ moviment }: { moviment: IMoviemntsReport }) => {
  return (
    <S.StyledTR key={`moviment-${random(0, 1000000)}`}>
    <S.StyledTD>{format(parseISO(moviment.createdAt), "dd/MM/yyyy")}</S.StyledTD>
    <S.StyledTD>{moviment.professional || " - "}</S.StyledTD>
    <S.StyledTD>{moviment.truck || " - "}</S.StyledTD>
    <S.StyledTD>{moviment.productId}</S.StyledTD>
    <S.StyledTD>{moviment.product}</S.StyledTD>
    <S.StyledTD>{moviment.brand}</S.StyledTD>
    <S.StyledTD>{formatarMoney(moviment.costPrice)}</S.StyledTD>
    <S.StyledTD>{formatarMoney(moviment.salePrice)}</S.StyledTD>
    <S.StyledTD>{normalizeTextFilter(moviment.stock_unit)}</S.StyledTD>
    <S.StyledTD>{moviment.quantity}</S.StyledTD>
    <S.StyledTD>{formatarMoney(moviment.totalPrice)}</S.StyledTD>
    <S.StyledTD>{moviment.stockQuantity}</S.StyledTD>
    <S.StyledTD>{moviment.movedBy}</S.StyledTD>
    <S.StyledTD>{moviment.reason}</S.StyledTD>
    </S.StyledTR>
  );
};

const MovimentsReportTable: React.FC<IProps> = ({
  data,
}) => {
  const renderTableHeader = () => {
    const headers = [
      "DATA",
      "PROFISSIONAL",
      "TRUCK",
      "PRODUTO ID",
      "PRODUTO",
      "MARCA",
      "PREÇO DE CUSTO",
      "PREÇO DE VENDA",
      "UNIDADE DE MEDIDA",
      "QUANTIDADE",
      "PREÇO TOTAL",
      "QUANTIDADE EM ESTOQUE",
      "MOVIDO POR",
      "MOTIVO",
    ];

    return (
      <S.StyledTHead>
        {headers?.map((header) => (
          <S.StyledTH key={header}>{header}</S.StyledTH>
        ))}
      </S.StyledTHead>
    );
  };

  const renderTableBody = () => {
    return (
      <S.StyledTBody>
        {data?.map((moviment) => (
          <TableRow
            key={`appointment-${random(0, 1000000)}`}
            moviment={moviment}
          />
        ))}
      </S.StyledTBody>
    );
  };

  return (
    <>
      <S.TableContainer>
        <S.StyledTable id="datatable">
          {renderTableHeader()}
          {renderTableBody()}
        </S.StyledTable>
      </S.TableContainer>
    </>
  );
};

export default MovimentsReportTable;
