import styled from "styled-components";
import colors from "../../../shared/utils/constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
`;

export const ContainerDiscount = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  border-radius: 4px;
  align-items: center;
  border-bottom: 2px dashed ${colors.primary.light};
`;

export const ContainerImageInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

export const ContainerInputsData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 2px dashed ${colors.primary.light};
  border-bottom: 2px dashed ${colors.primary.light};
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  margin-top: 20px;
`;

export const ContainerAnimation = styled.div`
  width: 100%;
  margin-top: -60px;
`;

export const Title = styled.h1`
  color: ${colors.primary.dark};
  font-size: 30px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid ${colors.primary.light};
  padding-bottom: 20px;
  font-weight: 500;
`;

export const Input = styled.input`
  display: none;
`;

export const InputType = styled.input<{
  isEditPartner?: boolean;
}>`
  padding: 10px 20px;
  border-radius: 10px;
  color: ${colors.primary.dark};
  margin-top: 20px;
  // se isEditPartner for true o margin-bottom vai ser 20px se não vai ser 10px
  margin-bottom: ${(props) => (props.isEditPartner ? "20px" : "10px")};
  border: 2px solid ${colors.primary.light};
  width: 100%;
`;

export const SelectType = styled.select`
  padding: 10px 20px;
  border-radius: 10px;
  color: ${colors.primary.dark};
  margin-top: 20px;
  margin-bottom: 10px;
  border: 2px solid ${colors.primary.light};
  width: 100%;
  height: 45px;
`;

export const Label = styled.label<{
  isDiscount?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  background: #518071;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  transition: background 0.2s ease-in-out;
  width: 100%;
  font-size: 14px;
`;

export const Img = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px;

  border-radius: 10px;
  border: 2px solid ${colors.primary.light};

  &:hover {
    opacity: 0.7;
  }
`;

export const InputData = styled.input`
  padding: 10px 20px;
  border-radius: 10px;
  color: ${colors.primary.dark};
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px solid ${colors.primary.light};
  width: 70%;
`;

export const Button = styled.button`
  background: ${colors.primary.dark};
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  width: 80%;

  &:hover {
    opacity: 0.7;
  }
`;

export const Strong = styled.strong`
  color: ${colors.primary.dark};
  margin-left: 10px;
  margin-right: 10px;
`;

export const ConainerPartner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
`;

export const SearchUser = styled.input`
  width: 100%;
  height: 40px;
  border: 2px solid ${colors.gray.light02};
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
  background: ${colors.gray.light02};
  color: ${colors.gray.dark02};
  font-size: 14px;
  font-weight: semi-bold;
  cursor: pointer;
`;

export const ContainerUsers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 2px dashed ${colors.gray.light02};
`;

export const AddButton = styled.div`
  background: ${colors.primary.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0.5rem;
  cursor: pointer;
  color: #fff;
  width: 140px;
  height: 40px;
  transition: background 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const NoDataAlert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
`;

export const ContainerUsersList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  overflow-y: auto;
  height: 200px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const FilterOption = styled.p`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;
