import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import CreateAnamnesisForm, { CreateAnamnesisFormId } from '../forms/createAnamnesis/CreateAnamnesisForm';
import { CREATE_ANAMNESIS, LIST_ANAMNESES } from '../graphql/AnamnesesQuery';
import { ICreateAnamnesisFormData } from '../constants/types';


export type ICreateAnamnesisModalProps = {
    isOpen: boolean;
    onClose: () => void;
};


const CreateAnamnesesModal: React.FC<ICreateAnamnesisModalProps> = ({ isOpen, onClose }) => {

    const [createAnamnesis, { loading }] = useMutation(CREATE_ANAMNESIS, {
        refetchQueries: [
            LIST_ANAMNESES,
            'ListAnamnesis'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: ICreateAnamnesisFormData,
            { reset }
        ) => {
            try {
                await createAnamnesis ({
                    variables: {
                        anamnese: {
                            pet_id: payload.pet_id,
                            comments: payload.comments,
                            avaliation: payload.avaliation,
                            temperament: payload.temperament,
                            castrated: payload.castrated,
                            status: 2,
                            allergy: payload.allergy,
                            allergy_detail: payload.allergy_detail,
                            feed_type: payload.feed_type,
                            feed: payload.feed,
                            feed_diary: payload.feed_diary,
                            feed_prescribed_by_nutritionist: payload.feed_prescribed_by_nutritionist,
                            feed_prescribed_by_nutritionist_detail: payload.feed_prescribed_by_nutritionist_detail,
                            skin_hygiene: payload.skin_hygiene,
                            skin_secretion: payload.skin_secretion,
                            skin_peeling: payload.skin_peeling,
                            skin_itching: payload.skin_itching,
                            ears_canals: payload.ears_canals,
                            eyes_red: payload.eyes_red,
                            eyes_whitish: payload.eyes_whitish,
                            eyes_secretion: payload.eyes_secretion,
                            eyes_visual_deficit: payload.eyes_visual_deficit,
                            respiration_cough: payload.respiration_cough,
                            respiration_sneeze: payload.respiration_sneeze,
                            respiration_cough_detail: payload.respiration_cough_detail,
                            respiration_sneeze_detail: payload.respiration_sneeze_detail,
                            respiration_secretion: payload.respiration_secretion,
                            respiration_secretion_detail: payload.respiration_secretion_detail,
                            respiration_noise: payload.respiration_noise,
                            heart_exercise_intolerance: payload.heart_exercise_intolerance,
                            heart_tiredness: payload.heart_tiredness,
                            syncope_details: payload.syncope_details,
                            heart_cyanosis: payload.heart_cyanosis,
                            heart_cyanosis_detail: payload.heart_cyanosis_detail,
                            digestive_appetite: payload.digestive_appetite,
                            digestive_depraved_appetite: payload.digestive_depraved_appetite,
                            digestive_depraved_appetite_detail: payload.digestive_depraved_appetite_detail,
                            digestive_vomit: payload.digestive_vomit,
                            digestive_vomit_detail: payload.digestive_vomit_detail,
                            digestive_diarrhea: payload.digestive_diarrhea,
                            digestive_diarrhea_detail: payload.digestive_diarrhea_detail,
                            urinary_water: payload.urinary_water,
                            urinary_aspect: payload.urinary_aspect,
                            urinary_frequency: payload.urinary_frequency,
                            urinary_volume: payload.urinary_volume,
                            reproductive_secretion: payload.reproductive_secretion,
                            reproductive_heat: payload.reproductive_heat,
                            reproductive_contraceptive: payload.reproductive_contraceptive,
                            reproductive_puppies: payload.reproductive_puppies,
                            locomotor_lameness: payload.locomotor_lameness,
                            locomotor_trauma: payload.locomotor_trauma,
                            locomotor_trauma_detail: payload.locomotor_trauma_detail,
                            locomotor_volume: payload.locomotor_volume,
                            locomotor_volume_detail: payload.locomotor_volume_detail,
                            locomotor_impotence: payload.locomotor_impotence,
                            locomotor_impotence_detail: payload.locomotor_impotence_detail,
                            neurologic_convulsion: payload.neurologic_convulsion,
                            neurologic_syncope: payload.neurologic_syncope,
                            neurologic_deficit: payload.neurologic_deficit,
                            appointment_id: payload.appointment_id,
                            breath_rate: payload.breath_rate,
                            heart_rate: payload.heart_rate,
                            ecc_condition_score: payload.ecc_condition_score,
                            muscle_mass_score: payload.muscle_mass_score,
                            hydration: payload.hydration,
                            temperature: payload.temperature,
                            lymph_nodes: payload.lymph_nodes,
                            dentition: payload.dentition,
                            customer_orientations: payload.customer_orientations
                          }
                    },
                });
                reset();
                onClose();
                launchToast('Anamneses criada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createAnamnesis],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Nova Anamnese"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
         
            </Styles.header>
            <Styles.FormDiv>
                <CreateAnamnesisForm 
                onSubmit={handleSubmit}
                />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={CreateAnamnesisFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default CreateAnamnesesModal