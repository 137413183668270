import { calculateSizeByWeigthPet } from './calculateSize';

const calculatePriceByPet = ({ prices, pet }:
  {
    prices: {
      specie_id: string;
      size?: string | null;
      price: number;
    }[];
    pet: {
      specie: { id: string; name: string; };
      breed?: { id: string; name: string; size?: string | null } | null;
      weight: number;
    };
  }) => {
  const petSize = pet?.breed?.size || calculateSizeByWeigthPet({
    specie: pet.specie.name,
    weight: pet.weight
  });

  const sssFound = prices.find((p) => {
    return (p.size ? (p.size.trim() === petSize?.trim()) : true) && p.specie_id === pet.specie.id;
  });

  if (sssFound) {
    return Number(sssFound.price);
  }

  throw new Error('Erro ao calcular valor produto 1')
};

export default calculatePriceByPet;
