import React, { useCallback, useRef, useEffect, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { getProfessionals, createProfessionalSupplies } from "./graphQL";

import Text from "../../components/Text";
import InputRef from "../../components/InputRef";
import Select from "../../components/Select";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { Label } from "../../components/Label/styles";

import colors from "../../shared/utils/constants/colors";
import processError from "../../shared/utils/processError";
import { launchToast } from "../../shared/utils/launchToast";

import * as Styles from "./styles";

interface ISelectProfessionalOptionsProps {
  value: string;
  label: string;
}

interface CreateProfessionalSuppliesFormData {
  item_name: string;
  item_value: number;
  date: string;
  professional_id: string;
}

const ProfessionalsSupplies: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [professionals, setProfessionals] = useState<ISelectProfessionalOptionsProps[]>([]);

  const handleSubmit = useCallback(async (data: CreateProfessionalSuppliesFormData) => {
    try {
      setLoading(true);

      if (!data.date || !data.item_name || !data.item_value || !data.professional_id) {
        launchToast('Favor preencher todos os campos', "error");

        setLoading(false);
        return;
      }

      if (data.item_value < 0) {
        launchToast('O preço do insumo não pode ser negativo', "error");

        setLoading(false);
        return;
      }

      if (/[a-zA-Z]/.test(`${data.item_value}`)) {
        launchToast('Favor não digitar letras no valor do insumo', "error");

        setLoading(false);
        return;
      }

      const formattedItemValue = Number(Number(`${data.item_value}`.replace(',', '.')).toFixed(2));

      await createProfessionalSupplies({
        date: data.date,
        item_name: data.item_name,
        item_value: formattedItemValue,
        professional_id: data.professional_id,
      });

      setLoading(false);

      launchToast('Insumo cadastrado com sucesso!', "success");
    } catch (error) {
      const { message } = processError(error, 'GRAPHQL');

      launchToast(message, "error");
    }
  }, []);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);

      const professionals = await getProfessionals();
      const professionalsOptionsArrayTemp = [] as ISelectProfessionalOptionsProps[];

      for (const professional of professionals) {
        professionalsOptionsArrayTemp.push({
          label: professional.user.name,
          value: professional.id,
        });
      }

      setProfessionals(professionalsOptionsArrayTemp);
      setLoading(false);
    } catch (error) {
      const { message } = processError(error, 'GRAPHQL');

      launchToast(message, "error");
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      {loading && <Loading></Loading>}

      <Styles.BackgroundPanel>
        <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Financeiro"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>
        </Styles.PanelHeader>

        <Styles.Container>
          <Text
            text="Cadastrar insumo para profissional"
            color={colors.argon.textColorDark}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            marginRight={4}
            marginTop={0}
            align="left"
          />

          <Styles.Content>
            <Styles.Line />

            <Styles.FormDiv>
              <Form onSubmit={handleSubmit} ref={formRef}>
                <Styles.Row>
                  <Styles.Field>
                    <Label>Nome do item</Label>

                    <InputRef
                      placeholder="Informe o nome do insumo"
                      name="item_name"
                      type="text"
                    />
                  </Styles.Field>

                  <Styles.Field>
                    <Label>Preço do item</Label>

                    <InputRef
                      placeholder="Informe o preço do insumo"
                      name="item_value"
                      type="string"
                    />
                  </Styles.Field>
                </Styles.Row>

                <Styles.Row>
                  <Styles.Field>
                    <Label>Data de compra</Label>
                    <InputRef name="date" type="date" />
                  </Styles.Field>

                  <Styles.Field>
                    <Label>Nome do profissional</Label>

                    <Select
                      name="professional_id"
                      placeholder="Selecione um profissional"
                      options={professionals}
                    />
                  </Styles.Field>
                </Styles.Row>

                <Styles.RowButton>
                  <Button
                    text="Cadastrar"
                    type="submit"
                    styleContainer={{ minWith: "100px" }}
                  />
                </Styles.RowButton>
              </Form>
            </Styles.FormDiv>
          </Styles.Content>
        </Styles.Container>
      </Styles.BackgroundPanel>
    </>
  );
};

export default ProfessionalsSupplies;