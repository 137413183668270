import { gql } from "@apollo/client";

import client from "../../services/api-graphql";

import IRegion from "../../types/IRegion";

const getRegions = async (): Promise<IRegion[]> => {
  const response = await client.query({
    query: gql`
      query {
        regions {
          id
          name
          status
        }
      }
    `,
    fetchPolicy: "no-cache",
  });

  return response.data.regions;
};

const addRegion = async (name: string): Promise<IRegion> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($name: String!) {
        addRegion(name: $name) {
          id
          name
          status
        }
      }
    `,
    variables: {
      name: name
    }
  });

  return response.data.addRegion;
};

export { getRegions, addRegion };
