import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import Select from 'components/Select';
import { listOptionAppointmentsToAnamnesisByPet, listOptionCustomer } from 'pages/Anamnesis/graphql/AnamnesesQuery';
import { IAppoitnmentToAnamnesis, ICreateAnamnesisFormData, ICustomerToAnamneses, IPetToAnamneses } from 'pages/Anamnesis/constants/types';
import { OptionTypeBase, ValueType } from 'react-select';
import TextArea from 'components/TextArea';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import { CreateAnamnesisFormValidations } from './CreateAnamnesisFormValidations';

export type ICreateAnamnesis = {
    onSubmit: (payload: ICreateAnamnesisFormData, options: { reset: () => void }) => Promise<void>;
}

type ISelectTypeBoolean = {
    value: boolean
    label: string
}

type ISelectTypeString = {
    value: string
    label: string
}

export const CreateAnamnesisFormId = 'create-anamnesis-form';

const CreateAnamnesisForm: React.FC<ICreateAnamnesis> = ({
    onSubmit,
}) => {

    const createAnamnesisFormRef = useRef<FormHandles>(null);

    const [searchCustomerInput, setSearchCustomerInput] = useState('')
    const [customerOptions, setCustomerOptions] = useState([] as any)
    const [selectedCustomer, setSelectedCustomer] = useState([] as any)
    const [petOptions, setPetOptions] = useState([] as any)
    const [selectedPet, setSelectedPet] = useState([] as any)
    const [petError, setPetError] = useState("");
    const [appointmentOptions, setAppointmentOptions] = useState([] as any)
    const [selectedAppointment, setSelectedAppointment] = useState([] as any)
    const [appointmentError, setAppointmentError] = useState("");
    const [alLergy, setAllergy] = useState<ISelectTypeBoolean | null>(null)
    const [feedType, setFeedtype] = useState<ISelectTypeString | null>(null)
    const [feedPprescribedByNutritionist, setFeedPprescribedByNutritionist] = useState<ISelectTypeBoolean | null>(null)
    const [respirationSecretion, setRespirationSecretion] = useState<ISelectTypeBoolean | null>(null)
    const [respirationCough, setRespirationCough] = useState<ISelectTypeBoolean | null>(null)
    const [respirationSneeze, setRespirationSneeze] = useState<ISelectTypeBoolean | null>(null)
    const [neurologicSyncope, setNeurologicSyncope] = useState<ISelectTypeBoolean | null>(null)
    const [heartCyanosis, setHeartCyanosis] = useState<ISelectTypeBoolean | null>(null)
    const [digestiveDepravedAppetite, setDigestiveDepravedAppetite] = useState<ISelectTypeBoolean | null>(null)
    const [digestiveVomit, setDigestiveVomit] = useState<ISelectTypeBoolean | null>(null)
    const [digestiveDiarrhea, setDigestiveDiarrhea] = useState<ISelectTypeBoolean | null>(null)
    const [locomotorTrauma, setLocomotorTrauma] = useState<ISelectTypeBoolean | null>(null)
    const [locomotorVolume, setLocomotorVolume] = useState<ISelectTypeBoolean | null>(null)
    const [locomotorImpotence, setLocomotorImpotence] = useState<ISelectTypeBoolean | null>(null)

    useEffect(() => {
        let timeoutCustomer: NodeJS.Timeout;
        const fatchCustomers = async () => {
            const customerList = await listOptionCustomer(searchCustomerInput)
            setCustomerOptions(customerList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutCustomer);
            timeoutCustomer = setTimeout(fatchCustomers, 200);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutCustomer);
    }, [searchCustomerInput]);

    const handleSelectedCustomer = (e: ValueType<OptionTypeBase, false> | ICustomerToAnamneses) => {
        setSelectedPet(null)
        setSelectedAppointment(null)
        setAppointmentOptions([])
        setSelectedCustomer(e)
        const petsCustomerList = e?.customer.pets.map((pet: IPetToAnamneses) => {
            return { label: pet.name, value: pet.id}
        })
        setPetOptions(petsCustomerList)
    }

    const handleSelectedPet = async (e: ValueType<OptionTypeBase, false> | ISelectTypeString) => {
        setPetError([] as any)
        setSelectedAppointment(null)
        setSelectedPet(e)
        let appointments: ISelectTypeString[] = []
        const petAppointmentsListResponse: IAppoitnmentToAnamnesis[] = await listOptionAppointmentsToAnamnesisByPet(e?.value)
         petAppointmentsListResponse.forEach(appointment => {
            if(appointment.service.category.id === '5d7b1d13-0cf0-482e-83b1-3deecbccd4e1'){
                appointments.push({label: `${appointment.date} - ${appointment.service.name} - ${appointment.professional.user.name}`, value: appointment.id }) 
            }
        })
        setAppointmentOptions(appointments)
    }

    const booleanOptions = [
        {
            label: 'Sim',
            value: true
        },
        {
            label: 'Não',
            value: false
        }
    ]

    const validateFields = async (
        payload: ICreateAnamnesisFormData,
        options: { reset: () => void }
    ) => {
        try {
            await CreateAnamnesisFormValidations.validate({ pet_id: selectedPet?.value, appointment_id: selectedAppointment?.value}, { abortEarly: false });
            onSubmit(
                { 
                    pet_id: selectedPet.value,
                    comments: payload.comments || null,
                    avaliation: payload.avaliation || null,
                    temperament: payload.temperament || null,
                    castrated: payload.castrated || false,
                    allergy: payload?.allergy || false,
                    allergy_detail: payload.allergy_detail || null,
                    feed_type: payload.feed_type || null,
                    feed: payload.feed || null,
                    feed_diary: payload.feed_diary || null,
                    feed_prescribed_by_nutritionist: payload.feed_prescribed_by_nutritionist || false,
                    feed_prescribed_by_nutritionist_detail: payload.feed_prescribed_by_nutritionist_detail || null,
                    skin_hygiene: payload.skin_hygiene || null,
                    skin_secretion: payload.skin_secretion || null,
                    skin_peeling: payload.skin_peeling || null,
                    skin_itching: payload.skin_itching || null,
                    ears_canals: payload.ears_canals || null,
                    eyes_red: payload.eyes_red || false,
                    eyes_whitish: payload.eyes_whitish || false,
                    eyes_secretion: payload.eyes_secretion || false,
                    eyes_visual_deficit: payload.eyes_visual_deficit || false,
                    respiration_cough: respirationCough?.value || false,
                    respiration_sneeze: respirationSneeze?.value  || false,
                    respiration_cough_detail: payload.respiration_cough_detail || null,
                    respiration_sneeze_detail: payload.respiration_sneeze_detail || null,
                    respiration_secretion: respirationSecretion?.value  || false,
                    respiration_secretion_detail: payload.respiration_secretion_detail || null,
                    respiration_noise: payload.respiration_noise || false,
                    heart_exercise_intolerance: payload.heart_exercise_intolerance || false,
                    heart_tiredness: payload.heart_tiredness || false,
                    syncope_details: payload.syncope_details || null,
                    heart_cyanosis: heartCyanosis?.value  || false,
                    heart_cyanosis_detail: payload.heart_cyanosis_detail || null,
                    digestive_appetite: payload.digestive_appetite || null,
                    digestive_depraved_appetite: digestiveDepravedAppetite?.value  || false,
                    digestive_depraved_appetite_detail: payload.digestive_depraved_appetite_detail || null,
                    digestive_vomit: digestiveVomit?.value  || false,
                    digestive_vomit_detail: payload.digestive_vomit_detail || null,
                    digestive_diarrhea: digestiveDiarrhea?.value  || false,
                    digestive_diarrhea_detail: payload.digestive_diarrhea_detail || null,
                    urinary_water: payload.urinary_water || null,
                    urinary_aspect: payload.urinary_aspect || null,
                    urinary_frequency: payload.urinary_frequency || null,
                    urinary_volume: payload.urinary_volume || null,
                    reproductive_secretion: payload.reproductive_secretion || false,
                    reproductive_heat: payload.reproductive_heat || false,
                    reproductive_contraceptive: payload.reproductive_contraceptive || false,
                    reproductive_puppies: payload.reproductive_puppies || null,
                    locomotor_lameness: payload.locomotor_lameness || false,
                    locomotor_trauma: locomotorTrauma?.value || false,
                    locomotor_trauma_detail: payload.locomotor_trauma_detail || null,
                    locomotor_volume: payload.locomotor_volume || false,
                    locomotor_volume_detail: payload.locomotor_volume_detail || null,
                    locomotor_impotence: locomotorImpotence?.value || false,
                    locomotor_impotence_detail: payload.locomotor_impotence_detail || null,
                    neurologic_convulsion: payload.neurologic_convulsion || false,
                    neurologic_syncope: neurologicSyncope?.value || false,
                    neurologic_deficit: payload.neurologic_deficit || false,
                    appointment_id: selectedAppointment.value || null,
                    breath_rate: payload.breath_rate || null,
                    heart_rate: payload.heart_rate || null,
                    ecc_condition_score: payload.ecc_condition_score || null,
                    muscle_mass_score: payload.muscle_mass_score || null,
                    hydration: payload.hydration || null,
                    temperature: payload.temperature || null,
                    lymph_nodes: payload.lymph_nodes || null,
                    dentition: payload.dentition || null,
                    customer_orientations: payload.customer_orientations || null
                }, 
                options);
        setPetError([] as any)
        setAppointmentError([] as any)
        setSearchCustomerInput('')
        setSelectedCustomer(null)
        setSelectedPet(null)
        setSelectedAppointment(null)
        setAllergy(null)
        setFeedtype(null)
        setFeedPprescribedByNutritionist(null)
        setRespirationSecretion(null)
        setRespirationCough(null)
        setRespirationSneeze(null)
        setNeurologicSyncope(null)
        setHeartCyanosis(null)
        setDigestiveDepravedAppetite(null)
        setDigestiveVomit(null)
        setDigestiveDiarrhea(null)
        setLocomotorTrauma(null)
        setLocomotorVolume(null)
        setLocomotorImpotence(null)
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            createAnamnesisFormRef.current?.setErrors(errors);
            if(!selectedPet?.label){setPetError("Selecione um pet")}
            if(!selectedAppointment?.label){setAppointmentError("Selecione um agendamento")}
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form onSubmit={validateFields} ref={createAnamnesisFormRef} id={CreateAnamnesisFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Tutor</Label>
                        <Select
                            name="customer"
                            options={customerOptions?.map((customer:  ICustomerToAnamneses) => ({
                            ...customer,
                            label: customer.name
                            })) as { value: string; label: string; }[]}
                            onInputChange={setSearchCustomerInput}
                            placeholder="Busque por nome"
                            onChange={(e) => handleSelectedCustomer(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Pet</Label>
                        <Select
                            name="pet_id"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione um pet"
                            options={petOptions}
                            error={petError}
                            value={selectedPet}
                            onChange={(e: any) => handleSelectedPet(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Agendamento</Label>
                        <Select
                            name="appointment_id"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione um agendamento"
                            options={appointmentOptions}
                            error={appointmentError}
                            value={selectedAppointment}
                            onChange={(e: any) => {setSelectedAppointment(e), setAppointmentError([] as any)}}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Geral"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Observações da consulta</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="comments"
                            placeholder="Relate as suas observações sobre o atendimento"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Queixa principal</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="avaliation"
                            placeholder="Quadro, tratamentos, resultados"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Castrado</Label>
                        <Select
                            name="castrated"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Temperamento</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="temperament"
                            placeholder="Digite sobre o temperamento do pet"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Alergia</Label>
                        <Select
                            name="allergy"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={alLergy}
                            onChange={(e: any) => setAllergy(e)}
                        />
                    </Styles.field>
                    { alLergy?.value && <Styles.field>
                        <Label>Sobre a alergia</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="allergy_detail"
                            placeholder="Descrever a alergia"
                        />
                    </Styles.field> }
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Alimentação"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Alimento utilizado</Label>
                        <Select
                            name="feed_type"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={[
                                {label: 'Alimentação natural', value: 'Alimentacao_natural'},
                                {label:'Ração seca', value: 'Racao_seca'},
                                {label: 'Alimento úmido', value: 'Alimento_umido'}
                              ]}
                            value={feedType}
                            onChange={(e: any) => setFeedtype(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Marca</Label>
                        <InputRef
                            name="feed"
                            type='feed'
                            placeholder="Digite a marca/tipo"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Quantidade em gramas</Label>
                        <InputRef
                            name="feed_diary"
                            type='number'
                            placeholder="Digite a quantidade"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                { feedType?.value === 'Alimentacao_natural' && <Styles.row>
                    <Styles.field>
                        <Label>Alimentação prescrita por nutrólogo</Label>
                        <Select
                            name="feed_prescribed_by_nutritionist"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={feedPprescribedByNutritionist}
                            onChange={(e: any) => setFeedPprescribedByNutritionist(e)}
                        />
                    </Styles.field>
                  { feedPprescribedByNutritionist?.value && <Styles.field>
                        <Label>Alimentação prescrita por nutrólogo</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="feed_prescribed_by_nutritionist_detail"
                            placeholder="Descrever a alimentação"
                        />
                    </Styles.field>}
                </Styles.row>}
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Pele"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Higiene</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="skin_hygiene"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Secreção</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="skin_secretion"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Descamação</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="skin_peeling"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Prurido (coceira)</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="skin_itching"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Orelhas"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Condutos auditivos</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="ears_canals"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Olhos"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Olhos vermelhos</Label>
                        <Select
                            name="eyes_red"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Olhos esbranquiçados</Label>
                        <Select
                            name="eyes_whitish"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Secreção</Label>
                        <Select
                            name="eyes_secretion"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Déficit Visual</Label>
                        <Select
                            name="eyes_visual_deficit"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema respiratório"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Secreção</Label>
                        <Select
                            name="respiration_secretion"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={respirationSecretion}
                            onChange={(e: any) => setRespirationSecretion(e)}
                        />
                    </Styles.field>
                { respirationSecretion?.value && <Styles.field>
                        <Label>Sobre a secreção</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="respiration_secretion_detail"
                            placeholder="Descrever a secreção"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Tosse</Label>
                        <Select
                            name="respiration_cough"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={respirationCough}
                            onChange={(e: any) => setRespirationCough(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    { respirationCough?.value && <Styles.field>
                        <Label>Sobre a tosse</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="respiration_cough_detail"
                            placeholder="Descrever a tosse"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Espirro</Label>
                        <Select
                            name="respiration_sneeze"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={respirationSneeze}
                            onChange={(e: any) => setRespirationSneeze(e)}
                        />
                    </Styles.field>
                    { respirationSneeze?.value && <Styles.field>
                        <Label>Sobre os espirros</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="respiration_sneeze_detail"
                            placeholder="Descrever o espirro"
                        />
                    </Styles.field>}
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Ruído</Label>
                        <Select
                            name="respiration_noise"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Cardiovascular"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Intolerância a exercícios</Label>
                        <Select
                            name="heart_exercise_intolerance"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Cansaço</Label>
                        <Select
                            name="heart_tiredness"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Síncope</Label>
                        <Select
                            name="neurologic_syncope"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={neurologicSyncope}
                            onChange={(e: any) => setNeurologicSyncope(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    { neurologicSyncope?.value && <Styles.field>
                        <Label>Sobre a síncope</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="syncope_details"
                            placeholder="Descrever a sincope"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Cianose</Label>
                        <Select
                            name="heart_cyanosis"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={heartCyanosis}
                            onChange={(e: any) => setHeartCyanosis(e)}
                        />
                    </Styles.field>
                   { heartCyanosis?.value && <Styles.field>
                        <Label>Sobre a cianose</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="heart_cyanosis_detail"
                            placeholder="Descrever a cianose"
                        />
                    </Styles.field>}
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Digestório"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Apetite</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="digestive_appetite"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Apetite depravado</Label>
                        <Select
                            name="digestive_depraved_appetite"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={digestiveDepravedAppetite}
                            onChange={(e: any) => setDigestiveDepravedAppetite(e)}
                        />
                    </Styles.field>
                    { digestiveDepravedAppetite?.value && <Styles.field>
                        <Label>Sobreo apetite depravado</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="digestive_depraved_appetite_detail"
                            placeholder="Descrever o apetite depravado"
                        />
                    </Styles.field>}
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Vômito</Label>
                        <Select
                            name="digestive_vomit"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={digestiveVomit}
                            onChange={(e: any) => setDigestiveVomit(e)}
                        />
                    </Styles.field>
                    { digestiveVomit?.value && <Styles.field>
                        <Label>Sobre os vômitos</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="digestive_vomit_detail"
                            placeholder="Descrever o vômito"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Diarréia</Label>
                        <Select
                            name="digestive_diarrhea"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={digestiveDiarrhea}
                            onChange={(e: any) => setDigestiveDiarrhea(e)}
                        />
                    </Styles.field>
                </Styles.row>
                {digestiveDiarrhea?.value  &&  <Styles.row>
                    <Styles.field>
                        <Label>Sobre a diarréia</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="digestive_diarrhea_detail"
                            placeholder="Descrever o diarréia"
                        />
                    </Styles.field>
                </Styles.row>}
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Urinário"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Ingestão de água</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="urinary_water"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Aspecto</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="urinary_aspect"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Volume</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="urinary_volume"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Frequência</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="urinary_frequency"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Reprodutor"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Secreção</Label>
                        <Select
                            name="reproductive_secretion"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Cio</Label>
                        <Select
                            name="reproductive_heat"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Anticoncepcional</Label>
                        <Select
                            name="reproductive_contraceptive"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Número de filhotes</Label>
                        <InputRef
                            name="reproductive_puppies"
                            type='number'
                            placeholder="Digite a quantidade"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Ortopédico"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Claudicação</Label>
                        <Select
                            name="locomotor_lameness"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Trauma</Label>
                        <Select
                            name="locomotor_trauma"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={locomotorTrauma}
                            onChange={(e: any) => setLocomotorTrauma(e)}
                        />
                    </Styles.field>
                    { locomotorTrauma?.value && <Styles.field>
                        <Label>Sobre traumas</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="locomotor_trauma_detail"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>}
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Aumento de volume</Label>
                        <Select
                            name="locomotor_volume"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={locomotorVolume}
                            onChange={(e: any) => setLocomotorVolume(e)}
                        />
                    </Styles.field>
                    { locomotorVolume?.value && <Styles.field>
                        <Label>Sobre o aumento de volume</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="locomotor_volume_detail"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>}
                    <Styles.field>
                        <Label>Impotência de membro</Label>
                        <Select
                            name="locomotor_impotence"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                            value={locomotorImpotence}
                            onChange={(e: any) => setLocomotorImpotence(e)}
                        />
                    </Styles.field>
                </Styles.row>
                { locomotorImpotence?.value && <Styles.row>
                    <Styles.field>
                        <Label>Sobre a impotência de membro</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="locomotor_impotence_detail"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>}
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Sistema Neurológico"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Convulsão</Label>
                        <Select
                            name="neurologic_convulsion"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Déficit neurológico</Label>
                        <Select
                            name="neurologic_deficit"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione uma opção"
                            options={booleanOptions}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Exame Físico"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Frequência respiratória</Label>
                        <InputRef
                            name="breath_rate"
                            placeholder="Digite a frequência respiratória"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Frequência cardiaca</Label>
                        <InputRef
                            name="heart_rate"
                            placeholder="Digite a frequência cardiaca"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Escore de condição corporal (ECC)</Label>
                        <InputRef
                            name="ecc_condition_score"
                            placeholder="Digite o escore"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Escore de massa muscular</Label>
                        <InputRef
                            name="muscle_mass_score"
                            placeholder="Digite o escore"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Hidratação</Label>
                        <InputRef
                            name="hydration"
                            placeholder="Descreva sobre a hidratação"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Temperatura em graus C</Label>
                        <InputRef
                            name="temperature"
                            placeholder="Digite a temperatura"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Linfonos</Label>
                        <InputRef
                            name="lymph_nodes"
                            placeholder="Descreva sobre"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Dentição</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="dentition"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.line/>
                <Styles.row>
                <Text
                    text="Mais detalhes"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Orientações para o cliente / Tratamento</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="customer_orientations"
                            placeholder="Descreva sobre"
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default CreateAnamnesisForm