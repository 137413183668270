import styled from "styled-components";

export const ModalStyled = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 2rem 0;

  overflow: hidden;
  z-index: 10;

  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ModalContent = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  margin: auto auto;
  padding: 4rem;
  border: 1px solid #888;
  width: ${(props) => (props.width ?`${props.width}px` : "90%")};
  max-width: 1200px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #eaedf0; /* color of the tracking area */
    margin: 1px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccd500; /* color of the scroll thumb */
    border-radius: 6px; /* roundness of the scroll thumb */
  }
`;

export const Steps = styled.button`
  display: flex;
  flex-direction: row;
  place-content: center;
  padding-bottom: 1rem;

  width: 100%;
  height: 20px;
`;
