import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { CREATE_CEP, LIST_SECTIONS_CEP_CATEGORIES } from '../graphql/CepsCategoriesQuery';
import {  ICreateSectionFormData } from '../constants/types';
import CreateCepSectionForm, { CreateCepSectionFormId } from '../forms/CreateCep/createSectionCepForm';
import { useDisclosure } from '@chakra-ui/react';


export type ICreateCepModalProps = {
    isOpen: boolean;
    onClose: () => void;
};


const CreateSectionModal: React.FC<ICreateCepModalProps> = ({ isOpen, onClose }) => {

    const createRegionModalControl = useDisclosure();

    const [createCep, { loading }] = useMutation(CREATE_CEP, {
        refetchQueries: [
            LIST_SECTIONS_CEP_CATEGORIES,
            'ListCepsCategoriesSections'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: ICreateSectionFormData,
            { reset }
        ) => {
            try {
                await createCep({
                    variables: {
                        startCep: payload.startCep,
                        endCep: payload.endCep,
                        regionId: payload.regionId
                    },
                });
                reset();
                onClose();
                launchToast('Setor criado com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createCep],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Novo Setor"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            <Button text="Nova Região"  onClick={createRegionModalControl.onOpen} />
            </Styles.header>
            <Styles.FormDiv>
                <CreateCepSectionForm onSubmit={handleSubmit} createRegionModalControl={createRegionModalControl}  />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={CreateCepSectionFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default CreateSectionModal