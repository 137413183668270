import { Form } from "@unform/web";
import React, { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Styles from '../../constants/styles';
import { Label } from "components/Label/styles";
import InputRef from "components/InputRef";
import Select from "components/Select";
import { IProductMovimentReport, IProfessionalMovimentReport, ITruckMovimentReport } from "../../constants/types";
import { listOptionProducts, listOptionProfessionals, listOptionTrucks } from "pages/Moviment/graphql/MovimentQuery";

export type IFiltersMovimentsReportFormProps = {
    onSubmit: (payload: IFilterMovimentsReport, { reset }: any) => Promise<void>
}

interface IFilterMovimentsReport {
    [key: string]: string | number | boolean
}

export const FiltersMovimentsReportFormId = 'filters-moviment-report-form';


const FiltersMovimentsReportForm = ({ onSubmit }: IFiltersMovimentsReportFormProps) => {
    const filtersMovimentsReportformRef = useRef<FormHandles>(null);

    const [searchProductInput, setSearchProductInput] = useState('')
    const [productOptions, setProductOptions] = useState([] as any)
    const [searchProfessionalInput, setSearchProfessionalInput] = useState('')
    const [professionalOptions, setProfessionalOptions] = useState([] as any)
    const [searchTruckInput, setSearchTruckInput] = useState('')
    const [truckOptions, setTruckOptions] = useState([] as any)

    useEffect(() => {
        let timeoutProduct: NodeJS.Timeout;
        const fatchProducts = async () => {
            const productList = await listOptionProducts(searchProductInput)
            setProductOptions(productList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutProduct);
            timeoutProduct = setTimeout(fatchProducts, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutProduct);
      }, [searchProductInput]);

      useEffect(() => {
        let timeoutProfessional: NodeJS.Timeout;
        const fatchProfessionals = async () => {
            const professionalList = await listOptionProfessionals(searchProfessionalInput)
            setProfessionalOptions(professionalList)
        }
        fatchProfessionals
        const delayedFetchData = () => {
            clearTimeout(timeoutProfessional);
            timeoutProfessional = setTimeout(fatchProfessionals, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutProfessional);
    }, [searchProfessionalInput]);

    useEffect(() => {
        let timeoutTruck: NodeJS.Timeout;
        const fatchTrucks = async () => {
            const truckList = await listOptionTrucks(searchTruckInput)
            setTruckOptions(truckList)
        }
        fatchTrucks
        const delayedFetchData = () => {
            clearTimeout(timeoutTruck);
            timeoutTruck = setTimeout(fatchTrucks, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutTruck);
    }, [searchTruckInput]);

    const resetFilters = () => {
        setSearchProductInput("")
        setSearchProfessionalInput("")
        setSearchTruckInput("")
        filtersMovimentsReportformRef.current?.setData({
            productId: '',
        });
    }

    const validateFields = async (
        payload: IFilterMovimentsReport,
        options: { reset: () => void }
    ) => {
        const nonEmptyKeys = Object.keys(payload).filter(key => payload[key] !== "");
        const filteredPayload: IFilterMovimentsReport = nonEmptyKeys.reduce(
            (result, key) => {
                result[key] = payload[key];
                return result;
            },
            {} as IFilterMovimentsReport
        );
        onSubmit(filteredPayload, options);

        resetFilters()
    };

    return (
        <Form onSubmit={validateFields} ref={filtersMovimentsReportformRef} id={FiltersMovimentsReportFormId} >
            <Styles.row>
                <Styles.field>
                    <Label>Data Inicial</Label>
                    <InputRef
                        name="from"
                        type="date"
                        containerStyle={{ width: "33rem" }}
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Data Final</Label>
                    <InputRef
                        name="to"
                        type="date"
                        containerStyle={{ width: "33rem" }}
                    />
                </Styles.field>
            </Styles.row>
            <Styles.row>
            <Styles.field>
                <Label>Produtos</Label>
                <Select
                    name="productId"
                    options={productOptions?.map((product:  IProductMovimentReport) => ({
                        value: product.id,
                        label: product.name
                    })) as { value: string; label: string; }[]}
                    onInputChange={setSearchProductInput}
                    placeholder="Busque um produto"
                />
            </Styles.field>
            <Styles.field>
                <Label>Destinatario</Label>
                    <Select
                        name="professionalId"
                        options={ 
                            professionalOptions?.map((professional:  IProfessionalMovimentReport) => ({
                                ...professional,
                                label: professional.user.name,
                                value: professional.user.id
                            })) 
                        }
                        onInputChange={setSearchProfessionalInput}
                        placeholder={"Busque um professional"}
                    />
            </Styles.field>
            <Styles.field>
                <Label>Truck</Label>
                    <Select
                        name="truckId"
                        options={ truckOptions?.map((recipient: ITruckMovimentReport) => ({
                            ...recipient,
                            label: recipient?.chasi_number,
                            value: recipient?.id
                        }))}                                
                        onInputChange={setSearchTruckInput}
                        placeholder={"Buscar Truck"}
                />
            </Styles.field>
            </Styles.row>
        </Form>
    )
}

export default FiltersMovimentsReportForm