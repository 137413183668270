export const headers = [
  { key: "date", name: "Data" },
  { key: "tutor", name: "TUTOR" },
  { key: "phone", name: "telefone" },
  { key: "pet", name: "pet" },
  { key: "breed", name: "raça" },
  { key: "service", name: "serviço" },
  { key: "address", name: "endereço completo" },
  { key: "status", name: "status" },
];
