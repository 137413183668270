import React from "react";
import { TextProps } from "@chakra-ui/react";

import { TextStyled } from "./styles";

interface ITextProps {
  color?: string;
  size?: number;
  text: string;
  align?: "center" | "left" | "right";
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  margin?: number;
  underline?: boolean;
  weight?: "600" | "400";
  fontFamily?: "OrelegaOne-regular" | "Open Sans";
  onTextClick?: Function;
  whiteSpace?: string;
  ellipsis?: boolean;
  clickable?: boolean
}

const Text: React.FC<ITextProps> = ({
  children,
  text,
  color,
  size,
  align,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  margin,
  underline,
  weight,
  fontFamily,
  onTextClick,
  whiteSpace,
  ellipsis,
  clickable,
  ...rest
}) => (
  <TextStyled
    color={color}
    size={size}
    align={align}
    marginBottom={marginBottom}
    marginTop={marginTop}
    marginLeft={marginLeft}
    marginRight={marginRight}
    margin={margin}
    underline={underline}
    weight={weight}
    fontFamily={fontFamily}
    whiteSpace={whiteSpace}
    onClick={() => onTextClick && onTextClick()}
    ellipsis={ellipsis}
    clickable={clickable}
    {...rest}
  >
    {text}
  </TextStyled>
);

export default Text;
