import { isBefore, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import Text from "../../components/Text";
import colors from "../../shared/utils/constants/colors";
import convertToCurrencyFormat from "../../shared/utils/convertToCurrencyFormat";
import { launchToast } from "../../shared/utils/launchToast";
import processError from "../../shared/utils/processError";
import ISubscription from "../../types/ISubscription";
import { getSubscriptions, updatePlanMonthlyPayment } from "./graphQL";
import PlanDetail from "./PlanDetail";
import * as Styles from "./styles";
import { Search } from "components/Search";
import MainContainer from "components/MainContainer";

type IAction = {
  name: string;
  icon: any;
  action: (payload: any) => void;
};

type ISubscriptionTable = {
  id: string;
  plano: string;
  cliente: string;
  pet: string;
  termino: string;
  date_to_renew: string;
  preco: string;
  status: string;
  actions: IAction[];
};

const headers = [
  { key: "plano", name: "plano" },
  { key: "cliente", name: "cliente" },
  { key: "pet", name: "pet" },
  { key: "date_to_renew", name: "renova dia" },
  { key: "termino", name: "termino" },
  { key: "status", name: "status" },
  { key: "preco", name: "preco" },
  { key: "actions", name: "AÇÕES" },
];

const SubscriptionsManager: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState<ISubscription | undefined>(
    undefined
  );
  const [data, setData] = useState<ISubscriptionTable[]>([]);
  const [search, setSearch] = useState("" as string);

  useEffect(() => {
    setLoading(true);
    getSubscriptions()
      .then((subs) => {
        setData(
          subs.map((sub) => ({
            id: sub.id,
            plano: sub.plan.name,
            cliente: sub.user.name,
            pet: sub.pet.name,
            termino: sub.ends_in.split("T")[0],
            date_to_renew: sub.day_to_renew,
            preco: convertToCurrencyFormat(sub.price),
            status: sub.payments.find(
              (payment) =>
                !payment.date_renewed &&
                isBefore(parseISO(payment.date_to_renew), new Date())
            )
              ? "atrasado"
              : "em dia",
            actions: [
              {
                name: "see_details",
                action: () => {
                  setModalDetail(sub);
                },
                icon: <FaEye size={14} color={colors.gray.dark02} />,
              },
            ],
          }))
        );
      })
      .catch((error) => {
        launchToast(processError(error, "GRAPHQL").message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleUpdateList = async (payload: any) => {
    try {
      if (!modalDetail) {
        launchToast("Erro ao substituir parcela do plano", "error");
        return;
      }
      const subscription_payment = await updatePlanMonthlyPayment({
        subscription_payment_id: payload.id,
        type: payload.type,
      });

      setModalDetail({
        ...modalDetail,
        payments: modalDetail.payments.map((p) => {
          if (p.id === subscription_payment.id) {
            return subscription_payment;
          }
          return p;
        }),
      });
      launchToast("Nova transação gerada com sucesso", "success");
    } catch (error) {
      launchToast("Erro ao substituir parcela do plano", "error");
    }
  };

  const filterSearch =
    search.length > 0
      ? data.filter((d) =>
          d.cliente
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase().trim())
        )
      : data;

  return (
    <>
      {loading && <Loading />}
      <MainContainer>
        <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Planos"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>
        </Styles.PanelHeader>

        <Search
          setSearch={setSearch}
          wordSearch={search}
          placeholder="Pesquisar por nome do cliente"
        />
        <Table headers={headers} data={filterSearch} />
      </MainContainer>
      {modalDetail && (
        <PlanDetail
          payload={modalDetail}
          onCloseModal={() => setModalDetail(undefined)}
          onSubmit={(payload) => handleUpdateList(payload)}
        />
      )}
    </>
  );
};
export default SubscriptionsManager;
