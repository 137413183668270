import ChooseRefundTypeModal from "components/ChooseRefundTypeModal";
import FilterList from "components/FilterList";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "components/Search";
import Table, { IAction } from "components/Table";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as CancelButton } from "../../assets/svg/generic/cancelbutton.svg";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/generic/searchicon.svg";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import RefundTypeEnum from "../../shared/enums/RefundTypeEnum";
import { getActualDate } from "../../shared/utils/getActualDate";
import { launchToast } from "../../shared/utils/launchToast";
import processError from "../../shared/utils/processError";
import IAppointment from "../../types/IAppointment";
import AppointmentModal from "./AppointmentModal";
import FilterModal from "./FilterModal";
import { cancelAppointment, searchAppointments } from "./graphQL";
import { headers } from "./utils/columns";
import { useAuth } from "hooks/Auth";
import OrderDetailsModal from "pages/Orders/modals/orderDetails";
import { useDisclosure } from "@chakra-ui/hooks";
import { searchOrders } from "pages/Orders/graphQL";
import { IFormattedOrder, formatOrder } from "shared/utils/formatOrder";
import MainContainer from "components/MainContainer";
import {
  IFormattedAppointment,
  formatAppointment,
} from "./utils/formatAppointment";

const actualDate = getActualDate();

const Appointments: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [loading, setLoading] = useState(false);
  const [isFilterModalVisible, setisFilterModalVisible] = useState(false);
  const [isEditModalVisible, setisEditModalVisible] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [filters, setFilters] = useState([
    { key: "created", searchValue: actualDate },
  ] as any);
  const [selectedAppointment, setSelectedAppointment] = useState<
    (IFormattedAppointment & IAction[]) | undefined
  >(undefined);
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [wordSearch, setWordSearch] = useState("");
  const [sort, setSort] = useState({} as any);
  const [timer, setTimer] = useState(null as any);
  const [modalRefundTypeVisible, setModalRefundTypeVisible] = useState(false);
  const [order, setOrder] = useState<IFormattedOrder | null>(null);

  const orderDetailsModalControl = useDisclosure();

  const { user } = useAuth();

  const loadAppointments = useCallback(
    async (f: any, wordSearch: any) => {
      setData([]);
      setAppointments([]);
      setLoading(true);
      let where = {
        wordSearch,
      } as any;

      for (let filter of f) {
        const { key, searchValue } = filter;
        switch (key) {
          case "Status":
            where.status = [parseInt(searchValue)];
            break;
          case "Categoria":
            where.categories = [searchValue];
            break;
          case "Pet":
            where.pet_name = searchValue;
            break;
          case "Tutor":
            where.tutor_name = searchValue;
            break;
          case "created":
            where.created_at = searchValue;
            break;
          case "Data Início":
            where.from = searchValue;
            break;
          case "Data Fim":
            where.to = searchValue;
            break;
        }
      }

      let orderBy = [] as any;
      if (!!sort.field) {
        const { field, direction } = sort;
        switch (field) {
          case "date_formated":
            orderBy.push({ date: direction });
            break;
          case "period":
            orderBy.push({ period: direction });
            break;
          case "customer":
            orderBy.push({ tutor_name: direction });
            break;
          case "pet_name":
            orderBy.push({ pet_name: direction });
            break;
          case "created_at":
            orderBy.push({ created_at: direction });
            break;
          case "status_description":
            orderBy.push({ status: direction });
            break;
        }
      }

      if (orderBy.length <= 0) orderBy.push({ date: "desc" });
      try {
        const results = await searchAppointments({
          pagination: { limit, page: currentPage },
          where,
          orderBy,
        });

        const { totalPages, appointments } = results;

        setTotalPage(totalPages);

        const data = appointments.map((appointment: IAppointment) => {
          const actions = [
            {
              name: "Editar",
              icon: <EditButton />,
              action: handleEdit,
            },
            {
              name: "Ver Pedido",
              icon: <SearchIcon />,
              action: handleSeeOrder,
            },
          ];

          const isFinancialUser = user.roles.find(
            (role) => role.name === "financeiro"
          );

          if (!appointment.pack_id || isFinancialUser) {
            actions.push({
              name: "Cancelar",
              icon: <CancelButton />,
              action: handleCancel,
            });
          }

          const formattedAppointment = formatAppointment(appointment);

          return {
            ...formattedAppointment,
            actions,
          };
        });

        setData(data);
        setAppointments(results.appointments);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [currentPage, limit, sort]
  );

  const onSetPageSize = (pageSize: number) => {
    setLimit(pageSize);
  };

  const handleApplyFilter = async (f: any) => {
    setFilters(f);
    setCurrentPage(1);
  };

  const handleRemoveFilter = (key: string) => {
    let newFilters = filters.filter((f: any) => f.key !== key);
    setFilters(newFilters);
    loadAppointments(newFilters, wordSearch);
  };

  const handleEdit = (id: string, appointmentsData: any) => {
    let appointmentFounded = appointmentsData.find((d: any) => d.id === id);
    setSelectedAppointment(appointmentFounded);
    setisEditModalVisible(true);
  };

  const handleSeeOrder = async (id: string, appointmentsData: any) => {
    let appointmentFounded = appointmentsData.find((d: any) => d.id === id);
    const order_id = appointmentFounded?.order_id;
    if (!!order_id) {
      try {
        setLoading(true);
        const response = await searchOrders({
          where: {
            ids: [order_id],
          },
        });
        const formattedOrder = formatOrder(response.orders[0]);
        setOrder(formattedOrder);
        orderDetailsModalControl.onOpen();
      } catch (error) {
        setOrder(null);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancel = async (id: string, appointmentsData: any) => {
    const appointment = appointmentsData.find((d: any) => d.id === id);
    setSelectedAppointment(appointment);
    setModalRefundTypeVisible(true);
  };

  const handleCloseEditModal = () => {
    if (isEditModalVisible) {
      setisEditModalVisible(false);
    }
  };

  useEffect(() => {
    setTimer(
      setTimeout(function () {
        loadAppointments(filters, wordSearch);
      }, 500)
    );
    return () => {
      clearTimeout(timer);
    };
  }, [wordSearch, currentPage, limit, filters, sort]);

  const updateAppointmentInList = (appointment_updated: IAppointment) => {
    const actions = [
      {
        name: "Editar",
        icon: <EditButton />,
        action: handleEdit,
      },
      {
        name: "Ver Pedido",
        icon: <SearchIcon />,
        action: handleSeeOrder,
      },
    ];

    const isFinancialUser = user.roles.find(
      (role) => role.name === "financeiro"
    );

    if (!appointment_updated.pack_id || isFinancialUser) {
      actions.push({
        name: "Cancelar",
        icon: <CancelButton />,
        action: handleCancel,
      });
    }

    const formattedAppointment = formatAppointment(appointment_updated);

    const updatedAppointment = {
      ...formattedAppointment,
      actions,
    };

    setData(
      data.map((element: any) => {
        if (element.id === updatedAppointment.id) {
          return updatedAppointment;
        }
        return element;
      })
    );

    setAppointments(
      appointments.map((app: any) => {
        if (app.id === appointment_updated.id) {
          return updatedAppointment;
        }
        return app;
      })
    );
  };

  const handleCancelApply = async (refund_type: RefundTypeEnum) => {
    if (!selectedAppointment) {
      return;
    }

    try {
      setRefunding(true);
      const appointment_updated = await cancelAppointment({
        appointment_id: selectedAppointment.id,
        refund_type: refund_type,
      });
      updateAppointmentInList(appointment_updated);
      setModalRefundTypeVisible(false);
      setSelectedAppointment(undefined);
      launchToast("Pedido cancelado com sucesso", "success");
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    } finally {
      setRefunding(false);
    }
  };

  const handleWordSearch = (event: string) => {
    setCurrentPage(1);
    setWordSearch(event);
  };

  return (
    <>
      {loading && <Loading />}
      <MainContainer>
        <PanelHeader
          title="Agenda"
          isFilterModalVisible={true}
          onClick={() => setisFilterModalVisible(true)}
        />
        <Search
          limit={limit}
          onSetPageSize={onSetPageSize}
          wordSearch={wordSearch}
          setSearch={handleWordSearch}
          isFilterForRegister={true}
          placeholder="Pesquisar por nome do tutor ou pet"
          isFilterForOrigin={true}
        />
        {filters.length >= 1 && filters[0].key !== "created" && (
          <FilterList filters={filters} removeFilter={handleRemoveFilter} />
        )}
        <Table headers={headers} data={data} />
        <Pagination
          totalPage={totalPage}
          pageIndex={currentPage}
          setPage={setCurrentPage}
        />
      </MainContainer>
      <FilterModal
        visible={isFilterModalVisible}
        onCloseModal={() => setisFilterModalVisible(false)}
        applyFilters={handleApplyFilter}
        reciviedFilter={filters}
      ></FilterModal>
      {isEditModalVisible && (
        <AppointmentModal
          visible={isEditModalVisible}
          appointment={selectedAppointment}
          updateAppointmentInList={(a) => updateAppointmentInList(a)}
          onCloseModal={() => handleCloseEditModal()}
        />
      )}
      {modalRefundTypeVisible && (
        <ChooseRefundTypeModal
          visible={modalRefundTypeVisible}
          loading={refunding}
          onCloseModal={() => setModalRefundTypeVisible(false)}
          onSubmit={(refund_type) => handleCancelApply(refund_type)}
        />
      )}
      {order && (
        <OrderDetailsModal
          isOpen={orderDetailsModalControl.isOpen}
          onClose={orderDetailsModalControl.onClose}
          order={order}
        />
      )}
    </>
  );
};

export default Appointments;
