import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import AddProductToReceiptForm, { AddProductToReceiptFormId } from '../forms/addProductToReceipts/AddProductToReceiptsForm';
import Button from 'components/Button';
import { IListProductReceipts, IProductReceipts } from '../constants/types';
import { useDisclosure } from '@chakra-ui/react';
import { columnAddProductsToReceiptsHeaders } from '../constants/conlumns';
import Table from "components/Table";
import { ReactComponent as CancelButton } from "../../../assets/svg/generic/cancelbutton.svg";
import AddProductToEditReceiptForm, { AddProductToEditReceiptFormId } from '../forms/UpdateReceipt/AddProductToEditReceiptsForm';
import RegisterStockModal from 'pages/Stock/modals/RegisterStockModal';



export type IAddProductsToReceiptsModalProps = {
    onSubmit: (product: IProductReceipts)  => void,
    isOpen: boolean;
    onClose: () => void;
    productsReceipt: []
    handleRemoveProduct: (id: number) => void
};

const AddProductsToEditReceiptsModal: React.FC<IAddProductsToReceiptsModalProps> = ({ onSubmit, isOpen, onClose, productsReceipt, handleRemoveProduct }) => {

    const createProductsModalControl = useDisclosure();

    return (
        <>
            <Modal visible={isOpen} closeModal={onClose}>
                <Styles.header>
                    <Text
                        text="Adicionar Produto"
                        color={colors.argon.darkBlue}
                        fontFamily="Open Sans"
                        size={17}
                        weight="600"
                    />
                    <Button onClick={createProductsModalControl.onOpen} text="Criar Produto"/>
                </Styles.header>
                <Styles.FormDiv>
                    <AddProductToEditReceiptForm onSubmit={onSubmit}/>
                </Styles.FormDiv>
                <Styles.row>
                    <Button text="Adicionar Produto" type="submit" form={AddProductToEditReceiptFormId} />
                </Styles.row>
                <Table
                    headers={columnAddProductsToReceiptsHeaders}
                    data={productsReceipt.map((product: IListProductReceipts) => ({
                        product: product.name,
                        stock_unit: product.stock_unit,
                        quantity: product.quantity,
                        unity_price: product.unity_price,
                        resale_price: product.resale_price,
                        actions: [
                        {
                            name: "remove",
                            icon: <CancelButton/>,
                            action: () => handleRemoveProduct(product.id),
                        },
                        ],
                    }))}
                />
                <Styles.rowButton
                    style={{
                    justifyContent: 'center',
                    }}
                >
                    <Button text="salvar" onClick={onClose} />
                </Styles.rowButton>
            </Modal>
            <RegisterStockModal
                isOpen={createProductsModalControl.isOpen}
                onClose={createProductsModalControl.onClose}
            />
        </>
    )
}

export default AddProductsToEditReceiptsModal