import styled from "styled-components";
import colors from "../../../shared/utils/constants/colors";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const FormDiv = styled.div`
  width: 90%;
`;

export const row = styled.div`
  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-left: 14px;
    margin-bottom: 24px;
  }
`;

export const rowButton = styled.div <{ margin?: string }>`
  display: flex;
  justify-content:space-between;
  margin: 0 auto ;
  margin-top: 70px;

  @media (min-width: 650px) {
    flex-direction: row;
    align-items: flex-end ;
    width: 60%;
    margin-left:${props => `${props?.margin}%` || "70%"};
  }
`;

export const field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
`;

export const line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.primary.light};
  margin-bottom: 24px;
  position: relative;
  top: -1px;
`;

export const TextResult = styled.button`
  margin-top: 32px;
  color: ${colors.gray.dark02};
  font-weight: bold;
`;