import styled from "styled-components";
import colors from "../../../../shared/utils/constants/colors";

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 16px;
  background-color: ${colors.gray.white};
  margin: 0 16px;
  width: 300px;
  border-radius: 8px;
  border: 2px solid transparent;
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  box-sizing: border-box;
  padding: 8px;
  min-height: 40px;
  margin-bottom: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    display: inline-block;
    b {
      color: ${colors.primary.default};
      font-size: 16px;
      margin-right: 8px;
    }
    color: ${colors.gray.dark02};
    font-size: 14px;
  }

  button {
    margin-left: auto;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
`;

export const Input = styled.input<{ length: number; }>`
  border: none;
  width: ${props => props.length * 10}px;
  color: ${colors.gray.dark02};
`;