import { gql } from "@apollo/client";
import client from "services/api-graphql";

export const LIST_SECTIONS_CEP_CATEGORIES = gql`
query ListCepsCategoriesSections($input: CepsCategoriesSectionInputType!) {
  listCepsCategoriesSections(input: $input) {
    totalPages
    data {
      cep_id
      start_cep
      end_cep
      region_name
      bath_and_grooming
      vaccines
      veterinarian
      region_id
    }
  }
}
`;

export const GET_SECTION_CEPS = gql`
query GetCepSectionCeps($getCepSectionCepsId: String!) {
  getCepSectionCeps(id: $getCepSectionCepsId) {
    id
    start_cep
    end_cep
    region_id
    ceps {
      cep_id
      start_cep
      end_cep
      region_name
      bath_and_grooming
      vaccines
      veterinarian
    }
  }
}
`;

export const UPDATE_STATIS_CEPS_CATEGORIES = gql`
mutation UpdateCespCategoriesStatus($input: UpdateCepsCategoriesStatusInputType!) {
  updateCepsCategoriesStatus(input: $input) {
    cepsCategoriesStatus {
      cep_id
      category_id
      status
    }
  }
}
`;

export const CREATE_CEP = gql`
mutation AddCep($endCep: String!, $startCep: String!, $regionId: String!) {
  addCep(end_cep: $endCep, start_cep: $startCep, region_id: $regionId) {
    id
    start_cep
    end_cep
    status
    created_at
    updated_at
    region_id
  }
}
`;

export const UPDATE_CEP = gql`
mutation UpdateCep($updateCepId: String!, $input: UpdateCepInputType!) {
  updateCep(id: $updateCepId, input: $input) {
    id
    start_cep
    end_cep
    region_id
  }
}
`;

export const GET_REGIONS = gql`
query Regions {
  regions {
    id
    name
  }
}
`;

export const CREATE_REGION = gql`
mutation AddRegion($name: String!) {
  addRegion(name: $name) {
    id
    name
  }
}
`;

export const DELETE_CEP = gql`
mutation DeleteCep($deleteCepId: String!) {
  deleteCep(id: $deleteCepId)
}
`;