import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import CreateCamaipaignForm, { CreateCampaignFormId } from '../forms/CreateCampaign/CreateCamapignForm';
import { ICreateCampaignFormData } from 'pages/Campaigns/constants/types';
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { CREATE_CAMPAIGN, LIST_CAMPAIGNS } from 'pages/Campaigns/graphql/CampaignQuery';


export type ICreateCampaignModalProps = {
    isOpen: boolean;
    onClose: () => void;
};


const CreateCampaignModal: React.FC<ICreateCampaignModalProps> = ({ isOpen, onClose }) => {

    const [createCampaign, { loading }] = useMutation(CREATE_CAMPAIGN, {
        refetchQueries: [
            LIST_CAMPAIGNS,
            'ListCampaigns'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: ICreateCampaignFormData,
            { reset }
        ) => {
            try {
                await createCampaign({
                    variables: {
                        input: {
                            name: payload.name,
                            start_date: payload.start_date,
                            end_date: payload.end_date,
                            type: payload.type,
                            description: payload.description
                        }
                    },
                });
                reset();
                onClose();
                launchToast('Campanha criada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createCampaign],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Nova Campanha"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <CreateCamaipaignForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={CreateCampaignFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default CreateCampaignModal