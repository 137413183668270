import React, { FC, useState } from "react";
import { Box, Flex, Text, Image, useToast, Icon } from "@chakra-ui/react";
import { BsFillPersonFill } from "react-icons/bs";
import IPartner from "types/IPartner";
import { OnOffButton } from "./ToggleButton";
import { deletePartner } from "../graphQL";

interface IPartnerProps {
  partner: IPartner;
  onClick?: () => void;

  partnersLoad: () => void;
}

export const ItemPartner: FC<IPartnerProps> = ({
  partner,
  onClick,

  partnersLoad,
}) => {
  const { logo_url, name, userCount, id } = partner;
  const [isOn, setIsOn] = useState<boolean>(true);
  const toast = useToast();

  const handleToggle = async () => {
    try {
      await deletePartner(id);
      setIsOn(!isOn);
      toast({
        title: "Parceiro desativado com sucesso!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      partnersLoad();
    } catch (error) {
      console.error(error);
      toast({
        title: "Erro ao desativar parceiro!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <OnOffButton isOn={isOn} handleToggle={handleToggle} />
      <Box
        onClick={() => {
          if (!isOn) return;
          else onClick && onClick();
        }}
        width={{ base: "100%", md: "200px" }}
        cursor={isOn ? "pointer" : "not-allowed"}
        borderWidth="2px"
        bg={"gray.100"}
        borderRadius="5px"
        height="140px"
        _hover={{
          boxShadow: "md",
        }}
      >
        <Image
          src={logo_url}
          width="100%"
          height="100px"
          objectFit="cover"
          borderTopRadius="2px"
          backgroundColor="gray.100"
        />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          padding="2"
          borderTopWidth="0"
          borderBottomRadius="4px"
        >
          <Text
            fontSize="14px"
            fontWeight="semibold"
            color="gray.800"
            marginRight="5px"
          >
            {name.substring(0, 15) + (name.length > 15 ? "..." : "")}
          </Text>
          <Flex
            alignItems="center"
            borderLeft={isOn ? "2px solid #E2E8F0" : "1px solid #E2E8F0"}
            paddingLeft="5px"
          >
            <Icon
              as={BsFillPersonFill}
              fontSize={16}
              style={{ marginRight: 4 }}
              color="gray.600"
            />

            <Text fontWeight="semibold" color="gray.600">
              {userCount}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
