import React from 'react';
import * as Styles from './styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from 'components/Button';


interface IModalConfirmationProps {
    isOpen: boolean;
    onClose: () => void;
    handleConfirm: () => void;
    paymentMethod: string,
    pendingPrice: number,
    paymentCard?: string,
    paymentReason: string
    loading?: boolean;
  }

const ConfirmationChoosePaymentModal: React.FC<IModalConfirmationProps> = ({
    isOpen,
    onClose,
    handleConfirm,
    paymentMethod,
    pendingPrice,
    paymentCard,
    paymentReason,
    loading
  })  => {

    return (
        <Modal visible={isOpen} closeModal={onClose} width={700}>
            <Styles.header>
                <Text
                    text={'Confirme a forma de pagamento'}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={24}
                    weight="600"
                />
            </Styles.header>
            <Styles.line></Styles.line>
            <Styles.row>
                <Text
                    text={`Valor a pagar: R$ ${Number(pendingPrice).toFixed(2).replace('.', ',')}`}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={20}
                    weight="600"
                />
            </Styles.row>
            <Styles.row>
                <Text
                    text={`Forma de pagamento:  ${paymentMethod}`}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={20}
                    weight="600"
                />
            </Styles.row>
            { paymentCard && <Styles.row>
                <Text
                    text={`Cartão: ${paymentCard}`}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={20}
                    weight="600"
                />
            </Styles.row>}
            <Styles.row>
                <Text
                    text={`Motivo:  ${paymentReason}`}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={20}
                    weight="600"
                />
            </Styles.row>
            <Styles.rowButton>
                <Button text="Cancelar"   onClick={onClose} />
                <Button text="Confrimar"  onClick={() => { handleConfirm(); onClose() }} />
            </Styles.rowButton>
        </Modal>
    )
}

export default ConfirmationChoosePaymentModal