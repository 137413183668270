
import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from '../constants/styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import AddProductsToReceiptsModal from './AddProductsToReceiptsModal';
import Button from 'components/Button';
import { useDisclosure } from '@chakra-ui/react';
import Table from "components/Table";
import { columnAddProductsToReceiptsHeaders } from '../constants/conlumns';
import { ICreateReceiptsFormData, IListProductReceipts, IProductReceipts, IReceipt } from '../constants/types';
import { LIST_RECEIPTS, UPDATE_RECEIPT } from '../graphql/ReceiptsQuery';
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { ReactComponent as CancelButton } from "../../../assets/svg/generic/cancelbutton.svg";
import UpdateReceiptsForm, { UpdateReceiptsFormId } from '../forms/UpdateReceipt/UpdateReceiptsForm';
import AddProductsToEditReceiptsModal from './AddProductsToEditReceiptsModal';
import CreateSupplierModal from './CrateSupplierModal';

export type IUpdateReceiptsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    receipt: IReceipt
};

const UpdateReceiptsModal: React.FC<IUpdateReceiptsModalProps> = ({ isOpen, onClose, receipt }) => {

    const [productsReceipt, setProductsReceipt] = useState([] as any)
    
    const addProductsToEditReceiptsModalControl = useDisclosure();
    const registerSupplierModalControl = useDisclosure();
    
    const handleAddProduct = (product: IProductReceipts) => {
        const isProductExist = productsReceipt.some((existingProduct: IProductReceipts) => existingProduct.id === product.id);
        if (isProductExist) {
            throw new Error('Produto duplicado');
        } else {
            setProductsReceipt([...productsReceipt, product]);
            launchToast('Produto adicionado', 'success');
        }
    }

    const handleRemoveProduct = (productId: number) => {
        setProductsReceipt(productsReceipt.filter((product: IProductReceipts) => product.id != productId ))
    }

    const priceMask = (price: string) => {
        const priceString = price.toString()
        const numericValue = priceString.replace(/\D/g, '');
        const formattedValue = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(Number(numericValue) / 100);
        return formattedValue
      }

    const [UpdateReceipt, { loading }] = useMutation(UPDATE_RECEIPT, {
        refetchQueries: [
            LIST_RECEIPTS,
            'Receipts'
        ],
    });

    const numberUnmask = (value: string) => {
        const formattedValue = value?.replace('R$', '').replace(/\./g, '').replace(',', '');;
        const numberFloat = parseFloat(formattedValue);
        return numberFloat
    }

    const normalizeTextFilter = (text: string) => {
        const normalizationMap: Record<string, string> = {
            "LITER": "Litros",
            "MILLILITRE": "Mililitros",
            "GRAM": "Gramas",
            "KILOGRAM": "Quilogramas",
            "MILIGRAM": "Miligramas",
            "UNITY": "Unidade"
        };
        return normalizationMap[text] || text;
      };

    
    useEffect(() => {
        setProductsReceipt(receipt.products.map( (product: IProductReceipts) => ({...product, unity_price: priceMask(product.cost_price), resale_price: priceMask(product.selling_price)})))
    }, [receipt]);

    const handleSubmit = useCallback(
        async (
            payload: ICreateReceiptsFormData,
            { reset }
        ) => {
            try {
                await UpdateReceipt({
                    variables: {
                        input: {
                            id: receipt.id,
                            emission_date: payload.emission_date,
                            supplier_id: payload.supplier_id,
                            products: payload.products.map( product => ({
                                product_id: product.id,
                                quantity: product.quantity,
                                unity_price: numberUnmask(product.unity_price),
                                resale_price: numberUnmask(product.resale_price)
                            })),
                            reason: payload.reason
                        }
                    },
                });
                reset();
                onClose();
                setProductsReceipt([] as any)
                launchToast('Nota fiscal atualizada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [UpdateReceipt],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
           <AddProductsToEditReceiptsModal
                onSubmit={handleAddProduct}
                isOpen={addProductsToEditReceiptsModalControl.isOpen}
                onClose={addProductsToEditReceiptsModalControl.onClose}
                productsReceipt={productsReceipt}
                handleRemoveProduct={handleRemoveProduct}
            />
            <CreateSupplierModal
                isOpen={registerSupplierModalControl.isOpen}
                onClose={registerSupplierModalControl.onClose}
            />
            <Styles.header>
                <Text
                    text="Editar Nota Fiscal"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <Button onClick={registerSupplierModalControl.onOpen} text="Cadastrar Fornecedor"/>
            </Styles.header>
            <Styles.FormDiv>
                <UpdateReceiptsForm 
                    onSubmit={handleSubmit} 
                    products={productsReceipt}
                    receipt={receipt}
                />
            </Styles.FormDiv>
            <Styles.row>
                <Button onClick={addProductsToEditReceiptsModalControl.onOpen} text="Adicionar Produto"/>
            </Styles.row>
            <Table
            headers={columnAddProductsToReceiptsHeaders}
            data={productsReceipt.map((product: IListProductReceipts) => ({
                    product: product.name,
                    stock_unit: normalizeTextFilter(product.stock_unit),
                    quantity: product.quantity,
                    unity_price: product.unity_price,
                    resale_price: product.resale_price,
                    actions: [
                        {
                            name: "remove",
                            icon: <CancelButton/>,
                            action: () => handleRemoveProduct(product.id),
                        },
                ],
            }))}
        />
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={UpdateReceiptsFormId} loading={loading}/>
            </Styles.rowButton>
        </Modal>
    )
}

export default UpdateReceiptsModal