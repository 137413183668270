import { IconButton } from '@chakra-ui/react';
import Button from 'components/Button';
import Modal from "components/Modal";
import Text from "components/Text";
import React, { useCallback } from 'react';
import { CgClose } from 'react-icons/cg';
import colors from "shared/utils/constants/colors";
import { launchToast } from 'shared/utils/launchToast';
import * as Styles from '../constants/styles';
import { APIFilters, EventFilterPortugueseTranslationEnum, IFilterFormData } from '../constants/types';
import FilterForm, { FilterFormId } from '../forms/Filters/FilterForm';

export type FilterModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleAddFilter: (variables?: Partial<{
    filterParams: {
      title?: string;
      subtitle?: string;
      name?: string;
      content?: string;
      is_active: boolean;
      titleOrCupon: string;
    };
    paginationParams: {
      limit: number;
      page: number;
    };
    orderParams: {
      field: string;
      order: string;
    };
}> | undefined) => void;
  onFilterSubmit: (filters: any) => void;
}

function FilterModal({
  isOpen,
  onClose,
  handleAddFilter,
  onFilterSubmit
}: FilterModalProps) {
  const handleSubmit = useCallback(
    async (
      payload: IFilterFormData,
      { reset }
    ) => {
      try {
        const activeFilters = [];
        
        const { end_filter, start_filter, ...rest } = payload;

        const APIFilters = rest as unknown as APIFilters;
        
        for (const key in payload) {
          if (key === 'event_start_date') {
            if (payload[key] !== '') {
              const comparison = payload['start_filter'] === 'gte'
              ? 'Maior ou igual a' 
              : 'Menor ou igual a'

              activeFilters.push({ key: 'Data de início', value: `${comparison} ${payload[key]}`});

              APIFilters[key] = {
                date: payload[key],
                filter: payload['start_filter'],
              };
            } else {
              delete APIFilters.event_start_date;
            }
          } else if (key === 'event_end_date') {
            if (payload[key] !== '') {
              const comparison = payload['end_filter'] === 'gte'
                ? 'Maior ou igual a'
                : 'Menor ou igual a'

              activeFilters.push({ key: 'Data de término', value: `${comparison} ${payload[key]}`});

              APIFilters[key] = {
                date: payload[key],
                filter: payload['end_filter'],
              };
            } else {
              delete APIFilters.event_end_date;
            }
          } else if (payload[key as keyof IFilterFormData] === 'Sim') {
            activeFilters.push({ key: 'Ativo', value: true });
          } else if (payload[key as keyof IFilterFormData] === 'Não') {
            activeFilters.push({ key: 'Ativo', value: false });
          } else if (payload[key as keyof IFilterFormData] === 'lte' || payload[key as keyof IFilterFormData] === 'gte') {
            
          } else if (payload[key as keyof IFilterFormData] !== '') {
            activeFilters.push({ key: EventFilterPortugueseTranslationEnum[key as keyof APIFilters], value: payload[key as keyof IFilterFormData] });
          } else {
            delete APIFilters[key as keyof APIFilters];
          }
        }

        onFilterSubmit(activeFilters);
  
        handleAddFilter({
          filterParams: {
            ...APIFilters,
            is_active: payload.is_active === 'Sim',
            titleOrCupon: ''
          },
          paginationParams: {
            limit: 10,
            page: 1,
          }
        });
        reset();
        onClose();
      } catch (error: any) {
        launchToast(error.message, "error");
      }
    }, []
  );

  return (
    <Modal visible={isOpen} closeModal={onClose}>
      <Styles.header>
        <Text
          text="Filtrar eventos"
          color={colors.argon.darkBlue}
          fontFamily="Open Sans"
          size={17}
          weight="600"
        />
        <IconButton
          aria-label='Fechar modal'
          icon={<CgClose />}
          onClick={onClose}
        />
      </Styles.header>
      <Styles.line></Styles.line>
      <Styles.FormDiv>
        <FilterForm onSubmit={handleSubmit} />
      </Styles.FormDiv>
      <Styles.rowButton>
        <Button text="Cancelar" onClick={onClose} />
        <Button
          text="Filtrar"
          type="submit"
          form={FilterFormId}
          // loading={loading}
        />
      </Styles.rowButton>
    </Modal>
  );
};

export default FilterModal;
