import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import {  CREATE_REGION } from '../graphql/CepsCategoriesQuery';
import { ICreateRegionFormData } from '../constants/types';
import CreateRegionForm, { CreateRegionFormId } from '../forms/createRegion/createRegionForm';


type ICreateRegionModalProps = {
    isOpen: boolean;
    onClose: () => void;
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<any>>
};


const CreateRegionModal: React.FC<ICreateRegionModalProps> = ({ isOpen, onClose,  refetch }) => {

    const [createRegion, { loading }] = useMutation(CREATE_REGION);

    const handleSubmit = useCallback(
        async (
            payload: ICreateRegionFormData,
            { reset }
        ) => {
            try {
                await createRegion({
                    variables: {
                        name: payload.name
                    }
                });
                refetch()
                reset();
                onClose();
                launchToast('Região criada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createRegion],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Nova Região"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <CreateRegionForm onSubmit={handleSubmit}/>
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={CreateRegionFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default CreateRegionModal