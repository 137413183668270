import React, {  useRef } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import { IRegisterCategoryFormData } from 'pages/Stock/constants/types';
import { RegisterCategoryFormValidationSchema } from './RegisterCategoryValidations';

export type IRegisterCategoryFormProps = {
    onSubmit: (payload: IRegisterCategoryFormData, options: { reset: () => void }) => Promise<void>;
}

export const RegisterCategoryFormId = 'register-category-form';

const RegisterCategoryForm: React.FC<IRegisterCategoryFormProps> = ({ onSubmit }) => {

    const registerCategoryFormRef = useRef<FormHandles>(null);

    const validateFields = async (
        payload: IRegisterCategoryFormData,
        options: { reset: () => void }
    ) => {
        try {
            await RegisterCategoryFormValidationSchema.validate(payload, { abortEarly: false });
            onSubmit(payload, options);
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            registerCategoryFormRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };  

    return (
        <>
            <Form onSubmit={validateFields} ref={registerCategoryFormRef} id={RegisterCategoryFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Nome</Label>
                        <InputRef
                            name="name"
                            placeholder="Digite o nome da categoria"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default RegisterCategoryForm