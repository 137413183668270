import * as Yup from "yup";

export const AddProductToReceiptsValidationSchema = Yup.object().shape({
    product_id: Yup.string()
        .required('Produto é obrigatório'),
    quantity: Yup.number()
        .min(0.0001, "Quantidade não pode ser negativa")
        .required('Quantidade é obrigatório'),
    unity_price: Yup.number()
        .min(0,"Preço de compra não pode ser menor que zaro"
        )
        .required('Preço de compra é obrigatório'),
    resale_price: Yup.number()
        .min(
            Yup.ref("unity_price"),
            "Preço de venda não pode ser menor que o preço de compra"
        )
        .required('Preço de venda é obrigatório'),
});