import Modal from "components/Modal";
import React, { useCallback } from "react"
import * as Styles from '../constants/styles';
import Text from "components/Text";
import { IconButton } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import colors from "shared/utils/constants/colors";
import Button from "components/Button";
import { launchToast } from "shared/utils/launchToast";
import { APIFiltersAnamnesis, AnamnesisFilterTranslateEnum, IFilterAnamnesisFormData } from "../constants/types";
import FilterAnamnesisForm, { FilterAnamnesisFormId } from "../forms/filterAnamnesis/FilterReceiptsForm";


export type FilterAnamnesisModalProps = {
    isOpen: boolean;
    onClose: () => void;
    handleAddFilter: (variables?: Partial<{
        filterParams: {
            customer_id?: string;
            professional_id?: string;
            search: string
        };
        paginationParams: {
            limit: number;
            page: number;
        };
    }> | undefined) => void;
    onFilterSubmit: (filters: any) => void;
}


const FilterAnamnesisModal = ({
    isOpen,
    onClose,
    handleAddFilter,
    onFilterSubmit,
}: FilterAnamnesisModalProps) => {

    const handleSubmit = useCallback(
        async (
            payload: IFilterAnamnesisFormData,
            { reset }
        ) => {
            try {
                const activeFilters = [];

                const {...rest } = payload;

                const extractValues = (data: IFilterAnamnesisFormData): { [key: string]: any } => {
                    const result: { [key: string]: any } = {};
                  
                    for (const key in data) {
                      if (data.hasOwnProperty(key)) {
                        if (data[key] && typeof data[key] === 'object' && 'value' in data[key]) {
                          result[key] = data[key].value;
                        } else {
                          result[key] = data[key];
                        }
                      }
                    }
                  
                    return result;
                  };

                  const APIFilters = extractValues(rest)
                  

                for (const key in payload) {
                    if (payload[key] !== '' && payload[key].value !== undefined) {
                        activeFilters.push({ key: AnamnesisFilterTranslateEnum[key as keyof APIFiltersAnamnesis], value: payload[key]});
                    } else {
                        delete APIFilters[key as keyof APIFiltersAnamnesis];
                    }
                }

                onFilterSubmit(activeFilters);


                handleAddFilter(APIFilters);
                reset();
                onClose();
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        }, []
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Filtrar Anamnesis"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <IconButton
                    aria-label='Fechar modal'
                    icon={<CgClose />}
                    onClick={onClose}
                />
            </Styles.header>
            <Styles.line></Styles.line>
            <Styles.FormDiv>
                <FilterAnamnesisForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button
                    text="Filtrar"
                    type="submit"
                    form={FilterAnamnesisFormId}
                />
            </Styles.rowButton>
        </Modal>
    )
}

export default FilterAnamnesisModal