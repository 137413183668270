export const columnHeaders = [
  { key: "street", name: "Endereço" },
  { key: "coupon", name: "Cupom" },
  { key: "event_start_date", name: "Inicio" },
  { key: "event_end_date", name: "Término" },
  { key: "title", name: "Título" },
  { key: "subtitle", name: "Subtítulo" },
  { key: "is_active", name: "Status" },
  { key: "actions", name: "ações" },
];
