import { useField } from '@unform/core';
import Text from 'components/Text';
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import colors from 'shared/utils/constants/colors';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  containerStyle?: object;
  icon?: any;
  onclickIcon?: () => void;
  onBlurExecute?: (value: string) => void;
}

export default function TextArea({
  name,
  containerStyle,
  icon: Icon,
  onclickIcon,
  onBlurExecute,
  disabled,
  ...rest
}: InputProps) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [isFocused, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, registerField, error } = useField(name)

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback((): void => {
    setIsFocus(false);

    if (onBlurExecute) {
      onBlurExecute(textAreaRef.current?.value || '');
    }

    setIsFilled(!!textAreaRef.current?.value);
  }, [onBlurExecute]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef,
      getValue: ref => {
        return ref.current.value
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <>
      <Container
          style={containerStyle}
          hasIcon={!!Icon}
          isErrored={!!error}
          isFocused={isFocused}
          isFilled={isFilled}
          isDisabled={disabled ? disabled : false}
        >
          {Icon && <Icon />}

        <textarea
          name={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={textAreaRef}
          defaultValue={defaultValue}
          disabled={disabled}
          style={{
            width: '100%',
            height: '150px',
            padding: '12px 20px',
            boxSizing: 'border-box',
            border: '2px solid #ccc',
            borderRadius: '4px',
            fontSize: '16px',
            resize: 'none',
            mixBlendMode: 'normal',
            borderColor: `${colors.argon}`,
            fontFamily: 'Open Sans',
          }}
          {...rest}
        />
      </Container>

      {error && (
        <Text
          text={error}
          color="#FF6340"
          fontFamily="Open Sans"
          align="left"
          weight="400"
          size={12}
          marginBottom={16}
        />
      )}
    </>
  )
}