import axios from 'axios';
import { launchToast } from '../shared/utils/launchToast';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('@zazuu:admin:user:token');
      localStorage.removeItem('@zazuu:admin:user');
      localStorage.removeItem('@zazuu:admin:customer');
      localStorage.removeItem('@zazuu:cart');

      launchToast('Sua sessão expirou', 'warning');

      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export default api;
