import styled from 'styled-components';
import colors from '../../../../shared/utils/constants/colors';

export const TableContainer = styled.div`
  max-height: 55vh;
  max-width: 81vw;
  overflow-y: auto;
  overflow-x: auto;
  margin: 16px 0;
`;

export const StyledTable = styled.table`
  border: 1px solid ${colors.gray.light02};
  border-collapse: separate;
  border-spacing: 0;
  min-width: max-content;
`;

export const StyledTHead = styled.thead``;

export const StyledTH = styled.th`
  position: sticky;
  top: 0;
  background: #f6f9fc;
  color: #8898aa;
  border: 1px solid ${colors.gray.light02};
  padding: 8px;
`;

export const StyledTBody = styled.tbody``;

export const StyledTR = styled.tr``;

export const StyledTD = styled.td`
  border: 1px solid ${colors.gray.light02};
  padding: 8px;
  color: ${colors.gray.dark01};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const PageButton = styled.button`
  display: flex;
  width: 36px;
  height: 36px;
  border: 1px solid ${colors.primary.default};
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  color: ${colors.gray.dark02};
  margin-left: 8px;
`;

