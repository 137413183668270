import Modal from 'components/Modal';
import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../constants/styles';
import { ICampaign, IDonationCampaigns } from 'pages/Campaigns/constants/types';
import { Form } from "@unform/web";
import { Label } from "components/Label/styles";
import InputRef from "components/InputRef";
import Text from "components/Text";
import colors from 'shared/utils/constants/colors';
import TextArea from 'components/TextArea';
import { FormHandles } from '@unform/core';
import Table from "components/Table";
import { columnCampaignReportHeaders } from '../constants/conlumns';
export type IReportCampaignModalProps = {
    isOpen: boolean;
    onClose: () => void;
    campaign: ICampaign;
};

export const ReportCampaignFormId = 'report-campaign-form';

const ReportCampaignModal = ({
    isOpen,
    onClose,
    campaign,
}: IReportCampaignModalProps) => {   

    const reportCampaignFormRef = useRef<FormHandles>(null);

    useEffect(() => {
        reportCampaignFormRef.current?.setData({
            name: campaign.name,
            description: campaign.description,
            start_date: formatDateTime(campaign.start_date),
            end_date: formatDateTime(campaign.end_date),
            is_active: campaign.is_active ? 'Sim' : 'Não',
            type: normalizeLabel(campaign.type)
        });
    }, [campaign]);


    const normalizeLabel = (roleLabel: string) => {
        const normalizationMap: Record<string, string> = {
            "DONATE": "Doação",
        };
        return normalizationMap[roleLabel] || roleLabel;
    };

    function formatDateTime(dateTimeString: string): string {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }

    const handleSubmit = () => {
        return true
    }

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.FormDiv>
            <Form onSubmit={handleSubmit}  ref={reportCampaignFormRef} id={ReportCampaignFormId}>
            <Styles.header>
                <Text
                    text="Relatorio da campanha"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.row>
                <Styles.field>
                    <Label>Nome</Label>
                    <InputRef
                        name="name"
                        containerStyle={{ width: "33rem" }}
                        disabled
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Modelo</Label>
                    <InputRef
                        name="type"
                        containerStyle={{ width: "33rem" }}
                        disabled
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Ativo</Label>
                    <InputRef
                        name="is_active"
                        containerStyle={{ width: "33rem" }}
                        disabled
                    />
                </Styles.field>
            </Styles.row>
            <Styles.row>
                <Styles.field>
                    <Label>Data de início</Label>
                    <InputRef
                        containerStyle={{ width: "33rem" }}
                        name="start_date"
                        type="date"
                        disabled
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Data do término </Label>
                    <InputRef
                        containerStyle={{ width: "33rem" }}
                        name="end_date"
                        type="date"
                        disabled
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Descrição</Label>
                        <TextArea
                        containerStyle={{ width: "33rem" }}
                        name="description"
                        disabled
                    />
                </Styles.field>
            </Styles.row>
        </Form>
        </Styles.FormDiv>
        <Table
            headers={columnCampaignReportHeaders}
            data={campaign.donations.map((donation: IDonationCampaigns) => ({
                    user: donation.user.name,
                    paid_price: donation.order.paid_price,
                    donate: donation.order.donated,
                    id: donation.order.id
            }))}
        />
        </Modal>
    );
};

export default ReportCampaignModal;