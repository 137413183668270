import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FaEdit, FaSave } from "react-icons/fa";
import colors from "../../../../shared/utils/constants/colors";
import { launchToast } from "../../../../shared/utils/launchToast";
import processError from "../../../../shared/utils/processError";
import IAppointment from "../../../../types/IAppointment";
import { Card, Input, Row } from "./styles";

type IProps = {
  index: number;
  appointment: IAppointment;
  handleEditAppointment: (payload: IAppointment) => IAppointment;
};

const ItemList = ({ appointment, index, handleEditAppointment }: IProps) => {
  const [editing, setEditing] = useState(false);
  const [executionTime, setExecutionTime] = useState(
    appointment.execution_time
  );
  const [hour, setHour] = useState<string>(
    appointment.hour || "sem hora definida"
  );

  // eslint-disable-next-line
  const handleAction = async () => {
    if (editing) {
      try {
        const appointment_updated = handleEditAppointment({
          ...appointment,
          execution_time: executionTime,
          hour: hour,
        });
        setHour(appointment_updated.hour || "");
        setExecutionTime(appointment_updated.execution_time);
      } catch (error) {
        launchToast(processError(error, "GRAPHQL").message, "error");
        setExecutionTime(appointment.execution_time);
      } finally {
        setEditing(false);
      }
    } else {
      setEditing(true);
      setHour(appointment.hour || "");
      setExecutionTime(appointment.execution_time);
    }
  };

  return (
    <Draggable draggableId={appointment.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card>
            <Row>
              <p>
                <b>Serviço:</b>
                {appointment.service.name}
              </p>
            </Row>
            <Row>
              <p>
                <b>Habilidades:</b>
                {appointment.skills_necessary.map(
                  (skill, index) => `${index > 0 ? ", " : ""}${skill}`
                )}
              </p>
            </Row>
            <Row>
              <p>
                <b>Tutor:</b>
                {appointment.customer.user.name}
              </p>
            </Row>
            <Row>
              <p>
                <b>Pet:</b>
                {`${appointment.pet.name} - ${appointment.pet.specie.name} - ${
                  appointment.pet.breed?.name || "SRD"
                }`}
              </p>
            </Row>
            <Row>
              <p style={{ color: colors.suport.alert }}>
                <b>Endereço:</b>{" "}
                {`${appointment?.address?.street || ""}, ${
                  appointment?.address?.number || "SN"
                } ${
                  appointment?.address?.complement
                    ? `- ${appointment?.address?.complement}`
                    : ""
                } - ${appointment?.address?.neighborhood || ""}`}
              </p>
            </Row>
            <Row>
              <p style={{ color: colors.suport.warning }}>
                <b>Periodo:</b>
                {`${appointment.period || "sem periodo definido"}`}
              </p>
            </Row>
            <Row>
              <p>
                <b>Hora:</b>
                <Input
                  type="text"
                  disabled={!editing}
                  value={
                    editing ? hour : appointment.hour || "sem hora definida"
                  }
                  length={!!appointment.hour ? appointment.hour?.length : 14}
                  onChange={(e) => setHour(e.target.value)}
                />
              </p>
            </Row>
            <Row>
              <p>
                <b>Tempo:</b>
                <Input
                  type="number"
                  disabled={!editing}
                  value={editing ? executionTime : appointment.execution_time}
                  length={executionTime.toString().length}
                  onChange={(e) => setExecutionTime(Number(e.target.value))}
                />
                minutos
              </p>
              <button onClick={() => handleAction()}>
                {editing ? (
                  <FaSave size={14} color={colors.suport.warning} />
                ) : (
                  <FaEdit size={14} color={colors.gray.dark02} />
                )}
              </button>
            </Row>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default ItemList;
