import { gql } from "@apollo/client";
import client from "../api-graphql";
import ICondominium from "../../types/ICondominium";
import { ICondominiumsDiscountsCreate } from "../../types/ICondominiumsDiscounts";

export const createCondominium = async (input: ICondominium) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation ($input: CreateCondominiumInputType!) {
        createCondominium(input: $input) {
          id
          name
          cep
          city
          neighborhood
          start_number
          end_number
          state
          street
          discounts {
            id
            amount
            condominium_id
            status
            type
            type_value
          }
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: "network-only",
  });

  return data;
};

export const loadCondominiums = async () => {
  const { data } = await client.query({
    query: gql`
      query {
        condominiuns {
          id
          name
          cep
          city
          neighborhood
          start_number
          end_number
          state
          street
          discounts {
            id
            amount
            condominium_id
            status
            type
            type_value
            fullType {
              id
              name
            }
          }
        }
      }
    `,
    fetchPolicy: "network-only",
  });

  return data.condominiuns;
};

export const createCondominiumDiscount = async (
  input: ICondominiumsDiscountsCreate
) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation ($input: CreateCondominiumDiscountsInputType!) {
        createCondominiumDiscount(input: $input) {
          id
          status
          amount
          type
          type_value
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: "network-only",
  });

  return data;
};

export const removeCondominium = async (condominimId: string) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation ($condominimId: String!) {
        removeCondominium(condominimId: $condominimId)
      }
    `,
    variables: {
      condominimId,
    },
    fetchPolicy: "network-only",
  });

  return data;
};

export const removeCondominiumDiscount = async (
  condominiumDiscountId: string
) => {
  await client.mutate({
    mutation: gql`
      mutation ($condominiumDiscountId: String!) {
        removeCondominiumDiscount(condominiumDiscountId: $condominiumDiscountId)
      }
    `,
    variables: {
      condominiumDiscountId,
    },
    fetchPolicy: "network-only",
  });
};
