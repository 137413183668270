import React, { useCallback} from 'react';
import * as Styles from '../../constants/styles';
import Button from "components/Button";
import { launchToast } from 'shared/utils/launchToast';
import { useMutation } from '@apollo/client';
import { IRegisterBrandFormData } from 'pages/Stock/constants/types';
import RegisterBrandForm, { RegisterBrandFormId } from 'pages/Stock/forms/registerBrand/RegisterBrandForm';
import { CREATE_BRAND } from 'pages/Stock/graphql/StockQuery';

export type IRegisterBrand = {
    isOpen: boolean;
    onClose: () => void;
};


const RegisterBrandTab: React.FC<IRegisterBrand> = ({ isOpen, onClose}) => {

    const [createBrand, { loading }] = useMutation(CREATE_BRAND, {});

    const handleSubmit = useCallback(
        async (
            payload: IRegisterBrandFormData,
            { reset }
        ) => {
            try {
                await createBrand({
                    variables: {
                        input: {
                            name: payload.name,
                        }
                    },
                });
                reset();
                launchToast('Marca registrada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createBrand],
    );

    return (
        <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}
        >

            <Styles.FormDiv>
                <RegisterBrandForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton
             style={{
                width: "33rem"
            }}
            >
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={RegisterBrandFormId} loading={loading} />
            </Styles.rowButton>
        </div>
    )
}

export default RegisterBrandTab