import * as Yup from "yup";

export const EditBalanceWalletFormValidations = Yup.object().shape({
    alteredReason: Yup.string()
        .required('Motivo é obrigatório')
        .max(255, 'O nome pode ter no máximo 255 caracteres'),
    amount: Yup.number()
        .required()
        .min(1, "O valor tem que ser maior que R$ 0,00"),
    action: Yup.string()
            .required('Selecione uma ação')
});