import styled from 'styled-components';

import loginbg from '../../assets/loginbg.png';

import colors from '../../shared/utils/constants/colors';

export const Container = styled.div`
  flex: 1;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #d7e5e0, #d9e6e1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 80vh;
  background-image: url(${loginbg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
`;

export const ContainerFooter = styled.div`
  background: linear-gradient(90deg, #d7e5e0, #d9e6e1);
  width: 150%;
  height: 100vh;
  min-height: calc(100vh + 500px);
  transform: rotate(-4deg);
  position: absolute;
  bottom: 0;
  top: 40%;
`;

export const WelcomeTextDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4px;
`;

export const Content = styled.div`
  position: absolute;
  z-index: 2;
  background: ${colors.argon.textColorLight02};
  border-radius: 6px;
  top: 30%;
  box-shadow: 0 0 32px #8898AA26;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 33px;
  padding-right: 33px;
  width: 40%;
`;

export const FormDiv = styled.div`
  width: 90%;
  margin-top: 16px;
`;

export const KeepConnectedDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;
  margin-bottom: 26px;
`;

export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${colors.argon.white};
  box-shadow: 0 1px 3px ${colors.argon.textColorLight};
  cursor: pointer;
`;

export const OptionsDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -30px;
  cursor: pointer;
`;
