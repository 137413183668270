import styled from 'styled-components';
import colors from '../../../../shared/utils/constants/colors';

export const Container = styled.div`
  flex: 1;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #d7e5e0, #d9e6e1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const rowButton = styled.div`
  display: flex;
  justify-content:space-between;
  margin-top: 50px;
`;

export const WhiteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid ${colors.argon.secondaryButton};
  border-radius: 4px;

  height: 47px;

  background: #FFFFFF;

  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  min-width: 120px;
  padding: 10px;

  color: ${colors.argon.secondaryButton};
  transition: background 0.2s;
  box-shadow: 0 6px 8px ${colors.argon.textColorGray};
  margin-right: 16px;

  :hover {
    transform: scale(1.05);
    background: ${colors.primary.background};
  }
`;
