import React, { FC, useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { BiTrashAlt } from "react-icons/bi";
import styled from "styled-components";
import IPartnerDiscount from "types/IPartnerDiscount";
import { removePartnerDiscount } from "../graphQL";
import { toast } from "react-toastify";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px;
  height: 250px;
  overflow-y: auto;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
`;

const DropdownIcon = styled.span`
  margin-left: 5px;
`;

const DropdownContent = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const TabHeader = styled.div`
  background-color: #f5f5f5;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: bold;
`;

const DiscountItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #333;
    margin-right: 10px;
  }
`;

interface IProps {
  discounts: IPartnerDiscount[];
  setDiscounts: (discounts: IPartnerDiscount[]) => void;
  isOpenDefault?: boolean;
  isNotEditable?: boolean;
}

export const DiscountsDropdown: FC<IProps> = ({
  discounts,
  setDiscounts,
  isOpenDefault = false,
  isNotEditable = false,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleRemoveDiscount = async (discountId: string) => {
    try {
      await removePartnerDiscount(discountId);
      toast.success("Desconto removido com sucesso!");
      setDiscounts(discounts.filter((discount) => discount.id !== discountId));
    } catch (error) {
      toast.error("Erro ao remover desconto!");
    }
  };

  return (
    <>
      <DropdownHeader onClick={toggleDropdown}>
        <span style={{ color: "#333" }}>Descontos Cadastrados: </span>
        {isOpen ? (
          <DropdownIcon>
            <RiArrowDropUpLine fontSize={30} />
          </DropdownIcon>
        ) : (
          <DropdownIcon>
            <RiArrowDropDownLine fontSize={30} />
          </DropdownIcon>
        )}
      </DropdownHeader>
      <DropdownContainer>
        {isOpen && (
          <DropdownContent>
            {discounts.length > 0 ? (
              discounts.map((discount) => {
                let nameType =
                  discount.discountType !== null
                    ? discount.discountType.name
                    : "";
                return (
                  <div key={discount.id}>
                    <TabHeader>
                      {String(discount.type) === "CATEGORY_ID"
                        ? "Categoria"
                        : String(discount.type) === "SERVICE_ID"
                        ? "Serviço"
                        : String(discount.type) === "PACK_BIWEEKLY"
                        ? "Pacote Quinzenal"
                        : String(discount.type) === "PACK_WEEKLY"
                        ? "Pacote Semanal"
                        : "Não definido"}
                      {nameType !== "" && " - "}
                      {nameType}
                    </TabHeader>

                    <DiscountItem>
                      <span style={{ color: "#888" }}>Tipo de desconto:</span>
                      <span style={{ color: "#333" }}>
                        {String(discount.discount_type) === "PERCENTAGE"
                          ? "Porcentagem"
                          : "Absoluto"}
                      </span>
                      <span style={{ color: "#888" }}>Valor:</span>
                      <span style={{ color: "#333" }}>
                        {discount.amount.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </span>
                      {String(discount.discount_type) === "PERCENTAGE" && (
                        <>
                          <span style={{ color: "#888" }}>Porcentagem:</span>
                          <span style={{ color: "#333" }}>
                            {discount.partner_percentage_paid} %
                          </span>
                        </>
                      )}
                      <span style={{ color: "#888" }}>Status:</span>
                      <span style={{ color: "#333" }}>{discount.status}</span>
                      {!isNotEditable && (
                        <BiTrashAlt
                          fontSize={20}
                          style={{ color: "#888", cursor: "pointer" }}
                          onClick={() => handleRemoveDiscount(discount.id)}
                        />
                      )}
                    </DiscountItem>
                  </div>
                );
              })
            ) : (
              <div>
                <TabHeader>Nenhum desconto cadastrado</TabHeader>
              </div>
            )}
          </DropdownContent>
        )}
      </DropdownContainer>
    </>
  );
};
