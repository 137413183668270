import styled from "styled-components";
import colors from "../../shared/utils/constants/colors";
export const Tabs = styled.div`
  overflow: hidden;
  background: #fff;
  font-family: Open Sans;
  height: 3em;
`;

export const Tab = styled.button<{ active: boolean }>`
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 10%;
  position: relative;
  border-radius: 8px 8px 0px 0px; 

  margin-right: 0.1em;
  font-size: 1em;
  border-bottom: ${props => (props.active ? "none" : "")};
  background-color: ${props => (props.active ? colors.primary.light : colors.gray.white)};
  height: ${props => (props.active ? "3em" : "2.6em; top:.4em")};
  transition: background-color 0.5s ease-in-out;
`;
export const Content = styled.div <{ active: boolean }>`
  ${props => (props.active ? "" : "display:none")}
`;
