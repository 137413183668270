import styled from "styled-components";

export const FormDiv = styled.div`
  width: 100%;
`;

export const Content = styled.div``;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start ;
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
`;

export const Row = styled.div`
  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start ;
    width: 100%;
    margin-left: 14px;  
    margin-bottom: 24px;
  }
`;

