import { gql } from "@apollo/client";

export const UPDATE_COUPON_STATUS = gql`
  mutation EditCoupon($code: String!, $newStatus: Boolean) {
    editCoupon(code: $code, newStatus: $newStatus) {
      status
      id
    }
  }
`;

export const CREATE_COUPON = gql`
  mutation CreateCoupon($data: CouponType!) {
    createCoupon(data: $data) {
      id
    }
  }
`;

export const COUPOM_SEGMENTATION = gql`
  mutation coupomSegmentation(
    $couponId: String!
    $filters: FilterCouponsSegmentationInputType
  ) {
    coupomSegmentation(coupon_id: $couponId, filters: $filters) {
      id
    }
  }
`;

export const ADD_COUPOM_TO_CUSTOMER = gql`
  mutation AddCoupomToCustomersByCpf($cpf: String!, $couponId: String!) {
    addCoupomToCustomersByCpf(cpf: $cpf, coupon_id: $couponId) {
      id
    }
  }
`;

export const REMOVE_CUSTOMER = gql`
  mutation Mutation($cpf: String!, $couponId: String!) {
    removeCoupomToCustomersByCpf(cpf: $cpf, coupon_id: $couponId) {
      id
    }
  }
`;
