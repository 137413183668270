import * as Yup from 'yup';

export const CreateCepFormValidationSchema = Yup.object().shape({
    startCep: Yup.string()
        .required('Cep inicial obrigatório')
        .length(5, 'O complemento do cep inicial deve ter 5 caracteres'),
    endCep: Yup.string()
        .required('Cep final obrigatório')
        .length(5, 'O complemento do cep final ter 5 caracteres'),
});
