import { useField } from "@unform/core";
import React, { useEffect, useRef, useState } from "react";
import { components, OptionTypeBase, Props as SelectProps } from "react-select";
import { ReactSelectStyled } from "./styles";
import Text from "../../components/Text";

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  style?: object;
}

const Select: React.FC<Props> = ({
  name,
  style,
  multi = false,
  error,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [className, setClassName] = useState("react-select");

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
      setValue: (ref: any, option: OptionTypeBase) => {
        ref.state.value = option;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  useEffect(() => {
    if (error === undefined || error === "" || error.length <= 0) {
      setClassName("react-select");
    } else {
      setClassName("error");
    }
  }, [error]);

  return (
    <div style={style}>
      <ReactSelectStyled
        ref={selectRef}
        defaultValue={defaultValue}
        style={style}
        classNamePrefix={className}
        isMulti={multi}
        hideSelectedOptions={false}
        components={{ MultiValue }}
        menuPosition="fixed"
        {...rest}
      />
      {error && (
        <Text
          text={error}
          color="#FF6340"
          fontFamily="Open Sans"
          align="left"
          weight="400"
          size={12}
          marginTop={1}
          marginBottom={16}
        />
      )}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }: any) => {
  const maxToShow = 1;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x: any) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const MoreSelectedBadge = ({ items }: any) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} ${length !== 1 ? "itens" : "item"}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

export default Select;
