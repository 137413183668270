import { Form } from "@unform/web";
import React, {  useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Styles from '../../constants/styles';
import { Label } from "components/Label/styles";
import InputRef from "components/InputRef";


export type IFiltersStockFormProps = {
    onSubmit: (payload: IFilterStock, { reset }: any) => Promise<void>
}

export interface IFilterStock {
    [key: string]: string | number | boolean
}

export const FiltersStockFormId = 'filters-stock--form';


const FiltersStockForm = ({ onSubmit }: IFiltersStockFormProps) => {
    const filtersStockformRef = useRef<FormHandles>(null)

    const validateFields = async (
        payload: IFilterStock,
        options: { reset: () => void }
    ) => {
        const nonEmptyKeys = Object.keys(payload).filter(key => payload[key] !== "");
        const filteredPayload: IFilterStock = nonEmptyKeys.reduce(
            (result, key) => {
                result[key] = payload[key];
                return result;
            },
            {} as IFilterStock
        );
        onSubmit(filteredPayload, options);
    };

    return (
        <Form onSubmit={validateFields} ref={filtersStockformRef} id={FiltersStockFormId} >
            <Styles.row>
                <Styles.field>
                    <Label>SKU</Label>
                        <InputRef
                            name="manufacturer_sku_code"
                            placeholder="Digite o código SKU"
                            containerStyle={{ width: "33rem" }}
                        />
                </Styles.field>
            </Styles.row>
        </Form>
    )
}

export default FiltersStockForm