import { gql } from "@apollo/client";

export const CREATE_RECEIPT = gql`
  mutation CreateReceipt( $input: CreateReceiptInputType!) {
    createReceipt(input: $input) {
      number
      emission_date
      supplier_id
    }
  }
`;

export const UPDATE_RECEIPT = gql`
 mutation UpdateReceipt($input: UpdateReceiptInputType!) {
  updateReceipt(input: $input) {
    id
    number
    total_price
  }
}
`;


export const LIST_RECEIPTS = gql`
  query Receipts(
    $orderParams: ListReceiptsOrderingInputType,
    $filterParams: ListReceiptsInputType,
    $paginationParams:  ReceiptPaginationInputType
  ) {
      receipts(
        orderParams: $orderParams,
        filterParams: $filterParams,
        paginationParams: $paginationParams
      ) {
        data {
          id
          number
          emission_date
          supplier {
            name
             id
          }
          products {
            id
            quantity
            name
            stock_unit
            cost_price
            selling_price
          }
        }  
        totalPages
      }
    }
`;


export const CREATE_PRODUCT = gql`
      mutation ($input: CreateProductInputType!) {
        createProduct(input: $input) {
          id
          category {
            name
          }
          brand {
            name
          }
        }
      }
`;


export const LIST_PRODUCTS = gql`
      query {
        products {
          id
          name
          description
          manufacturer_sku_code
          brand {
            name
          }
          quantity
          cost_price
          stock_unit
          selling_price
          minimum_stock
          maximum_stock
          comission_percentage
          class
          category {
            name
          }
        }
      }
`;



