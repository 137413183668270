import { gql } from "@apollo/client";

export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign( $input: CreateCampaignInputType!) {
    createCampaign(input: $input) {
      id
      name
      start_date
      end_date
      type
      description
      is_active
    }
  }
`;

export const TOGGLE_CAMPAIGN = gql`
  mutation ToggleCampaign($id: Float!) {
    toggleCampaign(id: $id){
      name
    }
  }
`

export const LIST_CAMPAIGNS = gql`
  query ListCampaigns(
    $orderParams: CampaignOrderingInputType!,
    $filterParams: ListCamapaignInputType!,
    $paginationParams: CampaignPaginationInputType!
  ) {
      listCampaigns(
        orderParams: $orderParams,
        filterParams: $filterParams,
        paginationParams: $paginationParams
      ) {
        data {
      id
      name
      start_date
      end_date
      type
      description
      is_active
      donations {
      order {
        id
        donated
        paid_price
        status
      }
      user {
        name
      }
      }
    }
        totalPages
      }
    }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $id: Float!,
    $input: UpdateCampaignInputType!
  ) {
    updateCampaign(
      id: $id
      input: $input
    ) {
      id
      name
      start_date
      end_date
      type
      description
      is_active
    }
  }
`

;

