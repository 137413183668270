import React, { FC } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text
} from "@chakra-ui/react";

interface IModalConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  nameAction: string;
  loading?: boolean;
}

export const ModalConfirmation: FC<IModalConfirmationProps> = ({
  isOpen,
  onClose,
  handleConfirm,
  nameAction,
  loading
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent maxW="500px" h="200px" fontSize="3xl" boxShadow="sm">
        <ModalHeader fontSize={"3xl"}>Confirmar Ação</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="3xl">Deseja realmente {nameAction}?</Text>
        </ModalBody>
        <ModalFooter
          borderTopWidth="1px"
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Button
            colorScheme="green"
            mr={3}
            onClick={() => {
              handleConfirm();
              onClose();
            }}
            width={"150px"}
            height={"30px"}
            fontSize={"xl"}
            isLoading={loading}
          >
            Confirmar ação
          </Button>
          <Button
            variant="ghost"
            onClick={onClose}
            width={"100px"}
            height={"30px"}
            fontSize={"xl"}
          >
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
