import IItemSpecieSize from "types/IItemSpecieSize";
import IServicesSpeciesSizes from "types/IServicesSpeciesSizes";

type IProps = IServicesSpeciesSizes | IItemSpecieSize;

enum sizes { 'PP' = 0, 'P' = 1, 'M' = 2, 'G' = 3, 'GG' = 4, 'XGG' = 5 };

const orderPricesBySize = (payload: IProps[]) => {
  if (!payload) {
    return [];
  }
  const ordered = [...payload].sort((a, b) => {
    const a_size = String(a.size || 'PP').trim() as 'PP' | 'P' | 'M' | 'G' | 'GG' | 'XGG';
    const b_size = String(b.size || 'PP').trim() as 'PP' | 'P' | 'M' | 'G' | 'GG' | 'XGG';
    if (sizes[a_size] < sizes[b_size]) {
      return -1;
    }
    if (sizes[a_size] > sizes[b_size]) {
      return 1;
    }
    return 0;
  });
  return ordered;
}

export default orderPricesBySize;