type IProps = {
  hour: string; // HH:MM:SS
  minutes: number;
}

const incrementHour = ({
  hour,
  minutes
}: IProps) => {
  const [hour_splitted, minutes_splitted] = hour.split(':');

  let final_hour = Number(hour_splitted);
  let final_minutes = Number(minutes_splitted);

  if ((Number(minutes_splitted) + minutes) >= 60) {
    const minutes_to_hour = Math.floor(minutes / 60);
    final_hour = final_hour + (minutes_to_hour > 1 ? minutes_to_hour : 1);

    if ((minutes % 60) !== 0) {
      final_minutes = final_minutes + minutes % 60;
      if (final_minutes >= 60) {
        final_hour = final_hour + 1;
        if (final_minutes % 60 !== 0) {
          final_minutes = final_minutes % 60;
        } else {
          final_minutes = 0;
        }
      }
    }
  } else {
    final_minutes = final_minutes + minutes;
  }

  const a = final_hour.toString().length < 2 ? `0${final_hour.toString()}` : final_hour.toString();
  const b = final_minutes.toString().length < 2 ? `0${final_minutes.toString()}` : final_minutes.toString();

  return `${a}:${b}`;
};

export default incrementHour;
