import React from 'react';
import { IconBaseProps } from 'react-icons';
import { FaSpinner } from 'react-icons/fa';

import { Container, Spin } from './styles';

const Spinner: React.FC<IconBaseProps> = ({...rest}) => (
  <Container>
    <Spin>
      <FaSpinner {...rest} />
    </Spin>
  </Container>
);

export default Spinner;
