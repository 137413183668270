import { Form } from "@unform/web";
import { IFilterCamapaignFormData } from "pages/Campaigns/constants/types";
import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Styles from '../../constants/styles';
import { Label } from "components/Label/styles";
import InputRef from "components/InputRef";
import Switch from 'components/Switch';
import { Stack } from "@chakra-ui/react";
import Select from "components/Select";

export type IFilterCamapignFormProps = {
    onSubmit: (payload: IFilterCamapaignFormData, { reset }: any) => void;
}

export const FilterCampaignFormId = 'filter-form';


const FilterCampaignForm = ({ onSubmit }: IFilterCamapignFormProps) => {
    const formRef = useRef<FormHandles>(null);

    const [selectType, setSelectType] = useState([] as any)
    const [selectStartFilter, setSelectStartFilter] = useState([] as any)
    const [selectEndFilter, setSelectEndFilter] = useState([] as any)

    const selectTypeOptions = [{ label: 'Doação' }]
    const selectFilterDateOptions = [{ label: 'Depois de' }, { label: 'Antes de' }]

    const normalizeLabel = (roleLabel: string): string => {
        const normalizationMap: Record<string, string> = {
            "Doação": "DONATE",
            "Depois de": "gte",
            "Antes de": "lte"
        };
        return normalizationMap[roleLabel] || roleLabel;
    };


    const validateFields = async (
        payload: IFilterCamapaignFormData,
        options: { reset: () => void }
    ) => {
        onSubmit({
            ...payload,
            type: normalizeLabel(selectType.label),
            end_filter: normalizeLabel(selectEndFilter.label),
            start_filter: normalizeLabel(selectStartFilter.label)
        }, options);
        setSelectType([] as any)
        setSelectStartFilter([] as any)
        setSelectEndFilter([] as any)
    };

    return (
        <Form onSubmit={validateFields} ref={formRef} id={FilterCampaignFormId} >
            <Styles.row>
                <Styles.field>
                    <Label>Nome</Label>
                    <InputRef
                        name="name"
                        placeholder="Digite o nome do Campanha"
                        containerStyle={{ width: "33rem" }}
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Modelo</Label>
                    <Select
                        name="type"
                        containerStyle={{ width: "33rem" }}
                        placeholder="Modelo da campanha"
                        options={selectTypeOptions}
                        value={selectType}
                        onChange={(e: any) => setSelectType(e)}
                    />
                </Styles.field>
            </Styles.row>
            <Styles.row>
                <Styles.field>
                    <Label>Data de Inicio</Label>
                    <Select
                        name="start_filter"
                        containerStyle={{ width: "33rem" }}
                        placeholder="Parametro"
                        options={selectFilterDateOptions}
                        value={selectStartFilter}
                        onChange={(e: any) => setSelectStartFilter(e)}
                    />
                    <Label></Label>
                    <InputRef
                        name="start_date"
                        type="date"
                        containerStyle={{ width: "33rem" }}
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Data Final</Label>
                    <Select
                        name="end_filter"
                        containerStyle={{ width: "33rem" }}
                        placeholder="Parametro"
                        options={selectFilterDateOptions}
                        value={selectEndFilter}
                        onChange={(e: any) => setSelectEndFilter(e)}
                    />
                    <Label></Label>
                    <InputRef
                        name="end_date"
                        type="date"
                        containerStyle={{ width: "33rem" }}
                    />
                </Styles.field>
            </Styles.row>
            <Styles.row>
                <Styles.field>
                    <Label>Ativo</Label>
                    <Switch
                        containerStyle={{ width: "33rem" }}
                        name="is_active"
                        options={[
                            { value: 'Sim', label: 'Ativo' },
                            { value: 'Não', label: 'Não ativo' },
                        ]}
                    />
                </Styles.field>
            </Styles.row>
        </Form>
    )
}

export default FilterCampaignForm