import colors from "shared/utils/constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-left: 32px;
`;

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ServiceCard = styled.div`
  background-color: ${colors.secondary.default};
  border: 1px solid ${colors.secondary.default};
  padding: 8px;
  text-align: center;
  border-radius: 8px;

  transition: all 300ms ease;

  cursor: pointer;

  :hover {
    background-color: ${colors.gray.white};
    p {
      color: ${colors.gray.dark02};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RowPrices = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Size = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
`;

export const AdditionalsPrices = styled.div`
  display: flex;
  flex-direction: column;
`;