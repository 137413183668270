import styled from 'styled-components';
import colors from '../../../../shared/utils/constants/colors';

export const TotalCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 8px 10px ${colors.gray.light01};
  padding: 16px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${colors.primary.default};
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  color: ${colors.gray.white};
`;

export const Body = styled.div`
  background-color: ${colors.primary.background};
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  color: ${colors.gray.dark02};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const Row = styled.div`
  background-color: ${colors.gray.white};
  padding: 8px;
  border-radius: 8px;

  & + div {
    margin-top: 4px;
  }
`;
