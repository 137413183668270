export const headers = [
  { key: "id", name: "id" },
  { key: "date", name: "data" },
  { key: "platform", name: "origem" },
  { key: "customer", name: "cliente" },
  { key: "cpf", name: "cpf" },
  { key: "itens", name: "itens" },
  { key: "paymentMethod", name: "pagamento" },
  { key: "appointmentsOrderValue", name: "Valor total agendamentos" },
  { key: "salesOrderValue", name: "valor total vendas" },
  { key: "finalOrderValue", name: "valor total " },
  { key: "total_discount", name: "desconto" },
  { key: "total", name: "valor pago" },
  { key: "status", name: "status" },
  { key: "actions", name: "ações" },
];
