import { useQuery } from "@apollo/client";
import MainContainer from "components/MainContainer"
import { PanelHeader } from "components/PanelHeader"
import React, { useCallback, useEffect, useState } from "react"
import Search from "components/Search";
import Table from "components/Table";
import Pagination from "components/Pagination";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { useDisclosure } from "@chakra-ui/react";
import { LIST_ANAMNESES } from "./graphql/AnamnesesQuery";
import { columnListAnamnesisHeaders } from "./constants/conlumns";
import { APIFiltersAnamnesis, IAnamnesis, IFilterAnamnesisFormData } from "./constants/types";
import CreateAnamnesesModal from "./modals/CreateAnamnesisModal";
import UpdateModalAnamnesis from "./modals/UpdateAnamnesisModal";
import { launchToast } from "shared/utils/launchToast";
import FilterAnamnesisModal from "./modals/FilterAnamnesisModal";
import FilterListAnamnesis from "./componets/FilterListAnamnesis";

const Anamnesis: React.FC = () => {

    const [paginationLimit, setPaginationLimit] = useState<number>(10);
    const [searchInput, setSearchInput] = useState<string>("");
    const [anamnesisSelected, setAnamnesisSelected] = useState<IAnamnesis | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filters, setFilters] = useState([]);
    const [FiltersInput, setFiltersInput] = useState({})

    const createAnamnesisModalControl = useDisclosure();
    const updateAnamnesisModalControl = useDisclosure();
    const filterAnamnesisModalControl = useDisclosure();

    const handleWordSearch = (input: string) => {
        setCurrentPage(1)
        setSearchInput(input)
    };

    const handleAnamnesisUpdate = useCallback(
        async (anamnesis: IAnamnesis) => {
            setAnamnesisSelected(anamnesis);
            updateAnamnesisModalControl.onOpen();
        },
        [updateAnamnesisModalControl]
    )

    const { data, loading, refetch} = useQuery(LIST_ANAMNESES, {
        variables: {
            filterParams: {
                search: searchInput,
                ...FiltersInput
              },
              paginationParams: {
                limit: paginationLimit,
                page: currentPage
              }
        },
    });

    useEffect(() => {
        try {
            const filterParams = filters.reduce((acc, filter: { key: string, value: { value: string} }) => {
                acc[filter.key as keyof IFilterAnamnesisFormData] = filter.value.value;
                return acc;
            }, {} as IFilterAnamnesisFormData);

            setFiltersInput(filterParams)
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    }, [paginationLimit, currentPage])

    const handleAddFilter = (params: any) => {
        setFiltersInput(params)
        setCurrentPage(1);
    };

    const normalizeKeyFilter = (roleLabel: string) => {
        const normalizationMap: Record<string, string> = {
            "Tutor": "customer_id",
            "Profissional": "professional_id",
        };
        return normalizationMap[roleLabel] || roleLabel;
    };

    const normalizeDate = (date: string) => {
        const datesplit = date.split('-')
        const dataNomalized = `${datesplit[2]}-${datesplit[1]}-${datesplit[0]}`
        return dataNomalized
    }

    const handleRemoveFilter = (key: string) => {
        try {
            const newFilters = filters.filter((filter: any) => filter.key !== key);
            setFilters(newFilters);
            const filterParams = newFilters.reduce((acc, filter: { key: string, value: {value: string} }) => {
                const normalizeKey = normalizeKeyFilter(filter.key) 
                acc[normalizeKey as keyof APIFiltersAnamnesis] = filter.value.value as any;
                return acc;
            }, {} as APIFiltersAnamnesis);
            setCurrentPage(1);
            setFiltersInput(filterParams)
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    };


    return (
        <>
            <CreateAnamnesesModal
                isOpen={createAnamnesisModalControl.isOpen}
                onClose={createAnamnesisModalControl.onClose}
            />
            {anamnesisSelected && <UpdateModalAnamnesis
                isOpen={updateAnamnesisModalControl.isOpen}
                onClose={updateAnamnesisModalControl.onClose}
                anamnesis={anamnesisSelected}
            />}
            <FilterAnamnesisModal
                isOpen={filterAnamnesisModalControl.isOpen}
                onClose={filterAnamnesisModalControl.onClose}
                handleAddFilter={handleAddFilter}
                onFilterSubmit={setFilters}
            />
            <MainContainer>
                <PanelHeader
                    title="Anamneses"
                    isNew
                    isFilterModalVisible
                    onClick={filterAnamnesisModalControl.onOpen}
                    onClickNew={createAnamnesisModalControl.onOpen}
                />
                <Search
                    wordSearch={searchInput}
                    setSearch={handleWordSearch}
                    isFilterForRegister
                    limit={paginationLimit}
                    onSetPageSize={(limit) => {
                        setCurrentPage(1)
                        setPaginationLimit(limit)
                    }}
                    placeholder="Pesquise por nome do pet ou nome do profissional"
                />
                             {
                    filters.length
                        ? (
                            <FilterListAnamnesis filters={filters} removeFilter={handleRemoveFilter} />
                        )
                        : ''
                }
                 {   !loading && (
                        <Table
                            headers={columnListAnamnesisHeaders}
                            data={data.ListAnamneses.data.map((anamnesis: IAnamnesis) => ({
                                pet_name: anamnesis.pet.name,
                                tutor: anamnesis?.tutor.name,
                                professional_name: anamnesis?.professional?.user?.name || 'Sem profissional',
                                date: normalizeDate(anamnesis.appointment.date),
                                actions: [
                                    {
                                        name: "Editar",
                                        icon: <EditButton />,
                                        action: () => handleAnamnesisUpdate(anamnesis),
                                    },
                                ],
                            }))}
                        />
                    )
                }
                {
                    !loading && (
                        <Pagination
                            totalPage={data.ListAnamneses.totalPages}
                            pageIndex={currentPage}
                            setPage={setCurrentPage}
                        />
                    )
                }
            </MainContainer>
        </>
    )
}

export default Anamnesis