import React, { useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";

import Text from "../../components/Text";
import { Form } from "@unform/web";
import InputRef from "../../components/InputRef";
import Button from "../../components/Button";
import colors from "../../shared/utils/constants/colors";

import * as Styles from "./styles";
import { FaEnvelope } from "react-icons/fa";
import { useAuth } from "../../hooks/Auth";
import { useHistory } from "react-router-dom";
import getValidationErrors from "../../shared/utils/getValidationErrors";
import { launchToast } from "../../shared/utils/launchToast";
import { ReactComponent as WhiteLogo } from "../../assets/svg/generic/whitelogo.svg";

interface SignInFormData {
  email: string;
}
const PasswordRecovery: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { passwordRecovery } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: SignInFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Informe um E-mail válido")
          .required("E-mail obrigatório"),
      });
      await schema.validate(data, { abortEarly: false });

      try {
        setLoading(true);

        await passwordRecovery(data.email);

        setLoading(false);
        launchToast(
          "Solicitação efetuada com sucesso, verifique no email informado.",
          "success"
        );
      } catch (error) {
        setLoading(false);
        launchToast("Verifique o email informado", "error");
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);
    }
  };

  return (
    <>
      <Styles.Container>
        <Styles.ContainerHeader>
          <Styles.WelcomeTextDiv>
            <Text
              text="Boas vindas à"
              color={colors.gray.white}
              fontFamily="OrelegaOne-regular"
              size={24}
              weight="600"
              marginRight={4}
              marginTop={-8}
            />
          <WhiteLogo />
          </Styles.WelcomeTextDiv>
        </Styles.ContainerHeader>

        <Styles.Content>
          <Text
            text="Recuperar Senha"
            color={colors.argon.textColorDark}
            fontFamily="OrelegaOne-regular"
            size={32}
            weight="600"
          />

          <Text
            text="Um e-mail será enviado para recuperar a sua senha"
            color={colors.argon.textColorGray}
            fontFamily="Open Sans"
            size={20}
            marginTop={16}
          />
          <Styles.FormDiv>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <InputRef
                placeholder="Email"
                name="email"
                type="email"
                icon={FaEnvelope}
              />

              <Button text="Recuperar Senha" type="submit" loading={loading} />
            </Form>
          </Styles.FormDiv>
          <Styles.OptionsDiv>
            <Text
              text="Esqueci meu email"
              color={colors.argon.textColorGray}
              fontFamily="Open Sans"
              size={14}
            />

            <Text
              text="Entrar em outra conta"
              color={colors.argon.textColorGray}
              fontFamily="Open Sans"
              size={14}
              onTextClick={() => history.push("/")}
            />
          </Styles.OptionsDiv>
        </Styles.Content>
        <Styles.ContainerFooter />
      </Styles.Container>
    </>
  );
};
export default PasswordRecovery;
