import styled from "styled-components";
import colors from "../../../shared/utils/constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 32px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${colors.primary.light};
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 16px;
`;
