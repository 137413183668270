export type ICreateCampaignFormData = {
    name: string;
    start_date: string;
    end_date: string;
    type: string
    description: string
};


export type ICampaign = {
    id: number,
    name: string,
    type: string,
    description: string,
    start_date: string,
    end_date: string,
    is_active: boolean,
    donations: IDonationCampaigns[]
}

export type IDonationCampaigns = {
    user: IUserDonationCampaigns,
    order: IOrderDonationCampaigns
}

export type IUserDonationCampaigns = {
    name: string
}

export type IOrderDonationCampaigns = {
    paid_price: number,
    donated: number,
    id: string,
}

export type IFilterCamapaignFormData = {
    name: string;
    type: string;
    description: string;
    is_active: string;
    start_filter: string;
    end_filter: string;
    start_date: string;
    end_date: string;
    [key: string]: any
}

export type IUpdateCampaignFormData = {
    name: string;
    type: string;
    description: string;
    start_date: string;
    end_date: string;
    is_active: string;
    id: number
};

export type IUpdateCampaignVariables = {
    input: {
        name: string;
        type: string;
        description: string;
        start_date: string;
        end_date: string;
        is_active: boolean;
    },
    id: number;
}

export type IToggleCampaign = {
    id: number;
}

export type IDateFilterFormDataCampaign = {
    filter: string;
    date: string;
};

export type APIFiltersCampaign = {
    name?: string;
    description?: string;
    type?: string;
    is_active: string;
    start_date?: IDateFilterFormDataCampaign;
    end_date?: IDateFilterFormDataCampaign;
}

export enum CampaignFilterTranslateEnum {
    name = 'Nome',
    description = 'Descrição',
    type = 'Modelo',
    is_active = 'Ativo',
    start_date = 'Data de início',
    end_date = 'Data de término',
}
