const calculateDistanceBetweenHours = (
  startHour: string,
  endHour: string,
): number => {
  let minutes = 0;

  const startHourDivided = startHour.split(':');
  const endHourDivided = endHour.split(':');

  const differenceHours =
    Number(endHourDivided[0]) - Number(startHourDivided[0]);
  const differenceMinutes =
    startHourDivided[1] < endHourDivided[1]
      ? Number(endHourDivided[1]) - Number(startHourDivided[1])
      : Number(startHourDivided[1]) - Number(endHourDivided[1]);

  minutes = differenceHours * 60;

  if (startHourDivided[1] < endHourDivided[1]) {
    minutes += differenceMinutes;
  } else {
    minutes -= differenceMinutes;
  }

  return minutes;
};

export default calculateDistanceBetweenHours;
