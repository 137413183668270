
import React, { useCallback, useState } from 'react';
import * as Styles from '../constants/styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import CreateReceiptsForm, { CreateReceiptsFormId } from '../forms/CreateReceipt/CreateReceiptsForm';
import AddProductsToReceiptsModal from './AddProductsToReceiptsModal';
import Button from 'components/Button';
import { useDisclosure } from '@chakra-ui/react';
import Table from "components/Table";
import { columnAddProductsToReceiptsHeaders } from '../constants/conlumns';
import { ICreateReceiptsFormData, IListProductReceipts, IProductReceipts } from '../constants/types';
import { CREATE_RECEIPT, LIST_RECEIPTS } from '../graphql/ReceiptsQuery';
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { ReactComponent as CancelButton } from "../../../assets/svg/generic/cancelbutton.svg";
import CreateSupplierModal from './CrateSupplierModal';

export type ICreateReceiptsModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const CreateReceiptsModal: React.FC<ICreateReceiptsModalProps> = ({ isOpen, onClose }) => {

    const [productsReceipt, setProductsReceipt] = useState([] as any)
    
    const addProductsToReceiptsModalControl = useDisclosure();
    const registerSupplierModalControl = useDisclosure();
    
    const handleAddProduct = (product: IProductReceipts) => {
        const isProductExist = productsReceipt.some((existingProduct: IProductReceipts) => existingProduct.id === product.id);
        if (isProductExist) {
            throw new Error('Produto duplicado');
        } else {
            setProductsReceipt([...productsReceipt, product]);
            launchToast('Produto adicionado', 'success');
        }
    }

    const handleRemoveProduct = (productId: number) => {
        setProductsReceipt(productsReceipt.filter((product: IProductReceipts) => product.id != productId ))
    }

    const numberUnmask = (value: string) => {
        const formattedValue = value?.replace('R$', '').replace(/\./g, '').replace(',', '');;
        const numberFloat = parseFloat(formattedValue);
        return numberFloat
    }

    const [CreateReceipt, { loading }] = useMutation(CREATE_RECEIPT, {
        refetchQueries: [
            LIST_RECEIPTS,
            'Receipts'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: ICreateReceiptsFormData,
            { reset }
        ) => {
            try {
                await CreateReceipt({
                    variables: {
                        input: {
                            number: payload.number,
                            emission_date: payload.emission_date,
                            supplier_id: payload.supplier_id,
                            products: payload.products.map( product => ({
                                product_id: product.id,
                                quantity: product.quantity,
                                unity_price: numberUnmask(product.unity_price),
                                resale_price: numberUnmask(product.resale_price)
                            })),
                        }
                    },
                });
                reset();
                onClose();
                setProductsReceipt([] as any)
                launchToast('Nota fiscal criada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [CreateReceipt],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
        <AddProductsToReceiptsModal
                onSubmit={handleAddProduct}
                isOpen={addProductsToReceiptsModalControl.isOpen}
                onClose={addProductsToReceiptsModalControl.onClose}
                productsReceipt={productsReceipt}
                handleRemoveProduct={handleRemoveProduct}
         />
         <CreateSupplierModal
           isOpen={registerSupplierModalControl.isOpen}
           onClose={registerSupplierModalControl.onClose}/>
            <Styles.header>
                <Text
                    text="Cadastrar Nota Fiscal"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <Button onClick={registerSupplierModalControl.onOpen} text="Cadastrar Fornecedor"/>
            </Styles.header>
            <Styles.FormDiv>
                <CreateReceiptsForm 
                    onSubmit={handleSubmit} 
                    products={productsReceipt}
                />
            </Styles.FormDiv>
            <Styles.row>
                <Button onClick={addProductsToReceiptsModalControl.onOpen} text="Adicionar Produtos"/>
            </Styles.row>
           {  productsReceipt.length > 0 && <Table
            headers={columnAddProductsToReceiptsHeaders}
            data={productsReceipt.map((product: IListProductReceipts) => ({
                    product: product.name,
                    stock_unit: product.stock_unit,
                    quantity: product.quantity,
                    unity_price: product.unity_price,
                    resale_price: product.resale_price,
                    actions: [
                        {
                            name: "remove",
                            icon: <CancelButton/>,
                            action: () => handleRemoveProduct(product.id),
                        },
                ],
            }))}
            />}
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={CreateReceiptsFormId} loading={loading} 
                />
            </Styles.rowButton>
        </Modal>
    )
}

export default CreateReceiptsModal