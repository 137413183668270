import React from "react";
import Modal from "../../../components/Modal";
import Text from "components/Text";
import IPet from "types/IPet";
import PetFiles from "components/PetFiles";
import { Center } from "@chakra-ui/react";

type IProps = {
  visible: boolean;
  onCloseModal: () => void;
  pet?: IPet;
};

const PetModal = ({ visible, onCloseModal, pet }: IProps) => {
  if (!pet) {
    return null;
  }

  const normalizeText = (text: string | undefined) => {
    if(text){
      const normalizationMap: Record<string, string> = {
        "F": "Fêmea",
        "M": "Macho",
        "not_informed": "Não informado",
        "neutered": "Sim",
        "not neutered": "Não",
        "cachorro": "Cachorro",
        "gato": "Gato"
    };
    return normalizationMap[text] || text;
    }
};

const calculateAge = (birthDate: string | undefined) => {
  if (birthDate) {
    const today = new Date();
    const dob = new Date(birthDate);
    let age = today.getFullYear() - dob.getFullYear();
    const month = today.getMonth() - dob.getMonth();
    const dayOfMonth = today.getDate() - dob.getDate();

    if (month < 0 || (month === 0 && dayOfMonth < 0)) {
      age--;
    }

    if (age < 1) {
      return "Menos de 1 ano";
    }

    return `${age} anos`;
  }
};



  return (
    <Modal closeModal={onCloseModal} visible={visible}>
      <div>
        <Text text={pet.name} size={24} weight="600" />
        <ul>
          <li style={{display: 'flex', alignItems: 'end'} }> <Text text="Espécie:" size={18} weight="600" /> <Text text={normalizeText(pet.specie?.name) || "Não definida"} size={16} weight="400" /></li>
          <li style={{display: 'flex', alignItems: 'end'} }> <Text text="Raça:" size={18} weight="600" /> <Text text={pet.breed?.name || "Não definida"} size={16} weight="400" /></li>
          <li style={{display: 'flex', alignItems: 'end'}}> <Text text="Sexo:" size={18} weight="600" /> <Text text={normalizeText(pet.gender) || "Não informado"} size={16} weight="400" /></li>
          <li style={{display: 'flex', alignItems: 'end'}}> <Text text="Idade:" size={18} weight="600" /> <Text text={calculateAge(pet.birthday) || "Não informado"} size={16} weight="400" /></li>
          <li style={{display: 'flex', alignItems: 'end'}}> <Text text="Peso:" size={18} weight="600" /> <Text text={`${pet.weight?.toString()}kg`|| "Não informado"} size={16} weight="400" /></li>
          <li style={{display: 'flex', alignItems: 'end'}}> <Text text="Castrado:" size={18} weight="600" /> <Text text={normalizeText(pet.neutered) || "Não informado"} size={16} weight="400" /></li>
          <li style={{display: 'flex', alignItems: 'end'}}> <Text text="Temperamento:" size={18} weight="600" /> <Text text={pet.temperament || "Não informado"} size={16} weight="400" /></li>
          <li style={{display: 'flex', alignItems: 'end'}}> <Text text="Tutores:" size={18} weight="600" /> {pet.tutors.map((tutor) => ( <Text text={tutor.name} size={16} weight="400" />))}</li>
        </ul>
        <PetFiles pet_id={pet.id} />
      </div>
    </Modal>
  );
};
export default PetModal;
