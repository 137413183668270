import React, { useCallback, useState } from 'react';
import * as Styles from '../constants/styles';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from 'components/Button';
import { useDisclosure } from '@chakra-ui/react';
import Table from "components/Table";
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { ReactComponent as EditButton } from "../../../assets/svg/generic/edit.svg";
import { ICategoryServiceNames, ISectorCepsCategories, IUpdateCepCategorysStatus } from '../constants/types';
import { DELETE_CEP, GET_SECTION_CEPS, LIST_SECTIONS_CEP_CATEGORIES, UPDATE_STATIS_CEPS_CATEGORIES } from '../graphql/CepsCategoriesQuery';
import { columnCepsCategoriesHeaders} from '../constants/conlumns';
import { FaCheckCircle } from "react-icons/fa";
import { BsFillXCircleFill } from "react-icons/bs"
import { FaExclamationCircle } from "react-icons/fa";
import CreateCepsModal from './CreateCepModal';
import UpdateCepsModal from './UpdateCepModal';
import ConfirmationModal from '../constants/ConfirmationModal';
import { ReactComponent as CancelButton } from "../../../assets/svg/generic/cancelbutton.svg";


export type IShowCepsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    section: ISectorCepsCategories
    setSectionSelected: React.Dispatch<React.SetStateAction<ISectorCepsCategories | null>>
    refetchSections: (variables?: Partial<{
        input: {
            search: string;
            limit: number;
            page: number;
        };
    }> | undefined) => Promise<ApolloQueryResult<any>>
};

export type IHandleChangeStatusType = {
    category_id: string,
    serviceName: ICategoryServiceNames, 
    cepsCategoriesToChange: ISectorCepsCategories[]
    isSection: boolean
}

type IDeleteCep = {
    cep_id: string, 
    isSection: boolean
}

const ShowCepsModal: React.FC<IShowCepsModalProps> = ({ isOpen, onClose, section, setSectionSelected, refetchSections }) => {

    const [dataToChangeStatus, setDataToChangeStatus] = useState<IHandleChangeStatusType>([] as any)
    const [dataToDeleteCep, setDataToDeleteCep] = useState<IDeleteCep>([] as any)
    const [cepSelected, setCepSelected] = useState<ISectorCepsCategories | null>(null);
    const [textConfirmationModal, setTextConfirmationModal] = useState<string>("");


    const [updateStatusCepsCategories, { loading: changeStatusLoading }] = useMutation(UPDATE_STATIS_CEPS_CATEGORIES);

    const toggleStatusControl = useDisclosure();
    const toggleDeleteControl = useDisclosure();
    const createCepModalControl = useDisclosure();
    const updateCepModalControl = useDisclosure();

    
    const handleCepUpdate = useCallback(
        async (cep: ISectorCepsCategories) => {
            setCepSelected(cep);
            updateCepModalControl.onOpen();
        },
        [updateCepModalControl]
    )

    const { data, refetch: rafechListCep } = useQuery(GET_SECTION_CEPS, {
        variables: {
            getCepSectionCepsId: section.cep_id
        },
    });

    const handleChangeStatus = (dataToChangeStatus: IHandleChangeStatusType ): void  => {
    
        const { category_id, serviceName, cepsCategoriesToChange, isSection } = dataToChangeStatus

        const inputData = cepsCategoriesToChange.map(cepCategorie => { return {category_id, cep_id: cepCategorie.cep_id, status: !Boolean(cepsCategoriesToChange[0][serviceName])} })

        const input = {
            cepsCategoriesStatus: [
                ...inputData
            ]
        };

        updateStatusCepsCategories({ variables: { input } })
            .then(() => {
                refetchSections();
                rafechListCep();
                launchToast('Status atualizado com sucesso!', 'success');
            })
            .catch((error) => {
                launchToast(error.message, 'error');
            });

        if(isSection){
            const newSection = {...section}
            newSection[serviceName] = !Boolean(cepsCategoriesToChange[0][serviceName])
            setSectionSelected(newSection)
        }
    };

    const onClickIcon = (dataToChangeStatus: IHandleChangeStatusType) => {
        const {isSection, serviceName} = dataToChangeStatus

        if(!isSection && section[serviceName]){
            return
        }

        setDataToChangeStatus(dataToChangeStatus)
        if(isSection){
            setTextConfirmationModal("Deseja ativar/desativar o serviço para TODOS os ceps da lista?")
        } else{
            setTextConfirmationModal("Deseja ativar/desativar o serviço?")
        }
        toggleStatusControl.onOpen();
    }

    const verifySomeStatusIsTrue = (category: ICategoryServiceNames, ceps: ISectorCepsCategories[]): boolean => {
        const someIsTrue =  ceps.some((cep: ISectorCepsCategories) => Boolean(cep[category]) === true);
        return someIsTrue
    }

    const iconStatus = (cep: ISectorCepsCategories, category: ICategoryServiceNames, status: boolean) => {

        const categoryMapId: { [key: string]: string } = {
            'bath_and_grooming': '5daabb01-bdec-40b3-b2e1-4698a42c4e3e',
            'vaccines': '8cc13037-ab8b-4716-9b34-8d914a2058b1',
            'veterinarian': '5d7b1d13-0cf0-482e-83b1-3deecbccd4e1'
        };
        
        const newCategoryId = categoryMapId[category.toString()];

        if(cep.cep_id === section?.cep_id){
            if (status) {
                return (
                    <div style={{ margin: '0 auto', width: '16px', cursor: 'pointer' }} 
                         onClick={() =>{ onClickIcon({ category_id: newCategoryId, serviceName: category, cepsCategoriesToChange: [cep], isSection: true})}}>
                        <FaCheckCircle color="green" size={'16px'} title={`Serviço liberado para ceps de inicio ${section.start_cep} a ${section.end_cep}`}/>
                    </div>
                );
            } else if(verifySomeStatusIsTrue( category, data?.getCepSectionCeps.ceps)){
                return (
                    <div style={{ margin: '0 auto', width: '16px', cursor: 'pointer' }} 
                         onClick={() => onClickIcon({ category_id: newCategoryId, serviceName: category, cepsCategoriesToChange: [cep], isSection: true })}>
                        <FaExclamationCircle  color="orange" size={'16px'} title={`Serviço parcialmente liberado para ceps de inicio ${section.start_cep} a ${section.end_cep}`} />
                    </div>
                );
            } else{
                return (
                    <div style={{ margin: '0 auto', width: '16px', cursor: 'pointer' }} 
                         onClick={() => onClickIcon({ category_id: newCategoryId, serviceName: category, cepsCategoriesToChange: [cep], isSection: true })}>
                        <BsFillXCircleFill color="red" size={'16px'} title={`Serviço não liberado para ceps de inicio ${section.start_cep} a ${section.end_cep}`} />
                    </div>
                );
            }
        }else{
            if (status) {
                return (
                    <div style={{ margin: '0 auto', width: '16px', cursor: 'pointer' }} 
                         onClick={ () => onClickIcon( { category_id: newCategoryId, serviceName: category, cepsCategoriesToChange: [cep], isSection: false } )
                    }>
                            <FaCheckCircle color={ section[category] ? "grey" : "green" } size={'16px'} title={ section[category] ? 'Serviço liberado para todo o setor' :'Serviço liberado para o cep'}/>
                    </div>
                );
            } else {
                return (
                    <div style={{ margin: '0 auto', width: '16px', cursor: 'pointer' }} 
                         onClick={() => onClickIcon({ category_id: newCategoryId, serviceName: category, cepsCategoriesToChange: [cep], isSection: false })
                    }>  
                        {section[category] ?
                          <FaCheckCircle color={"grey"} size={'16px'} title={'Serviço liberado para todo o setor'}/>  : 
                          <BsFillXCircleFill color={ "red" } size={'16px'} title={'Serviço não liberado para o cep'} />
                         }
                    </div>
                );
            }
        }
    };

    const [deleteCep, { loading }] = useMutation(DELETE_CEP, {
        refetchQueries: [
            GET_SECTION_CEPS,
            'getCepSectionCeps',
            LIST_SECTIONS_CEP_CATEGORIES,
            'listCepsCategoriesSections'
        ],
    });

    const handleDeleteCep = async (dataToDelete: IDeleteCep): Promise<void> => {
        const {isSection, cep_id} = dataToDelete
        if (isSection) {
            try {
                [section, ...data?.getCepSectionCeps.ceps].forEach(async (cep) => {
                    await deleteCep({
                        variables: {
                            deleteCepId: cep.cep_id
                        }
                    });
                });
                    launchToast(`Setor e ceps deletados com sucesso`, 'success');
                } catch (error: any) {
                    launchToast(error.message, "error");
                }
                onClose()
        } else {
            try {
                await deleteCep({
                    variables: {
                        deleteCepId: cep_id
                    }
                });
                launchToast('Cep deletado com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        }
    };

    const onClickDelete = (cep_id: string) => {

        const isSection = cep_id === section.cep_id

        const data = {
            cep_id,
            isSection
        }

        setDataToDeleteCep(data)

        if(isSection){
            setTextConfirmationModal("Deseja deletar o setor e TODOS os seus ceps?")
        } else{
            setTextConfirmationModal("Deseja deletar o cep?")
        }
        toggleDeleteControl.onOpen();
    }

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <ConfirmationModal
                isOpen={toggleStatusControl.isOpen}
                onClose={toggleStatusControl.onClose}
                text={textConfirmationModal}
                handleConfirm={() => handleChangeStatus(dataToChangeStatus)}
                loading={changeStatusLoading}
            />
            <ConfirmationModal
                isOpen={toggleDeleteControl.isOpen}
                onClose={toggleDeleteControl.onClose}
                text={textConfirmationModal}
                handleConfirm={() => handleDeleteCep(dataToDeleteCep)}
                loading={changeStatusLoading}
            />
            {cepSelected && <UpdateCepsModal
                isOpen={updateCepModalControl.isOpen}
                onClose={updateCepModalControl.onClose}
                section={section}
                cep={cepSelected}
            />}
            <CreateCepsModal
                isOpen={createCepModalControl.isOpen}
                onClose={createCepModalControl.onClose}
                section={section}
            />
            <Styles.header>
                <Text
                    text={`Setor ${section.start_cep} - ${section.end_cep}`}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={24}
                    weight="600"
                />
                <Button onClick={createCepModalControl.onOpen} 
                text="Novo Cep"/>
            </Styles.header>
            <Styles.FormDiv>
            </Styles.FormDiv>
                      {  data?.getCepSectionCeps.ceps &&  <Table
                            headers={columnCepsCategoriesHeaders}
                            data={[section, ...data?.getCepSectionCeps.ceps].map((sectionCepCategories: ISectorCepsCategories) => ({
                                start_cep: sectionCepCategories.start_cep,
                                end_cep: sectionCepCategories.end_cep,
                                region_name: sectionCepCategories.region_name,
                                bath_and_grooming: iconStatus( sectionCepCategories, "bath_and_grooming",  sectionCepCategories.bath_and_grooming ),
                                vaccines: iconStatus( sectionCepCategories, "vaccines", sectionCepCategories.vaccines),
                                veterinarian: iconStatus( sectionCepCategories, "veterinarian",  sectionCepCategories.veterinarian ),
                                actions: [
                                    {
                                        name: "Editar",
                                        icon: <EditButton />,
                                        action: () => handleCepUpdate(sectionCepCategories),
                                    },
                                    {
                                        name: "Desativar",
                                        icon: <CancelButton/>,
                                        action: () => onClickDelete(sectionCepCategories.cep_id),
                                    },
                                ],
                            }))} firstLine
                        />}
            <Styles.rowButton>
                <Button text="Fechar" onClick={onClose} />
            </Styles.rowButton>
        </Modal>
    )
}

export default ShowCepsModal