import * as Yup from "yup";

export const CreateMovimentFormValidationSchema = Yup.object().shape({
    productId: Yup.string()
        .required('Produto é obrigatório'),
    numberOfProducts: Yup.number()
        .required('Quantidade é obrigatória').
        min(1, "Digite uma quantidade maior que 0"),
    reason: Yup.string()
        .required('Motivo é obrigatório'),
    recipientId: Yup.string()
        .required('Selecione um destinatario valido'),
    stockAction: Yup.string()
        .required('Ação é obrigatória')
});