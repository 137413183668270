import React, { useState, useEffect } from "react";
import { removeCondominium } from "../../services/condominium/graphQL";
import { DiscountItem } from "./DiscountItem";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Flex,
  Divider,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { RiHome2Fill, RiMapPin2Fill, RiBuildingFill } from "react-icons/ri";
import ICondominium from "types/ICondominium";
import { DiscountForm } from "./DiscountForm";
import { BiTrash } from "react-icons/bi";

interface CondominiumCardProps {
  condominium: ICondominium;
  condominiums: ICondominium[];
  setCondominiums: React.Dispatch<React.SetStateAction<ICondominium[]>>;
  setUpIsUpdateInDiscount: React.Dispatch<React.SetStateAction<boolean>>;
}

const CondominiumInfoItem: React.FC<{
  icon: React.ElementType;
  label: string;
  value: string;
}> = ({ icon, label, value }) => (
  <Flex alignItems="center">
    {React.createElement(icon, { size: 20 })}
    <Text ml={2} fontWeight="bold">
      {label}:
    </Text>
    <Text ml={2}>{value}</Text>
  </Flex>
);

const CondominiumDetails: React.FC<{ condominium: ICondominium }> = ({
  condominium,
}) => (
  <Box p={4}>
    <CondominiumInfoItem
      icon={RiMapPin2Fill}
      label="CEP"
      value={condominium.cep}
    />
    <CondominiumInfoItem
      icon={RiMapPin2Fill}
      label="Cidade"
      value={condominium.city}
    />
    <CondominiumInfoItem
      icon={RiMapPin2Fill}
      label="Bairro"
      value={condominium.neighborhood}
    />
    <CondominiumInfoItem
      icon={RiHome2Fill}
      label="Rua"
      value={
        condominium.street.substring(0, 30) +
        (condominium.street.length > 30 ? "..." : "")
      }
    />
  </Box>
);

export const CondominiumCard: React.FC<CondominiumCardProps> = ({
  condominium,
  condominiums,
  setCondominiums,
  setUpIsUpdateInDiscount,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleRemoveCondominium = () => {
    try {
      condominiums = condominiums.filter(
        (condominiumItem) => condominiumItem.id !== condominium.id
      );
      setCondominiums(condominiums);
      removeCondominium(condominium.id);
      handleCloseModal();
      toast.success("Condomínio removido com sucesso!");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao remover condomínio!");
    }
  };

  return (
    <>
      <Box
        p={3}
        shadow="sm"
        borderWidth="1px"
        borderRadius="md"
        width="100%"
        mb={4}
        cursor="pointer"
        _hover={{ shadow: "md" }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          bg="gray.100"
          p={2}
          borderRadius="md"
        >
          <Flex alignItems="center">
            <RiBuildingFill size={20} />
            <Text ml={2}>
              {condominium.name.substring(0, 30) +
                (condominium.name.length > 30 ? "..." : "")}
            </Text>
          </Flex>
          <Button
            colorScheme="red"
            onClick={() => {
              handleOpenConfirmationModal();
            }}
          >
            <BiTrash size={15} />
          </Button>
        </Flex>
        <Box onClick={handleOpenModal}>
          <Divider my={2} />
          <CondominiumDetails condominium={condominium} />
        </Box>
      </Box>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent maxW="900px" h="600px" fontSize="3xl">
          <ModalHeader fontSize={"3xl"} display={"flex"} alignItems={"center"}>
            <RiBuildingFill size={20} />
            <Box ml={2} />
            {condominium.name}
          </ModalHeader>
          <Divider my={2} />
          <ModalCloseButton fontSize={"xl"} />

          <ModalBody fontSize={"3xl"}>
            <Tabs isLazy>
              <TabList>
                <Tab
                  fontSize={"2xl"}
                  _selected={{
                    color: "green.600",
                    bg: "green.50",
                    borderRadius: "0.5rem 0.5rem 0 0",
                    borderBottom: "2px solid green",
                  }}
                >
                  Detalhes
                </Tab>
                <Tab
                  fontSize={"2xl"}
                  _selected={{
                    color: "green.600",
                    bg: "green.50",
                    borderRadius: "0.5rem 0.5rem 0 0",
                    borderBottom: "2px solid green",
                  }}
                >
                  Adicionar Desconto
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <CondominiumDetails condominium={condominium} />
                  <Box height={"350px"} overflowY={"auto"}>
                    <Text fontWeight="bold" fontSize="2xl" mt={4} mb={2}>
                      Descontos Aplicados ({condominium.discounts.length})
                    </Text>
                    {condominium.discounts.length > 0 ? (
                      condominium.discounts.map((discount) => (
                        <DiscountItem
                          discount={discount}
                          setUpIsUpdateInDiscount={setUpIsUpdateInDiscount}
                          key={discount.id}
                        />
                      ))
                    ) : (
                      <Text>Nenhum desconto cadastrado</Text>
                    )}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <DiscountForm
                    condominiumId={condominium.id}
                    setIsNewDiscount={setUpIsUpdateInDiscount}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
      >
        <ModalOverlay />
        <ModalContent maxW="500px" h="200px" fontSize="3xl">
          <ModalHeader fontSize={"3xl"}>Confirmar remoção</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="3xl">Deseja realmente remover o condomínio?</Text>
          </ModalBody>
          <ModalFooter
            borderTopWidth="1px"
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                handleRemoveCondominium();
                handleCloseConfirmationModal();
              }}
              width={"100px"}
              height={"30px"}
              fontSize={"xl"}
            >
              Remover
            </Button>
            <Button
              variant="ghost"
              onClick={handleCloseConfirmationModal}
              width={"100px"}
              height={"30px"}
              fontSize={"xl"}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
