import styled from "styled-components";

export const Row = styled.div`
  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-left: 14px;
    margin-bottom: 24px;
  }
`;
