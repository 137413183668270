import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import {  GET_SECTION_CEPS, UPDATE_CEP } from '../graphql/CepsCategoriesQuery';
import { ISectorCepsCategories, IUpdateCepFormData } from '../constants/types';
import UpdateCepForm, { UpdateCepFormId } from '../forms/updateCep/updateCepForm';


export type IUpdateSectionModalProps = {
    isOpen: boolean;
    onClose: () => void;
    section: ISectorCepsCategories
    cep: ISectorCepsCategories
};


const UpdateCepsModal: React.FC<IUpdateSectionModalProps> = ({ isOpen, onClose, section, cep }) => {

    const [updateCep, { loading }] = useMutation(UPDATE_CEP, {
        refetchQueries: [
            GET_SECTION_CEPS,
            'GetCepSectionCeps'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: IUpdateCepFormData,
        ) => {
            try {
                await updateCep({
                    variables: {
                        updateCepId: cep.cep_id,
                        input: {
                            start_cep: payload.start_cep,
                            end_cep: payload.end_cep,
                            region_id: payload.regionId
                        }
                    },
                });
                onClose();
                launchToast('Cep atualizado com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [updateCep],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Novo Cep"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <UpdateCepForm onSubmit={handleSubmit} section={section} cep={cep} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={UpdateCepFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default UpdateCepsModal