import styled from 'styled-components';
import colors from '../../../../shared/utils/constants/colors';

export const Container = styled.div`
  flex: 1;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #d7e5e0, #d9e6e1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
/*
export const ContainerHeader = styled.div`
  width: 100%;
  height: 80vh;
`;

*/
export const Content = styled.div`
  position: absolute;
  z-index: 2;
  background: ${colors.argon.textColorLight02};
  border-radius: 6px;
  top: 30%;
  box-shadow: 0 0 32px #8898AA26;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 33px;
  padding-right: 33px;
  width: 30%;
  height: 100vh;
`;

export const FormDiv = styled.div`
  width: 100%;
`;

export const KeepConnectedDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;
  margin-bottom: 26px;
`;

export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${colors.argon.white};
  box-shadow: 0 1px 3px ${colors.argon.textColorLight};
  cursor: pointer;
`;

export const header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const row = styled.div`
@media (min-width: 650px) {
  display: flex;
  flex-direction: row;
  align-items: flex-start ;
  width: 100%;
  margin-left: 14px;  
  margin-bottom: 24px;
}
  `;

export const rowButton = styled.div`
display: flex;
justify-content:space-between;
margin-top: 24px;

@media (min-width: 650px) {
  flex-direction: row;
  align-items: flex-end ;
  width: 25%;
  margin-left:75%;
}
`;

export const field = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start ;
width: 100%;
margin-right: 20px;
margin-top: 20px;
`;

export const line = styled.div`
width: 100%;
border-bottom: 2px solid ${colors.primary.light};
margin-bottom: 24px;
position: relative;
top: -1px;
`;

export const WhiteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid ${colors.primary.default};
  border-radius: 4px;

  margin-top: 2rem;

  height: 47px;

  background: #FFFFFF;

  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  min-width: 120px;

  color: ${colors.primary.default};
  transition: background 0.2s;
  box-shadow: 0 6px 8px ${colors.argon.textColorGray};

  :hover {
    transform: scale(1.05);
    background: ${colors.primary.background};
  }
  `;

export const FormTitle = styled.h2`
    font-size: 24px;
    border-bottom: solid 1px rgba(160 ,160 ,160 , 0.5)
  `
