import React, { useCallback} from 'react';
import * as Styles from '../../constants/styles';
import Button from "components/Button";
import { launchToast } from 'shared/utils/launchToast';
import { useMutation } from '@apollo/client';
import { IRegisterCategoryFormData } from 'pages/Stock/constants/types';
import RegisterCategoryForm, { RegisterCategoryFormId } from 'pages/Stock/forms/registerCategory/RegisterCategoryForm';
import { CREATE_PRODUCT_CATEGORY } from 'pages/Stock/graphql/StockQuery';

export type IRegisterCategory = {
    isOpen: boolean;
    onClose: () => void;
};


const RegisterCategoryTab: React.FC<IRegisterCategory> = ({ isOpen, onClose}) => {

    const [createProductCategory, { loading }] = useMutation(CREATE_PRODUCT_CATEGORY, {});

    const handleSubmit = useCallback(
        async (
            payload: IRegisterCategoryFormData,
            { reset }
        ) => {
            try {
                await createProductCategory({
                    variables: {
                        input: {
                            name: payload.name,
                        }
                    },
                });
                reset();
                launchToast('Categoria registrada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createProductCategory],
    );

    return (
        <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}
        >

            <Styles.FormDiv>
                <RegisterCategoryForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton
             style={{
                width: "33rem"
            }}
            >
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={RegisterCategoryFormId} loading={loading} />
            </Styles.rowButton>
        </div>
    )
}

export default RegisterCategoryTab