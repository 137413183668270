import React from "react";
import * as Styles from "./styles";
import { MdDeleteForever } from "react-icons/md";
import Text from "components/Text";
import colors from "shared/utils/constants/colors";

export interface FilterProps {
  key: string;
  value: {label: string, value: string};
}

const FilterListAnamnesis = ({
  filters,
  removeFilter,
}: {
  filters: any[];
  removeFilter: any;
}) => {
  const remove = (key: string) => {
    removeFilter((key));
  };

  return (
    <Styles.FilterConatiner>
      {filters.map((f: FilterProps, index) => {
        const { key, value } = f;
        if (key !== "created") {
          let text = `${key} : ${value.label}`;

          return (
            <Styles.FilterDrop key={String(index)}>
              <Text
                text={text}
                color={colors.argon.white}
                fontFamily="Open Sans"
                size={12}
                weight="600"
              />
              <MdDeleteForever
                size={20}
                color={colors.suport.alert}
                onClick={() => remove(key)}
              />
            </Styles.FilterDrop>
          );
        } else {
          return <div key={index}></div>;
        }
      })}
    </Styles.FilterConatiner>
  );
};

export default FilterListAnamnesis;
