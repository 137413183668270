import Modal from 'components/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from '../constants/styles';
import { Content, Tabs } from 'components/Tab/styles';
import Tab from 'components/Tab';
import RegisterProductTab from './registerTabs/RegisterProductTab';
import RegisterBrandTab from './registerTabs/RegisterBrandTab';
import RegisterCategoryTab from './registerTabs/RegisterCategoryTab';
import RegisterPackagingTab from './registerTabs/RegisterPackagingTab';
import Text from 'components/Text';
import { IconButton } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import colors from 'shared/utils/constants/colors';

export type ICreateProductModalProps = {
    isOpen: boolean;
    onClose: () => void;
};


const RegisterStockModal: React.FC<ICreateProductModalProps> = ({ isOpen, onClose }) => {

    const [activedTab, setActivedTab] = useState(0);

    useEffect(() => {
        setActivedTab(0);
      }, []);

    const handleActive = (tab: number) => {
        switch (tab) {
            case 2:
                setActivedTab(tab);
                break;
            case 3:
                setActivedTab(tab);
                break;
            case 4:
                setActivedTab(tab);
                break;
            default:
                setActivedTab(tab);
        }
      };

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
            <Text
                    text="Cadastros de Estoque"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <IconButton
                    aria-label='Fechar modal'
                    icon={<CgClose />}
                    onClick={onClose}
                />
            </Styles.header>
            <Styles.header>
            <Tabs>
            <Tab
              activeTab={handleActive}
              active={activedTab === 0}
              id={0}
              title="Cadatrar Produto"
            />
            <Tab
              activeTab={handleActive}
              active={activedTab === 2}
              id={2}
              title="Cadastrar Marca"
            />
            <Tab
              activeTab={handleActive}
              active={activedTab === 3}
              id={3}
              title="Cadastrar Categoria"
            />
            <Tab
              activeTab={handleActive}
              active={activedTab === 4}
              id={4}
              title="Cadastrar Embalagem"
            />
          </Tabs>
        </Styles.header>
        <Styles.line></Styles.line>
            <Content active={activedTab === 0}>
               <RegisterProductTab isOpen={isOpen} onClose={onClose} />
            </Content>
            <Content active={activedTab === 2}>
               <RegisterBrandTab  isOpen={isOpen} onClose={onClose}/>
            </Content>
            <Content active={activedTab === 3}>
               <RegisterCategoryTab  isOpen={isOpen} onClose={onClose}/>
            </Content>
            <Content active={activedTab === 4}>
               <RegisterPackagingTab  isOpen={isOpen} onClose={onClose}/>
            </Content>
        </Modal>
    )
}

export default RegisterStockModal