import AwesomeDebouncePromise from "awesome-debounce-promise";
import { format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { ReactComponent as CheckedButton } from "../../assets/svg/generic/checked.svg";
import FilterList from "../../components/FilterList";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import convertToCurrencyFormat from "../../shared/utils/convertToCurrencyFormat";
import StatusTransactionEnum from "../../shared/utils/enums/StatusTransactionEnum";
import { launchToast } from "../../shared/utils/launchToast";
import processError from "../../shared/utils/processError";
import ITransaction from "../../types/ITransaction";
import FilterModal from "./FilterModal";
import {
  allowRefundAwaitingConfirmationFromAdmin,
  searchTransactions,
} from "./graphQL";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "components/Search";
import { headers } from "./utils/columns";
import Table from "components/Table";
import MainContainer from "components/MainContainer";

interface ITransactionCustom extends ITransaction {
  amount_formatted: string;
  status_formatted: string;
  created_at_formatted: string;
  id_to_small_code: string;
}

const Transactions: React.FC = () => {
  const [transactions, setTransactions] = useState<ITransactionCustom[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [wordSearch, setWordSearch] = useState("");
  const [filters, setFilters] = useState([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sort, setSort] = useState({} as any);

  const loadTransactions = async (wordSearch?: string) => {
    setLoading(true);
    let where = {
      ...(wordSearch && { wordSearch: wordSearch }),
    } as any;

    for (let filter of filters) {
      const { key, searchValue } = filter;
      switch (key) {
        case "Cliente":
          where.customer_name = searchValue;
          break;
        case "Status":
          where.status = searchValue;
          break;
        case "Data":
          where.created_at = searchValue;
          break;
      }
    }

    try {
      const { data, totalPages } = await searchTransactions({
        pagination: { limit, page: currentPage },
        where,
        orderBy: [
          {
            created_at: "desc",
          },
        ],
      });
      setTotalPage(totalPages);
      setTransactions(
        data.map((transaction) => {
          let status_converted = "sem dado";

          if (transaction.status === StatusTransactionEnum.cancelled) {
            status_converted = "cancelado";
          } else if (
            transaction.status === StatusTransactionEnum.changes_waiting_action
          ) {
            status_converted = "Aguardando confirmar alteração";
          } else if (
            transaction.status ===
            StatusTransactionEnum.refund_waiting_authorization
          ) {
            status_converted = "estorno aguardando permissão ";
          } else if (transaction.status === StatusTransactionEnum.paided) {
            status_converted = "pago";
          } else if (transaction.status === StatusTransactionEnum.waiting) {
            status_converted = "aguardando p.";
          } else if (transaction.status === StatusTransactionEnum.refunded) {
            status_converted = "Estornado";
          } else {
            status_converted = "sem dado";
          }

          return {
            ...transaction,
            status_formatted: status_converted,
            amount_formatted: convertToCurrencyFormat(transaction.amount / 100),
            created_at_formatted: format(
              parseISO(transaction.created_at),
              "dd/MM/yyyy"
            ),
            id_to_small_code: transaction.id.replace(/-/g, ""),
            actions: [
              {
                name: "Aprovar estorno",
                icon: <CheckedButton />,
                action: handleApproveRefundAwaitingAdminPermission,
              },
            ],
          };
        })
      );
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    } finally {
      setLoading(false);
    }
  };

  const loadTransactionsDebounce = AwesomeDebouncePromise(
    loadTransactions,
    500
  );

  const handleSearch = (wordSearch: string) => {
    setCurrentPage(1);
    setWordSearch(wordSearch);
    loadTransactionsDebounce(wordSearch);
  };

  const onSetPageSize = (pageSize: number) => {
    setLimit(pageSize);
  };

  const handleApplyFilter = async (f: any) => {
    setFilters(f);
  };

  const handleRemoveFilter = (key: string) => {
    let newFilters = filters.filter((f: any) => f.key !== key);
    setFilters(newFilters);
  };

  const handleApproveRefundAwaitingAdminPermission = async (
    transaction_id: string
  ) => {
    try {
      const transaction_updated =
        await allowRefundAwaitingConfirmationFromAdmin(transaction_id);
      setTransactions((old) =>
        old.map((transaction) => {
          if (transaction_updated.id === transaction.id) {
            let status_converted = "sem dado";

            if (transaction.status === StatusTransactionEnum.cancelled) {
              status_converted = "cancelado";
            } else if (
              transaction.status ===
              StatusTransactionEnum.changes_waiting_action
            ) {
              status_converted = "Aguardando confirmar alteração";
            } else if (
              transaction.status ===
              StatusTransactionEnum.refund_waiting_authorization
            ) {
              status_converted = "estorno aguardando permissão ";
            } else if (transaction.status === StatusTransactionEnum.paided) {
              status_converted = "pago";
            } else if (transaction.status === StatusTransactionEnum.waiting) {
              status_converted = "aguardando p.";
            } else {
              status_converted = "sem dado";
            }

            return {
              ...transaction_updated,
              status_formatted: status_converted,
              amount_formatted: convertToCurrencyFormat(
                transaction_updated.amount / 100
              ),
              created_at_formatted: format(
                parseISO(transaction_updated.created_at),
                "dd/MM/yyyy"
              ),
              id_to_small_code: transaction_updated.id.replace(/-/g, ""),
            };
          }
          return transaction;
        })
      );
      launchToast("Transacao criada com sucesso", "success");
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    }
  };
  // eslint-disable-next-line
  const handleSort = (field: string) => {
    let direction = "desc";
    if (!!sort.field && sort.field === field && sort.direction === "desc") {
      direction = "asc";
    }

    setSort({ field, direction });
  };

  useEffect(() => {
    loadTransactions();
    // eslint-disable-next-line
  }, [currentPage, limit, filters, sort]);

  return (
    <>
      {loading && <Loading />}
      <MainContainer>
        <PanelHeader
          title="Transações"
          isFilterModalVisible={true}
          onClick={() => setModalVisible(true)}
        />
        <Search
          placeholder="Pesquisar por nome"
          setSearch={handleSearch}
          wordSearch={wordSearch}
          isFilterForRegister={true}
          limit={limit}
          onSetPageSize={onSetPageSize}
        />
        {filters.length >= 1 && (
          <FilterList filters={filters} removeFilter={handleRemoveFilter} />
        )}
        <Table headers={headers} data={transactions} />
        <Pagination
          totalPage={totalPage}
          pageIndex={currentPage}
          setPage={setCurrentPage}
        />
      </MainContainer>
      {modalVisible && (
        <FilterModal
          visible={modalVisible}
          onCloseModal={() => setModalVisible(false)}
          applyFilters={handleApplyFilter}
          reciviedFilter={filters}
        />
      )}
    </>
  );
};
export default Transactions;
