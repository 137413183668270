import Modal from 'components/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './styles';
import Button from "components/Button";
import { gql, useMutation, useQuery } from '@apollo/client';
import Table from "components/Table";
import { useDisclosure } from '@chakra-ui/react';
import { petObservationsColumns } from './conlumns';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import { ReactComponent as EditButton } from "../../../assets/svg/generic/edit.svg";
import { ReactComponent as CancelButton } from "../../../assets/svg/generic/cancelbutton.svg";
import { launchToast } from 'shared/utils/launchToast';
import IPet from 'types/IPet';
import NewPetObservationModal from './newObservationModal';
import EditPetObservationModal from './editObservationModal';
import ConfirmationSoftDeletePetObservationModal from './confirmationSoftDeleteModal';

export type IPetObservarionsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    pet: IPet
};

interface IPermanetObsevation{
    id: string
    description: string
    added_by: string
    show_to_customer: boolean
    created_at: string
}

export const GET_OBSERVATIONS = gql`
query GetPermanetObservations($input: GetPermanentObservationInput!) {
  getPermanetObservations(input: $input) {
    pet_observations {
      id
      description
      added_by
      show_to_customer
      created_at
    }
  }
}
`;

const SOFT_DELETE_PET_OBSERVATION = gql`
    mutation SoftDeletePermanentObservation($input: SoftDeleteObservationInput!) {
        softDeletePermanentObservation(input: $input) {
            id
            deleted_at
            deleted_by
        }
    }
`;

const PetObservarionsModal: React.FC<IPetObservarionsModalProps> = ({ isOpen, onClose, pet }) => {
    const [observations, setObservations] = useState<IPermanetObsevation[]>([])
    const [selectedObservation, setSelectedObservation] = useState<IPermanetObsevation>([] as any)

    
    const newObeservationModal = useDisclosure();
    const editObeservationModal = useDisclosure();
    const SoftDeleteObeservationConfirmationModal = useDisclosure();

    const { data, loading, refetch } = useQuery(GET_OBSERVATIONS, {
        variables: {
            input: {
              pet_id: pet.id
            }
          }
    });

    const [softDeletePermanentObservation] = useMutation(SOFT_DELETE_PET_OBSERVATION, {
        refetchQueries: [
            GET_OBSERVATIONS,
            'GetPermanetObservations'
        ],
    });

    const handleConfirmationSoftDelete = useCallback(
        async () => {
            try {
                await softDeletePermanentObservation({
                    variables: {
                        input: {
                            id: selectedObservation.id,
                            observationTo: "pet"
                        }
                    },
                });
                launchToast('Observação deletada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [softDeletePermanentObservation, selectedObservation],
    );
    
    useEffect(() => {
        if(data){
            setObservations(data.getPermanetObservations.pet_observations)
        }
    }, [data])

    const normalizeDate =(isoString: string) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
    }

    const handleNewObservation= useCallback(() => {
        newObeservationModal.onOpen()
    },[]);

    const handleSoftDeleteObservation= useCallback((observation) => {
        setSelectedObservation(observation)
        SoftDeleteObeservationConfirmationModal.onOpen()
    },[]);

    const handleEditObservation= useCallback(( observation ) => {
        setSelectedObservation(observation)
        editObeservationModal.onOpen()
    },[]);

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <NewPetObservationModal
                isOpen={newObeservationModal.isOpen}
                onClose={newObeservationModal.onClose}
                pet={pet}
            />
            { selectedObservation && <EditPetObservationModal
                isOpen={editObeservationModal.isOpen}
                onClose={editObeservationModal.onClose}
                observation={selectedObservation}
            />}
            { selectedObservation && <ConfirmationSoftDeletePetObservationModal
                isOpen={SoftDeleteObeservationConfirmationModal.isOpen}
                onClose={SoftDeleteObeservationConfirmationModal.onClose}
                handleConfirm={() => handleConfirmationSoftDelete()}
             />}
            <Styles.header>
                <Text
                    text="Observações"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <Button text="Nova observação" onClick={() => handleNewObservation()} />
            </Styles.header>
                {!loading && observations && observations.length > 0 ? <Table
                    headers={petObservationsColumns}
                    data={observations.map((observation) => ({
                        id: observation.id,
                        description: observation.description,
                        added_by: observation.added_by,
                        show_to_customer: observation.show_to_customer ? "Mostrar" : 'Não mostrar',
                        created_at: normalizeDate(observation.created_at),
                        actions: [{
                            name: "Editar Observação",
                            icon: <EditButton />,
                            action: () => handleEditObservation(observation),
                        },
                        {
                            name: "Deletar Observação",
                            icon: <CancelButton/>,
                            action: () => handleSoftDeleteObservation(observation),
                        },
                    ],
                    }))}
                /> :             
                <Styles.centerRowButton>
                <Text
                    text="Adicione uma observação"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={18}
                    weight="600"
                 />      
                 </Styles.centerRowButton>        
                }
            <Styles.centerRowButton>
                <Button text="Fechar" onClick={onClose} />
            </Styles.centerRowButton>
        </Modal>
    )
}

export default PetObservarionsModal