const getActualDate = () => {
    let actualDate = new Date();
    let day = actualDate.getDate();
    let formatedDay = day < 10 ? `0${day}` : day;
    let mouth = actualDate.getMonth() + 1;
    let formatedMouth = mouth < 10 ? `0${mouth}` : mouth;
    let year = actualDate.getFullYear();

    return `${year}-${formatedMouth}-${formatedDay}`;

};

export { getActualDate };