import { useQuery } from "@apollo/client";
import { Divider, Heading } from "@chakra-ui/react";
import { Form } from "@unform/web";
import { Label } from "components/Label/styles";
import Loading from "components/Loading";
import Modal from "components/Modal";
import Text from "components/Text";
import TextInput from "components/TextInput";
import React from 'react';
import { normalizePlatformEnum } from "shared/enums/PlatformEnum";
import convertToCurrencyFormat from "shared/utils/convertToCurrencyFormat";
import { BrazilianPaymentType } from "shared/utils/enums/TypePaymentEnum";
import { GET_ORDER_ERROR_DETAILS } from "../OrdersErrors/GraphQL";
import { Row } from "../OrdersErrors/styles";
import { ICreateOrderDto, IOrderErrorLog } from "../OrdersErrors/types";
import OrderServiceCard from "./OrderServiceCard";

export type IOrderErrorDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  orderError: IOrderErrorLog;
};

export type IOrderErrorDetails = {
  listOrderErrorDetails: ICreateOrderDto
}

export default function OrderErrorDetailsModal({
  isOpen,
  onClose,
  orderError
}: IOrderErrorDetailsModalProps) {
  const { data, loading, error } = useQuery<IOrderErrorDetails>(GET_ORDER_ERROR_DETAILS, {
    variables: {
      orderError: {...orderError.dto},
    }
  });

  return (
    <Modal closeModal={onClose} visible={isOpen}>
      <Heading>Detalhes do pedido</Heading>
      {loading && <Loading />}
      {data?.listOrderErrorDetails && (
        <Form onSubmit={()=> null}>
          <Row>
            <TextInput
              label="Valor da compra"
              name="orderValue"
              value={convertToCurrencyFormat(orderError.order_value) || "Não identificado"}
              disabled
            />
            <TextInput
              label="Doação"
              name="donated"
              value={convertToCurrencyFormat(data.listOrderErrorDetails.donated || 0)}
              disabled
            />
            <TextInput
              label="Alteração de admin (valor)"
              name="adminChangeAmount"
              value={String(data.listOrderErrorDetails.adminChange?.amount || "Sem alteração")}
              disabled
            />
            <TextInput
              label="Alteração de admin (tipo)"
              name="adminChangeType"
              value={data.listOrderErrorDetails.adminChange?.type || "Sem alteração"}
              disabled
            />
          </Row>
          <Row>
            {/* <TextInput
              label="Habilitado pra comprar domingo"
              name="canCreateOnSunday"
              value={data.listOrderErrorDetails.canCreateOnSunday ? "Sim" : "Não"}
              disabled
            /> */}
            <TextInput
              label="Plataforma"
              name="platform"
              value={normalizePlatformEnum(data.listOrderErrorDetails.platform)}
              disabled
            />
            <TextInput
              label="Tipo de pagamento"
              name="paymentMethodType"
              value={BrazilianPaymentType[data.listOrderErrorDetails.paymentMethod.type] || "Não identificado"}
              disabled
            />
            <TextInput
              label="Numero do cartão"
              name="cardNumber"
              value={data.listOrderErrorDetails.paymentMethod.cardId ? `**** **** **** ${data.listOrderErrorDetails.paymentMethod.cardId}` : "Sem cartão"}
              disabled
            />
            <TextInput
              label="Saldo da carteira"
              name="walletUseIt"
              value={data.listOrderErrorDetails.wallet.useIt ? "Sim" : "Não"}
              disabled
            />
          </Row>
          <Divider />
          <Heading size="lg" textAlign="center" marginTop={8}>Carrinho</Heading>
          {!!data.listOrderErrorDetails.cart.packs.length && data.listOrderErrorDetails.cart.packs.map((pack) => (
            <>
              <Heading size="lg" marginTop={8}>Pacote</Heading>
              <Row>
                <TextInput
                  label="Nome"
                  name="name"
                  value={pack.id}
                  disabled
                />
                <TextInput
                  label="Renovação automática"
                  name="autoRenew"
                  value={pack.autoRenew ? "Sim" : "Não"}
                  disabled
                />
                <TextInput
                  label="Frequência"
                  name="frequency"
                  value={data.listOrderErrorDetails.wallet.useIt ? "Sim" : "Não"}
                  disabled
                />
              </Row>
              {pack.services.map((service) => (
                <OrderServiceCard
                  service={service}
                  key={service.id}
                />
              ))}
            </>
          ))}
          {data.listOrderErrorDetails.cart.services.map((service) => (
            <OrderServiceCard
              service={service}
              key={service.id}
            />
          ))}
          {!!data.listOrderErrorDetails.cart.products.productList.length && (
            <>
              <Label>Endereço de entrega:
                <Text text={data.listOrderErrorDetails.cart.products.addressId} />
              </Label>
              {data.listOrderErrorDetails.cart.products.productList.map((product) => (
                <>
                  <Label>Id:
                    <Text text={String(product.id)} />
                  </Label>
                  <Label>Quantidade:
                    <Text text={String(product.quantity)} />
                  </Label>
                </>
              ))}
            </>
          )}
        </Form>
      )}
      {error && <Text text={error.message || error.name} />}
    </Modal>
  );
}
