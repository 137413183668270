import React from "react";
import { Box, Flex, Input, Select, Text, Button } from "@chakra-ui/react";
import { BiSearchAlt2 } from "react-icons/bi";
import TotalPerPage from "components/TotalPerPage";

interface ISearchProps {
  placeholder: string;
  setSearch: (value: string) => void;
  wordSearch: string;
  onSetPageSize?: (pageSize: number) => void;
  limit?: number;
  isFilterForRegister?: boolean;
  isFilterForSelect?: boolean;
  isFilterForOrigin?: boolean;
  type?: string
}

export const Search: React.FC<ISearchProps> = ({
  setSearch,
  wordSearch,
  placeholder,
  onSetPageSize,
  limit,
  isFilterForRegister = false,
  isFilterForSelect = false,
  isFilterForOrigin = false,
  type = 'text'
}) => {
  return (
    <Box
      background="white"
      borderRadius="5px"
      width="calc(100% - 20px)"
      h={{ base: "90px", md: "70px" }}
      marginTop="-35px"
      marginX="auto"
      marginBottom="0"
      padding="20px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
      flexDirection={{ base: "column", md: "row" }}
    >
      <Flex position="relative" width={{ base: "100%", md: "50%" }}>
        <Input
          placeholder={placeholder}
          type={type}
          onChange={(e) => setSearch(e.target.value)}
          value={wordSearch}
          border="2px solid #CBD5E0"
          borderRadius="5px"
          pl="50px"
          pr="10px"
          fontSize="16px"
          _focus={{
            border: "2px solid #CBD5E0",
            boxShadow: "0 0 6px rgba(30, 200, 96, 0.4)",
          }}
          h={{ base: "40px", md: "45px" }}
        />
        <Button
          position="absolute"
          top="50%"
          left="10px"
          transform="translateY(-50%)"
          fontSize="20px"
          cursor="pointer"
          onClick={() => {
            const input = document.getElementById("search");
            if (input) {
              input.focus();
            }
          }}
          borderRadius="0"
          bg="transparent"
          _hover={{
            bg: "transparent",
          }}
          _active={{
            bg: "transparent",
          }}
          zIndex="1"
          pointerEvents="none"
          leftIcon={<BiSearchAlt2 color="#CBD5E0" />}
        />
      </Flex>
      {isFilterForSelect && (
        <Select
          width={{ base: "100%", md: "20%" }}
          height="40px"
          border="2px solid #CBD5E0"
          borderRadius="5px"
          padding="10px"
          marginLeft="10px"
          cursor="pointer"
          _focus={{
            boxShadow: "0 0 10px rgba(59, 130, 246, 0.5)",
          }}
        >
          <option value="0">Ordenar por</option>
          <option value="1">Mais recentes</option>
          <option value="2">Mais antigos</option>
          <option value="3">Com mais participantes</option>
          <option value="4">Com menos participantes</option>
        </Select>
      )}
      {isFilterForOrigin && (
        <Select
          width="20%"
          h={{ base: "40px", md: "45px" }}
          border="2px solid #CBD5E0"
          borderRadius="5px"
          marginLeft="10px"
          cursor="pointer"
          _focus={{
            boxShadow: "0 0 10px rgba(59, 130, 246, 0.5)",
          }}
          fontSize={{ base: "12px", md: "16px" }}
        >
          <option value="0">Padrão</option>
          <option value="1">Admin</option>
          <option value="2">Site</option>
          <option value="3">Mobile</option>
          <option value="4">Landpage</option>
          <option value="5">JOB</option>
        </Select>
      )}
      {isFilterForRegister && (
        <TotalPerPage pageSize={limit} setPageSize={onSetPageSize} />
      )}
    </Box>
  );
};

export default Search;
