import React, { useRef, useState } from "react";

import Text from "../../components/Text";
import { Form } from "@unform/web";
import InputRef from "../../components/InputRef";
import Button from "../../components/Button";
import colors from "../../shared/utils/constants/colors";

import * as Styles from "./styles";
import { FaUnlockAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { useAuth } from "../../hooks/Auth";
import getValidationErrors from "../../shared/utils/getValidationErrors";
import { launchToast } from "../../shared/utils/launchToast";
import { ReactComponent as Logo } from "../../assets/svg/sidebar/logo.svg";

interface ChancePasswordFormData {
  password: string;
  newPassword: string;
  passwordConfirmation: string;
}
const UpdatePassword: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { passwordUpdate } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: ChancePasswordFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        password: Yup.string().required("Senha é obrigatória"),
        newPassword: Yup.string().required("Nova senha é obrigatória"),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref("newPassword")], "Senha não confere")
          .required("Senha não confere"),
      });
      await schema.validate(data, { abortEarly: false });

      try {
        await passwordUpdate(
          data.password,
          data.newPassword,
          data.passwordConfirmation
        );

        setLoading(false);
        launchToast("Senha alterada com sucesso", "success");
      } catch (error) {}
    } catch (error) {
      setLoading(false);
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);
    }
  };

  return (
    <>
      <Styles.Container>
        <Styles.ContainerHeader>
          <Styles.WelcomeTextDiv>
            <Logo />
          </Styles.WelcomeTextDiv>
        </Styles.ContainerHeader>

        <Styles.Content>
          <Text
            text="alterar senha"
            color={colors.argon.textColorDark}
            fontFamily="OrelegaOne-regular"
            size={32}
            weight="600"
          />
          <Styles.FormDiv>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <InputRef
                placeholder="Digite a senha atual"
                name="password"
                type="password"
                icon={FaUnlockAlt}
              />
              <InputRef
                placeholder="Digite nova senha"
                name="newPassword"
                type="password"
                icon={FaUnlockAlt}
              />

              <InputRef
                placeholder="Digite novamente a senha"
                name="passwordConfirmation"
                type="password"
                icon={FaUnlockAlt}
              />

              <Button text="Alterar Senha" type="submit" loading={loading} />
            </Form>
          </Styles.FormDiv>
          <Styles.OptionsDiv>
            <Text
              text="&#x2190; Voltar"
              color={colors.argon.textColorGray}
              fontFamily="Open Sans"
              size={14}
              onTextClick={() => history.push("/")}
            />
          </Styles.OptionsDiv>
        </Styles.Content>
        <Styles.ContainerFooter />
      </Styles.Container>
    </>
  );
};
export default UpdatePassword;
