import { toast } from "react-toastify";

export type IMessageType = 'error' | 'success' | 'info' | 'warning'

export const launchToast = (message: string, type: IMessageType = "error") => {
  let definedType = toast.TYPE.DEFAULT;

  switch (type) {
    case "error":
      definedType = toast.TYPE.ERROR;
      break;
    case "success":
      definedType = toast.TYPE.SUCCESS;
      break;
    case "info":
      definedType = toast.TYPE.INFO;
      break;
    case "warning":
      definedType = toast.TYPE.WARNING;
      break;
  }

  toast(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: definedType,
  });
};
