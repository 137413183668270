import React from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import { default as ReactTooltip } from "react-tooltip";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";

interface IOnOffButtonProps {
  isOn: boolean;
  handleToggle: () => void;
}

const ToggleButton: React.FC<{ on: boolean; onClick: () => void }> = ({
  on,
  onClick,
}) => {
  return (
    <>
      <Button
        variant="ghost"
        colorScheme={on ? "green" : "red"}
        onClick={onClick}
        data-tip="ativar/desativar"
        p={4}
        fontSize="xl"
        borderRadius="full"
      >
        {on ? <FaToggleOn size={28} /> : <FaToggleOff size={28} />}
      </Button>
      <ReactTooltip place="top" effect="solid" />
    </>
  );
};

export const OnOffButton: React.FC<IOnOffButtonProps> = ({
  isOn,
  handleToggle,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleConfirm = () => {
    handleToggle();
    onClose();
  };

  return (
    <>
      <ToggleButton on={isOn} onClick={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="500px" h="200px" fontSize="3xl">
          <ModalHeader fontSize={"3xl"}>Confirmar remoção</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="3xl">Deseja realmente remover o parceiro?</Text>
          </ModalBody>
          <ModalFooter
            borderTopWidth="1px"
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                handleConfirm();
                onClose();
              }}
              width={"100px"}
              height={"30px"}
              fontSize={"xl"}
            >
              Remover
            </Button>
            <Button
              variant="ghost"
              onClick={onClose}
              width={"100px"}
              height={"30px"}
              fontSize={"xl"}
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
