export type ICreateMovimentFormData = {
  numberOfProducts: number;
  product: IProductMoviment;
  reason: IOptions;
  recipient: IRecipient;
  stockAction: string;
};

export type IRecipient = {
  id: string;
  name?: string;
  chasi_number?: string
  label?: string
  value?: string
}

export type IprofessionalMoviment = {
  user: User
  products: Product[]
}

export interface ITruckMoviment {
  id: number;
  chasi_number: string;
  products: Product[]
}

interface Product{
  id: number
  quantity: number
}

interface User {
  id: string;
  name: string;
  email: string
}

export type IProductMoviment = {
  id: number
  name: string
  quantity: number
  stock_unit: string
}

type IOptions = {
  value: string;
  label: string
}

export type IMoviment = {
  costPrice?: number
  brand?: string
  salePrice?: number
  movedBy?: string
  product?: string
  professional?: string
  quantity?: number
  reason?: string
  stockQuantity?: number
  totalPrice?: number
  truck?: string
  createdAt?: string
}

export type IMovimentInputFiltesData = {
  professionalId?: string | null
  truckId?: string | null
  productId?: number
  pagination?: IMovimentInputPaginationFiltesData
}

type IMovimentInputPaginationFiltesData = {
  limit?: number
  page?: number
}

export type IMovimentLog = {
  id?: number
  product?: IMovimentProductLog
  professional?: IMovimentProfessionalLog
  truck?: IMovimentTruckLog
  action_type?: string
  quantity?: number
  created_at: string
  moved_by: string
}

type IMovimentProductLog = {
  id: number
  name: string
  stock_unit: string
}

type IMovimentProfessionalLog = {
  id?: string
  name?: string
}

type IMovimentTruckLog = {
  id?: string
  chasi_number?: string
}


export type IFilterMovimentsFormData= {
  productId?: string;
  professionalId?: string;
  truckId?: number 
  [key: string]: any
}

export type APIFiltersMoviments = {
  productId?: string;
  professionalId?: string;
  truckId?: number 
}

export enum MovimentsFilterTranslateEnum {
  productId = 'Produto',
  professionalId = 'Profissional',
  truckId = 'Truck'
}
