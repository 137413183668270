import React from "react";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import Text from "../../../../components/Text";
import colors from "../../../../shared/utils/constants/colors";
import convertToCurrencyFormat from "../../../../shared/utils/convertToCurrencyFormat";
import * as Styles from "./styles";

type IProps = {
  visible: boolean; 
  onCloseModal: () => void;
  handleConfirm: () => void;
  price_default: number;
  price_updated: number;
}

const ModalConfirmation = ({ visible, onCloseModal, handleConfirm, price_default, price_updated }: IProps) => {
  return (
      <Modal visible={visible} closeModal={() => onCloseModal()} width={350}>
        <Text
          text="Valor alterado!"
          color="#595959"
          fontFamily="Open Sans"
          size={24}
          marginTop={60}
          weight="600"
        />
        <Text
          text="Ao alterar o serviço, o valor também foi alterado, o que deseja fazer?"
          color={colors.gray.black}
          fontFamily="Open Sans"
          size={16}
          marginTop={24}
          weight="400"
        />
        <Text
          text="Valor anterior"
          color={colors.gray.black}
          fontFamily="Open Sans"
          size={16}
          marginTop={36}
          weight="400"
        />
        <Text
          text={convertToCurrencyFormat(price_default)}
          color={colors.gray.black}
          fontFamily="Open Sans"
          size={16}
          marginTop={8}
          weight="600"
        />
        <Text
          text="Valor atual"
          color={colors.gray.black}
          fontFamily="Open Sans"
          size={16}
          marginTop={16}
          weight="400"
        />
        <Text
          text={convertToCurrencyFormat(price_updated)}
          color={colors.gray.black}
          fontFamily="Open Sans"
          size={16}
          marginTop={8}
          weight="600"
        />
        <Text
          text="Diferença"
          color={colors.gray.black}
          fontFamily="Open Sans"
          size={16}
          marginTop={16}
          weight="400"
        />
        <Text
          text={convertToCurrencyFormat(price_updated - price_default)}
          color={(price_updated - price_default) < 0 ? colors.suport.alert : colors.suport.sucess}
          fontFamily="Open Sans"
          size={16}
          marginTop={8}
          weight="600"
        />
        <Styles.rowButton>
          <Styles.WhiteButton
            onClick={() => onCloseModal()}
          >
            Descartar
          </Styles.WhiteButton>
          <Button
            text="Salvar"
            type="button"
            onClick={() => handleConfirm()}
            styleContainer={{ minWith: "100px" }}
          />
        </Styles.rowButton>
      </Modal>
  );
};
export default ModalConfirmation;
