import Modal from "components/Modal";
import Text from "components/Text";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import processError from "shared/utils/processError";
import ICategory from "types/ICategory";
import ICep from "types/ICep";
import IRegion from "types/IRegion";
import CategoriesSession from "./CategoriesSession";
import CepsSession from "./CepsSession";
import {
  addCep,
  createOrUpdateRegionCategory,
  getAllCategories,
  getRegion,
} from "./graphQL";
import * as Styles from "./styles";

type IProps = {
  region?: IRegion;
  onRequestClose: () => void;
};

type ICategoryCustom = {
  id: string;
  name: string;
  status: boolean;
};

const ModalRegionDetails = ({ onRequestClose, region }: IProps) => {
  const [ceps, setCeps] = useState<ICep[]>([]);
  const [categories, setCategories] = useState<ICategoryCustom[]>([]);

  const loadData = async (region_id: string) => {
    let allCategories = [] as ICategory[];
    try {
      allCategories = await getAllCategories();
    } catch (error) {
      toast.error(processError(error, "GRAPHQL").message);
    }
    try {
      const regionReloaded = await getRegion(region_id);
      setCeps(regionReloaded.ceps || []);
      setCategories(
        allCategories.map((category) => ({
          id: category.id,
          name: category.name,
          status: !!regionReloaded.categories?.find(
            (c) => c.id === category.id
          ),
        }))
      );
    } catch (error) {
      toast.error(processError(error, "GRAPHQL").message);
    }
  };

  const handleUpdateCepStatus = async (cepUpdated: ICep) => {
    setCeps(
      ceps.map((cep) => {
        if (cep.id === cepUpdated.id) {
          return {
            ...cep,
            status: cepUpdated.status,
          };
        }
        return cep;
      })
    );
  };

  const handleCreateOrUpdateCategoryStatus = async (
    category: ICategoryCustom
  ) => {
    if (!region?.id) {
      toast.error("Erro no ID da zona");
      return;
    }
    try {
      const categoryUpdated = await createOrUpdateRegionCategory({
        regionId: region?.id,
        categoryId: category.id,
        status: !category.status,
      });
      setCategories(
        categories.map((category) => {
          if (category.id === categoryUpdated.category_id) {
            return {
              ...category,
              status: categoryUpdated.status,
            };
          }
          return category;
        })
      );
      toast.success("Alerado com sucesso");
    } catch (error) {
      toast.error(processError(error, "GRAPHQL").message);
    }
  };

  const handleAddCep = async (payload: {
    start_cep: string;
    end_cep: String;
  }) => {
    if (!region?.id) {
      toast.error("Erro no ID da zona");
      return;
    }
    if (payload.start_cep === "" || payload.end_cep === "") {
      toast.warning("Os CEPS precisam conter mesmo número de digitos");
    }
    if (payload.start_cep.length !== payload.end_cep.length) {
      toast.warning("Os CEPS precisam conter mesmo número de digitos");
    }
    try {
      const newCepSaved = await addCep({
        region_id: region?.id,
        start_cep: payload.start_cep,
        end_cep: payload.end_cep,
      });
      setCeps([...ceps, newCepSaved]);
      toast.success("Cep adicionado com sucesso");
    } catch (error) {
      toast.error(processError(error, "GRAPHQL").message);
    }
  };

  useEffect(() => {
    if (!!region) {
      loadData(region.id);
    }
  }, [region]);

  return (
    <Modal visible={!!region} closeModal={() => onRequestClose()}>
      <Styles.Header>
        <Text
          text={region?.name || "erro"}
          fontFamily="Open Sans"
          weight="600"
          size={24}
        />
      </Styles.Header>
      <Styles.Container>
        <Styles.Row>
          <CepsSession
            ceps={ceps}
            onUpdateCepStatus={handleUpdateCepStatus}
            handleAddCep={handleAddCep}
          />
          <CategoriesSession
            region_id={region?.id}
            categories={categories}
            onUpdateCategoryStatus={handleCreateOrUpdateCategoryStatus}
          />
        </Styles.Row>
      </Styles.Container>
    </Modal>
  );
};

export default ModalRegionDetails;
