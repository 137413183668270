import listSizesPets from './listSizesPets';

interface IParameters {
  specie: string;
  weight: number;
}

const calculateSizePet = (size: number): string => {
  const sizes = [
    { label: 'PP', size: 1 },
    { label: 'P', size: 2 },
    { label: 'M', size: 3 },
    { label: 'G', size: 4 },
    { label: 'GG', size: 5 },
    { label: 'XGG', size: 6 },
  ];

  return sizes.find(si => si.size === size)?.label || '';
};

const calculateSizeByWeigthPet = ({
  specie,
  weight,
}: IParameters): string | undefined => {
  const specieFormatted = specie.toLocaleLowerCase() as 'cachorro' | 'gato';

  const results = listSizesPets[specieFormatted].weights.find(
    weigthItem => weigthItem.min <= weight && weigthItem.max >= weight,
  );

  if (!results) {
    return undefined;
  }

  const label = calculateSizePet(results.size);

  return label;
};

const calculateLabelPet = (label: string): number => {
  const sizes = [
    { label: 'PP', size: 1 },
    { label: 'P', size: 2 },
    { label: 'M', size: 3 },
    { label: 'G', size: 4 },
    { label: 'GG', size: 5 },
    { label: 'XGG', size: 6 },
  ];

  return sizes.find(si => si.label === label.trim())?.size || 1;
};

export { calculateSizePet, calculateLabelPet, calculateSizeByWeigthPet };
