export const appointmentsHeader = [
  { key: "category", name: "categoria" },
  { key: "date_formated", name: "data" },
  { key: "period", name: "período" },
  { key: "customer", name: "tutor" },
  { key: "pet_name", name: "pet" },
  { key: "service_name", name: "serviço" },
  { key: "professional", name: "Profissional" },
  { key: "appointment_price_total", name: "total" },
  { key: "appointment_discount", name: "desconto" },
  { key: "appointment_price_paided", name: "pago" },
  { key: "status_description", name: "status" },
  { key: "created_at", name: "criação" },
  { key: "platform", name: "origem" },
];

export const salesHeader = [
  { key: "name", name: "Produto" },
  { key: "address", name: "Endereço" },
  { key: "cep", name: "Cep" },
  { key: "quantity", name: "quantidade" },
  { key: "price", name: "preço por produto" },
  { key: "totalPrice", name: "preço total" },
];
