import React, { useEffect, useRef, useState } from "react";
import * as Styles from "../../styles";
import { Label } from "components/Label/styles";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import getValidationErrors from "shared/utils/getValidationErrors";
import { ValidationError } from "yup";
import { launchToast } from "shared/utils/launchToast";
import Select from "components/Select";
import TextArea from "components/TextArea";
import { NewUserObservationFormValidations } from "./newUserObservationFormValidations";

export type IEditPetObservationPayload = {
  id: string;
  observation: string;
  showToCustomer: boolean;
  observationTo: string;
};

export type IEditPetObservationFormProps = {
  onSubmit: (
    payload: IEditPetObservationPayload,
    options: { reset: () => void }
  ) => Promise<void>;
  observation: {
    id: string;
    description: string;
    added_by: string;
    show_to_customer: boolean;
    created_at: string;
  };
};

export const editPetObservationFormId = "edit-pet-observation-form";

const EditPetObservationForm: React.FC<IEditPetObservationFormProps> = ({
  onSubmit,
  observation,
}) => {
  const [showToCustomer, setShowToCustomer] = useState([] as any);
  const [showToCustomerError, setShowToCustomerError] = useState("");

  const editObservatiionFormRef = useRef<FormHandles>(null);

  const showToCustomerOptions = [
    { label: "Mostrar", value: true },
    { label: "Não mostrar", value: false },
  ];

  useEffect(() => {
    editObservatiionFormRef.current?.setFieldValue(
      "observation",
      observation.description
    );
    editObservatiionFormRef.current?.setErrors({});
    observation.show_to_customer
      ? setShowToCustomer({ label: "Mostrar", value: true })
      : setShowToCustomer({ label: "Não mostrar", value: false });
    setShowToCustomerError("");
  }, [observation]);

  const resetForm = () => {
    setShowToCustomerError("");
    editObservatiionFormRef.current?.setErrors({});
    setShowToCustomer([] as any);
  };

  const validateFields = async (
    payload: IEditPetObservationPayload,
    options: { reset: () => void }
  ) => {
    try {
      await NewUserObservationFormValidations.validate(
        { ...payload },
        { abortEarly: false }
      );
      onSubmit(
        { ...payload, id: observation.id, observationTo: "pet" },
        options
      );
      resetForm();
    } catch (error) {
      const errors = getValidationErrors(error as ValidationError);
      editObservatiionFormRef.current?.setErrors(errors);
      if (!showToCustomer.label) {
        setShowToCustomerError("Selecione uma opção");
      }
      launchToast("Verifique o preenchimento dos dados", "error");
    }
  };

  return (
    <>
      <Form
        onSubmit={validateFields}
        ref={editObservatiionFormRef}
        id={editPetObservationFormId}
      >
        <Styles.row>
          <Styles.field>
            <Label>Exibir para o cliente</Label>
            <Select
              name="showToCustomer"
              containerStyle={{ width: "33rem" }}
              placeholder="Selecione a ação"
              options={showToCustomerOptions}
              value={showToCustomer}
              error={showToCustomerError}
              onChange={(e: any) => setShowToCustomer(e)}
            />
          </Styles.field>
          <Styles.field>
            <Label>Observação</Label>
            <TextArea
              containerStyle={{ width: "40rem" }}
              name="observation"
              placeholder="Digite a observação"
            />
          </Styles.field>
        </Styles.row>
      </Form>
    </>
  );
};

export default EditPetObservationForm;
