import React, {  ChangeEvent, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import { RegisterSupplierFormValidationSchema } from './RegisterSupplierValidations';
import {  IRegisterSupplierFormData } from 'pages/Stock/constants/types';

export type IRegisterSupplierFormProps = {
    onSubmit: (payload: IRegisterSupplierFormData, options: { reset: () => void }) => Promise<void>;
}

export const RegisterSupplierFormId = 'register-supplier-form';

const RegisterSupplierForm: React.FC<IRegisterSupplierFormProps> = ({ onSubmit }) => {

    const [cnpj, setCnpj] = useState<string>('');

    const registerSupplierFormRef = useRef<FormHandles>(null);

    const handleCnpjChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.replace(/\D/g, '');
        value = value.substring(0, 14); 
        value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        setCnpj(value);
      };

      const extractNumbersFromCnpj = (cnpj: string): string => {
        return cnpj.replace(/\D/g, '');
      };
      

    const validateFields = async (
        payload: IRegisterSupplierFormData,
        options: { reset: () => void }
    ) => {
        try {
            await RegisterSupplierFormValidationSchema.validate({...payload, cnpj: extractNumbersFromCnpj(payload.cnpj)} , { abortEarly: false });
            onSubmit({...payload, cnpj: extractNumbersFromCnpj(payload.cnpj)}, options);
            setCnpj("")
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            registerSupplierFormRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };  

    return (
        <>
            <Form onSubmit={validateFields} ref={registerSupplierFormRef} id={RegisterSupplierFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Nome</Label>
                        <InputRef
                            name="name"
                            placeholder="Digite o nome do fornecedor"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>CNPJ</Label>
                        <InputRef
                            name="cnpj"
                            placeholder="Digite o CNPJ do fornecedor"
                            containerStyle={{ width: "33rem" }}
                            value={cnpj}
                            onChange={handleCnpjChange}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default RegisterSupplierForm