import { useField } from '@unform/core';
import React, {
  InputHTMLAttributes, useCallback, useEffect,
  useRef, useState
} from 'react';

import Text from '../../components/Text';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: any;
  onclickIcon?: () => void;
  onBlurExecute?: (value: string) => void;
}

const InputRef: React.FC<InputProps> = ({
  name,
  containerStyle,
  icon: Icon,
  onclickIcon,
  onBlurExecute,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback((): void => {
    setIsFocus(false);

    if (onBlurExecute) {
      onBlurExecute(inputRef.current?.value || '');
    }

    setIsFilled(!!inputRef.current?.value);
  }, [onBlurExecute]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Container
        style={containerStyle}
        hasIcon={!!Icon}
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={disabled ? disabled : false}
      >
        {Icon && <Icon />}

        <input
          name={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          disabled={disabled}
          defaultValue={defaultValue}
          {...rest}
        />
      </Container>

      {error && (
        <Text
          text={error}
          color="#FF6340"
          fontFamily="Open Sans"
          align="left"
          weight="400"
          size={12}
          marginBottom={16}
          
        />
      )}
    </>
  );
};

export default InputRef;
