import Modal from "components/Modal";
import React, { useCallback } from "react"
import * as Styles from '../constants/styles';
import Text from "components/Text";
import { IconButton } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import colors from "shared/utils/constants/colors";
import Button from "components/Button";
import { launchToast } from "shared/utils/launchToast";
import { APIFiltersReceipts, IFilterReceiptsFormData, ReceiptsFilterTranslateEnum } from "../constants/types";
import FilterReceiptsForm, { FilterReceiptsFormId } from "../forms/FilterReceipts/FilterReceiptsForm";

export type FilterReceiptsModalProps = {
    isOpen: boolean;
    onClose: () => void;
    handleAddFilter: (variables?: Partial<{
        filterParams: {
            cnpj?: string;
            endDate?: string;
            number?: string | number 
            startDate?: string;
            supplier?: string
        };
        paginationParams: {
            limit: number;
            page: number;
        };
        orderParams: {
            field: string;
            order: string;
        };
    }> | undefined) => void;
    onFilterSubmit: (filters: any) => void;
}


const FilterReceiptsModal = ({
    isOpen,
    onClose,
    handleAddFilter,
    onFilterSubmit
}: FilterReceiptsModalProps) => {

    const handleSubmit = useCallback(
        async (
            payload: IFilterReceiptsFormData,
            { reset }
        ) => {
            try {
                const activeFilters = [];

                const {...rest } = payload;

                const APIFilters = rest as unknown as APIFiltersReceipts;

                for (const key in payload) {
                    if (payload[key] !== '' && payload[key] !== undefined) {
                        activeFilters.push({ key: ReceiptsFilterTranslateEnum[key as keyof APIFiltersReceipts], value: payload[key] });
                    } else {
                        delete APIFilters[key as keyof APIFiltersReceipts];
                    }
                }

                onFilterSubmit(activeFilters);


                handleAddFilter({
                    filterParams: {
                        ...APIFilters,
                    },
                    paginationParams: {
                        limit: 10,
                        page: 1,
                    }
                });
                reset();
                onClose();
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        }, []
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Filtrar Notas Fiscais"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <IconButton
                    aria-label='Fechar modal'
                    icon={<CgClose />}
                    onClick={onClose}
                />
            </Styles.header>
            <Styles.line></Styles.line>
            <Styles.FormDiv>
                <FilterReceiptsForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button
                    text="Filtrar"
                    type="submit"
                    form={FilterReceiptsFormId}
                />
            </Styles.rowButton>
        </Modal>
    )
}

export default FilterReceiptsModal