import colors from "shared/utils/constants/colors";
import styled from "styled-components";


export const FilterConatiner = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 10px;
  margin-left: 24px;
`;

export const FilterDrop = styled.div`
  min-height: 30px;
  border-radius: 4px;
  background-color: ${colors.primary.dark};
  display: flex;
  align-items: center;
  padding: 8px;
  margin-right: 15px;
  gap: 10px;
  cursor: pointer;
`;
