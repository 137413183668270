import Button from "components/Button";
import Modal from "components/Modal";
import Text from "components/Text";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SpeciesEnum from "shared/enums/SpeciesEnum";
import colors from "shared/utils/constants/colors";
import orderPricesBySize from "shared/utils/orderPricesBySize";
import processError from "shared/utils/processError";
import IService from "types/IService";
import { createOrUpdatePricesByRegion, getServiceDetails } from "./graphQL";
import * as Styles from "./styles";

type IProps = {
  serviceBasic?: {
    id: string;
    name: string;
  };
  region_id?: string;
  category?: {
    id: string;
    name: string;
  };
  onRequestClose: () => void;
};

type IPropsChangeValue =
  | {
      type: "BASE_VALUE";
      service_specie_size_id: string;
      value: number;
    }
  | {
      type: "ADDICIONAL";
      additional_id: string;
      item_specie_size_id: string;
      value: number;
    };

const ModalServiceDetails = ({
  serviceBasic,
  region_id,
  category,
  onRequestClose,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [service, setService] = useState<IService | undefined>(undefined);

  const handleChangeValue = (payload: IPropsChangeValue) => {
    const value_formatted = parseInt(payload.value.toString(), 10);

    if (payload.type === "BASE_VALUE") {
      if (!service) {
        return;
      }
      const prices = service?.prices || [];
      setService({
        ...service,
        prices: prices.map((sss) => {
          if (sss.id === payload.service_specie_size_id) {
            return { ...sss, price: value_formatted };
          }
          return sss;
        }),
      });
    } else if (payload.type === "ADDICIONAL") {
      if (!service) {
        return;
      }
      const additionals = service?.additionals || [];
      setService({
        ...service,
        additionals: additionals.map((additional) => {
          if (additional.id === payload.additional_id) {
            return {
              ...additional,
              prices: additional.prices.map((iss) => {
                if (iss.id === payload.item_specie_size_id) {
                  return {
                    ...iss,
                    price: value_formatted,
                  };
                }
                return iss;
              }),
            };
          }
          return additional;
        }),
      });
    }
  };

  const handleSubmit = async () => {
    if (!region_id) {
      toast.error("Contate o suporte REGIONID missing");
      return;
    }
    try {
      setLoading(true);
      await createOrUpdatePricesByRegion({
        region_id: region_id,
        service_prices: service?.prices
          ? service?.prices.map((sss) => ({
              service_id: sss.service_id,
              specie_id: sss.specie_id,
              size: sss.size,
              price: Number(sss.price),
            }))
          : [],
        additionals_prices: service?.additionals
          ? service?.additionals.reduce((results: any, additional) => {
              results = results.concat(
                additional.prices.map((iss) => ({
                  item_id: iss.item_id,
                  specie_id: iss.specie_id,
                  size: iss.size,
                  price: Number(iss.price),
                }))
              );
              return results;
            }, [])
          : [],
      });
      setLoading(false);
      toast.success("Salvo com sucesso");
    } catch (error) {
      setLoading(false);
      toast.error(processError(error, "GRAPHQL").message);
    }
  };

  useEffect(() => {
    if (!!category && !!serviceBasic?.id && !!region_id) {
      getServiceDetails({
        service_id: serviceBasic.id,
        region_id: region_id,
      }).then((data) => {
        orderPricesBySize(data.prices);
        setService(data);
      });
    }
  }, [category, serviceBasic, region_id]);

  return (
    <Modal visible={!!serviceBasic} closeModal={() => onRequestClose()}>
      <Styles.Container>
        <Text
          text={serviceBasic?.name || "Sem nome"}
          fontFamily="Open Sans"
          weight="600"
          size={24}
        />
        <Styles.ServiceContainer>
          <Styles.Column>
            <div style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Text text="Preco base" fontFamily="Open Sans" weight="600" />
            </div>
            <Styles.Row>
              <Styles.Column>
                <Text text="Cachorro" fontFamily="Open Sans" weight="400" />
                <Styles.RowPrices>
                  {service?.prices
                    .filter((sss) => sss.specie_id === SpeciesEnum.CACHORRO)
                    .map((sss, index) => (
                      <div
                        key={sss.id}
                        style={{
                          marginLeft: index > 0 ? 8 : 0,
                        }}
                      >
                        <Text
                          text={sss.size || "Todos os tamanhos"}
                          fontFamily="Open Sans"
                          weight="400"
                          color={colors.secondary.default}
                        />
                        <Styles.Input
                          value={sss.price.toString()}
                          type="number"
                          onChange={(e) =>
                            handleChangeValue({
                              type: "BASE_VALUE",
                              service_specie_size_id: sss.id,
                              value: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    ))}
                </Styles.RowPrices>
              </Styles.Column>
            </Styles.Row>
            <Styles.Row>
              <Styles.Column>
                <Text text="Gato" fontFamily="Open Sans" weight="400" />
                <Styles.RowPrices>
                  {service?.prices
                    .filter((sss) => sss.specie_id === SpeciesEnum.GATO)
                    .map((sss, index) => (
                      <div
                        key={sss.id}
                        style={{
                          marginLeft: index > 0 ? 8 : 0,
                        }}
                      >
                        <Text
                          text={sss.size || "Todos os tamanhos"}
                          fontFamily="Open Sans"
                          weight="400"
                          color={colors.secondary.default}
                        />
                        <Styles.Input
                          value={sss.price.toString()}
                          type="number"
                          onChange={(e) =>
                            handleChangeValue({
                              type: "BASE_VALUE",
                              service_specie_size_id: sss.id,
                              value: Number(e.target.value),
                            })
                          }
                        />
                      </div>
                    ))}
                </Styles.RowPrices>
              </Styles.Column>
            </Styles.Row>
          </Styles.Column>
        </Styles.ServiceContainer>
        <hr />
        <Styles.AdditionalsPrices>
          {service?.additionals.map((additional, index) => (
            <Styles.Column key={index} style={{ marginTop: 16 }}>
              <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                <Text
                  text={additional.name}
                  fontFamily="Open Sans"
                  weight="600"
                />
              </div>
              <Styles.Row>
                <Styles.Column>
                  <Text text="Cachorro" fontFamily="Open Sans" weight="400" />
                  <Styles.RowPrices>
                    {additional?.prices
                      .filter((iss) => iss.specie_id === SpeciesEnum.CACHORRO)
                      .map((iss, indexAdditionalDog) => (
                        <div
                          key={iss.id}
                          style={{
                            marginLeft: indexAdditionalDog > 0 ? 8 : 0,
                          }}
                        >
                          <Text
                            text={iss.size || "Todos os tamanhos"}
                            fontFamily="Open Sans"
                            weight="400"
                            color={colors.secondary.default}
                          />
                          <Styles.Input
                            value={iss.price.toString()}
                            type="number"
                            onChange={(e) =>
                              handleChangeValue({
                                type: "ADDICIONAL",
                                additional_id: additional.id,
                                item_specie_size_id: iss.id,
                                value: Number(e.target.value),
                              })
                            }
                          />
                        </div>
                      ))}
                  </Styles.RowPrices>
                </Styles.Column>
              </Styles.Row>
              <Styles.Row>
                <Styles.Column>
                  <Text text="Gato" fontFamily="Open Sans" weight="400" />
                  <Styles.RowPrices>
                    {additional?.prices
                      .filter((iss) => iss.specie_id === SpeciesEnum.GATO)
                      .map((iss, indexAdditionalCat) => (
                        <div
                          key={iss.id}
                          style={{
                            marginLeft: indexAdditionalCat > 0 ? 8 : 0,
                          }}
                        >
                          <Text
                            text={iss.size || "Todos os tamanhos"}
                            fontFamily="Open Sans"
                            weight="400"
                            color={colors.secondary.default}
                          />
                          <Styles.Input
                            value={iss.price.toString()}
                            type="number"
                            onChange={(e) =>
                              handleChangeValue({
                                type: "ADDICIONAL",
                                additional_id: additional.id,
                                item_specie_size_id: iss.id,
                                value: Number(e.target.value),
                              })
                            }
                          />
                        </div>
                      ))}
                  </Styles.RowPrices>
                </Styles.Column>
              </Styles.Row>
            </Styles.Column>
          ))}
        </Styles.AdditionalsPrices>
        <div style={{ marginLeft: "auto", marginTop: 16 }}>
          <Button text="SALVAR" onClick={handleSubmit} loading={loading} />
        </div>
      </Styles.Container>
    </Modal>
  );
};

export default ModalServiceDetails;
