enum PlatformEnum {
  default = 0,
  admin = 1,
  site = 2,
  mobile = 3,
  landpage = 4,
  job = 5,
  auto_renew = 6,
};

export const normalizePlatformEnum = (value: PlatformEnum) => {
  const translations = {
    0: "Padrão",
    1: "Admin",
    2: "site",
    3: "Mobile",
    4: "Landing Page",
    5: "Job",
    6: "Renovação Automática",
  };

  return translations[value] || "Valor desconhecido";
};

export default PlatformEnum;
