import React, { useEffect, useRef, useState } from 'react';
import { Form } from "@unform/web";
import { ICampaign, IUpdateCampaignFormData } from "pages/Campaigns/constants/types";
import * as Styles from '../../constants/styles';
import { Label } from "components/Label/styles";
import InputRef from "components/InputRef";
import Text from "components/Text";
import colors from 'shared/utils/constants/colors';
import TextArea from 'components/TextArea';
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import { CreateCampaignFormValidationSchema } from '../CreateCampaign/CreateFormValidations';
import { ValidationError } from 'yup';
import Switch from 'components/Switch';


export type IUpdateCampaignFormProps = {
    onSubmit: (payload: IUpdateCampaignFormData, options: { reset: () => void }) => Promise<void>;
    campaign: ICampaign
}

export const UpdateCampaignFormId = 'update-campaign-form';

function formatDateTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const normalizeDateCampaign = (date: string) => {
    const originalDate = new Date(date);
    const newDate = new Date(originalDate.getTime() + 3 * 60 * 60 * 1000);

 return newDate.toISOString();
}

const UpdateCampaignForm = ({
    onSubmit,
    campaign
}: IUpdateCampaignFormProps) => {
    const updateCampaignFormRef = useRef<FormHandles>(null);

    const [selectType, setSelectType] = useState([] as any)

    const selectTypeOptions = [{ id: '1', label: 'Doação' }]

    useEffect(() => {
        updateCampaignFormRef.current?.setErrors({});
        updateCampaignFormRef.current?.setData({
            name: campaign.name,
            description: campaign.description,
            start_date: formatDateTime(campaign.start_date),
            end_date: formatDateTime(campaign.end_date),
            is_active: campaign.is_active ? 'Sim' : 'Não',
        });
        setSelectType({ label: normalizeLabel(campaign.type) })
    }, [campaign]);

    const normalizeLabel = (roleLabel: string) => {
        const normalizationMap: Record<string, string> = {
            "Doação": "DONATE",
            "DONATE": "Doação",
        };
        return normalizationMap[roleLabel] || roleLabel;
    };


    const validateFields = async (
        payload: IUpdateCampaignFormData,
        options: { reset: () => void }
    ) => {
        try {
            await CreateCampaignFormValidationSchema.validate({ ...payload, type: normalizeLabel(selectType.label) }, { abortEarly: false });
            onSubmit({ ...payload, start_date: normalizeDateCampaign(payload.start_date), end_date: normalizeDateCampaign(payload.end_date), type: normalizeLabel(selectType.label), id: campaign.id }, options);
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            updateCampaignFormRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <Form onSubmit={validateFields} ref={updateCampaignFormRef} id={UpdateCampaignFormId}>
            <Styles.header>
                <Text
                    text="Editar Campanha"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.row>
                <Styles.field>
                    <Label>Nome</Label>
                    <InputRef
                        name="name"
                        placeholder="Digite o nome do Campanha"
                        containerStyle={{ width: "33rem" }}
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Modelo</Label>
                    <Select
                        name="type"
                        containerStyle={{ width: "33rem" }}
                        placeholder="Modelo de campanha"
                        options={selectTypeOptions}
                        value={selectType}
                        onChange={(e: any) => setSelectType(e)}
                    />
                </Styles.field>
            </Styles.row>
            <Styles.row>
                <Styles.field>
                    <Label>Data de início</Label>
                    <InputRef
                        containerStyle={{ width: "33rem" }}
                        name="start_date"
                        type="date"
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Data do término </Label>
                    <InputRef
                        containerStyle={{ width: "33rem" }}
                        name="end_date"
                        type="date"
                    />
                </Styles.field>
            </Styles.row>
            <Styles.row>
                <Styles.field>
                    <Label>Descrição</Label>
                    <TextArea
                        containerStyle={{ width: "40rem" }}
                        name="description"
                        placeholder="Descrição da campanha"
                    />
                </Styles.field>
                <Styles.field>
                    <Label>Ativo</Label>
                    <Switch
                        name="is_active"
                        options={[
                            { value: 'Não', label: 'Não ativo' },
                            { value: 'Sim', label: 'Ativo' }
                        ]}
                    />
                </Styles.field>
            </Styles.row>
        </Form>
    );
}

export default UpdateCampaignForm