import React, { FC } from "react";
import { createPartner } from "../graphQL";
import { IPartnerCreate } from "types/IPartner";
import { handleFileChange } from "../Utils";

import Lottie from "react-lottie";
import { AiOutlineSelect } from "react-icons/ai";
import { BiSelectMultiple } from "react-icons/bi";
import partnerAnimation from "assets/partner.json";
import {
  Input,
  Label,
  Container,
  Img,
  ContainerImageInputs,
  ContainerAnimation,
  InputText,
  Button,
  Title,
  ContainerInputsData,
  InputData,
  Strong,
  ContainerButtons,
} from "./styles";
import { toast } from "react-toastify";

interface IProps {
  onClose: () => void;
}

export const NewPartnerModal: FC<IProps> = ({ onClose }) => {
  const [name, setName] = React.useState("" as string);
  const [logo, setLogo] = React.useState<File | undefined>();
  const [banner, setBanner] = React.useState<File | undefined>();
  const [logoSelected, setLogoSelected] = React.useState("" as string);
  const [bannerSelected, setBannerSelected] = React.useState("" as string);
  const [dateStart, setDateStart] = React.useState("" as string);
  const [dateEnd, setDateEnd] = React.useState("" as string);

  const handleLogoChange = (event: any) => {
    handleFileChange(event, setLogoSelected, setLogo);
  };

  const handleBannerChange = (event: any) => {
    handleFileChange(event, setBannerSelected, setBanner);
  };

  const handleSubmit = async (values: IPartnerCreate) => {
    if (
      !values.name ||
      !values.banner_file ||
      !values.logo_file ||
      !values.partnership_end ||
      !values.partnership_start
    ) {
      toast.error("Preencha todos os campos!");
      return;
    }
    try {
      await createPartner(values);
      toast.success("Parceiro criado com sucesso!");
      onClose();
    } catch (error) {
      console.log(error);
      toast.error("Erro ao criar parceiro!");
    }
  };

  return (
    <div
      style={{
        width: "800px",
        height: "500px",
      }}
    >
      <Title>Novo Parceiro</Title>
      <ContainerAnimation>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: partnerAnimation,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={70}
          width={70}
        />
      </ContainerAnimation>
      <Container
        style={{
          width: "800px",
        }}
      >
        <ContainerImageInputs>
          <Img
            src={
              bannerSelected.length > 0
                ? bannerSelected
                : "https://play-lh.googleusercontent.com/G7wIUsuTWuv4d4c-xrmeLKpfHgjo3CSBbrcahUwqDZyGO4aKc09d4Vsqfl_Itf4Ufh4=w240-h480-rw"
            }
            alt="Selecione o banner"
          />
          <Label htmlFor="bannerInput">
            <Input
              type="file"
              accept="image/*"
              id="bannerInput"
              onChange={handleBannerChange}
            />
            {bannerSelected.length > 0 ? (
              <>
                <BiSelectMultiple size={20} />
                Banner escolhido
              </>
            ) : (
              <>
                <AiOutlineSelect size={20} />
                Escolher banner
              </>
            )}
          </Label>
        </ContainerImageInputs>
        <ContainerImageInputs>
          <Img
            src={
              logoSelected.length > 0
                ? logoSelected
                : "https://play-lh.googleusercontent.com/G7wIUsuTWuv4d4c-xrmeLKpfHgjo3CSBbrcahUwqDZyGO4aKc09d4Vsqfl_Itf4Ufh4=w240-h480-rw"
            }
            alt="Selecione a logo"
          />
          <Label htmlFor="logoInput">
            <Input
              type="file"
              accept="image/*"
              id="logoInput"
              onChange={handleLogoChange}
            />
            {logoSelected.length > 0 ? (
              <>
                <BiSelectMultiple size={20} />
                Logo escolhida
              </>
            ) : (
              <>
                <AiOutlineSelect size={20} />
                Escolher logo
              </>
            )}
          </Label>
        </ContainerImageInputs>
      </Container>
      <InputText
        type="text"
        placeholder="Nome do parceiro"
        maxLength={17}
        onChange={(event) => setName(event.target.value)}
        value={name}
      />
      <ContainerInputsData>
        <Strong>De:</Strong>
        <InputData
          value={dateStart}
          type="date"
          onChange={(event) => setDateStart(event.target.value)}
        />
        <Strong>Até:</Strong>
        <InputData
          value={dateEnd}
          type="date"
          onChange={(event) => setDateEnd(event.target.value)}
        />
      </ContainerInputsData>
      <ContainerButtons>
        <Button
          onClick={onClose}
          style={{ backgroundColor: "#ff0020", width: "20%" }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() =>
            handleSubmit({
              banner_file: banner,
              logo_file: logo,
              name: name,
              partnership_end: dateEnd,
              partnership_start: dateStart,
            })
          }
        >
          Criar Parceiro
        </Button>
      </ContainerButtons>
      <p
        style={{
          marginTop: "2rem",
          fontSize: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <strong style={{ marginRight: "0.5rem" }}>Obs:</strong> É necessário que
        todos os campos estejam preenchidos para criar um novo parceiro.
      </p>
    </div>
  );
};
