import styled from "styled-components";

import colors from "../../shared/utils/constants/colors";

export const Panel = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 9999;
`;

export const Loader = styled.div`
  border: 16px solid ${colors.gray.light02};
  border-top: 16px solid ${colors.suport.blue};
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  top: 30%;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ItemContainer = styled.div`
  position: relative;
  top: 40%;
`;
