import { gql } from "@apollo/client";

import IProfessional from '../../types/IProfessional';

import client from "../../services/api-graphql";

const getProfessionals = async (): Promise<IProfessional[]> => {
  const response = await client.query({
    query: gql`
      query($filters: FiltersProfessionalInputType) {
        professionals(filters: $filters) {
          id
          user {
            name
          }
        }
      }
    `,
    variables: {
      filters: {
        where: {
          status: true,
        }
      }
    },
    fetchPolicy: "no-cache",
  });

  return response.data.professionals;
};

const createProfessionalSupplies = async (payload: {
  item_name: string;
  item_value: number;
  date: string;
  professional_id: string;
}): Promise<{ id: string }> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($professionalId: String!, $date: String!, $itemValue: Float!, $itemName: String!) {
        createProfessionalsSupplies(professional_id: $professionalId, date: $date, item_value: $itemValue, item_name: $itemName) {
          id
        }
      }
    `,
    variables: {
      itemName: payload.item_name,
      itemValue: payload.item_value,
      date: payload.date,
      professionalId: payload.professional_id,
    },
    fetchPolicy: "no-cache",
  });

  return response.data.openCep;
};

export { getProfessionals, createProfessionalSupplies };