import React, { useCallback, useEffect, useMemo, useState } from "react"
import * as Styles from "./styles";
import { useDisclosure } from "@chakra-ui/react";
import { PanelHeader } from "components/PanelHeader";
import CreateReceiptsModal from "./modals/CreateReceiptsModal";
import { useQuery } from "@apollo/client";
import { LIST_RECEIPTS } from "./graphql/ReceiptsQuery";
import Search from "components/Search";
import Pagination from "components/Pagination";
import Table from "components/Table";
import { APIFiltersReceipts, IFilterReceiptsFormData, IReceipt } from "./constants/types";
import { columnReceiptsHeaders } from "./constants/conlumns";
import { format, parseISO } from "date-fns";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import UpdateReceiptsModal from "./modals/UpdateReceiptsModal";
import FilterReceiptsModal from "./modals/FilterReceiptsModal";
import { launchToast } from "shared/utils/launchToast";
import FilterList from "components/FilterList";

const Receipts: React.FC = () => {

    const [paginationLimit, setPaginationLimit] = useState<number>(10);
    const [searchInput, setSearchInput] = useState<string>("");
    const [receiptSelected, setReceiptSelected] = useState<IReceipt | null>(null);
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    // const [idToToggleCampaign, setIdToToggleCampaign] = useState<number>(1)

    const createReceiptsModalControl = useDisclosure();
    const updateReceiptsModalControl = useDisclosure();
    const filterReceiptsModalControl = useDisclosure();

    const handleWordSearch = (receipt: string) => {
        setCurrentPage(1)
        setSearchInput(receipt)
    };

    // const normalizeLabel = (roleLabel: string) => {
    //     const normalizationMap: Record<string, string> = {
    //         "DONATE": "Doação",
    //     };
    //     return normalizationMap[roleLabel] || roleLabel;
    // };

    const handleReceiptUpdate = useCallback(
        async (receipt: IReceipt) => {
            setReceiptSelected(receipt);
            updateReceiptsModalControl.onOpen();
        },
        [updateReceiptsModalControl]
    )

    // const handleCampaignReport = useCallback(
    //     async (campaign: ICampaign) => {
    //         setCampaignSelected(campaign);
    //         reportCampaignModalControl.onOpen();
    //     },
    //     [reportCampaignModalControl]
    // )

    // const [toggleCampaign, { loading: toggleCampaingLoad }] = useMutation(TOGGLE_CAMPAIGN, {
    //     refetchQueries: [
    //         LIST_CAMPAIGNS,
    //         'ListCampaign',
    //     ],
    // });

    // const handleCampaignToggle = useCallback(
    //     async () => {
    //         try {
    //             const variables: IToggleCampaign = {
    //                 id: idToToggleCampaign,
    //             }
    //             await toggleCampaign({
    //                 variables,
    //             });
    //             launchToast('Status alterado com sucesso!', "success");
    //         } catch (error: any) {
    //             launchToast(error.message, "error");
    //         }
    //     }, [toggleCampaign, idToToggleCampaign]
    // );

    // const handleToggleConfirmation = (campaignId: number) => {
    //     setIdToToggleCampaign(campaignId);
    //     toggleCampaignModalControl.onOpen();
    // }


    const { data, loading, refetch } = useQuery(LIST_RECEIPTS, {
        variables: {
            filterParams: 
                {
                    ...(searchInput.length > 0 && { number: Number(searchInput) }),
                },
            paginationParams: {
                limit: paginationLimit,
                page: currentPage,
            },
            orderParams: {
                field: "emission_date",
                order: "desc",
            },
        },
    });

    useEffect(() => {
        try {
            const filterParams = filters.reduce((acc, filter: { key: string, value: string }) => {
                acc[filter.key as keyof IFilterReceiptsFormData] = filter.value;
                return acc;
            }, {} as IFilterReceiptsFormData);
            refetch({
                filterParams: {
                    ...filterParams,
                },
                paginationParams: {
                    limit: paginationLimit,
                    page: currentPage,
                },
                orderParams: {
                    field: "emission_date",
                    order: "desc",
                },
            });
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    }, [paginationLimit, currentPage])

    const handleAddFilter = (params: any) => {
        setCurrentPage(1);
        refetch(params);
    };

    const normalizeKeyFilter = (roleLabel: string) => {
        const normalizationMap: Record<string, string> = {
            "Numero": "number",
            "CNPJ": "cnpj",
            "Depois de": "startDate",
            "Antes de": "endDate",
            "Fornecedor": "supplier"
        };
        return normalizationMap[roleLabel] || roleLabel;
    };


    const pageInfo = useMemo(() => ({
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === data?.receipts.totalPages,
    }), [currentPage, data?.receipts.totalPages]);

    const handleRemoveFilter = (key: string) => {
        try {
            const newFilters = filters.filter((filter: any) => filter.key !== key);
            setFilters(newFilters);
            const filterParams = newFilters.reduce((acc, filter: { key: string, value: string }) => {
                const normalizeKey = normalizeKeyFilter(filter.key) 
                acc[normalizeKey as keyof APIFiltersReceipts] = filter.value as any;
                return acc;
            }, {} as APIFiltersReceipts);
            setCurrentPage(1);
            refetch({
                filterParams: {
                    ...filterParams,
                }
            });
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    };

    return (
        <>
            <CreateReceiptsModal
                isOpen={createReceiptsModalControl.isOpen}
                onClose={createReceiptsModalControl.onClose}
            />
            {receiptSelected && (
                <UpdateReceiptsModal
                    isOpen={updateReceiptsModalControl.isOpen}
                    onClose={updateReceiptsModalControl.onClose}
                    receipt={receiptSelected}
                />
            )}
            {/* {campaingSelected && (
                <ReportCampaignModal
                    isOpen={reportCampaignModalControl.isOpen}
                    onClose={reportCampaignModalControl.onClose}
                    campaign={campaingSelected}
                />
            )} */}
            <FilterReceiptsModal
                isOpen={filterReceiptsModalControl.isOpen}
                onClose={filterReceiptsModalControl.onClose}
                handleAddFilter={handleAddFilter}
                onFilterSubmit={setFilters}
            />
            {/* <ModalConfirmation
                isOpen={toggleCampaignModalControl.isOpen}
                onClose={toggleCampaignModalControl.onClose}
                nameAction="ativar/desativar essa campanha"
                handleConfirm={handleCampaignToggle}
                loading={toggleCampaingLoad}
            /> */}
            <Styles.BackgroundPanel>
                <PanelHeader
                    title="Notas Fiscais"
                    isFilterModalVisible
                    isNew
                    onClick={filterReceiptsModalControl.onOpen}
                    onClickNew={createReceiptsModalControl.onOpen}
                />
                <Search
                    wordSearch={searchInput}
                    setSearch={handleWordSearch}
                    isFilterForRegister
                    limit={paginationLimit}
                    onSetPageSize={(limit) => {
                        setCurrentPage(1)
                        setPaginationLimit(limit)
                    }}
                    placeholder="Pesquise numero da NF"
                    type="number"
                />
                {
                    filters.length
                        ? (
                            <FilterList filters={filters} removeFilter={handleRemoveFilter} />
                        )
                        : ''
                }
                {
                    !loading &&  (
                        <Table
                            headers={columnReceiptsHeaders}
                            data={data.receipts.data.map((receipt: IReceipt) => ({
                                id: receipt.id,
                                number: receipt.number,
                                supplier_id: receipt.supplier.name,
                                emission_date: format(parseISO(receipt.emission_date), "dd/MM/yyyy"),
                                actions: [
                                    // {
                                    //     name: "Relatoirio",
                                    //     icon: <ReportButton />,
                                    //     action: () => handleCampaignReport(campaign),
                                    // },
                                    {
                                        name: "Editar",
                                        icon: <EditButton />,
                                        action: () => handleReceiptUpdate(receipt),
                                    },
                                    // {
                                    //     name: "Desativar",
                                    //     icon: <CancelButton/>,
                                    //     action: handleToggleConfirmation,
                                    // },
                                ],
                            }))}
                        />
                    )
                }
                {
                    !loading && (
                        <Pagination
                            totalPage={data.receipts.totalPages}
                            canPreviousPage={currentPage > 1}
                            previousPage={() => {
                                !pageInfo.isFirstPage && setCurrentPage(currentPage - 1)
                            }}
                            nextPage={() => {
                                !pageInfo.isLastPage && setCurrentPage(currentPage + 1)
                            }}
                            canNextPage={currentPage < data.receipts.totalPages}
                            pageIndex={currentPage}
                            setPage={(page: number) => setCurrentPage(page)}
                        />
                    )
                }
            </Styles.BackgroundPanel>
        </>
    )
}

export default Receipts