import { gql } from "@apollo/client";

export const LIST_EVENTS = gql`
  query ListEvents(
    $orderParams: EventOrderingInputType!,
    $filterParams: ListEventsInputType!,
    $paginationParams: EventPaginationInputType!
  ) {
      listEvents(
        orderParams: $orderParams,
        filterParams: $filterParams,
        paginationParams: $paginationParams
      ) {
        data {
          id
          address {
            id
            cep
            city
            state
            street
            number
            neighborhood
            complement
            surname
          }
          cupon {
            id
            name
            code
            status
          }
          name
          event_start_date
          event_end_date
          title
          subtitle
          content
          image_url
          is_active
        }
        totalPages
      }
    }
`;

export const CREATE_EVENT = gql`
  mutation CreateEvent($file: Upload!, $input: CreateEventInputType!) {
    createEvent(file: $file, input: $input) {
      id
      event_address_id
      coupon_id
      name
      title
      subtitle
      image_url
      event_start_date
      event_end_date
    }
  }
`;

export const DEACTIVATE_EVENT = gql`
  mutation RemoveEvent($removeEventId: Float!) {
    removeEvent(id: $removeEventId)
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent(
    $file: Upload,
    $updateEventId: Float!,
    $input: UpdateEventInputType!
  ) {
    updateEvent(
      file: $file,
      id: $updateEventId,
      input: $input
    ) {
      id
      name
    }
  }
`;