import styled from "styled-components";

import loginbg from "../../assets/loginbg.png";

import colors from "../../shared/utils/constants/colors";

export const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  background: url(${loginbg}) no-repeat center;
  flex-direction: column;
  padding: 20px;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.argon.white};
  width: 500px;
  height: 160px;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(
    90deg,
    rgba(142, 183, 170, 1) 10%,
    rgba(204, 213, 0, 1) 90%
  );
  background-position: bottom;
  background-size: 100% 2%;
  background-repeat: no-repeat;
  animation: fadein 1s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  z-index: 1;
`;

export const Header = styled.h4`
  color: ${colors.argon.white};
  width: 50%;
  background-color: ${colors.primary.default};
  font-size: 14px;
  font-weight: 600;
  margin-top: -15px;
  border: 1px solid ${colors.primary.light};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
`;

export const Profile = styled.div`
  width: 350px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  background-color: ${colors.argon.white};
  padding: 20px;
  margin-top: 60px;
`;

export const ProfileImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 10px;
  background-image: url("https://images.unsplash.com/photo-1598134706627-d7ee8c927785?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1169&q=80");
  background-size: cover;
  background-position: center;
  border: 2px solid ${colors.primary.default};
`;

export const ProfileName = styled.h5`
  color: ${colors.primary.dark};
  font-size: 18px;
  font-weight: 600;
`;

export const ProfileEmail = styled.h6`
  color: ${colors.primary.default};
  font-size: 14px;
  font-weight: 400;
`;

export const ContainerAppointments = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const Pet = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`;

export const NamePet = styled.h6`
  color: ${colors.primary.dark};
  font-size: 14px;
`;

export const StatusAppointment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${colors.suport.warning};
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  padding: 5px;
`;

export const ServiceAppointment = styled.h6`
  color: ${colors.primary.default};
  font-size: 14px;
  margin-bottom: 10px;
`;

export const AddressAppointment = styled.h6`
  color: ${colors.primary.dark};
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
