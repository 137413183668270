import React, { FC, useState } from "react";
import { updatePartner, updatePartnerFile } from "../graphQL";
import { handleFileChange } from "../Utils";

import {
  Title,
  ContainerButtons,
  Button,
  Container,
  ContainerImageInputs,
  Img,
  ContainerInputsData,
  Label,
  Input,
  InputData,
  Strong,
  InputType,
} from "./styles";
import IPartner from "types/IPartner";
import { BiSelectMultiple } from "react-icons/bi";
import { AiOutlineSelect } from "react-icons/ai";
import Loading from "components/Loading";
import { toast } from "react-toastify";

interface IEditarProps {
  onClose: () => void;
  partner: IPartner;
}

export const Editar: FC<IEditarProps> = ({ onClose, partner }) => {
  const [banner, setBanner] = useState<File | null>(null);
  const [logo, setLogo] = useState<File | null>(null);
  const [name, setName] = useState<string>("");
  const [partnershipStart, setPartnershipStart] = useState<string>("");
  const [partnershipEnd, setPartnershipEnd] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [bannerSelected, setBannerSelected] = useState<string>("");
  const [logoSelected, setLogoSelected] = useState<string>("");

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (banner) {
        const dataBanner = {
          field: "BANNER",
          file: banner,
          updatePartnerFileId: partner.id,
        };
        await updatePartnerFile(
          dataBanner.field,
          dataBanner.file,
          dataBanner.updatePartnerFileId
        );
        toast.success("Banner atualizado com sucesso!");
      }
      if (logo) {
        const dataLogo = {
          field: "LOGO",
          file: logo,
          updatePartnerFileId: partner.id,
        };
        await updatePartnerFile(
          dataLogo.field,
          dataLogo.file,
          dataLogo.updatePartnerFileId
        );
        toast.success("Logo atualizado com sucesso!");
      }
      if (name || partnershipStart || partnershipEnd) {
        const data = {
          name,
          id: partner.id,
          partnership_start: partnershipStart,
          partnership_end: partnershipEnd,
        };
        await updatePartner(data);
        toast.success("Parceiro atualizado com sucesso!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Erro ao atualizar parceiro!");
    } finally {
      setLoading(false);
    }
  };

  const handleLogoChange = (event: any) => {
    handleFileChange(event, setLogoSelected, setLogo);
  };

  const handleBannerChange = (event: any) => {
    handleFileChange(event, setBannerSelected, setBanner);
  };

  return (
    <div
      style={{
        width: "800px",
        height: "500px",
      }}
    >
      {loading && <Loading />}
      <Container>
        <ContainerImageInputs>
          <Img
            src={bannerSelected ? bannerSelected : partner.banner_url}
            alt="Selecione o banner"
          />
          <Label htmlFor="bannerInput">
            <Input
              type="file"
              accept="image/*"
              id="bannerInput"
              onChange={handleBannerChange}
            />
            {partner.banner_url && !bannerSelected ? (
              <>
                <BiSelectMultiple size={20} />
                Banner escolhido
              </>
            ) : bannerSelected ? (
              <>
                <AiOutlineSelect size={20} />
                Banner atualizado
              </>
            ) : (
              <>
                <AiOutlineSelect size={20} />
                Escolher banner
              </>
            )}
          </Label>
        </ContainerImageInputs>
        <ContainerImageInputs>
          <Img
            src={logoSelected ? logoSelected : partner.logo_url}
            alt="Selecione a logo"
          />
          <Label htmlFor="logoInput">
            <Input
              type="file"
              accept="image/*"
              id="logoInput"
              onChange={handleLogoChange}
            />
            {partner.logo_url && !logoSelected ? (
              <>
                <BiSelectMultiple size={20} />
                Logo escolhida
              </>
            ) : logoSelected ? (
              <>
                <AiOutlineSelect size={20} />
                Logo atualizada
              </>
            ) : (
              <>
                <AiOutlineSelect size={20} />
                Escolher logo
              </>
            )}
          </Label>
        </ContainerImageInputs>
      </Container>
      <InputType
        isEditPartner={true}
        type="text"
        placeholder={partner.name}
        onChange={({ target }) => setName(target.value)}
        value={name}
      />
      <ContainerInputsData>
        <Strong>De:</Strong>
        <InputData
          value={
            partnershipStart
              ? partnershipStart
              : partner.partnership_start?.substr(0, 10)
          }
          type="date"
          onChange={({ target }) => setPartnershipStart(target.value)}
        />
        <Strong>Até:</Strong>
        <InputData
          value={
            partnershipEnd
              ? partnershipEnd
              : partner.partnership_end?.substr(0, 10)
          }
          type="date"
          onChange={({ target }) => setPartnershipEnd(target.value)}
        />
      </ContainerInputsData>
      <ContainerButtons>
        <Button
          onClick={onClose}
          style={{
            backgroundColor: "red",
            width: "20%",
          }}
        >
          Fechar
        </Button>
        <Button onClick={handleSubmit}>Editar</Button>
      </ContainerButtons>
      <p
        style={{
          marginTop: "2rem",
          fontSize: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <strong style={{ marginRight: "0.5rem" }}>Obs:</strong> Você pode editar
        todos os campos ou apenas um
      </p>
    </div>
  );
};
