import { gql } from "@apollo/client";
import client from "services/api-graphql";
import ICategory from "types/ICategory";
import ICep from "types/ICep";
import IRegion from "types/IRegion";
import IRegionCategory from "types/IRegionCategory";

const getRegion = async (region_id: string): Promise<IRegion> => {
  const response = await client.query({
    query: gql`
      query($regionId: String!) {
        region(id: $regionId) {
          id
          ceps {
            id
            status
            start_cep
            end_cep
          }
          categories {
            id
            name
          }
        }
      }
    `,
    variables: {
      regionId: region_id
    },
    fetchPolicy: 'no-cache'
  });

  return response.data.region;
};

const getAllCategories = async (): Promise<ICategory[]> => {
  const response = await client.query({
    query: gql`
      query {
        categories {
          id
          name
        }
      }
    `
  });

  return response.data.categories;
};

const createOrUpdateRegionCategory = async ({ categoryId, regionId, status }: { regionId: string; categoryId: string; status: boolean; }): Promise<IRegionCategory> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($status: Boolean!, $categoryId: String!, $regionId: String!) {
        createOrUpdateRegionCategory(status: $status, category_id: $categoryId, region_id: $regionId) {
          id
          status
          category_id
        }
      }
    `,
    variables: {
      regionId: regionId,
      categoryId: categoryId,
      status: status
    }
  });

  return response.data.createOrUpdateRegionCategory;
};

const addCep = async ({ region_id, start_cep, end_cep }: { region_id: string; start_cep: string; end_cep: String; }): Promise<ICep> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($endCep: String!, $startCep: String!, $regionId: String!) {
        addCep(end_cep: $endCep, start_cep: $startCep, region_id: $regionId) {
          id
          status
          start_cep
          end_cep
        }
      }
    `,
    variables: {
      regionId: region_id,
      startCep: start_cep,
      endCep: end_cep,
    }
  });

  return response.data.addCep;
};

export { getRegion, getAllCategories, createOrUpdateRegionCategory, addCep };
