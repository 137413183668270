export const inputFields = [
  {
    label: "Nome",
    key: "name",
    placeholder: "Nome do condomínio",
    optional: false,
  },
  { label: "CEP", key: "cep", placeholder: "CEP", optional: false },
  { label: "Cidade", key: "city", placeholder: "Cidade", optional: false },
  {
    label: "Bairro",
    key: "neighborhood",
    placeholder: "Bairro",
    optional: false,
  },
  { label: "Rua", key: "street", placeholder: "Rua", optional: false },
  { label: "Estado", key: "state", placeholder: "Estado", optional: false },
  {
    label: "Número Inicial",
    key: "start_number",
    placeholder: "Número",
    type: "number",
    optional: false,
  },
  {
    label: "Número Final",
    key: "end_number",
    placeholder: "Número",
    type: "number",
    optional: false,
  },
];
