import styled from "styled-components";

import colors from "../../shared/utils/constants/colors";

export const TotalConatiner = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalSelector = styled.select`
  margin: 0 8px;
  padding: 2px 4px;
  color: ${colors.argon.textColorGray};
  border-radius: 5px;
  border-color: ${colors.argon.textColorLight02}
  
`;
