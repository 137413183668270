import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { ICreateReceiptsFormData, IProductReceipts, IReceipt } from 'pages/Receipts/constants/types';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import {  getSuppliers } from 'services/stock/graphQL';
import { launchToast } from 'shared/utils/launchToast';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { CreateReceiptFormValidationSchema } from '../CreateReceipt/CreateFormValidations';
import TextArea from 'components/TextArea';

export type IUpdateReceiptsFormProps = {
    onSubmit: (payload: ICreateReceiptsFormData, options: { reset: () => void }) => Promise<void>,
    products: IProductReceipts[], 
    receipt: IReceipt
}

export const UpdateReceiptsFormId = 'update-receipts-form';


const UpdateReceiptsForm: React.FC<IUpdateReceiptsFormProps> = ({
    onSubmit,
    products,
    receipt
}) => {

    const [selectSupplier, setSelectSupplier] = useState([] as any)
    const [suppliers, setSuppliers] = useState([] as any[]);

    const updateReceiptsFormRef = useRef<FormHandles>(null);
    
    const fetchSuppliers = async () => {
        try {
          const results = await getSuppliers();
          setSuppliers(results.map( (supplier: {name: string, id: string}) => ({label: supplier.name, id: supplier.id})))
        } catch (error: any) {
          console.log(error.message);
        }
      };

    useEffect(() => {
        const fetchData = async () => {
          await Promise.all([
            fetchSuppliers(),
          ]);
        };
    
        fetchData();
      }, []);

      useEffect(() => {
        updateReceiptsFormRef.current?.setErrors({});
        updateReceiptsFormRef.current?.setData({
            number: receipt.number,
            emission_date: formatDateTime(receipt.emission_date),
            reason: ''
        });
        setSelectSupplier({ label: receipt.supplier.name, id: receipt.supplier.id })
    }, [receipt]);

    function formatDateTime(dateTimeString: string): string {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
    
        return `${year}-${month}-${day}`;
    }

      const normalizeDateCampaign = (date: string) => {
        const originalDate = new Date(date);
        const newDate = new Date(originalDate.getTime() + 3 * 60 * 60 * 1000);
    
     return newDate.toISOString();
    }

    const handleSubmit = async (
        payload: ICreateReceiptsFormData,
        options: { reset: () => void }
    ) => {
        try {
            await CreateReceiptFormValidationSchema.validate({ ...payload, number: Number(payload.number), supplier_id: selectSupplier.id, products: products}, { abortEarly: false });
            onSubmit({ ...payload, emission_date: normalizeDateCampaign(payload.emission_date) ,number: Number(payload.number), supplier_id: selectSupplier.id, products: products}, options);
            setSelectSupplier([] as any)
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            updateReceiptsFormRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form 
            onSubmit={handleSubmit} ref={updateReceiptsFormRef} id={UpdateReceiptsFormId}
            >
                <Styles.row>
                    <Styles.field>
                        <Label>Numero</Label>
                        <InputRef
                            disabled
                            name="number"
                            placeholder="Digite o numero da NF"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Data de Emissão</Label>
                        <InputRef
                            containerStyle={{ width: "33rem" }}
                            name="emission_date"
                            type="date"
                        />
                    </Styles.field>

                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Fornecedor</Label>
                        <Select
                            name="supplier_id"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione o Fornecedor"
                            options={suppliers}
                            value={selectSupplier}
                            onChange={(e: any) => setSelectSupplier(e)}
                        />
                    </Styles.field>
                <Styles.field>
                        <Label>Motivo</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="reason"
                            placeholder="Motivo da edição"
                        />
                    </Styles.field>
                    </Styles.row>
            </Form >
        </>
    )
}

export default UpdateReceiptsForm