import React from "react";
import Text from "../../components/Text";
import colors from "../../shared/utils/constants/colors";
import * as Styles from "./styles";

function Tab({ id, active, activeTab, title }: any) {
  return (
    <Styles.Tab active={active} onClick={() => activeTab(id)}>
      <Text
        text={title.toUpperCase()}
        color={colors.gray.dark02}
        fontFamily="Open Sans"
        size={12}
        weight="600"
        marginLeft={20}
        marginRight={20}
      />{" "}
    </Styles.Tab>
  );
}

export default Tab;
