import styled from 'styled-components';
import colors from '../../../shared/utils/constants/colors';

export const Container = styled.div`
  flex: 1;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #d7e5e0, #d9e6e1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
/*
export const ContainerHeader = styled.div`
  width: 100%;
  height: 80vh;
`;

*/
export const Content = styled.div`
  position: absolute;
  z-index: 2;
  background: ${colors.argon.textColorLight02};
  border-radius: 6px;
  top: 30%;
  box-shadow: 0 0 32px #8898AA26;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 33px;
  padding-right: 33px;
  width: 30%;
  height: 100vh;
`;

export const FormDiv = styled.div`
  width: 100%;
`;

export const KeepConnectedDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;
  margin-bottom: 26px;
`;

export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${colors.argon.white};
  box-shadow: 0 1px 3px ${colors.argon.textColorLight};
  cursor: pointer;
`;

export const header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const row = styled.div`
  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
    align-items: flex-end ;
    width: 100%;
    margin-left: 14px;  
    margin-bottom: 24px;
  }
`;

export const rowButton = styled.div <{ margin?: string }>`
  display: flex;
  justify-content:space-between;
  margin-top: 24px;

  @media (min-width: 650px) {
    flex-direction: row;
    align-items: flex-end ;
    width: 30%;
    margin-left:${props => `${props?.margin}%` || "70%"};
  }
`;

export const field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start ;
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
`;

export const line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.primary.light};
  margin-bottom: 24px;
  position: relative;
  top: -1px;
`;

export const WhiteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid ${colors.argon.secondaryButton};
  border-radius: 4px;

  margin-right: 16px;

  height: 47px;

  background: #FFFFFF;

  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  min-width: 120px;
  padding: 10px;

  color: ${colors.argon.secondaryButton};
  transition: background 0.2s;
  box-shadow: 0 6px 8px ${colors.argon.textColorGray};

  :hover {
    transform: scale(1.05);
    background: ${colors.primary.background};
  }
  `;

export const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: transparent;
  border-radius: 4px;

  margin-top: 2rem;

  height: 47px;

  background: ${colors.argon.secondaryButton};

  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: bold;
  min-width: 120px;
  padding: 10px;

  color: ${colors.gray.white};
  transition: background 0.2s;
  box-shadow: 0 6px 8px ${colors.argon.textColorGray};

  :hover {
    transform: scale(1.05);
    opacity: 0.8;

  }
`;

export const AdditionalsSection = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-left: 14px;  
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: 31%;
  min-width: 20vw;
  margin-right: 2%;
`;

export const GroupHeader = styled.div`
  background-color: ${colors.primary.light};
  padding: 5px 5px 5px 0;
  margin-bottom: 16px;
`;

export const GroupItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
`;

export const GroupItemCheck = styled.button<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: 1px solid ${props => props.selected ? '#5E72E4' : '#DEE2E6'};
  background-color: ${props => props.selected ? colors.primary.default : (props => props.disabled ? colors.primary.disabled : colors.gray.white)};
  border-radius: 4px;
`;

export const AdditionalsSectionGroups = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const GroupItens = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminChangeTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const AdminChangeTypeIncrease = styled.button<{ actived: boolean }>`
  width: 100%;
  background-color: ${props => props.actived ? colors.secondary.default : colors.gray.dark03};
  color: ${colors.gray.white};
  padding: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  :hover {
    background-color: ${colors.secondary.default};
  }
`;

export const AdminChangeTypeDecrease = styled.button<{ actived: boolean }>`
  width: 100%;
  background-color: ${props => !props.actived ? colors.argon.secondaryButton : colors.gray.dark03};
  color: ${colors.gray.white};
  padding: 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  :hover {
    background-color: ${colors.argon.secondaryButton};
  }
`;