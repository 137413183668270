import styled from "styled-components";

import colors from "../../shared/utils/constants/colors";

export const Label = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.argon.textColorDark};
  text-transform: uppercase;
  margin-bottom: 8px;
`;