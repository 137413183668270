import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useCallback, useRef, useState } from "react";
import { FaEnvelope, FaUnlockAlt } from "react-icons/fa";
import * as Yup from "yup";

import Button from "../../components/Button";
import InputRef from "../../components/InputRef";
import Text from "../../components/Text";
import colors from "../../shared/utils/constants/colors";
import getValidationErrors from "../../shared/utils/getValidationErrors";

import { useAuth } from "../../hooks/Auth";

import { useHistory } from "react-router-dom";
import { ReactComponent as WhiteLogo } from "../../assets/svg/generic/whitelogo.svg";
import { launchToast } from "../../shared/utils/launchToast";
import * as Styles from "./styles";

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [persistLogin, setPersistLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: SignInFormData) => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Digite um e-mail válido")
          .required("E-mail obrigatório"),
        password: Yup.string().required("Senha obrigatória"),
      });

      await schema.validate(data, { abortEarly: false });

      await signIn({ email: data.email, password: data.password });

      setLoading(false);
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);

      setLoading(false);

      launchToast("Email/senha incorretos", "error");
    }
  };

  const handlePersistLogin = useCallback(() => {
    setPersistLogin(!persistLogin);
  }, [persistLogin]);

  return (
    <>
      <Styles.Container>
        <Styles.ContainerHeader>
          <Styles.WelcomeTextDiv>
            <Text
              text="Boas vindas à"
              color={colors.gray.white}
              fontFamily="OrelegaOne-regular"
              size={24}
              weight="600"
              marginRight={4}
              marginTop={-8}
            />

            <WhiteLogo />
          </Styles.WelcomeTextDiv>
        </Styles.ContainerHeader>

        <Styles.Content>
          <Text
            text="Login"
            color={colors.argon.textColorDark}
            fontFamily="OrelegaOne-regular"
            size={32}
            weight="600"
          />

          <Text
            text="Faça o login para acessar o admin zazuu"
            color={colors.argon.textColorGray}
            fontFamily="Open Sans"
            size={20}
            marginTop={16}
          />

          <Styles.FormDiv>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <InputRef
                placeholder="Email"
                name="email"
                type="email"
                icon={FaEnvelope}
              />

              <InputRef
                placeholder="Senha"
                name="password"
                type={passwordVisibility ? "text" : "password"}
                icon={FaUnlockAlt}
                onclickIcon={() => setPasswordVisibility((old) => !old)}
              />

              <Styles.KeepConnectedDiv>
                <Styles.Checkbox type="checkbox" onClick={handlePersistLogin} />

                <Text
                  text="Manter conectado"
                  color={
                    persistLogin
                      ? colors.argon.textColorDark
                      : colors.argon.textColorGray
                  }
                  fontFamily="Open Sans"
                  size={16}
                  marginLeft={8}
                />
              </Styles.KeepConnectedDiv>

              <Button text="Entrar" type="submit" loading={loading} />
            </Form>
          </Styles.FormDiv>

          <Styles.OptionsDiv>
            <Text
              text="Esqueci minha senha"
              color={colors.argon.textColorGray}
              fontFamily="Open Sans"
              size={14}
              onTextClick={() => history.push("/recovery")}
            />
          </Styles.OptionsDiv>
        </Styles.Content>

        <Styles.ContainerFooter />
      </Styles.Container>
    </>
  );
};

export default SignIn;
