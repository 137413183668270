import React, { useEffect, useState } from "react";
import { removeCondominiumDiscount } from "../../services/condominium/graphQL";
import ICondominiumsDiscounts from "types/ICondominiumsDiscounts";
import { Box, Text, Flex, Divider, Button } from "@chakra-ui/react";
import { RiBuildingFill } from "react-icons/ri";
import { BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";

interface DiscountItemProps {
  discount: ICondominiumsDiscounts;
  setUpIsUpdateInDiscount: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DiscountItem: React.FC<DiscountItemProps> = ({
  discount,
  setUpIsUpdateInDiscount,
}) => {
  const handleDisable = async () => {
    try {
      await removeCondominiumDiscount(discount.id);
      toast.success("Desconto desativado com sucesso!");
      setUpIsUpdateInDiscount(true);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao desativar desconto!");
    } finally {
      setUpIsUpdateInDiscount(false);
    }
  };

  const handleRemove = async () => {
    // logic to remove discount
  };

  const getTypeLabel = () => {
    switch (discount.type) {
      case "CATEGORY_ID":
        return "Categoria";
      case "PACK_WEEKLY":
        return "Pacote semanal";
      case "PACK_BIWEEKLY":
        return "Pacote quinzenal";
      default:
        return "";
    }
  };

  return (
    <Box
      p={4}
      shadow="sm"
      borderWidth="1px"
      borderRadius="md"
      width="100%"
      mb={4}
      cursor="pointer"
      transition="all 0.3s"
      _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        bg="gray.100"
        p={2}
        rounded="md"
      >
        <Flex alignItems="center">
          <RiBuildingFill size={20} />
          <Text ml={2} fontWeight="bold" fontSize="2xl">
            {getTypeLabel()}
            {discount.fullType ? ` - ${discount.fullType.name}` : ""}
          </Text>
        </Flex>
        {discount.status === "ACTIVE" && (
          <Button
            colorScheme="yellow"
            onClick={() => {
              handleDisable();
            }}
          >
            Desativar
          </Button>
        )}
      </Flex>
      <Divider my={4} />
      <Flex justifyContent="space-between" alignItems="center">
        <Flex direction="column">
          <Text fontWeight="bold" fontSize="xl">
            Valor:
          </Text>
          <Text fontSize="xl">{discount.amount}%</Text>
        </Flex>
        <Divider orientation="vertical" h={10} />
        <Flex direction="column">
          <Text fontWeight="bold" fontSize="xl">
            Status:
          </Text>
          <Text fontSize="xl">{discount.status}</Text>
        </Flex>
        <Divider orientation="vertical" h={10} />
      </Flex>
    </Box>
  );
};
