import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../../UserModal/styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { gql, useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { GET_OBSERVATIONS } from '..';
import EditUserObservationForm, { editCustomerObservationFormId, IEditUserObservationPayload } from './form/EditUserObservationForm';


type IEditObservationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    observation: {
        id: string
        description: string
        added_by: string
        show_to_customer: boolean
        created_at: string
    }
};

const EDIT_USER_OBSERVATION = gql`
    mutation EditPermanentObservation($input: EditPermanentObservationInput!) {
        editPermanentObservation(input: $input) {
            petOrCustomerId
            id
            description
            added_by
            show_to_customer
            created_at
        }
    }
`;

const EditObservationModal: React.FC<IEditObservationModalProps> = ({ isOpen, onClose, observation,}) => {

    const [editPermanentObservation, { loading }] = useMutation(EDIT_USER_OBSERVATION, {
        refetchQueries: [
            GET_OBSERVATIONS,
            'GetPermanetObservations'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: IEditUserObservationPayload,
            { reset }
        ) => {
            try {
                await editPermanentObservation({
                    variables: {
                        input: payload
                    },
                });
                reset();
                onClose();
                launchToast('Observação editada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [editPermanentObservation],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text={'Editar observação'}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <EditUserObservationForm onSubmit={handleSubmit}  observation={observation}/>
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={editCustomerObservationFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default EditObservationModal