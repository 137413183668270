import React, { useEffect, useState } from "react";

import { addRegion, getRegions } from "./graphQL";

import IRegion from "../../types/IRegion";

import Loading from "../../components/Loading";
import Text from "../../components/Text";

import colors from "../../shared/utils/constants/colors";
import { launchToast } from "../../shared/utils/launchToast";
import processError from "../../shared/utils/processError";

import ModalRegionDetails from "./ModalRegionDetails";
import * as Styles from "./styles";
import { toast } from "react-toastify";
import Modal from "components/Modal";
import Input from "components/Input";
import Button from "components/Button";
import Lottie from "react-lottie";
import globeAnimation from "../../assets/globe.json";
import areaAnimation from "../../assets/area.json";

interface ISelectRegionOptionsProps {
  value: string;
  label: string;
}

interface OpenCepFormData {
  start_cep: string;
  end_cep: string;
  region_id: string;
}

const Zones: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [showAddRegion, setShowAddRegion] = useState(false);
  const [newRegionName, setNewRegionName] = useState("");
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<IRegion | undefined>();

  const loadData = async () => {
    try {
      setLoading(true);
      const regions = await getRegions();
      setRegions(regions);
      setLoading(false);
    } catch (error) {
      const { message } = processError(error, "GRAPHQL");
      launchToast(message, "error");
    }
  };

  const handleAddRegion = async () => {
    try {
      const newRegion = await addRegion(newRegionName);
      setRegions([...regions, newRegion]);
      toast.success("Adicionado com sucesso");
      setShowAddRegion(false);
      setNewRegionName("");
    } catch (error) {
      toast.error(processError(error, "GRAPHQL").message);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Loading></Loading>}
      <Styles.BackgroundPanel>
        <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Zonas"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>
        </Styles.PanelHeader>
        <Styles.Container>
          <Styles.RegionCard
            status={true}
            style={{
              background: colors.gray.white,
              color: colors.gray.dark02,
              width: "100%",
              marginBottom: 16,
            }}
            onClick={() => setShowAddRegion(true)}
          >
            <div
              style={{
                marginRight: 8,
              }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: globeAnimation,
                }}
                height={40}
                width={40}
              />
            </div>
            Adicionar nova região
          </Styles.RegionCard>
          <Styles.Content>
            <Styles.Regions>
              {regions.map((region) => (
                <Styles.RegionCard
                  key={region.id}
                  status={region.status}
                  onClick={() => setSelectedRegion(region)}
                >
                  {region.name}
                </Styles.RegionCard>
              ))}
            </Styles.Regions>
          </Styles.Content>
        </Styles.Container>
        <ModalRegionDetails
          region={selectedRegion}
          onRequestClose={() => setSelectedRegion(undefined)}
        />
        <Modal
          visible={showAddRegion}
          closeModal={() => setShowAddRegion(false)}
          width={400}
        >
          <Lottie
            speed={0.7}
            options={{
              loop: true,
              autoplay: true,
              animationData: areaAnimation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={200}
            width={200}
          />
          <Input
            name="zona nome"
            value={newRegionName}
            onChange={(e) => setNewRegionName(e.target.value)}
            containerStyle={{ marginTop: 16, width: "100%" }}
            placeholder="Nome da zona"
          />
          <Button
            text="Adicionar"
            onClick={() => handleAddRegion()}
            style={{
              width: "300px",
            }}
          />
        </Modal>
      </Styles.BackgroundPanel>
    </>
  );
};

export default Zones;
