import { gql } from "@apollo/client";

export const GET_ORDERS_ERRORS = gql`
  query ListOrdersErrors($pagination: OrdersErrorsPaginationInputType!) {
    listOrdersErrors(pagination: $pagination) {
      logsList {
        id
        type
        created_at
        customer_name
        customer_id
        error
        order_value
        dto {
          cart {
            packs {
              id
              frequency
              autoRenew
              services {
                id
                addressId
                petId
                date
                period
                additionalsId
                executionTime
              }
            }
            products {
              addressId
              productList {
                id
                quantity
              }
            }
            services {
              id
              addressId
              petId
              date
              period
              additionalsId
              executionTime
            }
          }
          paymentMethod {
            type
            cardId
          }
          platform
          wallet {
            id
            useIt
          }
          donated
          adminChange {
            type
            amount
          }
          canCreateOnSunday
        }
      }
      totalPages
    }
  }
`

export const GET_ORDER_ERROR_DETAILS = gql`
  query ListOrderErrorDetails($orderError: ICreateOrderDtoInputType!) {
    listOrderErrorDetails(orderError: $orderError) {
      cart {
        packs {
          id
          frequency
          autoRenew
          services {
            id
            addressId
            petId
            date
            period
            additionalsId
            executionTime
          }
        }
        products {
          addressId
          productList {
            id
            quantity
          }
        }
        services {
          id
          addressId
          petId
          date
          period
          additionalsId
          executionTime
        }
      }
      paymentMethod {
        type
        cardId
      }
      platform
      wallet {
        id
        useIt
      }
      donated
      adminChange {
        type
        amount
      }
      canCreateOnSunday
    }
  }
`;
