export type IEvent = {
  id: number;
  address: {
    id: string;
    cep: string;
    city: string;
    state: string;
    street: string;
    number: string;
    neighborhood: string;
    complement: string;
    surname: string;
  };
  cupon: {
    id: string;
    name: string;
    code: string;
    status: boolean;
  };
  name: string;
  event_start_date: string;
  event_end_date: string;
  title: string;
  subtitle: string;
  content: string;
  image_url: string;
  is_active: boolean;
}

export type ICreateEventFormData = {
  name: string;
  title: string;
  subtitle: string;
  content: string;
  image_url: string;
  coupon_id: string;
  event_start_date: string;
  event_end_date: string;
  cep: string;
  city: string;
  state: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
  surname: string;
};

export type IUpdateEventFormData = {
  name: string;
  title: string;
  subtitle: string;
  content: string;
  image_url: string;
  coupon_id: string;
  event_start_date: string;
  event_end_date: string;
  is_active: string;
  cep: string;
  city: string;
  state: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
  surname: string;
};

export type IDateFilterFormData = {
  filter: string;
  date: string;
};

export type IFilterFormData = {
  title: string;
  subtitle: string;
  name: string;
  content: string;
  is_active: string;
  start_filter: string;
  end_filter: string;
  event_start_date: string;
  event_end_date: string;
}

export type EventFilterPortugueseKey = keyof typeof EventFilterEnglishTranslationEnum;

export enum EventFilterPortugueseTranslationEnum {
  title = 'Título',
  subtitle = 'Subtítulo',
  name = 'Nome',
  content = 'Conteúdo',
  is_active = 'Ativo',
  event_start_date = 'Data de início',
  event_end_date = 'Data de término',
}

export enum EventFilterEnglishTranslationEnum {
  Título = 'title',
  Subtítulo = 'subtitle',
  Nome = 'name',
  Conteúdo = 'content',
  Ativo = 'is_active',
  'Data de início' = 'event_start_date',
  'Data de término' = 'event_end_date',
}

export type APIFilters = {
  title?: string;
  subtitle?: string;
  name?: string;
  content?: string;
  is_active: string;
  event_start_date?: IDateFilterFormData;
  event_end_date?: IDateFilterFormData;
}

export type IUpdateEventVariables = {
  input: {
    name: string;
    title: string;
    subtitle: string;
    content: string;
    coupon_id: string;
    event_start_date: string;
    event_end_date: string;
    is_active: boolean;
    addresses: {
      id: string;
      cep: string;
      city: string;
      state: string;
      street: string;
      number: string;
      neighborhood: string;
      complement: string;
      surname: string;
    },
  },
  file?: string,
  updateEventId: number,
}
