import { gql } from "@apollo/client";
import client from "./api-graphql";

export const getPetlastAppointments = async (filters?: any): Promise<any[]> => {
  const response = await client.query({
    query: gql`
    query LastAppointmentsFromPet($filters: FiltersAppointmentInputType) {
      lastAppointmentsFromPet(filters: $filters) {
          totalPages
          appointments {
            id
            status
            pet {
              name
              breed {
                name
              }
            }
            date
            customer {
              user {
                name
                phone
              }
            }
            service {
              name
            }
            address {
              street
              neighborhood
              number
              city
              state
              cep
              complement
            }
          }
        }
      }
    `,
    variables: {
      filters,
    },
    
  });

  return response.data.lastAppointmentsFromPet;
};
