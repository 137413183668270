import { useQuery } from "@apollo/client";
import { useDisclosure } from "@chakra-ui/react";
import React, {useCallback, useEffect, useState } from "react"
import { LIST_PRODUCTS } from "./graphql/StockQuery";
import RegisterStockModal from "./modals/RegisterStockModal";
import MainContainer from "components/MainContainer";
import { PanelHeader } from "components/PanelHeader";
import Search from "components/Search";
import Pagination from "components/Pagination";
import Table from "components/Table";
import { columnProductsHeaders } from "./constants/conlumns";
import { format, parseISO } from "date-fns";
import { APIFiltersStock, IFilterStockFormData, IProduct } from "./constants/types";
import { launchToast } from "shared/utils/launchToast";
import FilterList from "components/FilterList";
import FiltersStockModal from "./modals/FilterStockModal";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import UpdateProductModal from "./modals/updateProductModal";

const Stock: React.FC = () => {

    const [paginationLimit, setPaginationLimit] = useState<number>(10);
    const [searchInput, setSearchInput] = useState<string>("");
    const [productSelected, setProductSelected] = useState<IProduct | null>(null);
    const [filters, setFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    // const [idToToggleCampaign, setIdToToggleCampaign] = useState<number>(1)

    const createProductModalControl = useDisclosure();
    const filterModalControl = useDisclosure();
    const updateProductModalControl = useDisclosure();
    // const toggleCampaignModalControl = useDisclosure();
    // const reportCampaignModalControl = useDisclosure();

    const handleWordSearch = (campaign: string) => {
        setCurrentPage(1)
        setSearchInput(campaign)
    };

    // const normalizeLabel = (roleLabel: string) => {
    //     const normalizationMap: Record<string, string> = {
    //         "DONATE": "Doação",
    //     };
    //     return normalizationMap[roleLabel] || roleLabel;
    // };

    const handleProductUpdate = useCallback(
        async (product: IProduct) => {
            setProductSelected(product);
            updateProductModalControl.onOpen();
        },
        [updateProductModalControl]
    )

    // const handleCampaignReport = useCallback(
    //     async (campaign: ICampaign) => {
    //         setCampaignSelected(campaign);
    //         reportCampaignModalControl.onOpen();
    //     },
    //     [reportCampaignModalControl]
    // )

    // const [toggleCampaign, { loading: toggleCampaingLoad }] = useMutation(TOGGLE_CAMPAIGN, {
    //     refetchQueries: [
    //         LIST_CAMPAIGNS,
    //         'ListCampaign',
    //     ],
    // });

    // const handleCampaignToggle = useCallback(
    //     async () => {
    //         try {
    //             const variables: IToggleCampaign = {
    //                 id: idToToggleCampaign,
    //             }
    //             await toggleCampaign({
    //                 variables,
    //             });
    //             launchToast('Status alterado com sucesso!', "success");
    //         } catch (error: any) {
    //             launchToast(error.message, "error");
    //         }
    //     }, [toggleCampaign, idToToggleCampaign]
    // );

    // const handleToggleConfirmation = (campaignId: number) => {
    //     setIdToToggleCampaign(campaignId);
    //     toggleCampaignModalControl.onOpen();
    // }


    const { data, refetch } = useQuery(LIST_PRODUCTS, {
      variables: {
        filters: {
          name: searchInput,
          pagination: {
            limit: paginationLimit,
             page: currentPage,
          },   
        },
      },
    });
    
    useEffect(() => {
        try {
            const filterParams = filters.reduce((acc, filter: { key: string, value: string }) => {
                acc[filter.key as keyof IFilterStockFormData] = filter.value;
                return acc;
            }, {} as IFilterStockFormData);

            refetch({
                filters: {
                    name: "",
                    ...filterParams,
                    pagination: {
                        limit: paginationLimit,
                        page: currentPage,
                    },   
                },
            });
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    }, [paginationLimit, currentPage])

    const handleAddFilter = (params: any) => {
        setCurrentPage(1);
        refetch(params);
    };

    const handleRemoveFilter = (key: string) => {
        try {
            const newFilters = filters.filter((filter: any) => filter.key !== key);
            setFilters(newFilters);
            const filterParams = newFilters.reduce((acc, filter: { key: string, value: string }) => {
                const normalizeKey = filter.key
                acc[normalizeKey as keyof APIFiltersStock] = filter.value as any;
                return acc;
            }, {} as APIFiltersStock);


            setCurrentPage(1);

            refetch({
                filters: {
                    name: "",
                    ...filterParams,
                    pagination: {
                        limit: paginationLimit,
                        page: currentPage,
                    },   
                },
            });
        } catch (error: any) {
            launchToast(error.message, "error");
        }
    };

    return (
        <>
            <RegisterStockModal
                isOpen={createProductModalControl.isOpen}
                onClose={createProductModalControl.onClose}
            />
            {productSelected && (
                <UpdateProductModal
                    isOpen={updateProductModalControl.isOpen}
                    onClose={updateProductModalControl.onClose}
                    product={productSelected}
                />
            )}
            {/* {campaingSelected && (
                <ReportCampaignModal
                    isOpen={reportCampaignModalControl.isOpen}
                    onClose={reportCampaignModalControl.onClose}
                    campaign={campaingSelected}
                />
            )} */}
            <FiltersStockModal
                isOpen={filterModalControl.isOpen}
                onClose={filterModalControl.onClose}
                handleAddFilter={handleAddFilter}
                onFilterSubmit={setFilters}
            />
            {/* <ModalConfirmation
                isOpen={toggleCampaignModalControl.isOpen}
                onClose={toggleCampaignModalControl.onClose}
                nameAction="ativar/desativar essa campanha"
                handleConfirm={handleCampaignToggle}
                loading={toggleCampaingLoad}
            /> */}
            <MainContainer>
                <PanelHeader
                    title="Produtos"
                    isFilterModalVisible
                    isNew
                    onClick={filterModalControl.onOpen}
                    onClickNew={createProductModalControl.onOpen}
                />
                <Search
                    wordSearch={searchInput}
                    setSearch={handleWordSearch}
                    isFilterForRegister
                    limit={paginationLimit}
                    onSetPageSize={(limit) => {
                        setCurrentPage(1)
                        setPaginationLimit(limit)
                    }}
                    placeholder="Pesquise pelo nome"
                />
                {
                    filters.length
                        ? (
                            <FilterList filters={filters} removeFilter={handleRemoveFilter} />
                        )
                        : ''
                }
                {
                    data && (
                        <Table
                            headers={columnProductsHeaders}
                            data={data.products.products.map((product: IProduct) => ({
                                id: product.id,
                                manufacturer_sku_code: product.manufacturer_sku_code,
                                name: product.name,
                                stock_unit: product.stock_unit,
                                quantity: product.quantity,
                                cost_price: product.cost_price,
                                selling_price: product.selling_price,
                                comission_percentage: product.comission_percentage,
                                maximum_stock: product.maximum_stock,
                                minimum_stock: product.minimum_stock,
                                Brand: product.brand.name,
                                Category: product.category.name,
                                packaging: product.packaging.name,
                                description: product.description,
                                created_at: format(parseISO(product.created_at), "dd/MM/yyyy"),
                                updated_at: format(parseISO(product.updated_at), "dd/MM/yyyy"),
                                actions: [
                                    // {
                                    //     name: "Relatoirio",
                                    //     icon: <ReportButton />,
                                    //     action: () => handleCampaignReport(campaign),
                                    // },
                                    {
                                        name: "Editar",
                                        icon: <EditButton />,
                                        action: () => handleProductUpdate(product),
                                    },
                                    // {
                                    //     name: "Desativar",
                                    //     icon: <CancelButton/>,
                                    //     action: handleToggleConfirmation,
                                    // },
                                ],
                            }))}
                        />
                    )
                }
                {
                    data && (
                        <Pagination
                            totalPage={data.products.totalPages}
                            pageIndex={currentPage}
                            setPage={setCurrentPage}
                        />
                    )
                }
            </MainContainer>
        </>
    )
}

export default Stock