import React, { useEffect, useRef, useState } from "react";

import { Form } from "@unform/web";
import Button from "../../../../components/Button";

import * as Styles from "./styles";
import { FormHandles } from "@unform/core";

import { updateCustommer } from "../../../../services/user/UserService";
import { launchToast } from "../../../../shared/utils/launchToast";
import getValidationErrors from "../../../../shared/utils/getValidationErrors";
import { Label } from "../../../../components/Label/styles";
import Select from "../../../../components/Select";
import MeetUs from "../../../../shared/utils/enums/MeetUsEnum";

interface UserFormData {
  meetUs: string;
}
const CustommerModal = ({ userId, onDiscard, customer }: any) => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  // const [id, setId] = useState("");
  const [meetUsOptions, setMeetUsOptions] = useState([] as any);
  const [error, setError] = useState("");
  const [meetUs, setMeetUs] = useState([] as any);
  const [customerRecivied, setCustomerRecivied] = useState({} as any);

  useEffect(() => {
    setMeetUs([]);
    if (!!customer) {
      setCustomerRecivied(customer);
    }
  }, [customer]);

  useEffect(() => {
    if (!!customerRecivied.id) {
      let selectedMeet = meetUsOptions.find(
        (m: any) => m.value === customerRecivied.meet_us.toString()
      );
      setMeetUs(selectedMeet);

    }
  }, [customerRecivied, meetUsOptions]);

  useEffect(() => {
    let MeetUsSelect = [];
    for (let k in MeetUs) {
      if (typeof MeetUs[k] === "number") break;
      MeetUsSelect.push({ value: k, label: MeetUs[k] });
    }
    setMeetUsOptions(MeetUsSelect);
  }, []);

  const handleSubmit = async (data: UserFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      data.meetUs === "" ? setError("Informe uma opção") : setError("");
      try {
        if (error === "") {
          await updateCustommer({ userId, ...data });
          setLoading(false);
          launchToast("Cliente salvo com sucesso", "success");
          return false;
        }
      } catch (error: any) {
        launchToast(error.message, "error");
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);
      launchToast("Verifique o preenchimento dos dados", "error");
      return false;
    }
    setLoading(false);
    return false;
  };

  const handleDiscard = (e: any) => {
    formRef.current?.reset();
    formRef.current?.setErrors({});
    setError("");
    onDiscard();
  };

  return (
    <>
      <Styles.FormDiv>
        <Form onSubmit={handleSubmit}>
          <div style={{ height: "80vh" }}>
            <Styles.row>
              <Styles.field>
                <Label>como conheceu a zazuu?</Label>
                <Select
                  name="meetUs"
                  containerStyle={{ width: "33rem" }}
                  placeholder="Como nos conheceu"
                  options={meetUsOptions}
                  value={meetUs}
                  onChange={(e: any) => setMeetUs(e)}
                  error={error}
                />
              </Styles.field>
            </Styles.row>
          </div>
          <Styles.rowButton>
            <Styles.WhiteButton
              type="button"
              onClick={handleDiscard}
            >
              Descartar
            </Styles.WhiteButton>
            <Button
              text="Salvar"
              type="submit"
              loading={loading}
              styleContainer={{ minWith: "100px" }}
            />
          </Styles.rowButton>
        </Form>
      </Styles.FormDiv>
    </>
  );
};
export default CustommerModal;
