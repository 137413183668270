import styled from 'styled-components';

import colors from '../../shared/utils/constants/colors';

export const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  button {
    padding: 12px 24px;
    background: ${colors.argon.default};
    box-shadow: 0 4px 6px ${colors.argon.textColorGray};
    color: ${colors.argon.white};
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    min-width: 120px;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 8px ${colors.argon.textColorGray};
      background: #669d8c;
    }
  }
`;
