import listSizesPets from "./constants/listSizesPets";

export const calculateSizeByWeigthPet = (
  specie: string,
  weight: number
): string | undefined => {
  const specieFormatted = specie.toLocaleLowerCase() as "cachorro" | "gato";

  const results = listSizesPets[specieFormatted].weights.find(
    (weigthItem) => weigthItem.min <= weight && weigthItem.max >= weight
  );

  if (!results) {
    return undefined;
  }

  return calculateSizePet(results.size);
};

const calculateSizePet = (size: number): string => {
  const sizes = [
    { label: "PP", size: 1 },
    { label: "P", size: 2 },
    { label: "M", size: 3 },
    { label: "G", size: 4 },
    { label: "GG", size: 5 },
    { label: "XGG", size: 6 },
  ];

  return sizes.find((si) => si.size === size)?.label.trim() || "";
};
