export type IAnamnesis = {
    id: string;
    pet_id: string;
    professional_id: string
    avaliation: string
    castrated: boolean
    allergy: boolean
    allergy_detail: string
    feed: string
    feed_diary: string
    skin_hygiene: string
    skin_secretion: string
    skin_peeling: string
    eyes_red: boolean
    eyes_whitish: boolean
    eyes_secretion: boolean
    eyes_visual_deficit: boolean
    respiration_cough: boolean
    respiration_sneeze: boolean
    respiration_secretion: boolean
    respiration_noise: boolean
    heart_exercise_intolerance: boolean
    heart_tiredness: boolean
    digestive_appetite: string
    digestive_vomit: boolean
    digestive_diarrhea: boolean
    urinary_water: string
    urinary_aspect: string
    urinary_volume: string
    urinary_frequency: string
    reproductive_secretion: boolean
    reproductive_heat: boolean
    reproductive_contraceptive: boolean
    reproductive_puppies: string
    locomotor_lameness: boolean
    locomotor_trauma: boolean
    locomotor_volume: boolean
    locomotor_impotence: boolean
    neurologic_convulsion: boolean
    neurologic_syncope: boolean
    neurologic_deficit: boolean
    updated_at: string
    created_at: string
    status: number
    temperament: string
    comments: string
    appointment_id: string
    feed_type: string
    feed_prescribed_by_nutritionist: boolean
    feed_prescribed_by_nutritionist_detail: string
    skin_itching: string
    ears_canals: string
    respiration_cough_detail: string
    respiration_sneeze_detail: string
    respiration_secretion_detail: string
    syncope_details: string
    heart_cyanosis: boolean
    heart_cyanosis_detail: string
    digestive_depraved_appetite: boolean
    digestive_depraved_appetite_detail: string
    digestive_vomit_detail: string
    digestive_diarrhea_detail: string
    locomotor_trauma_detail: string
    locomotor_volume_detail: string
    locomotor_impotence_detail: string
    breath_rate: string
    heart_rate: string
    ecc_condition_score: string
    muscle_mass_score: string
    hydration: string
    temperature: string
    lymph_nodes: string
    dentition: string
    customer_orientations: string
    pet: {
      id: string
      name: string
    }
    tutor: {
      name: string
      id: string
    }
    professional: {
      id: string
      user: {
        name: string
      }
    }
    appointment: {
      professional_id: string
      customer_id: string
      date: string
    }
}

export type ICustomerToAnamneses = {
    name: string
    id: string
    customer: {
        pets: {
            name: string
            id: string
        }
    }
}

export type IProfessionalToAnamneses = {
  id: string
  user: {
    name: string
  }
}

export type IPetToAnamneses = {
    name: string
    id: string
}

export type ICreateAnamnesisFormData = {
    pet_id:string;
    avaliation:null | string
    castrated:null | boolean
    allergy:null | boolean
    allergy_detail:null | string
    feed:null | string
    feed_diary:null | string
    skin_hygiene:null | string
    skin_secretion:null | string
    skin_peeling:null | string
    eyes_red:null | boolean
    eyes_whitish:null | boolean
    eyes_secretion:null | boolean
    eyes_visual_deficit:null | boolean
    respiration_cough:null | boolean
    respiration_sneeze:null | boolean
    respiration_secretion:null | boolean
    respiration_noise:null | boolean
    heart_exercise_intolerance:null | boolean
    heart_tiredness:null | boolean
    digestive_appetite:null | string
    digestive_vomit:null | boolean
    digestive_diarrhea:null | boolean
    urinary_water:null | string
    urinary_aspect:null | string
    urinary_volume:null | string
    urinary_frequency:null | string
    reproductive_secretion:null | boolean
    reproductive_heat:null | boolean
    reproductive_contraceptive:null | boolean
    reproductive_puppies:null | string
    locomotor_lameness:null | boolean
    locomotor_trauma:null | boolean
    locomotor_volume:null | boolean
    locomotor_impotence:null | boolean
    neurologic_convulsion:null | boolean
    neurologic_syncope:null | boolean
    neurologic_deficit:null | boolean
    temperament:null | string
    comments:null | string
    appointment_id: string
    feed_type:null | string
    feed_prescribed_by_nutritionist:null | boolean
    feed_prescribed_by_nutritionist_detail:null | string
    skin_itching:null | string
    ears_canals:null | string
    respiration_cough_detail:null | string
    respiration_sneeze_detail:null | string
    respiration_secretion_detail:null | string
    syncope_details:null | string
    heart_cyanosis:null | boolean
    heart_cyanosis_detail:null | string
    digestive_depraved_appetite:null | boolean
    digestive_depraved_appetite_detail:null | string
    digestive_vomit_detail:null | string
    digestive_diarrhea_detail:null | string
    locomotor_trauma_detail:null | string
    locomotor_volume_detail:null | string
    locomotor_impotence_detail:null | string
    breath_rate:null | string
    heart_rate:null | string
    ecc_condition_score:null | string
    muscle_mass_score:null | string
    hydration:null | string
    temperature:null | string
    lymph_nodes:null | string
    dentition:null | string
    customer_orientations:null | string
}

export type IAppoitnmentToAnamnesis = {
    id: string
    date: string
    service: {
      name: string
      category: {
        id: string
      }
    }
    professional: {
      user: {
        name: string
      }
    }
}

export type IFilterAnamnesisFormData = {
  customer_id?: {
    label: string,
    value: string
  };
  professional_id?: {
    label: string,
    value: string
  };
  [key: string]: any
}

export type APIFiltersAnamnesis = {
  customer_id?: string;
  professional_id?: string;
}

export enum AnamnesisFilterTranslateEnum {
  professional_id = 'Profissional',
  customer_id = 'Tutor',
}