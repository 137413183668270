import { gql } from "@apollo/client";
import client from "services/api-graphql";
import IService from "types/IService";

const getServiceDetails = async ({ region_id, service_id }: { service_id: string; region_id: string; }): Promise<IService> => {
  const response = await client.query({
    query: gql`
      query($serviceId: String!, $region_id: String) {
        service(id: $serviceId) {
          id
          name
          prices(region_id: $region_id) {
            id
            region_id
            service_id
            specie_id
            size
            price
            execution_time
          }
          additionals {
            id
            name
            prices(region_id: $region_id) {
              id
              region_id
              item_id
              specie_id
              size
              price
              execution_time
            }
          }
        }
      }
    `,
    variables: {
      serviceId: service_id,
      regionId: region_id
    }
  });

  return response.data.service;
};

type ICreateOrUpdatePricesByRegionProps = {
  region_id: string;
  service_prices: {
    service_id: string;
    specie_id: string;
    size?: string;
    price: number;
  }[];
  additionals_prices: {
    item_id: string;
    specie_id: string;
    size?: string;
    price: number;
  }[];
}

const createOrUpdatePricesByRegion = async ({ region_id, service_prices, additionals_prices }: ICreateOrUpdatePricesByRegionProps): Promise<IService> => {
  const response = await client.mutate({
    mutation: gql`
      mutation($additionalsPrices: [CreateOrUpdateAdditionalPricesInputType!]!, $servicePrices: [CreateOrUpdateServicePricesInputType!]!, $regionId: String!) {
        createOrUpdatePricesByRegion(additionals_prices: $additionalsPrices, service_prices: $servicePrices, region_id: $regionId)
      }
    `,
    variables: {
      regionId: region_id,
      servicePrices: service_prices,
      additionalsPrices: additionals_prices
    }
  });

  return response.data.createOrUpdatePricesByRegion;
};

export { getServiceDetails, createOrUpdatePricesByRegion };

