import { gql } from "@apollo/client";
import client from "services/api-graphql";

export const CREATE_PRODUCT = gql`
      mutation CreateProduct($input: CreateProductInputType!) {
  createProduct(input: $input) {
    id
    description
    class
    stock_unit
    manufacturer_sku_code
    minimum_stock
    maximum_stock
    quantity
    comission_percentage
    cost_price
    selling_price
    profit_margin
    brand_id
    packaging_id
    product_category_id
    status
    name
    created_at
    updated_at
  }
}
`;


export const LIST_PRODUCTS = gql`
  query Products($filters: ListProductsInputType) {
    products(filters: $filters) {
      products {
        id
        manufacturer_sku_code
        name
        stock_unit
        quantity 
        class
        cost_price
        selling_price
        comission_percentage
        maximum_stock
        minimum_stock
        brand {
          name
        }
        category {
          name
        }
        packaging {
          name
        }
        description
        created_at
        updated_at
      }
      totalPages
    }
  }
`;

export const CREATE_SUPPLIER = gql`
  mutation CreateSupplier($input: CreateSupplierInputType!) {
    createSupplier(input: $input) {
      id
      name
      cnpj
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation CreateBrand($input: FindOrCreateBrandInputType!) {
    createBrand(input: $input) {
      name
      id
    }
  }
`;

export const CREATE_PRODUCT_CATEGORY = gql`
  mutation CreateProductCategory($input: FindOrCreateProductCategoryInputType!) {
    createProductCategory(input: $input) {
      id
      name  
  }
}
`;

export const CREATE_PACKAGING = gql`
  mutation CreatePackaging($input: FindOrCreatePackagingInputType!) {
    createPackaging(input: $input) {
      name
      id
    }
  }
`;

export const listOptionBrands = async (input: string) => {
  const variables = {
    filter: {
      name: input,
      pagination: {
        limit: 6,
        page: 1
      }
    }
  }; 
  const { data } = await client.query({
      query: gql`
        query Brands($filter: ListBrandsInputType) {
          brands(filter: $filter) {
            name
            id
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.brands
};

export const listOptionCategories = async (input: string) => {
  const variables = {
    filter: {
      name: input,
      pagination: {
        limit: 6,
        page: 1
      }
    }
  }; 
  const { data } = await client.query({
      query: gql`
        query ProductCategories($filters: ListProductCategoriesInputType) {
          productCategories(filters: $filters) {
            id
            name
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.productCategories
};

export const listOptionPackagings = async (input: string) => {
  const variables = {
    filter: {
      name: input,
      pagination: {
        limit: 6,
        page: 1
      }
    }
  }; 
  const { data } = await client.query({
      query: gql`
        query Packagings($filter: ListPackagingInputType) {
          packagings(filter: $filter) {
            id
            name
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.packagings
};

export const listOptionSupplier = async (input: string) => {
  const variables = {
    filter: {
      name: input,
      pagination: {
        limit: 6,
        page: 1
      }
    }
  }; 
  const { data } = await client.query({
      query: gql`
        query Suppliers($filter: ListSuppliersInputType) {
          suppliers(filter: $filter) {
            name
            id
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.suppliers
};

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($input: UpdateProductInputType!) {
  updateProduct(input: $input) {
    id
    description
    class
    stock_unit
    manufacturer_sku_code
    minimum_stock
    maximum_stock
    quantity 
    comission_percentage
    cost_price
    selling_price
    profit_margin
    brand_id
    packaging_id
    product_category_id
    status
    name
    created_at
    updated_at
    category {
      id
      name
    }
    packaging {
      id
      name
    }
    brand {
      id
      name
    }
  }
 }
`
