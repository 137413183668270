import React, { useRef, useState } from "react";

import Text from "../../components/Text";
import { Form } from "@unform/web";
import InputRef from "../../components/InputRef";
import Button from "../../components/Button";
import colors from "../../shared/utils/constants/colors";

import * as Styles from "./styles";
import { FaUnlockAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { useAuth } from "../../hooks/Auth";
import getValidationErrors from "../../shared/utils/getValidationErrors";
import { launchToast } from "../../shared/utils/launchToast";
import { ReactComponent as WhiteLogo } from "../../assets/svg/generic/whitelogo.svg";

interface ChancePasswordFormData {
  code: string;
  password: string;
  passwordConfirmation: string;
}
const ChangePassword: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { passwordReset } = useAuth();

  const [loading, setLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const handleSubmit = async (data: ChancePasswordFormData) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        code: Yup.string().required("Código é obrigatório"),
        password: Yup.string().required("Senha é obrigatória"),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref("password")], "Senha não confere")
          .required("Senha não confere"),
      });
      await schema.validate(data, { abortEarly: false });

      try {
        setLoading(true);

        await passwordReset(data.password, data.code);

        setLoading(false);
        launchToast("Senha redefinida com sucesso", "success");
      } catch (error) {
        setLoading(false);
        launchToast("Verifique o preenchimento dos dados", "error");
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);
    }
  };

  return (
    <>
      <Styles.Container>
        <Styles.ContainerHeader>
          <Styles.WelcomeTextDiv>
            <Text
              text="Boas vindas à"
              color={colors.gray.white}
              fontFamily="OrelegaOne-regular"
              size={24}
              weight="600"
              marginRight={4}
              marginTop={-8}
            />

            <WhiteLogo />
          </Styles.WelcomeTextDiv>
        </Styles.ContainerHeader>

        <Styles.Content>
          <Text
            text="Recuperar Senha"
            color={colors.argon.textColorDark}
            fontFamily="OrelegaOne-regular"
            size={32}
            weight="600"
          />
          <Styles.FormDiv>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <InputRef
                placeholder="Digite o código recebido"
                name="code"
                type="text"
                icon={FaUnlockAlt}
              />
              <InputRef
                placeholder="Digite nova senha"
                name="password"
                type={passwordVisibility ? "text" : "password"}
                icon={FaUnlockAlt}
                onclickIcon={() => setPasswordVisibility((old) => !old)}
              />

              <InputRef
                placeholder="Digite novamente a senha"
                name="passwordConfirmation"
                type={passwordVisibility ? "text" : "password"}
                icon={FaUnlockAlt}
                onclickIcon={() => setPasswordVisibility((old) => !old)}
              />

              <Button text="Recuperar Senha" type="submit" loading={loading} />
            </Form>
          </Styles.FormDiv>
          <Styles.OptionsDiv>
            <Text
              text="&#x2190; Voltar"
              color={colors.argon.textColorGray}
              fontFamily="Open Sans"
              size={14}
              onTextClick={() => history.push("/")}
            />
          </Styles.OptionsDiv>
        </Styles.Content>
        <Styles.ContainerFooter />
      </Styles.Container>
    </>
  );
};
export default ChangePassword;
