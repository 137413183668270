import { Stack } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { Form } from "@unform/web";
import cepPromise from "cep-promise";
import ImageInput from 'components/ImageInput';
import InputRef from "components/InputRef";
import { Label } from 'components/Label/styles';
import Select from 'components/Select';
import Switch from 'components/Switch';
import Text from 'components/Text';
import TextArea from 'components/TextArea';
import { ICouponResponse } from 'pages/Coupon/types';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { searchCoupons } from 'services/coupon/couponService';
import colors from 'shared/utils/constants/colors';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { launchToast } from 'shared/utils/launchToast';
import masks from 'shared/utils/masks';
import { ValidationError } from 'yup';
import PreviewEventCard, { PreviewEventCardProps } from '../../PreviewEventCard';
import * as Styles from '../../constants/styles';
import { IEvent, IUpdateEventFormData } from '../../constants/types';
import { CreateEventFormValidationSchema } from '../CreateEvent/CreateEventFormValidation';
import { useLazyQuery } from '@apollo/client';
import { LIST_EVENTS } from 'pages/Events/graphql/EventQuery';

export type IUpdateEventFormProps = {
  onSubmit: (payload: IUpdateEventFormData, options: { reset: () => void }) => Promise<void>;
  event: IEvent
}

export const UpdateEventFormId = 'update-event-form';

function formatDateTime(dateTimeString: string): string {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

function UpdateEventForm({
  onSubmit,
  event
}: IUpdateEventFormProps) {
  const updateEventFormRef = useRef<FormHandles>(null);

  const [searchInput, setSearchInput] = useState<string>("");
  const [couponsList, setCouponsList] = useState<ICouponResponse[] | []>([]);
  const [previewInfo, setPreviewInfo] = useState<PreviewEventCardProps>();
  const [isValidStartDate, setIsValidStartDate] = useState<boolean>(true);
  const [isValidEndDate, setIsValidEndDate] = useState<boolean>(true);

  const [validateEventDate] = useLazyQuery(LIST_EVENTS);

  const handleCepFind = async () => {
    try {
      const address = await cepPromise(updateEventFormRef.current?.getFieldValue("cep"));
  
      updateEventFormRef.current?.setFieldValue('city', address.city);
      updateEventFormRef.current?.setFieldValue('state', address.state);
      updateEventFormRef.current?.setFieldValue('street', address.street);
      updateEventFormRef.current?.setFieldValue('neighborhood', address.neighborhood);
    } catch (error) {
      console.log(error);
      launchToast("Houve erro na solicitação do cep", "error");
    }
  };

  useEffect(() => {
    updateEventFormRef.current?.setErrors({});
    updateEventFormRef.current?.setData({
      name: event.name,
      title: event.title,
      subtitle: event.subtitle,
      coupon_id: {
        label: `${event.cupon.name} - ${event.cupon.code}`,
        value: event.cupon.id,
      },
      is_active: event.is_active ? 'Sim' : 'Não',
      content: event.content,
      event_start_date: formatDateTime(event.event_start_date),
      event_end_date: formatDateTime(event.event_end_date),
      cep: event.address.cep,
      city: event.address.city,
      state: event.address.state,
      street: event.address.street,
      number: event.address.number,
      neighborhood: event.address.neighborhood,
      complement: event.address.complement,
      surname: event.address.surname,
    });

    setPreviewInfo({
      content: event.content,
      title: event.title,
      subtitle: event.subtitle,
      image: event.image_url,
    });
  }, [event]);
  
  useEffect(() => {
    const fetchCoupons = async () => {
      const response = await searchCoupons({
        where: {
          wordSearch: searchInput,
          status: true,
        },
        pagination: {
          limit: 20,
          page: 1,
        },
      }) as any;
      const filteredCoupons = response.data.coupons.coupons as ICouponResponse[];
      setCouponsList(filteredCoupons);
    };

    fetchCoupons();
  }, [searchInput]);

  const applyMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, mask?: string) => {
      updateEventFormRef.current?.setFieldValue(
        e.target.name,
        mask ? masks[mask](e.target.value) : e.target.value
      );
    },
    []
  );

  const handleImageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      setPreviewInfo((previewValue) => ({
        ...previewValue,
        image: '',
      }));
    } else {
      const previewURL = URL.createObjectURL(file);
  
      setPreviewInfo((previewValue) => ({
        ...previewValue,
        image: previewURL,
      }));
    }
  }, []);

  const validateFields = async (
    payload: IUpdateEventFormData,
    options: { reset: () => void }
  ) => {
      try {
        await CreateEventFormValidationSchema.validate(payload, { abortEarly: false });
        onSubmit(payload, options);
      } catch (error) {
        const errors = getValidationErrors(error as ValidationError);
        updateEventFormRef.current?.setErrors(errors);
        launchToast("Verifique o preenchimento dos dados", "error");
      }
    };

  useEffect(() => {
    if (!isValidStartDate) {
      updateEventFormRef.current?.setFieldError(
        'event_start_date',
        'Já existe um evento nessa data'
      );
    } else {
      updateEventFormRef.current?.setFieldError(
        'event_start_date',
        ''
      );
    }

    if (!isValidEndDate) {
      updateEventFormRef.current?.setFieldError(
        'event_end_date',
        'Já existe um evento nessa data'
      );
    } else {
      updateEventFormRef.current?.setFieldError(
        'event_end_date',
        ''
      );
    }
  }, [isValidStartDate, isValidEndDate]);

  const handleValidateDate = (e: FocusEvent<HTMLInputElement>) => {
    try {
      validateEventDate({
        variables: {
          filterParams: {
            event_start_date: {
              filter: "lte",
              date: e.target.value
            },
            event_end_date: {
              filter: "gte",
              date: e.target.value
            },
          },
          paginationParams: {
            limit: 1,
            page: 1,
          },
          orderParams: { },
        },
        onCompleted(data) {
          if (event.id !== data.listEvents.data[0].id) {
            if (e.target.name === 'event_start_date') {
              setIsValidStartDate(!data.listEvents.data.length);
            } else if (e.target.name === 'event_end_date') {
              setIsValidEndDate(!data.listEvents.data.length);
            }
          }
        },
      });
    } catch (error) {
      console.error(error);
      launchToast(error as string, 'error');
    }
  };

  return (
    <Form onSubmit={validateFields} ref={updateEventFormRef} id={UpdateEventFormId}>
      <Styles.row>
        <Styles.field>
          <Label>cep
            <InputRef
              placeholder="00000-000"
              name="cep"
              type="text"
              onBlur={handleCepFind}
              size={12}
              onChange={(e) => applyMask(e, "cep")}
          />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>endereço
            <InputRef
              placeholder="Digite o endereço"
              name="street"
              type="text"  
              size={32}
          />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>número
            <InputRef
              placeholder="ex: 100"
              name="number"
              type="text"
              maxLength={10}
              width="10px"
              size={8}
          />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>complemento
            <InputRef
              placeholder="Ex: AP 201, Casa 10"
              name="complement"
              type="text"
              size={20}
            />
          </Label>
        </Styles.field>
      </Styles.row>
      <Styles.row>
        <Styles.field>
          <Label>cidade
            <InputRef
              placeholder="Digite a cidade"
              name="city"
              type="text"
              size={16}
          />
          </Label>          
        </Styles.field>
        <Styles.field>
          <Label>estado
            <InputRef
              placeholder="Ex: SP"
              name="state"
              type="text"
              maxLength={2}
              size={8}
          />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>bairro
            <InputRef
              placeholder="Digite o nome do bairro"
              name="neighborhood"
              type="text"
              size={24}
          />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>apelido
            <InputRef
              placeholder="Ex: casa"
              name="surname"
              type="text"
          />
          </Label>
        </Styles.field>
      </Styles.row>
      <Styles.header>
        <Text
          text="Novo evento"
          color={colors.argon.darkBlue}
          fontFamily="Open Sans"
          size={17}
          weight="600"
        />
      </Styles.header>
      <Styles.row>
        <Styles.field>
          <Label>Nome
            <InputRef
              name="name"
              placeholder="Digite o nome do evento"
          />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Título
            <InputRef
              name="title"
              placeholder="Digite o título do evento"
              onChange={(e) => setPreviewInfo((previewValue) => ({ ...previewValue, title: e.target.value}))}
            />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Subtítulo
            <InputRef
              name="subtitle"
              placeholder="Digite um subtítulo"
              size={32}
              onChange={(e) => setPreviewInfo((previewValue) => ({ ...previewValue, subtitle: e.target.value}))}
            />
          </Label>
        </Styles.field>
      </Styles.row>
      <Styles.row>
        <Styles.field>
          <Label>Cupom</Label>
          <Select
            name="coupon_id"
            options={couponsList.map((coupon: ICouponResponse) => ({
              value: coupon.id,
              label: `${coupon.name} - ${coupon.code}`
            })) as { value: string; label: string; }[]}
            onInputChange={setSearchInput}
            placeholder="Nome ou código"
          />
        </Styles.field>
        <Styles.field>
          <Label>Data de início
            <InputRef
              name="event_start_date"
              type="datetime-local"
              onBlur={handleValidateDate}
            />
          </Label>
        </Styles.field>
        <Styles.field>
          <Label>Data do término
            <InputRef
              name="event_end_date"
              type="datetime-local"
              onBlur={handleValidateDate}
            />
          </Label>
        </Styles.field>
      </Styles.row>
      <Styles.row>
        <Styles.field>
          <Label>Conteúdo
          <TextArea
            name="content"
            placeholder="Digite o conteúdo do evento"
            onChange={(e) => setPreviewInfo((previewValue) => ({ ...previewValue, content: e.target.value}))}
          />
          </Label>
        </Styles.field>
        <Stack>
          <Styles.field>
            <Label>Imagem</Label>
              <ImageInput
                name="image_url"
                onChange={handleImageChange}
              />
          </Styles.field>
          <Styles.field>
            <Label>Ativo</Label>
            <Switch
              name="is_active"
              options={[
                {value: 'Não', label: 'Não ativo'},
                {value: 'Sim', label: 'Ativo'}
              ]}
            />
          </Styles.field>
        </Stack>
      </Styles.row>
      <PreviewEventCard
        content={previewInfo?.content}
        title={previewInfo?.title}
        subtitle={previewInfo?.subtitle}
        image={previewInfo?.image}
      />
    </Form>
  );
}

export default UpdateEventForm;
