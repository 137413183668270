import * as Yup from 'yup';

export const UpdateCepSectionFormValidationSchema = Yup.object().shape({
    start_cep: Yup.string()
        .required('Cep inicial obrigatório')
        .length(3, 'Cep inicial deve ter 3 caracteres'),
    end_cep: Yup.string()
        .required('Cep final obrigatório')
        .length(3, 'Cep final deve ter 3 caracteres'),
    region_id: Yup.string()
        .required('Região obrigatória')
});
