import { Divider } from '@chakra-ui/react';
import { FormHandles, FormHelpers } from '@unform/core';
import { Form } from '@unform/web';
import InputRef from 'components/InputRef';
import Select from 'components/Select';
import Text from 'components/Text';
import React, { useEffect, useRef, useState } from 'react';
import { ActionMeta, OptionTypeBase, ValueType } from 'react-select';
import { getCategories } from 'services/api-graphql-calls';
import AppointmentStatusEnum from 'shared/enums/AppointmentStatusEnum';
import colors from 'shared/utils/constants/colors';
import * as Styles from './styles';
import { launchToast } from 'shared/utils/launchToast';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { AppointmentFiltersFormId, AppointmentFiltersFormValidationSchema } from './constants';
import { ValidationError } from 'yup';
import { IAppointmentFiltersFormData, IAppointmentFiltersFormProps } from './types';
import TextInput from 'components/TextInput';

export default function AppointmentFiltersForm({
  onSubmit
}: IAppointmentFiltersFormProps) {
  const formRef = useRef<FormHandles>(null);

  const [categories, setCategories] = useState<{ value: string, label: string }[]>([]);
  const [status, setStatus] = useState<{ value: string, label: string }[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<{ value: string, label: string }[] | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<{ value: string, label: string }[] | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const data = await getCategories();
      const categoriesOptions = data?.categories.map((category) => ({
        value: category.id,
        label: category.name,
      }));

      setCategories(categoriesOptions);
    }

    for (const key in AppointmentStatusEnum) {
      if (typeof AppointmentStatusEnum[key] === "string") {
        setStatus((previousState) => (
          [...previousState, { value: key, label: AppointmentStatusEnum[key] }]
        ));
      }
    }

    fetchCategories();
  }, []);

  const handleFormValidation = async (data: IAppointmentFiltersFormData, { reset }: FormHelpers) => {
    try {
      const startDate = new Date(data.start_date);
      const endDate = new Date(data.end_date);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      const diff = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      const maximumPeriod = 190;

      if (diffDays > maximumPeriod) {
        formRef.current?.setFieldError(
          "end_date",
          "A data final deve ser no máximo 190 dias após a data inicial",
        );
        return;
      }

      const normalizedData = {
        ...data,
        categories: selectedCategories?.map((category) => category.value),
        status: selectedStatus?.map((status) => Number(status.value)),
      };
      await AppointmentFiltersFormValidationSchema.validate(normalizedData, { abortEarly: false });
      onSubmit(normalizedData);
      formRef.current?.setErrors({});
      reset();
    } catch (error) {
      const errors = getValidationErrors(error as ValidationError);
      formRef.current?.setErrors(errors);
      launchToast("Verifique o preenchimento dos dados", "error");
    }
  }

  return (
    <Styles.FormDiv>
      <Styles.Content>
        <Form onSubmit={handleFormValidation} ref={formRef} id={AppointmentFiltersFormId}>
          <Styles.Row>
            <TextInput
              label="Data inicial"
              name="start_date"
              type="date"
            />
            <TextInput
              label="Data final"
              name="end_date"
              type="date"
            />
          </Styles.Row>

          <Divider mt={8} mb={8} />

          <Text
            text="Opcionais"
            color={colors.argon.textColorDark}
            fontFamily="Open Sans"
            size={14}
            weight="600"
          />
          <Styles.Row>
            <Styles.Field>
              <Text
                text="Categoria"
                color={colors.argon.textColorDark}
                fontFamily="Open Sans"
                size={14}
                weight="600"
              />
              <Select
                multi
                name="categories"
                fontSize="2xl"
                h={16}
                onChange={setSelectedCategories as (value: ValueType<OptionTypeBase, false>, action: ActionMeta<OptionTypeBase>) => void}
                w={"100px"}
                placeholder="Selecione as categorias"
                options={categories}
              />
            </Styles.Field>
            <Styles.Field>
              <Text
                text="Status"
                color={colors.argon.textColorDark}
                fontFamily="Open Sans"
                size={14}
                weight="600"
              />
              <Select
                multi
                name="status"
                fontSize="2xl"
                h={16}
                onChange={setSelectedStatus as (value: ValueType<OptionTypeBase, false>, action: ActionMeta<OptionTypeBase>) => void}
                w={"100px"}
                placeholder="Selecione os status"
                options={status}
              />
            </Styles.Field>
          </Styles.Row>
          <TextInput
            label="Tutor"
            name="tutor_name"
          />
        </Form>
      </Styles.Content>
    </Styles.FormDiv>
  )
};
