import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";

import Button from "../../../components/Button";
import InputRef from "../../../components/InputRef";
import Text from "../../../components/Text";
import { Label } from "../../../components/Label/styles";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";

import colors from "../../../shared/utils/constants/colors";
import TypePaymentEnum from "../../../shared/enums/TypePaymentEnum";
import StatusTransactionEnum from "../../../shared/utils/enums/StatusTransactionEnum";
import formatDate from "../../../shared/utils/formatDate";
import getValidationErrors from "../../../shared/utils/getValidationErrors";
import masks from "../../../shared/utils/masks";

import * as Styles from "./styles";
import { ActionMeta, OptionTypeBase, ValueType } from "react-select";

interface FilterFormData {
  customer: string;
  cpf: string;
  paymentMethod: string;
  initialDate: string;
  finalDate: string;
  status: string;
}

const FilterModal = ({
  visible,
  onCloseModal,
  applyFilters,
  reciviedFilter,
}: any) => {
  const formRef = useRef<FormHandles>(null);

  const [statusOrder, setStatusOrder] = useState([] as any);
  const [paymentMethods, setpaymentMethods] = useState([] as any);
  const [selectedStatus, setSelectedStatus] = useState<{ value: string, label: string }[] | null>(null);

  useEffect(() => {
    setStatusOrder(
      Object.entries(StatusTransactionEnum).reduce<
        { value: string | number; label: string }[]
      >((tmp, s) => {
        if (typeof s[1] === "string") {
          let currentTranslatedName = "";

          switch (s[0]) {
            case "paided":
              currentTranslatedName = "Pago";
              break;

            case "waiting":
              currentTranslatedName = "Em espera";
              break;

            case "cancelled":
              currentTranslatedName = "Cancelado";
              break;

            case "changes_waiting_action":
              currentTranslatedName = "Mudanças aguardando ação";
              break;

            case "refunded":
              currentTranslatedName = "Reembolsado";
              break;

            case "refund_waiting_authorization":
              currentTranslatedName = "Reembolso aguardando confirmação";
              break;

            default:
              break;
          }

          tmp.push({
            value: s[0],
            label: currentTranslatedName,
          });
        }

        return tmp;
      }, [])
    );

    setpaymentMethods(
      Object.entries(TypePaymentEnum).reduce<
        { value: string | number; label: string }[]
      >((tmp, s) => {
        if (typeof s[1] === "string") {
          tmp.push({
            value: s[0],
            label: s[1],
          });
        }
        return tmp;
      }, [])
    );
  }, []);

  useEffect(() => {
    let i;

    i = reciviedFilter.findIndex((r: any) => r.key === "Cliente");
    if (i < 0) formRef.current?.setFieldValue("customer", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "CPF");
    if (i < 0) formRef.current?.setFieldValue("cpf", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Status");
    if (i < 0) formRef.current?.setFieldValue("status", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Forma de Pagamento");
    if (i < 0) formRef.current?.setFieldValue("paymentMethod", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Data Início");
    if (i < 0) formRef.current?.setFieldValue("initialDate", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Data Fim");
    if (i < 0) formRef.current?.setFieldValue("finalDate", "");
  }, [reciviedFilter]);

  const handleSubmit = async (data: FilterFormData) => {
    const { customer, cpf, paymentMethod, initialDate, finalDate, status } =
      data;

    try {
      formRef.current?.setErrors({});

      if (initialDate !== "" && finalDate === "") {
        formRef.current?.setErrors({ finalDate: "Informar data final" });

        return false;
      }

      if (initialDate === "" && finalDate !== "") {
        formRef.current?.setErrors({ initialDate: "Informar data inicial" });

        return false;
      }

      if (initialDate !== "") {
        const schema = Yup.object().shape({
          initialDate: Yup.date(),
          finalDate: Yup.date().min(
            Yup.ref("initialDate"),
            "Data final deve ser maior que a incial"
          ),
        });

        await schema.validate(data, { abortEarly: false });
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);

      return false;
    }

    let filtersToSend = [];

    if (customer !== "")
      filtersToSend.push({
        key: "Cliente",
        value: customer,
        searchValue: customer,
      });

    if (cpf !== "")
      filtersToSend.push({ key: "CPF", value: cpf, searchValue: cpf });

    if (initialDate !== "")
      filtersToSend.push({
        key: "Data Início",
        value: formatDate(initialDate),
        searchValue: initialDate,
      });

    if (finalDate !== "")
      filtersToSend.push({
        key: "Data Fim",
        value: formatDate(finalDate),
        searchValue: finalDate,
      });

    if (status !== "") {      
      filtersToSend.push({
        key: "Status",
        value: selectedStatus?.map((status) => StatusTransactionEnum[status.value as keyof typeof StatusTransactionEnum]),
        searchValue: selectedStatus?.map((status) => StatusTransactionEnum[status.value as keyof typeof StatusTransactionEnum]),
      });
    }

    if (paymentMethod !== "")
      filtersToSend.push({
        key: "Forma de pagamento",
        value: TypePaymentEnum[Number(paymentMethod)],
        searchValue: Number(paymentMethod),
      });
    applyFilters(filtersToSend);
    onCloseModal();
  };

  const clearFilters = () => {
    formRef.current?.reset();
    applyFilters([]);
  };

  const applyMask = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, mask?: string) => {
      formRef.current?.setFieldValue(
        e.target.name,
        mask ? masks[mask](e.target.value) : e.target.value
      );
    },
    []
  );

  return (
    <>
      <Modal visible={visible} closeModal={() => onCloseModal()}>
        <Styles.header>
          <Text
            text="Filtrar pedidos"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={() => onCloseModal()}
          />
        </Styles.header>

        <Styles.line />

        <Styles.FormDiv>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Styles.row>
              <Styles.field>
                <Label>cliente</Label>

                <InputRef
                  placeholder="Digite o nome do cliente"
                  name="customer"
                  type="text"
                />
              </Styles.field>

              <Styles.field>
                <Label>CPF</Label>

                <InputRef
                  placeholder="Digite o CPF"
                  name="cpf"
                  type="text"
                  onChange={(e) => applyMask(e, "cpf")}
                />
              </Styles.field>

              <Styles.field>
                <Label>Forma de pagamento</Label>

                <Select
                  name="paymentMethod"
                  containerStyle={{ width: "33rem" }}
                  placeholder="Forma de pagamento"
                  options={paymentMethods}
                />
              </Styles.field>
            </Styles.row>

            <Styles.row>
              <Styles.field>
                <Label>data início do pedido</Label>

                <InputRef name="initialDate" type="date" />
              </Styles.field>

              <Styles.field>
                <Label>data fim do pedido</Label>

                <InputRef name="finalDate" type="date" />
              </Styles.field>

              <Styles.field>
                <Label>status do pedido</Label>

                <Select
                  multi
                  name="status"
                  containerStyle={{ width: "33rem" }}
                  placeholder="Status"
                  options={statusOrder}
                  onChange={setSelectedStatus as (value: ValueType<OptionTypeBase, false>, action: ActionMeta<OptionTypeBase>) => void}
                />
              </Styles.field>
            </Styles.row>

            <Styles.rowButton>
              <Styles.WhiteButton type="button" onClick={clearFilters}>
                Limpar
              </Styles.WhiteButton>

              <Button
                text="Aplicar"
                type="submit"
                styleContainer={{ minWith: "100px" }}
              />
            </Styles.rowButton>
          </Form>
        </Styles.FormDiv>
      </Modal>
    </>
  );
};
export default FilterModal;
