import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import { CreateCepSectionFormValidationSchema } from './CreateCepSectionFormValidations';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import Select from 'components/Select';
import { ICreateSectionFormData } from 'pages/Ceps/constants/types';
import { GET_REGIONS } from 'pages/Ceps/graphql/CepsCategoriesQuery';
import { useQuery } from '@apollo/client';
import CreateRegionModal from 'pages/Ceps/modals/CreateRegionModal';

export type ICreateCepSectionFormProps = {
    onSubmit: (payload: ICreateSectionFormData, options: { reset: () => void }) => Promise<void>;
    createRegionModalControl: {
        isOpen: boolean;
        onOpen: () => void;
        onClose: () => void;
        onToggle: () => void;
        isControlled: boolean;
        getButtonProps: (props?: any) => any;
        getDisclosureProps: (props?: any) => any;
    }
}

export const CreateCepSectionFormId = 'create-cep-section-form';

const CreateCepSectionForm: React.FC<ICreateCepSectionFormProps> = ({
    onSubmit,
    createRegionModalControl
}) => {

    const [selectedRegion, setSelectedRegion] = useState([] as any)
    const [regionError, setRegionError] = useState("");
    const [regions, setRegions] = useState([] as any[]);

    const createCepSectionFormRef = useRef<FormHandles>(null);

    const { data, loading, refetch } = useQuery(GET_REGIONS);

    const fetchDataAndUpdateState = async (
        dataList: [],
        setStateFunction: (data: any) => void
      ) => {
          const results = dataList
          setStateFunction(results.map( (option: {name: string, id: string}) => ({label: option.name, id: option.id})));
      };

    useEffect(() => {
        !loading && fetchDataAndUpdateState(data.regions, setRegions)
      }, [data]);

    useEffect(() => {
        if(selectedRegion.label){setRegionError("")}
    }, [selectedRegion]);


    const validateFields = async (
        payload: ICreateSectionFormData,
        options: { reset: () => void }
    ) => {

        try {
            await CreateCepSectionFormValidationSchema.validate({ startCep: `${payload.startCep}`, endCep: `${payload.endCep}`, regionId: selectedRegion.id}, { abortEarly: false });
            onSubmit({ startCep: `${payload.startCep}`, endCep: `${payload.endCep}`, regionId: selectedRegion.id}, options);
        setSelectedRegion([] as any)
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            createCepSectionFormRef.current?.setErrors(errors);
            if(!selectedRegion.label){setRegionError("Selecione uma região")}
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form onSubmit={validateFields} ref={createCepSectionFormRef} id={CreateCepSectionFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Cep Inicial</Label>
                        <InputRef
                            name="startCep"
                            type='number'
                            placeholder="Digite o cep inicial"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Cep Final</Label>
                        <InputRef
                            name="endCep"
                            type='number'
                            placeholder="Digite o cep final"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Região</Label>
                        <Select
                            name="regionId"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a região"
                            options={regions}
                            error={regionError}
                            value={selectedRegion}
                            onChange={(e: any) => setSelectedRegion(e)}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
            <CreateRegionModal
                isOpen={createRegionModalControl.isOpen}
                onClose={createRegionModalControl.onClose}
                refetch={refetch}
            />
        </>
    )
}

export default CreateCepSectionForm