export const headers = [
  { key: "category", name: "categoria" },
  { key: "date_formated", name: "data" },
  { key: "period", name: "período" },
  { key: "customer", name: "tutor" },
  { key: "pet_name", name: "pet" },
  { key: "service_name", name: "serviço" },
  { key: 'professional', name: 'Profissional' },
  { key: "appointment_price_total", name: "total" },
  { key: "appointment_price_paided", name: "pago" },
  { key: "status_description", name: "status" },
  { key: "created_at", name: "criação" },
  { key: "platform", name: "origem" },
  { key: "actions", name: "ações" },
];
