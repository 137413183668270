export const columnProductsHeaders = [
    { key: "id", name: "Id" },
    { key: "manufacturer_sku_code", name: "SKU" },
    { key: "name", name: "Nome" },
    { key: "stock_unit", name: "Unidade de medida" },
    { key: "quantity", name: "Quantidade" },
    { key: "cost_price", name: "Preço de compra" },
    { key: "selling_price", name: "Preço de venda" },
    { key: "comission_percentage", name: "Porcentagem de comissão" },
    { key: "maximum_stock", name: "Estoque máximo" },
    { key: "minimum_stock", name: "Estoque mínimo" },
    { key: "Brand", name: "Marca" },
    { key: "Category", name: "Categoria" },
    { key: "packaging", name: "Embalagem" },
    { key: "description", name: "Descrição" },
    { key: "created_at", name: "Criado" },
    { key: "updated_at", name: "Alterado" },
    { key: "actions", name: "ações" },
];