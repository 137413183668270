import React from "react";
import { default as ReactTooltip } from "react-tooltip";
import * as Styles from "./styles";
import { useAuth } from "../../../hooks/Auth";
import { FaSignOutAlt } from "react-icons/fa";

interface Props {
  opened?: boolean;
}

export const Logout: React.FC<Props> = ({ opened }) => {
  const { signOut, user } = useAuth();

  return (
    <Styles.Container opened={opened as boolean}>
      {opened && <Styles.Name>{user?.name.split(" ")[0]}</Styles.Name>}
      <FaSignOutAlt
        onClick={signOut}
        style={{
          cursor: "pointer",
          color: "#fff",
          marginRight: opened ? "20px" : "0",
          fontSize: "17px",
        }}
        data-tip="Sair"
      />
      <ReactTooltip place="top" effect="solid" />
    </Styles.Container>
  );
};

export default Logout;
