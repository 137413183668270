import { gql } from "@apollo/client";
import client from "services/api-graphql";


export const LIST_ANAMNESES = gql`
 query ListAnamneses($filterParams: ListAnamnesesInputType, $paginationParams: ListAnamnesesPaginationInputType) {
  ListAnamneses(filterParams: $filterParams, paginationParams: $paginationParams) {
    totalPages
    data {
      id
      pet_id
      professional_id
      avaliation
      complaint
      castrated
      allergy
      allergy_detail
      feed
      feed_diary
      skin_hygiene
      skin_secretion
      skin_peeling
      eyes_red
      eyes_whitish
      eyes_secretion
      eyes_visual_deficit
      respiration_cough
      respiration_sneeze
      respiration_secretion
      respiration_noise
      heart_exercise_intolerance
      heart_tiredness
      digestive_appetite
      digestive_vomit
      digestive_diarrhea
      urinary_water
      urinary_aspect
      urinary_volume
      urinary_frequency
      reproductive_secretion
      reproductive_heat
      reproductive_contraceptive
      reproductive_puppies
      locomotor_lameness
      locomotor_trauma
      locomotor_volume
      locomotor_impotence
      neurologic_convulsion
      neurologic_syncope
      neurologic_deficit
      created_at
      temperament
      comments
      appointment_id
      feed_type
      feed_prescribed_by_nutritionist
      feed_prescribed_by_nutritionist_detail
      skin_itching
      ears_canals
      respiration_cough_detail
      respiration_sneeze_detail
      respiration_secretion_detail
      syncope_details
      heart_cyanosis
      heart_cyanosis_detail
      digestive_depraved_appetite
      digestive_depraved_appetite_detail
      digestive_vomit_detail
      locomotor_trauma_detail
      digestive_diarrhea_detail
      locomotor_volume_detail
      locomotor_impotence_detail
      breath_rate
      heart_rate
      ecc_condition_score
      muscle_mass_score
      hydration
      temperature
      lymph_nodes
      dentition
      customer_orientations
      pet {
        id
        name
      }
      tutor {
        name
        id
      }
      professional {
        id
        user {
          name
        }
      }
      appointment {
        professional_id
        customer_id
        date
      }
      ecc_condition_score_id
      muscle_mass_score_id
    }
    }
  }
`;

export const listOptionCustomer = async (input: string) => {
  const variables = {
    filters: {
      where:{
        name: input
      },
      pagination: {
        limit: 6,
        page: 1
      }
    }
  }; 
  const { data } = await client.query({
      query: gql`
        query Users($filters: UsersFiltersInputType!) {
          users(filters: $filters) {
            users {
              name
              id
              customer {
                pets {
                  name
                  id
                }
              }
            }
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.users.users
};

export const CREATE_ANAMNESIS = gql`
mutation CreateAnamneseByVet($anamnese: AnamneseInputType!) {
  createAnamneseByVet(anamnese: $anamnese) {
    id
  }
}
`;

export const UPDATE_ANAMNESIS = gql`
mutation UpdateAnamneseByVet($anamnese: UpdateAnamneseInputType!) {
  UpdateAnamneseByVet(anamnese: $anamnese) {
    id
  }
}
`;

export const listOptionAppointmentsToAnamnesisByPet = async (input: string) => {
  const variables = {
    filters: {
      where: {
        pet_id: input
      }
    }
  }; 
  const { data } = await client.query({
      query: gql`
        query Appointments($filters: FiltersAppointmentInputType) {
          appointments(filters: $filters) {
            appointments {
              id
              date
              service {
                name
                category {
                  id
                }
              }
              professional {
                user {
                  name
                }
              }
            }
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.appointments.appointments
};

export const listOptionAppointmentsToAnamnesisById = async (input: string) => {
  const variables = {
    filters: {
      where: {
        id: input
      }
    }
  }; 
  const { data } = await client.query({
      query: gql`
        query Appointments($filters: FiltersAppointmentInputType) {
          appointments(filters: $filters) {
            appointments {
              id
              date
              service {
                name
                category {
                  id
                }
              }
              professional {
                user {
                  name
                }
              }
            }
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.appointments.appointments
};

export const listOptionProfessional = async (input: string) => {
  const variables = {
    filters: {
      where:{
        name: input
      },
      pagination: {
        limit: 6,
        page: 1
      }
    }
  }; 
  const { data } = await client.query({
      query: gql`
        query Professionals($filters: FiltersProfessionalInputType) {
          professionals(filters: $filters) {
            id
            user {
              name      
            }
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.professionals
};

