import { IconButton } from '@chakra-ui/react';
import Button from 'components/Button';
import Modal from "components/Modal";
import Text from 'components/Text';
import React from 'react';
import { CgClose } from 'react-icons/cg';
import colors from 'shared/utils/constants/colors';
import AppointmentFiltersForm from '../../forms/AppointmentFiltersForm';
import { AppointmentFiltersFormId } from '../../forms/AppointmentFiltersForm/constants';
import { IAppointmentFiltersFormData } from '../../forms/AppointmentFiltersForm/types';
import * as Styles from './styles';
import { IAppointmentFiltersModalProps } from './types';

export default function AppointmentFiltersModal({
  isOpen,
  onClose,
  onSubmit,
}: IAppointmentFiltersModalProps) {
  const handleSubmit = (data: IAppointmentFiltersFormData) => {
    for (const key in data) {
      if (!data[key as keyof IAppointmentFiltersFormData]) {
        delete data[key as keyof IAppointmentFiltersFormData];
      }
    }
    onSubmit(data);
    onClose();
  };

  return (
    <Modal visible={isOpen} closeModal={onClose}>
      <Styles.header>
        <Text
          text="Filtrar agendamentos"
          color={colors.argon.darkBlue}
          fontFamily="Open Sans"
          size={17}
          weight="600"
        />
        <IconButton
          aria-label='Fechar modal'
          icon={<CgClose />}
          onClick={onClose}
        />
      </Styles.header>
      <Styles.line />
        <AppointmentFiltersForm onSubmit={handleSubmit} />
      <Styles.rowButton>
        <Button text="Cancelar" onClick={onClose} />
        <Button
          text="Filtrar"
          type="submit"
          form={AppointmentFiltersFormId}
        />
      </Styles.rowButton>
    </Modal>
  );
}