import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { launchToast } from "../../shared/utils/launchToast";
import PetModal from "./PetModal";
import IPet from "types/IPet";
import { GET_ALL_PETS} from "./graphQL";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/generic/searchicon.svg";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "components/Search";
import { headers } from "./utils/columns";
import Table from "components/Table";
import { useQuery } from "@apollo/client";
import MainContainer from "components/MainContainer";
import { useDisclosure } from "@chakra-ui/react";
import PetObservarionsModal from "./PetObservarionsModal";

const Pets: React.FC = () => {
  const [selectedPet, setSelectedPet] = useState<IPet | undefined>(undefined);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState<number>(10);

  const observationsModalControl = useDisclosure();
  const editPetModalControl = useDisclosure();


  const { data, loading, refetch } = useQuery(GET_ALL_PETS, {
      variables: {
        filters:{
          where: {
              wordSearch: searchInput,
          },
          pagination: {
              limit: paginationLimit,
              page: currentPage,
          },
          orderBy: {
            name: "asc"
          },
      },
    }
  });

  useEffect(() => {
    try {
        refetch({
          filters:{
            where: {
              wordSearch: searchInput,
            },
            pagination: {
                limit: paginationLimit,
                page: currentPage,
            },
            orderBy: {
              name: "asc"
            },
        },
        });
    } catch (error: any) {
        launchToast(error.message, "error");
    }
  }, [paginationLimit, currentPage])

  const handleWordSearch = (pet: string) => {
    setCurrentPage(1)
    setSearchInput(pet)
  };

  const handleSelectedPet = (id: string) => {
    const pet = data.pets.pets.find((pet: IPet) => pet.id === id);
    setSelectedPet(pet);
    editPetModalControl.onOpen()
  };

  const handleObservations = (id: string) => {
    const pet = data.pets.pets.find((pet: IPet) => pet.id === id);
    setSelectedPet(pet);
    observationsModalControl.onOpen()
  };

  enum neutered  {
    "neutered" = "castrado",
    "not_neutered" = 'não castrado',
    "not_informed" = "não informado"
  }
  
  return (
    <>
      { selectedPet && <PetObservarionsModal
        isOpen={observationsModalControl.isOpen}
        onClose={observationsModalControl.onClose}
        pet={selectedPet as IPet}
      />}
      <MainContainer>
        <PanelHeader title="Pets" isFilterModalVisible={false} />
        <Search
          wordSearch={searchInput}
          setSearch={handleWordSearch}
          isFilterForRegister
          limit={paginationLimit}
          onSetPageSize={(limit) => {
            setCurrentPage(1)
            setPaginationLimit(limit)
          }}
          placeholder="Pesquise pelo nome"
        />
        { 
          !loading && 
          (
            <Table
              headers={headers}
              data={data.pets.pets.map((pet: IPet) => ({
                id: pet.id,
                name: pet.name,
                tutors: pet.tutors,
                neutered: neutered[pet.neutered],
                actions: [
                   {
                     name: "Editar",
                     icon: <EditButton />,
                     action: handleSelectedPet,
                   },
                   {
                    name: "Observações",
                    icon: <SearchIcon />,
                    action: handleObservations,
                  },
                 ],
              }))}
            />
          )
        }
        {
          !loading && (
            <Pagination
              totalPage={data.pets.totalPages}
              pageIndex={currentPage}
              setPage={setCurrentPage}
            />
          )
        }
      </MainContainer>
      { selectedPet && <PetModal
        visible={editPetModalControl.isOpen}
        pet={selectedPet}
        onCloseModal={() => editPetModalControl.onClose()}
      />}
    </>
  );
};

export default Pets;
