import { gql } from "@apollo/client";

export const GET_ALL_PETS = gql`
  query Pets($filters: FiltersPetInputType!) {
    pets(filters: $filters) {
    pets {  
     id
     name
     neutered
     gender
     birthday
     neutered
     temperament
     weight
     specie{
      name
     }
     breed {
      name
     }
     tutors {
      name
      id
      email
    }
  }  
    totalPages
    }
  }
`;