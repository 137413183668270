import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useEffect, useRef } from "react";
import Button from "../../../components/Button";
import InputRef from "../../../components/InputRef";
import { Label } from "../../../components/Label/styles";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import Text from "../../../components/Text";
import colors from "../../../shared/utils/constants/colors";
import StatusTransactionEnum from "../../../shared/utils/enums/StatusTransactionEnum";
import formatDate from "../../../shared/utils/formatDate";
import * as Styles from "./styles";

interface FilterFormData {
  customer: string;
  date: string;
  status: string;
}
const FilterModal = ({
  visible,
  onCloseModal,
  applyFilters,
  reciviedFilter,
}: any) => {
  const formRef = useRef<FormHandles>(null);
  const modal_ref = useRef<any>(null);
  const status_list_converted = Object.entries(StatusTransactionEnum).map((item) => {
    let status_converted = 'sem dado';

    if(item[1] === StatusTransactionEnum.cancelled) {
      status_converted = 'cancelado'
    } else if(item[1] === StatusTransactionEnum.changes_waiting_action) {
      status_converted = 'Aguardando confirmar alteração'
    } else if(item[1] === StatusTransactionEnum.refund_waiting_authorization) {
      status_converted = 'estorno aguardando permissão '
    } else if(item[1] === StatusTransactionEnum.paided) {
      status_converted = 'pago'
    } else if(item[1] === StatusTransactionEnum.waiting) {
      status_converted = 'aguardando p.'
    } else {
      status_converted = 'sem dado'
    }
    return {
      value: item[1], 
      label: status_converted
    }
  });

  useEffect(() => {
    let i;

    i = reciviedFilter.findIndex((r: any) => r.key === "Cliente");
    if (i < 0) formRef.current?.setFieldValue("customer", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Status");
    if (i < 0) formRef.current?.setFieldValue("status", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Data");
    if (i < 0) formRef.current?.setFieldValue("date", "");
  }, [reciviedFilter]);

  const handleSubmit = async (data: FilterFormData) => {
    const { customer, date, status } = data;

    let filtersToSend = [];
    if (customer !== "")
      filtersToSend.push({
        key: "Cliente",
        value: customer,
        searchValue: customer,
      });
    
    if (date !== "")
      filtersToSend.push({
        key: "Data",
        value: formatDate(date),
        searchValue: date,
      });

    if (status !== "")
      filtersToSend.push({
        key: "Status",
        value: status,
        searchValue: status,
      });

    applyFilters(filtersToSend);
    onCloseModal();
  };

  const clearFilters = () => {
    formRef.current?.reset();
    applyFilters([]);
  };

  return (
    <div ref={modal_ref}>
      <Modal visible={visible} closeModal={() => onCloseModal()}>
        <Styles.header>
          <Text
            text="Filtrar pedidos"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={() => onCloseModal()}
          />
        </Styles.header>

        <Styles.line></Styles.line>

        <Styles.FormDiv>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Styles.row>
              <Styles.field>
                <Label>cliente</Label>
                <InputRef
                  placeholder="Digite o nome do cliente"
                  name="customer"
                  type="text"
                />
              </Styles.field>
              <Styles.field>
                <Label>data</Label>
                <InputRef name="date" type="date" />
              </Styles.field>
              <Styles.field>
                <Label>status</Label>
                <Select
                  name="status"
                  containerStyle={{ width: "33rem" }}
                  placeholder="Status"
                  options={status_list_converted}
                
                />
              </Styles.field>
            </Styles.row>

            <Styles.rowButton>
              <Styles.WhiteButton onClick={clearFilters}>
                Limpar
              </Styles.WhiteButton>
              <Button
                text="Aplicar"
                type="submit"
                styleContainer={{ minWith: "100px" }}
              />
            </Styles.rowButton>
          </Form>
        </Styles.FormDiv>
      </Modal>
    </div>
  );
};
export default FilterModal;
