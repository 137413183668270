import Modal from "components/Modal";
import React, { useCallback } from "react"
import * as Styles from '../constants/styles';
import Text from "components/Text";
import { IconButton } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import colors from "shared/utils/constants/colors";
import Button from "components/Button";
import FilterCampaignForm, { FilterCampaignFormId } from "../forms/Filter/FilterCampaignForm";
import { APIFiltersCampaign, CampaignFilterTranslateEnum, IFilterCamapaignFormData } from "../constants/types";
import { launchToast } from "shared/utils/launchToast";

export type FilterModalProps = {
    isOpen: boolean;
    onClose: () => void;
    handleAddFilter: (variables?: Partial<{
        filterParams: {
            name?: string;
            description?: string;
            type?: string;
            is_active: boolean;
        };
        paginationParams: {
            limit: number;
            page: number;
        };
        orderParams: {
            field: string;
            order: string;
        };
    }> | undefined) => void;
    onFilterSubmit: (filters: any) => void;
}


const FilterCampaingModal = ({
    isOpen,
    onClose,
    handleAddFilter,
    onFilterSubmit
}: FilterModalProps) => {

    const handleSubmit = useCallback(
        async (
            payload: IFilterCamapaignFormData,
            { reset }
        ) => {
            try {
                const activeFilters = [];

                const { end_filter, start_filter, ...rest } = payload;

                const APIFilters = rest as unknown as APIFiltersCampaign;

                for (const key in payload) {
                    if (key === 'start_date') {
                        if (payload[key] !== '') {
                            const comparison = payload['start_filter'] === 'gte'
                                ? 'Depois de'
                                : 'Antes de'
                            activeFilters.push({ key: 'Data de início', value: `${comparison} ${payload[key]}` });
                            APIFilters[key] = {
                                date: payload[key],
                                filter: start_filter,
                            };
                        } else {
                            delete APIFilters.start_date;
                        }
                    } else if (key === 'end_date') {
                        if (payload[key] !== '') {
                            const comparison = payload['end_filter'] === 'gte'
                                ? 'Depois de'
                                : 'Antes de'
                            activeFilters.push({ key: 'Data de término', value: `${comparison} ${payload[key]}` });
                            APIFilters[key] = {
                                date: payload[key],
                                filter: payload['end_filter'],
                            };
                        } else {
                            delete APIFilters.end_date;
                        }
                    } else if (payload[key] === 'Sim') {
                        activeFilters.push({ key: 'Ativo', value: true });
                    } else if (payload[key] === 'Não') {
                        activeFilters.push({ key: 'Ativo', value: false });
                    } else if (payload[key] === 'lte' || payload[key] === 'gte') {

                    } else if (payload[key] !== '' && payload[key] !== undefined) {
                        activeFilters.push({ key: CampaignFilterTranslateEnum[key as keyof APIFiltersCampaign], value: payload[key] });
                    } else {
                        delete APIFilters[key as keyof APIFiltersCampaign];
                    }
                }

                onFilterSubmit(activeFilters);

                handleAddFilter({
                    filterParams: {
                        ...APIFilters,
                        is_active: payload.is_active === 'Sim',
                    },
                    paginationParams: {
                        limit: 10,
                        page: 1,
                    }
                });
                reset();
                onClose();
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        }, []
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Filtrar campanhas"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <IconButton
                    aria-label='Fechar modal'
                    icon={<CgClose />}
                    onClick={onClose}
                />
            </Styles.header>
            <Styles.line></Styles.line>
            <Styles.FormDiv>
                <FilterCampaignForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button
                    text="Filtrar"
                    type="submit"
                    form={FilterCampaignFormId}
                />
            </Styles.rowButton>
        </Modal>
    )
}

export default FilterCampaingModal