import * as Yup from "yup";

const getYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  };

export const CreateCampaignFormValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nome é obrigatório')
        .max(255, 'O nome pode ter no máximo 255 caracteres'),
        start_date: Yup
        .date()
        .min(getYesterday(), 'Campanha não pode ser iniciada antes do dia anterior')
        .required('Data de início é obrigatória'),
    end_date: Yup
        .date()
        .min(
            Yup.ref("start_date"),
            "Data final deve ser maior que a incial"
        )
        .required('Data do término é obrigatória'),
    type: Yup.string()
        .required('Data de ínicio é obrigatória'),
    description: Yup.string()
        .required('Descrição é obrigatória')
        .max(255, 'A descrição deve ter no máximo 500 caracteres'),
});