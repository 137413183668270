import styled from 'styled-components';
import colors from '../../shared/utils/constants/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
`;

export const Spin = styled.div`
  color: ${colors.gray.white};
  display: flex;
  animation: lds-dual-ring 1.2s linear infinite;
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
