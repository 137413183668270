import * as Yup from "yup";

export const CreateReceiptFormValidationSchema = Yup.object().shape({
    number: Yup.string()
        .required('Numero é obrigatório')
        .max(6, "Máximo de 6 digitos "),
    emission_date: Yup.date()
        .required('Data de emissão é obrigatória'),
    supplier_id: Yup.string()
        .required('Fornecedo é obrigatório'),
        products: Yup.array()
        .required('Lista de produtos é obrigatório')
        .min(1, 'Alista de podrutos deve conter no minimo 1 produto'),
});