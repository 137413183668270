import { gql } from "@apollo/client";
import { IFilterMovimentsReport } from "../constants/types";
import client from "services/api-graphql";

export const MOVIMENTS_REPORT = gql`
 query ProductActionsReport($input: ProductActionReportInputType!) {
  productActionsReport(input: $input) {
    totalPage
    report {
      costPrice
      brand
      createdAt
      product
      productId
      stock_unit
      professional
      quantity
      salePrice
      stockQuantity
      totalPrice
      truck
      movedBy
      reason
    }
  }
}
`;

export const movimentReport = async (filters: IFilterMovimentsReport) => {
    const variables = { 
        input :{
          to: filters.to,
          from: filters.from,
          productId: filters.productId,
          professionalId: filters.professionalId,
          truckId: filters.truckId,
          pagination: {
            limit: filters.pagination?.limit,
            page: filters.pagination?.page
        }
      }
    }; 
    const { data } = await client.query({
        query: gql`
            query ProductActionsReport($input: ProductActionReportInputType!) {
                productActionsReport(input: $input) {
                    totalPage
                        report {
                        costPrice
                        brand
                        createdAt
                        product
                        productId
                        stock_unit
                        professional
                        quantity
                        salePrice
                        stockQuantity
                        totalPrice
                        truck
                        movedBy
                        reason
                    }
                }
            }
        `,
        variables,
        fetchPolicy: 'network-only',
    });
    return data
  };