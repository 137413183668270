import { Avatar, HStack, Heading, Stack, Text } from "@chakra-ui/react";
import React from 'react';

export type PreviewEventCardProps = {
  image?: string;
  title?: string;
  subtitle?: string;
  content?: string;
}

function PreviewEventCard({
  content,
  image,
  subtitle,
  title,
}: PreviewEventCardProps) {
  return (
    <HStack
      backgroundColor="yellow"
      height="xs"
      padding={12}
      width="50%"
      borderRadius="xl"
      border="1px"
      borderColor="yellow"
      // alignItems="baseline"
    >
      <Avatar
        src={image}
        boxSize={24}
        marginRight={8}
      />
      <Stack>
        <Heading fontSize={18}>{title}</Heading>
        <Text fontSize={18}>{subtitle}</Text>
        <Text>{content}</Text>
      </Stack>
    </HStack>
  );
};

export default PreviewEventCard;
