enum MeetUs {
   "Indicação de amigo" = 1,
   "Google" = 2,
   "Redes Sociais" = 3,
   "Ação no condomínio" = 4,
   "Através do PetTruck" = 5,
   "PlayStore ou AppStore" = 6,
   "Outro" = 0
}

export default MeetUs