import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import Text from "../../components/Text";
import colors from "../../shared/utils/constants/colors";
import * as Styles from "./styles";

const NotFound: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Styles.Container>
        <Text
          text="404"
          color={colors.argon.textColorDark}
          fontFamily="Open Sans"
          size={70}
          weight="600"
          marginRight={4}
          marginTop={0}
        />
        <Text
          text="A página que você tentou acessar não existe"
          color={colors.argon.textColorDark}
          fontFamily="Open Sans"
          size={17}
          weight="600"
          marginRight={4}
          marginTop={20}
        />
        <Text
          text="Verifique o URL ou tente novamente"
          color={colors.argon.textColorDark}
          fontFamily="Open Sans"
          size={17}
          weight="600"
          marginRight={4}
          marginTop={0}
        />
        <Text
          text="ou encontre o que você precisa na na página inicial"
          color={colors.argon.textColorDark}
          fontFamily="Open Sans"
          size={17}
          weight="600"
          marginRight={4}
          marginTop={0}
          marginBottom={30}
        />

        <Button
          text="Página Inicial"
          type="submit"
          styleContainer={{ minWith: "100px" }}
          onClick={() => history.push('/home')}
        />
      </Styles.Container>
    </>
  );
};
export default NotFound;
