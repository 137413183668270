export const columnCepsCategoriesSectionHeaders = [
    { key: "start_cep", name: "inicio" },
    { key: "end_cep", name: "Final" },
    { key: "region_name", name: "Região" },
    { key: "actions", name: "ações" },
];


export const columnCepsCategoriesHeaders = [
    { key: "start_cep", name: "inicio" },
    { key: "end_cep", name: "Final" },
    { key: "region_name", name: "Região" },
    { key: "bath_and_grooming", name: "Banho e Tosa" },
    { key: "vaccines", name: "Vacinas" },
    { key: "veterinarian", name: "Veterinario" },
    { key: "actions", name: "ações" },
];
