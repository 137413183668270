import styled from "styled-components";
import colors from "../../shared/utils/constants/colors";

export const BackgroundPanel = styled.div`
  width: 100%;
`;

export const PanelHeader = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${colors.primary.default};
  color: ${colors.argon.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelItens = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
`;

export const TablePanel = styled.div`
  background: ${colors.argon.white};
  border-radius: 5px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
`;

export const Table = styled.table`
  background: ${colors.argon.white};
  border-collapse: collapse;
  font-weight: 600;
  font-size: 14px;
  font-family: Open Sans;
  text-align: left;
  width: 100%;
`;

export const TableHead = styled.thead`
  color: ${colors.argon.textColorGray};
`;

export const TableRow = styled.tr``;

export const TableHeader = styled.th`
  text-transform: uppercase;
  background: linear-gradient(0deg, #f6f9fc, #f6f9fc);
  padding: 12px;
`;

export const TableBody = styled.tbody``;

export const TableData = styled.td`
  color: ${colors.argon.darkBlue};
  font-size: 14px;
  font-weight: normal;
  padding: 12px;
  margin: 12px;
  white-space: nowrap;
`;

export const ActionsButtons = styled.div`
  display: flex;
`;

export const ActionButton = styled.button`
  margin: 8px;
`;

export const OrderButton = styled.img`
  margin-left: 8px;
  width: 18px;
`;

export const HeaderItens = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterDisplay = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${colors.gray.light02}
`;

export const SeachDisplay = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

export const WhiteButton = styled.button`
  margin: 5%;
  background-color: ${colors.argon.white};
  border: 2px ${colors.argon.default} solid;
  border-radius: 5px;
  width: 100px;
  font-family: Open Sans;
  color: ${colors.argon.default};
  height: 40px;

  
`;