import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import { RegisterProductFormValidationSchema } from './RegisterProductValidations';
import { IBrand, ICategory, IPackaging, IRegisterProductFormData } from 'pages/Stock/constants/types';
import { listOptionBrands, listOptionCategories, listOptionPackagings } from 'pages/Stock/graphql/StockQuery';

export type IRegisterProductFormProps = {
    onSubmit: (payload: IRegisterProductFormData, options: { reset: () => void }) => Promise<void>;
}

export const RegisterProductFormId = 'register-product-form';

const RegisterProductForm: React.FC<IRegisterProductFormProps> = ({ onSubmit}) => {

    const registerProductFormRef = useRef<FormHandles>(null);

    const [searchBrandInput, setSearchBrandInput] = useState('')
    const [brandOptions, setBrandOptions] = useState([] as any)
    const [selectedBrand, setSelectedBrand] = useState([] as any)
    const [brandError, setBrandError] = useState("");
    const [selectedStockUnity, setSelectedStockUnity] = useState([] as any)
    const [StockUnityError, setStockUnityError] = useState("");
    const [selectedClass, setSelectedClass ] = useState([] as any)
    const [classError, setClassError] = useState("");
    const [searchCategoryInput, setSearchCategoryInput] = useState('')
    const [categoryOptions, setCategoryOptions] = useState([] as any)
    const [selectedCategory, setSelectedCategory ] = useState([] as any)
    const [categoryError, setCategoryError] = useState("");    
    const [searchPackagingInput, setSearchPackagingInput] = useState('')
    const [packagingOptions, setPackagingOptions] = useState([] as any)
    const [packagingError, setPackagingError] = useState("");
    const [selectedPackaging, setSelectedPackaging] = useState([] as any);
    const [costPrice, setCostPrice] = useState([] as any);
    const [sellingPrice, setSellingPrice] = useState([] as any);
    const [comissionPercentage, setComissionPercentage] = useState([] as any);

    useEffect(() => {
        let timeoutBrand: NodeJS.Timeout;
        const fatchBrands = async () => {
            const brandList = await listOptionBrands(searchBrandInput)
            setBrandOptions(brandList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutBrand);
            timeoutBrand = setTimeout(fatchBrands, 400);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutBrand);
    }, [searchBrandInput]);

    useEffect(() => {
        let timeoutCategory: NodeJS.Timeout;
        const fatchCategorys = async () => {
            const categoryList = await listOptionCategories(searchCategoryInput)
            setCategoryOptions(categoryList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutCategory);
            timeoutCategory = setTimeout(fatchCategorys, 400);
        };
      
        delayedFetchData();
      
        return () => clearTimeout(timeoutCategory);
    }, [searchCategoryInput]);

    useEffect(() => {
        let timeoutPackaging: NodeJS.Timeout;
        const fatchPackagings = async () => {
            const packagingList = await listOptionPackagings(searchPackagingInput)
            setPackagingOptions(packagingList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutPackaging);
            timeoutPackaging = setTimeout(fatchPackagings, 400);
        };
      
        delayedFetchData();
      
         return () => clearTimeout(timeoutPackaging);
    }, [searchPackagingInput]);

    const stockUnityOptions = [
        { value: "LITER", label: "Litro" },
        { value: "MILLILITRE", label: "Mililitro" },
        { value: "GRAM", label: "Grama" },
        { value: "KILOGRAM", label: "Quilograma" },
        { value: "MILIGRAM", label: "Miligrama" },
        { value: "UNITY", label: "Unidade" },
    ]

    const classOptions =               [
        { value: "INPUT", label: "Insumo" },
        { value: "RESALE", label: "Revenda" },
    ]

    const priceMask = (e: any) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(Number(numericValue) / 100);
        return formattedValue
    }

    const priceUnmask = (value: string) => {
        const formattedValue = value?.replace('R$', '').replace(/\./g, '').replace(',', '');;
        const numberFloat = parseFloat(formattedValue);
        return numberFloat
    }

    const percentageMask = (e: any) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        const clampedValue = Math.min(100, Number(numericValue));
        const formattedValue = `${clampedValue}`;
        return formattedValue;
    }
    

    const validateFields = async (
        payload: IRegisterProductFormData,
        options: { reset: () => void }
    ) => {
        try {
            await RegisterProductFormValidationSchema.validate({...payload, comission_percentage: Number(payload.comission_percentage), maximum_stock: Number(payload.maximum_stock),  quantity: Number(payload.quantity),   minimum_stock: Number(payload.minimum_stock),  brand: selectedBrand.label, category: selectedCategory.label, class: selectedClass.value, packaging: selectedPackaging.label }, { abortEarly: false });
            onSubmit({...payload, comission_percentage: Number(payload.comission_percentage), maximum_stock: Number(payload.maximum_stock),  quantity: Number(payload.quantity),  minimum_stock: Number(payload.minimum_stock),  brand: selectedBrand.label, category: selectedCategory.label, class: selectedClass.value, packaging: selectedPackaging.label, cost_price: priceUnmask(costPrice), selling_price: priceUnmask(sellingPrice) }, options);
            setSelectedBrand([] as any)
            setSelectedCategory([] as any)
            setSelectedClass([] as any)
            setSelectedPackaging([] as any)
            setSelectedStockUnity([] as any)
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            registerProductFormRef.current?.setErrors(errors);
            if(!selectedBrand.label){setBrandError("Selecione uma marca")}
            if(!selectedStockUnity.label){setStockUnityError("Selecione uma unidade de medida")}
            if(!selectedClass.label){setClassError("Selecione uma classe")}
            if(!selectedCategory.label){setCategoryError("Selecione uma categoria")}
            if(!selectedPackaging.label){setPackagingError("Selecione uma embalagem")}
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    useEffect(() => {
        if(selectedBrand.label){setBrandError("")}
        if(selectedClass.label){setClassError("")}
        if(selectedStockUnity.label){setStockUnityError("")}
        if(selectedCategory.label){setCategoryError("")}
        if(selectedPackaging.label){setPackagingError("")}
    }, [selectedBrand, selectedClass, selectedStockUnity, selectedCategory, selectedPackaging]);
    

    return (
        <>
            <Form onSubmit={validateFields} ref={registerProductFormRef} id={RegisterProductFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Nome</Label>
                        <InputRef
                            name="name"
                            placeholder="Digite o nome do Produto"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Marca</Label>
                        <Select
                            name="brand"
                            options={brandOptions?.map((brand:  IBrand) => ({
                            ...brand,
                            label: brand.name
                            })) as { value: string; label: string; }[]}
                            onInputChange={setSearchBrandInput}
                            error={brandError}
                            placeholder="Busque uma marca"
                            onChange={(e) => setSelectedBrand(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Descrição</Label>
                        <TextArea
                            containerStyle={{ width: "33rem" }}
                            name="description"
                            placeholder="Descrição do produto"
                        />
                    </Styles.field>
                <Styles.field>
                    <Label>Unidade de Medida</Label>
                        <Select
                            name="stock_unit"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a marca"
                            options={stockUnityOptions}
                            error={StockUnityError}
                            value={selectedStockUnity}
                            onChange={(e: any) => setSelectedStockUnity(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>SKU</Label>
                        <InputRef
                            name="manufacturer_sku_code"
                            placeholder="SKU do produto"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Classe do Produto</Label>
                        <Select
                            name="class"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a marca"
                            options={classOptions}
                            error={classError}
                            value={selectedClass}
                            onChange={(e: any) => setSelectedClass(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Categoria</Label>
                        <Select
                            name="category"
                            options={categoryOptions?.map((category:  ICategory) => ({
                            ...category,
                            label: category.name
                            })) as { value: string; label: string; }[]}
                            onInputChange={setSearchCategoryInput}
                            error={categoryError}
                            placeholder="Busque uma marca"
                            onChange={(e) => setSelectedCategory(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Embalagem</Label>
                        <Select
                            name="packaging"
                            options={packagingOptions?.map((packaging:  IPackaging) => ({
                            ...packaging,
                            label: packaging.name
                            })) as { value: string; label: string; }[]}
                            onInputChange={setSearchPackagingInput}
                            error={packagingError}
                            placeholder="Busque uma marca"
                            onChange={(e) => setSelectedPackaging(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Quantidade</Label>
                        <InputRef
                            type='number'
                            name="quantity"
                            placeholder="0"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Estoque Mínimo</Label>
                        <InputRef
                            name="minimum_stock"
                            type='number'
                            placeholder="0"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Estoque Máximo</Label>
                        <InputRef
                            name="maximum_stock"
                            type='number'
                            placeholder="0"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Valor de Compra</Label>
                        <InputRef
                            name="cost_price"
                            placeholder="Digite o valor"
                            containerStyle={{ width: "33rem" }}
                            value={costPrice}
                            onChange={(e: any) => setCostPrice(priceMask(e))}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Valor de Venda</Label>
                        <InputRef
                        name="selling_price"
                        placeholder="Digite o valor"
                        containerStyle={{ width: "33rem" }}
                        value={sellingPrice}
                        onChange={(e: any) => setSellingPrice(priceMask(e))}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Percentual de Comissão</Label>
                        <InputRef
                            name="comission_percentage"
                            placeholder="0"
                            containerStyle={{ width: "33rem" }}
                            value={comissionPercentage}
                            onChange={(e: any) => setComissionPercentage(percentageMask(e))}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default RegisterProductForm