import * as Yup from "yup";

export const newAppointmentObservationFormValidations = Yup.object().shape({
    observation: Yup.string()
        .required('Observação é obrigatória')
        .max(500, 'A observação pode ter no máximo 500 caracteres')
        .min(5, 'A observação deve ter no mínimo 5 caracteres'),
    observationTo: Yup.string()
        .required('Obeservação para é obrigatorio'),
    showToCustomer: Yup.string()
        .required()
});