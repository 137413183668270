import colors from "shared/utils/constants/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const CepContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    row-gap: 16px;
    column-gap: 16px;
    width: 100%;
    align-items: center;
    margin: auto;
    margin-top: 24px;
`;

export const CepCard = styled.div<{ status: boolean }>`
    background-color: ${colors.secondary.default};
    border: 1px solid ${colors.secondary.default};
    padding: 8px;
    text-align: center;
    border-radius: 8px;
    
    transition: all 300ms ease;
    
    cursor: pointer;
    white-space: nowrap;

    ${props => props.status && css`
        background-color: ${colors.secondary.default};
        border: 1px solid ${colors.secondary.default};
    `}
    ${props => !props.status && css`
        background-color: ${colors.suport.warning};
        border: 1px solid ${colors.suport.warning};
    `}

    :hover {
      background-color: ${colors.gray.white};
      p {
        color: ${colors.gray.dark02};
      }
    }
`;
