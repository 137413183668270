export const petObservationsColumns = [
    { key: "id", name: "Id" },
    { key: "ObservationTo", name: "Obsevação para" },
    { key: "description", name: "Observação" },
    { key: "added_by", name: "Autor" },
    { key: "show_to_customer", name: "Exibir para o cliente" },
    { key: "show_to_professional", name: "Exibir para o profissional" },
    { key: "created_at", name: "Data" },
    { key: "actions", name: "ações" },
];
