import React, { useRef } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import { CreateRegionFormValidationSchema } from './createRegionFormValidations';
import { ICreateRegionFormData } from 'pages/Ceps/constants/types';

export type ICreateRegionFormProps = {
    onSubmit: (payload: ICreateRegionFormData, options: { reset: () => void }) => Promise<void>
}

export const CreateRegionWhenEditFormId = 'create-region-edit-form';

const CreateRegionWhenEditForm: React.FC<ICreateRegionFormProps> = ({
    onSubmit,
}) => {

    const createRegionWhenEditFormRef = useRef<FormHandles>(null);

    const validateFields = async (
        payload: ICreateRegionFormData,
        options: { reset: () => void }
    ) => {
        try {
            await CreateRegionFormValidationSchema.validate({ name: payload.name}, { abortEarly: false });
            onSubmit({ name: payload.name}, options);
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            createRegionWhenEditFormRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form onSubmit={validateFields} ref={createRegionWhenEditFormRef} id={CreateRegionWhenEditFormId}>
                <Styles.rowRegionCreateForm>
                    <Styles.fieldRegionCreateForm>
                        <Label>{"Nome"}</Label>
                        <InputRef
                            name="name"
                            placeholder="Nome da região"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.fieldRegionCreateForm>
                </Styles.rowRegionCreateForm>
            </Form >
        </>
    )
}

export default CreateRegionWhenEditForm