import styled from "styled-components";
import colors from "../../shared/utils/constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  flex: 1;
`;

export const BackgroundPanel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PanelHeader = styled.div`
  display: flex;
  height: 150px;
  background-color: ${colors.primary.default};
  color: ${colors.argon.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelItens = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-between;
  align-items: center;
  margin-left: 3%;
`;

export const TablePanel = styled.div`
  display: flex;
  background: ${colors.argon.white};
  border-radius: 5px;
  margin: 16px;
  padding: 16px;
`;

export const FirstSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const TrucksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const ProfessionalGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const AppointmentsContainer = styled.div`
  display: grid;  
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
`;

export const AppointmentsCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 3px solid ${colors.secondary.default};
  border-radius: 16px;

  :hover {
    border: 3px solid ${colors.primary.default};
  }
`;

export const Checklist = styled.button<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => (props.checked ? colors.primary.default : colors.gray.dark01)};
  border-radius: 4px;
  background-color: ${(props) => (props.checked ? colors.primary.default : colors.gray.white)};
`;

export const ButtonUpdateStatus = styled.button`
  transition: 300ms;
  justify-content: center;
  align-items: center;
  background-color: ${colors.secondary.default};
  border: 1px solid ${colors.secondary.default};
  border-radius: 4px;
  margin-left: 8px;
  padding: 8px;
  color: ${colors.gray.white};
  box-shadow: 0 6px 8px ${colors.gray.light02};
`;