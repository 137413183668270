import styled, { css } from 'styled-components';

import colors from '../../shared/utils/constants/colors';

interface IInputProps {
  hasIcon: boolean;
  isErrored: boolean;
  isFocused: boolean;
  isFilled: boolean;
  isDisabled: boolean;
}

export const Container = styled.div<IInputProps>`
  width: 100%;
  background-color: ${colors.argon.white};
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(50, 50, 93, 0.15);
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 16px;
  padding-right: 2px;
  border: 1px solid transparent;

  ${(props) => props.isErrored && css`
    border: 1px solid #FF6340;
  `};

  ${(props) => props.isFocused && css`
    border: 1px solid ${colors.gray.dark03};
  `};

  ${(props) => props.isFilled && css`
    border: 1px solid #2DCE89;
  `};

  ${(props) => props.isDisabled && css`
  background-color: ${colors.primary.disabled};
  color: ${colors.argon.textColorGray};
`};

  &+div {
    margin-top: 16px;
  }
  
  input {
    border: none;
    width: 100%;
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
    margin-left: -8px;
    background: transparent;
    height: 5rem;
    font-family: 'Open Sans';
    font-size: 2rem;
    color: ${colors.argon.textColorDark};

    &::placeholder {
      color: ${colors.argon.textColorLight};
    }
  }

  input:disabled {
    color: ${colors.argon.textColorGray};
  }

  
  input[type="date"]::-webkit-datetime-edit-text,
  input[type="date"]::-webkit-datetime-edit-month-field,
  input[type="date"]::-webkit-datetime-edit-day-field,
  input[type="date"]::-webkit-datetime-edit-year-field {
    color: ${colors.argon.textColorLight};
  }

  svg {
    font-size: 2.2rem;
    color: ${colors.argon.textColorLight};
    margin-right: 16px;

    ${(props) => props.isErrored && css`
      color: #FF6340;
    `};

    ${(props) => props.isFilled && css`
      color:  #2DCE89;
    `};

    @media (max-width: 650px) {
      font-size: 2.4rem;
    }
  }
`;
