import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../constants/styles';
import Button from "components/Button";
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { IProduct, IUpdateProductVariables } from '../constants/types';
import { LIST_PRODUCTS, UPDATE_PRODUCT } from '../graphql/StockQuery';
import { ICreateProductFormData } from 'pages/Receipts/constants/types';

import Text from 'components/Text';
import { IconButton } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import colors from 'shared/utils/constants/colors';
import UpdateProductForm, { UpdateProductFormId } from '../forms/updateProduct/UpdateProductForm';

export type IUpdateProductModalProps = {
    isOpen: boolean;
    onClose: () => void;
    product: IProduct;
};

const UpdateProductModal = ({
    isOpen,
    onClose,
    product,
}: IUpdateProductModalProps) => {

    const [updateProduct, { loading }] = useMutation(UPDATE_PRODUCT, {
        refetchQueries: [
            LIST_PRODUCTS,
            'Products'
        ],
    });


    const handleSubmit = useCallback(
        async (payload: ICreateProductFormData, { reset }) => {
            try {
                const variables: IUpdateProductVariables = {
                    input: {
                        productId: product.id,
                        name: payload.name,
                        brand: payload.brand,
                        category: payload.category,
                        class: payload.class,
                        description: payload.description,
                        comission_percentage: payload.comission_percentage,
                        manufacturer_sku_code: payload.manufacturer_sku_code,
                        maximum_stock: payload.maximum_stock,
                        minimum_stock: payload.minimum_stock,
                        packaging: payload.packaging,
                        stock_unit: payload.stock_unit,
                        quantity: payload.quantity,
                        cost_price: payload.cost_price,
                        selling_price: payload.selling_price
                    },
                }
                await updateProduct({
                    variables,
                });
                onClose();
                launchToast('Produto atualizado com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [updateProduct],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Editar Produto"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <IconButton
                    aria-label='Fechar modal'
                    icon={<CgClose />}
                    onClick={onClose}
                />
            </Styles.header>
            <Styles.FormDiv>
                <UpdateProductForm onSubmit={handleSubmit} product={product} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={UpdateProductFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    );
};

export default UpdateProductModal;