import { gql } from "@apollo/client";
import client from "services/api-graphql";
import IService from "types/IService";

const getServicesByCategory = async (category_id: string): Promise<IService[]> => {
  const response = await client.query({
    query: gql`
      query($filters: FiltersServiceInputType) {
        services(filters: $filters) {
          id
          name
        }
      }
    `,
    variables: {
      filters: {
        order: {
          by: 'name',
          direction: 'ASC'
        },
        where: {
          category_id: category_id
        }
      }
    }
  });

  return response.data.services;
};

export { getServicesByCategory };

