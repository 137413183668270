import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../components/Button";
import { Label } from "../../../components/Label/styles";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import Text from "../../../components/Text";
import StatusTransactionEnum from "../../../shared/enums/StatusTransactionEnum";
import colors from "../../../shared/utils/constants/colors";
import TypePaymentEnum from "../../../shared/utils/enums/TypePaymentEnum";
import { launchToast } from "../../../shared/utils/launchToast";
import processError from "../../../shared/utils/processError";
import IOrder from "../../../types/IOrder";
import * as Styles from "./styles";
import { toNumber } from "lodash";
import { useDisclosure } from "@chakra-ui/react";
import ConfirmationChoosePaymentModal from "../modals/ConfirmationChoosePaymentModal";
import TextArea from "components/TextArea";
import { getUserCards } from "../graphQL";
import { ChoosePaymentValidationsSchema } from "./ChoosePaymentValidations";
import getValidationErrors from "shared/utils/getValidationErrors";
import { ValidationError } from "yup";
import { ChoosePaymentCardValidationsSchema } from "./ChoosePaymentCardValidations";
import { ChoosePaymentReasonValidationsSchema } from "./ChoosePaymentReasonValidation";

interface FormData {
  paymentType: number
  orderId: string
  cardId?: string
  reason?: string
}

type IPropsOnSubmit = {
  paymentType: number
  orderId: string
  cardId?: string
  reason?: string
};

type IProps = {
  visible: boolean;
  order?: IOrder;
  onCloseModal: () => void;
  onSubmit: (payload: IPropsOnSubmit) => Promise<any>;
  paymentStatus: "W" | "U"
};

const ChoosePaymentPendingModal = ({
  visible,
  onCloseModal,
  order,
  onSubmit,
  paymentStatus,
}: IProps) => {

  const choosePaymentFormRef = useRef<FormHandles>(null);;
  const [loading, setLoading] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState([] as any);
  const [paymentSelectedError, setPaymentSelectedError] = useState("");
  const [paymentCardSelected, setPaymentCardSelected] = useState([] as any);
  const [paymentCardSelectedError, setPaymentCardSelectedError] = useState([] as any);
  const [PaymentReason, setPaymentReason] = useState([] as any);
  const [cardOptions, setCardOptions] = useState([] as any);


  const confirmationChooseModal = useDisclosure();

  const normalizationPayment = (paymentType: string) => {
    const normalizationPaymentType: Record<string, string> = {
        "card": "Cartão",
        "link": "Link",
        "pix": "Pix",
        "transferencia/pix avulso": "Transferencia/Pix avulso",
        "link avulso": "Link avulso",
        "gratuito": "Gratuito",
        "dinheiro": "Dinheiro"
    };
    return normalizationPaymentType[paymentType] || paymentType;
  };

  const paymentDescriptions = (paymentType: string) => {
    const description: Record<string, string> = {
        "card": "Solicitar pagamento por cartão asaas/pagseguro",
        "link": "Solicitar pagamento via link asaas, o cliente escolhe a forma de pagamento (pix, boleto, asaas)",
        "pix": "Solicitar pagamento via pix asaas",
        "transferencia/pix avulso": "Valor solicitado por outra plataforma e quero informar o pagamento ao backoffice",
        "link avulso": "Valor solicitado por link fora do asaas e quero informar o pagamento ao backoffice",
        "gratuito": "Nao quero solicitar o valor e avisar ao brackoffice que o serviço é gratuito",
        "dinheiro": "Valor pago em dinheiro e quero informar o pagamento ao backoffice"
    };
    return description[paymentType] || paymentType;
  };

  const paymentMethods = useMemo(() => {
    let paymentMethod = [];
    for (let k in TypePaymentEnum) {
      if (typeof TypePaymentEnum[k] === "number") break;
      if ([1, 2, 3, 4, 5, 7, 9].includes(Number(k))) {
        const label = normalizationPayment(TypePaymentEnum[k])
        const description = paymentDescriptions(TypePaymentEnum[k])
        paymentMethod.push({ value: k, label, description });
      }
    }
    return paymentMethod;
  }, []);

  useEffect(() => {
    setPaymentCardSelected([])
    setCardOptions([])
    const fetchUserCards = async () => {
      if (order?.customer.user.id) {
        try {
          const cards = await getUserCards(order?.customer.user.id);
          const formattedCards = cards.map((card) => ({
            value: card.id,
            label: `****.****.****.${card.last_digits} - ${card.exp_month}/${card.exp_year}`,
          }));
          setCardOptions(formattedCards);
        } catch (error) {
          launchToast("Erro ao buscar cartões", "error");
        }
      }
    };

    if(paymentSelected.value === '1'){
      fetchUserCards();
    }

  }, [order, paymentSelected]);

  useEffect(() => {
    if (!!order) {
      const { transactions } = order;
      const transaction = transactions.find(
        (t) => t.status === StatusTransactionEnum.waiting
      );
      let method = paymentMethods.find((p: any) => {
        if (!transaction) {
          return false;
        }
        return p.value === transaction.type;
      });

      if (method) {
        setPaymentSelected(method);
      }
    }
  }, [order, paymentMethods]);

  const handleSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      if (!!order) {
        const order_updated = await onSubmit({
          orderId: order.id,
          paymentType: Number(paymentSelected.value),
          cardId: paymentCardSelected.value,
          reason: PaymentReason
        });
      }
    } catch (error) {
      launchToast(processError(error, "GRAPHQL").message, "error");
    } finally {
      setLoading(false);
    }
  };

  const openConfirmationModal = async(data: FormData) => {
    try {
      await ChoosePaymentValidationsSchema.validate({...data, paymentMethod: paymentSelected.value}, { abortEarly: false });
      if (paymentSelected.value === '1' && !paymentCardSelected.value) {
        await ChoosePaymentCardValidationsSchema.validate({card: paymentCardSelected.value}, { abortEarly: false });
      }
      if (paymentStatus === 'U') {
        await ChoosePaymentReasonValidationsSchema.validate({...data}, { abortEarly: false });
      }
      setPaymentSelectedError("")
      setPaymentCardSelectedError("")
      choosePaymentFormRef.current?.setErrors({});
      confirmationChooseModal.onOpen();
    } catch (error) {
      const errors = getValidationErrors(error as ValidationError);
      choosePaymentFormRef.current?.setErrors(errors);
      if(!paymentSelected.label){setPaymentSelectedError("Selecione um metodo de pagamento")}
      if(!paymentCardSelected.label){setPaymentCardSelectedError("Selecione um cartão")}
      launchToast("Verifique o preenchimento dos dados", "error");
    }
  }

  const transctionsValue = Number(
    order?.transactions
      .reduce((total, transaction) => {
        if (
          [
            StatusTransactionEnum.waiting,
            StatusTransactionEnum.changes_waiting_action
          ].includes(transaction.status as any)
        ) {
          return total + transaction.amount / 100
        }
        return total
      }, Number(order.pending_price) || 0)
      .toFixed(2)
  )

  return (
    <>
      <Modal visible={visible} closeModal={() => onCloseModal()} width={850}>
        <ConfirmationChoosePaymentModal
          isOpen={confirmationChooseModal.isOpen}
          onClose={confirmationChooseModal.onClose}
          paymentMethod={`${paymentSelected.label}`}
          pendingPrice={ paymentStatus === 'U' ? (order?.pending_price || 0) : transctionsValue }
          handleConfirm={() => handleSubmit({
            orderId: order?.id!,
            paymentType: Number(paymentSelected.value),
            cardId: paymentCardSelected.value,
            reason: PaymentReason
          })}
          paymentCard={paymentCardSelected.label}
          paymentReason={paymentStatus == 'U' ? PaymentReason : "Alteração na forma de pagamento"}
          loading={true}
        />
        <Styles.Header>
          <Text
            text="Alterar forma de pagamento"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />
          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={() => onCloseModal()}
          />
        </Styles.Header>
        <Styles.line></Styles.line>
          <Styles.FormDiv>
          <Styles.row>
                <Text
                  text={ paymentStatus === 'U' ? (`Valor pendente: R$ ${toNumber(order?.pending_price).toFixed(2)}`).replace('.', ',') : (`Valor pendente: R$ ${(transctionsValue).toFixed(2)}`).replace('.', ',') }
                  color={colors.gray.dark01}
                  fontFamily="Open Sans"
                  size={16}
                  weight="600"
                />
          </Styles.row>
            <Form onSubmit={(openConfirmationModal)} ref={choosePaymentFormRef}>
              <Styles.row>
                <Styles.field>
                  <Label>Forma de pagamento</Label>
                  <Select
                    name="paymentMethod"
                    containerStyle={{ width: "50rem" }}
                    placeholder="Forma de pagamento"
                    options={paymentMethods}
                    value={paymentSelected}
                    onChange={(e: any) => setPaymentSelected(e)}
                    error={paymentSelectedError}
                  />
                </Styles.field>
                {
                  paymentSelected.value === '1' && cardOptions.length > 0 &&
                  <Styles.field>
                    <Label>Cartão</Label>
                    <Select
                      name="card"
                      containerStyle={{ width: "50rem" }}
                      placeholder="Cartão"
                      options={cardOptions}
                      value={paymentCardSelected}
                      onChange={(e: any) => setPaymentCardSelected(e)}
                      error={paymentCardSelectedError}
                    />
                  </Styles.field>
                }
              </Styles.row>
              { paymentSelected.description && 
                <Styles.row>
                  <Text
                    text={`${(paymentSelected.value === '1' && cardOptions.length <= 0) ? 'Cliente não tem cartão cadastrado' : paymentSelected.description}`}
                    color={(paymentSelected.value === '1' && cardOptions.length <= 0) ? colors.suport.alert : colors.gray.dark01}
                    fontFamily="Open Sans"
                    size={14}
                    weight="400"
                  />
                </Styles.row>
              }
              { paymentStatus === 'U' && <Styles.row>
                <Styles.field>
                  <Label>Motivo</Label>
                  <TextArea
                    name="reason"
                    placeholder="Digite o motivo da cobrança"
                    onChange={(e:any) => setPaymentReason(e.target.value)}
                  />
                </Styles.field>
              </Styles.row>}
              <Styles.rowButton>
                <Button text="Cancelar" onClick={() => onCloseModal()} />
                <Button
                  text="Salvar alteração"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  styleContainer={{ minWith: "100px" }}
                />
              </Styles.rowButton>
            </Form>
          </Styles.FormDiv>
      </Modal>
    </>
  );
};
export default ChoosePaymentPendingModal;
