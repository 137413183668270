import React, { useEffect, useRef, useState } from "react";

import Text from "../../../../components/Text";
import { Form } from "@unform/web";
import InputRef from "../../../../components/InputRef";
import Button from "../../../../components/Button";
import colors from "../../../../shared/utils/constants/colors";
import * as Yup from "yup";

import * as Styles from "./styles";
import { FormHandles } from "@unform/core";
import Modal from "../../../../components/Modal";
import getValidationErrors from "../../../../shared/utils/getValidationErrors";
import formatDate from "../../../../shared/utils/formatDate";
import { Label } from "../../../../components/Label/styles";
import Select from "../../../../components/Select";
import AppointmentStatusEnum from "../../../../shared/utils/enums/AppointmentStatusEnum";
import stringOfEnum from "../../../../shared/utils/StringOfEnum";

interface FilterFormData {
  status: string;
  neighborhood: string;
  initialDate: string;
  finalDate: string;
}

/*const ZONES = [
  { value: "Dentro", label: "Dentro" },
  { value: "Fora", label: "Fora" },
];*/

const FilterModal = ({
  visible,
  onCloseModal,
  applyFilters,
  reciviedFilter,
}: any) => {
  const [status, setStatus] = useState([] as any);

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    let status = [];
    for (let k in AppointmentStatusEnum) {
      if (typeof AppointmentStatusEnum[k] === "string") {
        status.push({ value: k, label: AppointmentStatusEnum[k] });
      }
    }
    setStatus(status);
  }, []);

  useEffect(() => {
    let i;

    i = reciviedFilter.findIndex((r: any) => r.key === "Bairro");
    if (i < 0) formRef.current?.setFieldValue("neighborhood", "");

    // i = reciviedFilter.findIndex((r: any) => r.key === "Zona");
    // if (i < 0) formRef.current?.setFieldValue("zone", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Data Início");
    if (i < 0) formRef.current?.setFieldValue("initialDate", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Data Fim");
    if (i < 0) formRef.current?.setFieldValue("finalDate", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Status");
    if (i < 0) formRef.current?.setFieldValue("status", "");
  }, [reciviedFilter]);

  const handleSubmit = async (data: FilterFormData) => {
    const { neighborhood, initialDate, finalDate, status } = data;
    try {
      formRef.current?.setErrors({});

      if (initialDate !== "" && finalDate === "") {
        formRef.current?.setErrors({ finalDate: "Informar data final" });
        return false;
      }

      if (initialDate === "" && finalDate !== "") {
        formRef.current?.setErrors({ initialDate: "Informar data inicial" });
        return false;
      }

      if (initialDate !== "") {
        const schema = Yup.object().shape({
          initialDate: Yup.date(),
          finalDate: Yup.date().min(
            Yup.ref("initialDate"),
            "Data final deve ser maior que a incial"
          ),
        });
        await schema.validate(data, { abortEarly: false });
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);

      return false;
    }
    let filtersToSend = [];
    if (neighborhood !== "")
      filtersToSend.push({
        key: "Bairro",
        value: neighborhood,
        searchValue: neighborhood,
      });

    if (initialDate !== "")
      filtersToSend.push({
        key: "Data Início",
        value: formatDate(initialDate),
        searchValue: initialDate,
      });

    if (finalDate !== "")
      filtersToSend.push({
        key: "Data Fim",
        value: formatDate(finalDate),
        searchValue: finalDate,
      });

    if (status !== "") {
      filtersToSend.push({
        key: "Status",
        value: stringOfEnum(AppointmentStatusEnum, parseInt(status)),
        searchValue: status,
      });
    }

    applyFilters(filtersToSend);
    onCloseModal();
  };

  const clearFilters = () => {
    formRef.current?.reset();
    applyFilters([]);
  };

  return (
    <>
      <Modal visible={visible} closeModal={() => onCloseModal()}>
        <Styles.header>
          <Text
            text="Filtrar agendamentos"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={() => onCloseModal()}
          />
        </Styles.header>

        <Styles.line></Styles.line>

        <Styles.FormDiv>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Styles.row>
              {/*       <Styles.field>
                <Label>zona</Label>
                <Select
                  name="zone"
                  style={{ width: "54rem" }}
                  placeholder="zona"
                  options={ZONES}
                />
       </Styles.field> */}

              <Styles.field>
                <Label>bairro</Label>
                <InputRef
                  placeholder="Digite o bairro"
                  name="neighborhood"
                  type="text"
                />
              </Styles.field>

              <Styles.field>
                <Label>status</Label>
                <Select
                  name="status"
                  containerStyle={{ width: "44rem" }}
                  placeholder="Status"
                  options={status}
                />
              </Styles.field>
            </Styles.row>

            <Styles.row>
              <Styles.field>
                <Label>data início (agendamento)</Label>
                <InputRef name="initialDate" type="date" />
              </Styles.field>

              <Styles.field>
                <Label>data fim (agendamento)</Label>
                <InputRef name="finalDate" type="date" />
              </Styles.field>
            </Styles.row>

            <Styles.rowButton>
              <Styles.WhiteButton onClick={clearFilters}>
                Limpar
              </Styles.WhiteButton>
              <Button
                text="Aplicar"
                type="submit"
                styleContainer={{ minWith: "100px" }}
              />
            </Styles.rowButton>
          </Form>
        </Styles.FormDiv>
      </Modal>
    </>
  );
};
export default FilterModal;
