const formatDate = (date: string | Date | undefined) => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    const dateSplited = date.slice(0, 10).split('-');
    return `${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]}`;
  }

  if (typeof date === 'object') {
    const dateSplited = date.toISOString().slice(0, 10).split('-');
    return `${dateSplited[2]}/${dateSplited[1]}/${dateSplited[0]}`;
  }

  return '';
};

export default formatDate;
