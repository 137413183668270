import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import { UpdateCepSectionFormValidationSchema } from './updateCepSectionFormValidations';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import Select from 'components/Select';
import {  ISectorCepsCategories, IUpdateSectionFormData } from 'pages/Ceps/constants/types';
import { getRegions } from 'pages/Zones/graphQL';
import CreateRegionModal from 'pages/Ceps/modals/CreateRegionModal';
import { useQuery } from '@apollo/client';
import { GET_REGIONS } from 'pages/Ceps/graphql/CepsCategoriesQuery';
import CreateRegionWhenEditModal from 'pages/Ceps/modals/CreateRegionModalWhenEdit';

export type IUpdateCepSectionFormProps = {
    onSubmit: (payload: IUpdateSectionFormData, options: { reset: () => void }) => Promise<void>;
    section: ISectorCepsCategories
    createRegionModalControl: {
        isOpen: boolean;
        onOpen: () => void;
        onClose: () => void;
        onToggle: () => void;
        isControlled: boolean;
        getButtonProps: (props?: any) => any;
        getDisclosureProps: (props?: any) => any;
    }
}

export const UpdateCepSectionFormId = 'update-cep-section-form';

const UpdateCepSectionForm: React.FC<IUpdateCepSectionFormProps> = ({
    onSubmit,
    section,
    createRegionModalControl
}) => {

    const [selectedRegion, setSelectedRegion] = useState([] as any)
    const [regionError, setRegionError] = useState("");
    const [regions, setRegions] = useState([] as any[]);

    const updateCepSectionFormRef = useRef<FormHandles>(null);

    const { data, loading, refetch } = useQuery(GET_REGIONS);

    const fetchDataAndUpdateState = async (
        dataList: [],
        setStateFunction: (data: any) => void
      ) => {
          const results = dataList
          setStateFunction(results.map( (option: {name: string, id: string}) => ({label: option.name, id: option.id})));
      };

    useEffect(() => {
            fetchDataAndUpdateState(data.regions, setRegions)
      }, [data]);

    useEffect(() => {
        if(selectedRegion.label){setRegionError("")}
    }, [selectedRegion]);

    useEffect(() => {
        updateCepSectionFormRef.current?.setErrors({});
        updateCepSectionFormRef.current?.setData({
            start_cep: section.start_cep,
            end_cep: section.end_cep,
        });
        setSelectedRegion({ label: section.region_name, id: section.region_id })
    }, [section]);

    const validateFields = async (
        payload: IUpdateSectionFormData,
        options: { reset: () => void }
    ) => {
        try {
            await UpdateCepSectionFormValidationSchema.validate({ start_cep: `${payload.start_cep}`, end_cep: `${payload.end_cep}`, region_id: selectedRegion.id}, { abortEarly: false });
            onSubmit({ start_cep: `${payload.start_cep}`, end_cep: `${payload.end_cep}`, region_id: selectedRegion.id}, options);
            setSelectedRegion([] as any)
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            updateCepSectionFormRef.current?.setErrors(errors);
            if(!selectedRegion.label){setRegionError("Selecione uma região")}
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form onSubmit={validateFields} ref={updateCepSectionFormRef} id={UpdateCepSectionFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Cep Inicial</Label>
                        <InputRef
                            name="start_cep"
                            type='number'
                            placeholder="Digite o cep inicial"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Cep Final</Label>
                        <InputRef
                            name="end_cep"
                            type='number'
                            placeholder="Digite o cep final"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Região</Label>
                        <Select
                            name="region_id"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a região"
                            options={regions}
                            error={regionError}
                            value={selectedRegion}
                            onChange={(e: any) => setSelectedRegion(e)}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
            <CreateRegionWhenEditModal
                isOpen={createRegionModalControl.isOpen}
                onClose={createRegionModalControl.onClose}
                refetch={refetch}
            />
        </>
    )
}

export default UpdateCepSectionForm