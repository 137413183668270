import * as Yup from "yup";

export const RegisterProductFormValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Nome é obrigatório')
        .max(255, 'O nome pode ter no máximo 255 caracteres'),
    brand: Yup.string()
        .required('Marca é obrigatório')
        .max(255, 'A marca pode ter no máximo 255 caracteres'),
    stock_unit: Yup.string()
        .required('Unidade de medida é obrigatório')
        .max(255, 'A Unidade de medida pode ter no máximo 255 caracteres'),
    description: Yup.string()
        .required('Descrição  é obrigatória')
        .max(1000, 'A descrição pode ter no máximo 1000 caracteres'),
    class: Yup.string()
        .required('Classe é obrigatório')
        .max(255, 'A classe pode ter no máximo 255 caracteres'),
    manufacturer_sku_code: Yup.string()
        .required('SKU é orbigatório')
        .max(255, 'O SKU pode ter no máximo 255 caracteres'),
    quantity: Yup.number()
        .required('Quantidade é orbigatória')
        .min(0, 'A quantidade tem que ser maior ou igual a 0'),
    minimum_stock: Yup.number()
        .required('Estoque mínimo é orbigatório')
        .min(0, 'O estoque mínimo tem que ser maior ou igual a 0'),
    maximum_stock: Yup.number()
        .required('Estoque máximo é orbigatório')
        .min(0, 'O estoque máximo tem que ser maior ou igual a 0'),
    comission_percentage: Yup.number()
        .required('Comissão é orbigatório')
        .min(0, 'a comissão tem que ser maior ou igual a 0'),
    category: Yup.string()
        .required('Categoria é obrigatório')
        .max(255, 'A categoria pode ter no máximo 255 caracteres'),
    packaging: Yup.string()
        .required('Embalagem é obrigatório')
        .max(255, 'A embalagem pode ter no máximo 255 caracteres'),
    cost_price: Yup.string()
        .required('Valor de compra é obrigatório')
        .min(0, 'Valor de compra não pode ser negativo'),
    selling_price: Yup.string()
        .required('Valor de venda é obrigatório')
        .min(0, 'Valor de venda não pode ser negativo'),
})