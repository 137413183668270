import React, { useEffect, useMemo, useState } from "react";
import {
  Column,
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import * as Styles from "./styles";
import colors from "../../shared/utils/constants/colors";

import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { ReactComponent as Active } from "../../assets/svg/generic/active.svg";
import { ReactComponent as HomeButton } from "../../assets/svg/generic/homebutton.svg";
import { ReactComponent as Shield } from "../../assets/svg/generic/shield.svg";
import { ReactComponent as Sortneutral } from "../../assets/svg/generic/sortneutral.svg";
import { ReactComponent as Ascending } from "../../assets/svg/generic/ascending.svg";
import { ReactComponent as Descending } from "../../assets/svg/generic/descending.svg";

import GlobalFilter from "../../components/GlobalFilter";
import Pagination from "../../components/Pagination";
import TotalPerPage from "../../components/TotalPerPage";

import Text from "../../components/Text";
import Loading from "../../components/Loading";

interface IData {
  id: string;
  name: string;
  permissions: string;
  status: string;
}

const ProfilePanel: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  // const [isFilterModalVisible, setisFilterModalVisible] = useState(false);

  const columns = useMemo<Column<IData>[]>(
    () => [
      {
        Header: "ID do agendamento",
        accessor: "id",
      },
      {
        Header: "nome do perfil",
        accessor: "name",
      },
      {
        Header: "permissões",
        accessor: "permissions",
      },
      {
        Header: "status",
        accessor: "status",
      },
    ],
    []
  );

  useEffect(() => {
    setData([]);
    setLoading(false);
  }, []);

  const tableHooks = (hooks: any) => {
    hooks.visibleColumns.push((columns: any) => [
      ...columns,
      {
        id: "acoes",
        Header: "Ações",
        Cell: ({ row }: any) => (
          <Styles.ActionsButtons>
            <Styles.ActionButton
              onClick={() => alert("ativando/desativando " + row.values.id)}
            >
              <Active />
            </Styles.ActionButton>
            <Styles.ActionButton
              onClick={() => alert("Editando " + row.values.id)}
            >
              <EditButton />
            </Styles.ActionButton>
            <Styles.ActionButton
              onClick={() => alert("alterando permissões " + row.values.id)}
            >
              <Shield />
            </Styles.ActionButton>
          </Styles.ActionsButtons>
        ),
      },
    ]);
  };

  const tableInstantece = useTable(
    { columns, data, initialState: { hiddenColumns: ["id"] } },
    useGlobalFilter,
    tableHooks,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    pageOptions,
    canNextPage,
    canPreviousPage,
    gotoPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    setPageSize,
    state,
  } = tableInstantece;

  const onSetPageSize = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const onSetPage = (page: number) => {
    gotoPage(page - 1);
  };

  return (
    <>
      {loading && <Loading></Loading>}
      <Styles.BackgroundPanel>
        <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Modo de segurança"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
            <HomeButton />
            <Text
              text="Perfis de usuário"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={14}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>
          {/*    <Styles.WhiteButton onClick={() => setisFilterModalVisible(true)}>
            Filtrar
  </Styles.WhiteButton> */}
        </Styles.PanelHeader>

        <Styles.TablePanel>
          <Styles.FilterDisplay>
            <Text
              text="Perfis de usuário"
              color={colors.argon.textColorDark}
              fontFamily="Open Sans"
              size={17}
              weight="600"
              marginRight={4}
              marginTop={0}
              align="left"
            />
            <Text
              text="Crie ou edite perfis de usuário e altere as permissões concedidas para cada um"
              color={colors.argon.textColorDark}
              fontFamily="Open Sans"
              size={14}
              weight="400"
              marginRight={4}
              marginTop={2}
              align="left"
            />
          </Styles.FilterDisplay>
          <Styles.SeachDisplay>
            <TotalPerPage
              pageSize={state.pageSize}
              setPageSize={onSetPageSize}
            />

            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              setGlobalFilter={setGlobalFilter}
              globalFilter={state.globalFilter}
            />
          </Styles.SeachDisplay>
          <Styles.Table {...getTableProps()}>
            <Styles.TableHead>
              {headerGroups.map((headerGroup) => (
                <Styles.TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Styles.TableHeader
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Styles.HeaderItens>
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Descending />
                          ) : (
                            <Ascending />
                          )
                        ) : (
                          <Sortneutral />
                        )}
                      </Styles.HeaderItens>
                    </Styles.TableHeader>
                  ))}
                </Styles.TableRow>
              ))}
            </Styles.TableHead>
            <Styles.TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Styles.TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <Styles.TableData {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </Styles.TableData>
                      );
                    })}
                  </Styles.TableRow>
                );
              })}
            </Styles.TableBody>
          </Styles.Table>

          <Pagination
            totalPage={pageOptions.length}
            canPreviousPage={canPreviousPage}
            previousPage={() => previousPage()}
            nextPage={() => nextPage()}
            canNextPage={canNextPage}
            pageIndex={state.pageIndex}
            setPage={onSetPage}
          />
        </Styles.TablePanel>
      </Styles.BackgroundPanel>
    </>
  );
};
export default ProfilePanel;
