import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import { IBalanceCategoryWallet, IEditBalanceWalletInput } from '../../types';
import { EditBalanceWalletFormValidations } from './EditBalanceWalletFormValidations';

export type IEditBalanceWalletPayload = {
    alteredReason: string;
    amount: string;
    action: { label: string, value: string };
};

export type IEditBalanceWalletFormProps = {
    onSubmit: (payload: IEditBalanceWalletInput, options: { reset: () => void }) => Promise<void>;
    categoryBalance: IBalanceCategoryWallet;
    walletId: number
    isLoading: boolean
}

export const EditBalanceWalletFormId = 'edit-balance-wallet-form';

const EditBalanceWalletForm: React.FC<IEditBalanceWalletFormProps> = ({
    onSubmit,
    categoryBalance,
    walletId,
    isLoading
}) => {

    const [selectedAction, setSelectedAction] = useState([] as any)
    const [actionError, setActionError] = useState("");
    const [amount, setAmount] = useState("");
    const editBalanceWalletFormRef = useRef<FormHandles>(null);

    useEffect(() => {
        setAmount("R$ 0,00")
        editBalanceWalletFormRef.current?.setErrors({})
        setSelectedAction([] as any)
        setActionError("")
    }, [categoryBalance])

    const priceMask = (e: any) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(Number(numericValue) / 100);
        return formattedValue
    }

    const priceUnmask = (value: string) => {
        const formattedValue = value?.replace('R$', '').replace(/\./g, '').replace(',', '');;
        const numberFloat = parseFloat(formattedValue);
        return numberFloat
    }

    const selectTypeOptions = [{ label: 'Adicionar', value: "add" }, { label: 'Retirar', value: "remove" }]

    const resetForm = () => {
        // editBalanceWalletFormRef.current?.setData({
        //   alteredReason: '',
        // });
        // setActionError("")
        editBalanceWalletFormRef.current?.setErrors({})
        // setAmount("R$ 0,00")
        // setSelectedAction([] as any)
    }

    const validateFields = async (
        payload: IEditBalanceWalletPayload,
        options: { reset: () => void }
    ) => {
        try {
            await EditBalanceWalletFormValidations.validate({ ...payload, amount: priceUnmask(amount) }, { abortEarly: false });
            onSubmit({ [`${categoryBalance.category}`]: (selectedAction.value === 'add' ? priceUnmask(amount) : -priceUnmask(amount)), walletId, alteredReason: payload.alteredReason }, options);
            resetForm()
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            editBalanceWalletFormRef.current?.setErrors(errors);
            if (!selectedAction.label) { setActionError("Selecione um produto") }
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form onSubmit={validateFields} ref={editBalanceWalletFormRef} id={EditBalanceWalletFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Ação</Label>
                        <Select
                            name="action"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a ação"
                            options={selectTypeOptions}
                            value={selectedAction}
                            error={actionError}
                            onChange={(e: any) => setSelectedAction(e)}
                            isDisabled={isLoading}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Valor</Label>
                        <InputRef
                            name="amount"
                            placeholder="Digite o valor"
                            containerStyle={{ width: "33rem" }}
                            value={amount}
                            onChange={(e: any) => setAmount(priceMask(e))}
                            disabled={isLoading}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Motivo</Label>
                        <TextArea
                            containerStyle={{ width: "40rem" }}
                            name="alteredReason"
                            placeholder="Digite o motivo da ação"
                            disabled={isLoading}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default EditBalanceWalletForm