import { gql } from "@apollo/client";
import client from "services/api-graphql";

export const CREATE_MOVIMENT = gql`
 mutation StockAction($input: [StockActionInputType!]!) {
  stockAction(input: $input) {
  data  
  }
}
`;

export const LIST_MOVIMENT = gql`
  query StockActionLogs($input: ListStockActionLogsInputType!) {
    stockActionLogs(input: $input) {
      totalPages
      logs {
      product {
        name
        id
        stock_unit
      }
      id
      action_type
      recipient
      quantity
      created_at
      moved_by
      professional {
        id
        name
      }
      truck {
        id
        chasi_number
      }
    }}
  }
`;

export const getUser = async (id: string) => {
  const variables ={
    getUserId: id
  } 
  const { data } = await client.query({
      query: gql`
        query GetUser($getUserId: String!) {
          getUser(id: $getUserId) {
            name
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.getUser
};

export const logsMoviments = async () => {
  const variables ={
    input: {
      from: '1994-03-20',
      to: `${new Date().toISOString().split('T')[0]}`
    }
  } 
  const { data } = await client.query({
      query: gql`
        query ProductActionsReport($input: ProductActionReportInputType!) {
          productActionsReport(input: $input) {
            report {
              costPrice
              brand
              createdAt
              product
              professional
              quantity
              salePrice
              stockQuantity
              totalPrice
              truck
              movedBy
              reason
            }
          }
        }
      `,
  variables,
  fetchPolicy: 'network-only',
});
  return data.productActionsReport.report
};

export const listOptionProducts = async (input: string) => {
  const variables = {
      filters: {
        pagination: {
          limit: 6,
          page: 1
        },
        name: input
      }
  }; 
  const { data } = await client.query({
    query: gql`
      query Products($filters: ListProductsInputType) {
        products(filters: $filters) {
          products {
            name,
            quantity,
            id,
            stock_unit
          }
        }
      }
    `,
    variables,
    fetchPolicy: 'network-only',
});
  return data.products.products
};

export const listOptionProfessionals = async (input: string) => {
  const variables = {
    filters: {
      pagination: {
        limit: 6,
        page: 1,
      },
      where: {
        status: true,
        name: input,
      },
    },
  };

  try {
    const { data } = await client.query({
      query: gql`
        query GetProfessionals($filters: FiltersProfessionalInputType) {
          professionals(filters: $filters) {
            user {
              name
              id
              email
            }
            products {
              id
              quantity
            }
          }
        }
      `,
      variables,
      fetchPolicy: 'network-only',
    });
    return data.professionals
  } catch (error) {
    console.error('Error fetching professionals:', error);
  }
};

export const listOptionTrucks = async (input: string) => {
  const variables = {
    filters: {
      pagination: {
        limit: 6,
        page: 1,
      },
      chasi_number: input
    },
  };
  try {
    const { data } = await client.query({
      query: gql`
        query Trucks($filters: ListTrucksInputType) {
          trucks(filters: $filters) {
          id
          chasi_number
          products {
            id
            quantity
          }  
        }
      }
      `,
      variables,
      fetchPolicy: 'network-only',
    });
    return(data.trucks);
  } catch (error) {
    console.error('Error fetching truck:', error);
  }
};

export const getProduct = async (input: number) => {
  const variables = {
    productId: input
  }
  try {
    const { data } = await client.query({
      query: gql`
        query Query($productId: Float!) {
          product(productId: $productId) {
            quantity
            id
            name
          }
        }
      `,
      variables,
      fetchPolicy: 'network-only',
    });
    return(data.product);
  } catch (error) {
    console.error('Error fetching truck:', error);
  }
};
