import colors from "shared/utils/constants/colors";
import styled from "styled-components";

export const header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const rowButton = styled.div <{ margin?: string }>`
display: flex;
justify-content:space-between;
margin-top: 24px;

@media (min-width: 650px) {
  flex-direction: row;
  align-items: flex-end ;
  width: 30%;
  margin-left:${props => `${props?.margin}%` || "70%"};
}
`;

export const line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.primary.light};
  margin-bottom: 24px;
  position: relative;
  top: -1px;
`;
