import React from "react";
import Text from "../../components/Text";
import colors from "../../shared/utils/constants/colors";
import * as Styles from "./styles";

const range = [5, 10, 15, 20];

function TotalPerPage({ pageSize, setPageSize }: any) {
  return (
    <Styles.TotalConatiner>
      <Text
        text="Mostrar"
        color={colors.argon.textColorDark}
        fontFamily="Open Sans"
        size={14}
        weight="400"
        marginRight={4}
        marginTop={0}
      />
      <Styles.TotalSelector
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        {range.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </Styles.TotalSelector>
      <Text
        text="registros"
        color={colors.argon.textColorDark}
        fontFamily="Open Sans"
        size={14}
        weight="400"
        marginRight={4}
        marginTop={0}
      />
    </Styles.TotalConatiner>
  );
}

export default TotalPerPage;
