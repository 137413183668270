const parameters = {
  cachorro: {
    weights: [
      { size: 1, min: 0, max: 5 },
      { size: 2, min: 5, max: 10 },
      { size: 3, min: 10, max: 20 },
      { size: 4, min: 20, max: 30 },
      { size: 5, min: 30, max: 40 },
      { size: 6, min: 40, max: 100 },
    ],
  },
  gato: {
    weights: [
      { size: 1, min: 0, max: 4 },
      { size: 2, min: 4, max: 7 },
      { size: 3, min: 7, max: 50 },
    ],
  },
};

export default parameters;
