import { useQuery } from "@apollo/client";
import MainContainer from "components/MainContainer"
import { PanelHeader } from "components/PanelHeader"
import React, { useCallback, useEffect, useState } from "react"
import {  LIST_SECTIONS_CEP_CATEGORIES } from "./graphql/CepsCategoriesQuery";
import Search from "components/Search";
import Table from "components/Table";
import Pagination from "components/Pagination";
import { columnCepsCategoriesSectionHeaders } from "./constants/conlumns";
import { ISectorCepsCategories } from "./constants/types";
import { ReactComponent as EditButton } from "../../assets/svg/generic/edit.svg";
import { ReactComponent as ReportButton } from "../../assets/svg/generic/searchicon.svg";
import { useDisclosure } from "@chakra-ui/react";
import ShowCepsModal from "./modals/showCepsModal";
import CreateSectionModal from "./modals/CreateSectionModal";
import UpdateSectionModal from "./modals/UpdateSectionModal ";

const Ceps: React.FC = () => {

    const [paginationLimit, setPaginationLimit] = useState<number>(10);
    const [searchInput, setSearchInput] = useState<string>("");
    const [sectionSelected, setSectionSelected] = useState<ISectorCepsCategories | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const showCepsModalControl = useDisclosure();
    const createSectionModalControl = useDisclosure();
    const updateCepSectionModalControl = useDisclosure();

    const handleWordSearch = (input: string) => {
        setCurrentPage(1)
        setSearchInput(input)
    };


    const handleSectionShowCeps = useCallback(
        async (section: ISectorCepsCategories) => {
            setSectionSelected(section);
            showCepsModalControl.onOpen();
        },
        [showCepsModalControl]
    )

    const handleCepSectionUpdate = useCallback(
        async (section: ISectorCepsCategories) => {
            setSectionSelected(section);
            updateCepSectionModalControl.onOpen();
        },
        [updateCepSectionModalControl]
    )

    const { data, loading, refetch} = useQuery(LIST_SECTIONS_CEP_CATEGORIES, {
        variables: {
            input: {
                search: searchInput,
                limit: paginationLimit,
                page: currentPage,
            },
        },
    });

    useEffect(() => {
        refetch({
            input: {
                search: searchInput,
                limit: paginationLimit,
                page: currentPage,
            },
        });
    }, [paginationLimit, currentPage])


    return (
        <>
            <CreateSectionModal
                isOpen={createSectionModalControl.isOpen}
                onClose={createSectionModalControl.onClose}
            />
            {sectionSelected && <UpdateSectionModal
                isOpen={updateCepSectionModalControl.isOpen}
                onClose={updateCepSectionModalControl.onClose}
                section={sectionSelected}
            />}
            {sectionSelected && (
                <ShowCepsModal
                    isOpen={showCepsModalControl.isOpen}
                    onClose={showCepsModalControl.onClose}
                    section={sectionSelected}
                    setSectionSelected={setSectionSelected}
                    refetchSections={refetch}
                />
            )}
            <MainContainer>
                <PanelHeader
                    title="Setores"
                    isNew
                    isFilterModalVisible={false}
                    onClick={createSectionModalControl.onOpen}
                />
                <Search
                    wordSearch={searchInput}
                    setSearch={handleWordSearch}
                    isFilterForRegister
                    limit={paginationLimit}
                    onSetPageSize={(limit) => {
                        setCurrentPage(1)
                        setPaginationLimit(limit)
                    }}
                    placeholder="Pesquise por setor ou região"
                />
                 {   data?.listCepsCategoriesSections && (
                        <Table
                            headers={columnCepsCategoriesSectionHeaders}
                            data={data.listCepsCategoriesSections.data.map((sectionCepCategories: ISectorCepsCategories) => ({
                                start_cep: sectionCepCategories.start_cep,
                                end_cep: sectionCepCategories.end_cep,
                                region_name: sectionCepCategories.region_name,
                                actions: [
                                    {
                                        name: "Relatoirio",
                                        icon: <ReportButton />,
                                        action: () => handleSectionShowCeps(sectionCepCategories),
                                    },
                                    {
                                        name: "Editar",
                                        icon: <EditButton />,
                                        action: () => handleCepSectionUpdate(sectionCepCategories),
                                    },
                                ],
                            }))}
                        />
                    )
                }
                {
                     data?.listCepsCategoriesSections  && (
                        <Pagination
                            totalPage={data.listCepsCategoriesSections.totalPages}
                            pageIndex={currentPage}
                            setPage={setCurrentPage}
                        />
                    )
                }
            </MainContainer>
        </>
    )
}

export default Ceps