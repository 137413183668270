import Modal from 'components/Modal';
import React, { useCallback, useState, useEffect } from 'react';
import * as Styles from '../constants/styles';
import Button from "components/Button";
import { ICampaign, IUpdateCampaignFormData, IUpdateCampaignVariables } from 'pages/Campaigns/constants/types';
import { useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import { LIST_CAMPAIGNS, UPDATE_CAMPAIGN } from 'pages/Campaigns/graphql/CampaignQuery';
import UpdateCampaignForm, { UpdateCampaignFormId } from '../forms/UpdateCampaing/updateCampaignForm';

export type IUpdateCampaignModalProps = {
    isOpen: boolean;
    onClose: () => void;
    campaign: ICampaign;
};

const UpdateCampaignModal = ({
    isOpen,
    onClose,
    campaign,
}: IUpdateCampaignModalProps) => {
    const [updateCampaign, { loading }] = useMutation(UPDATE_CAMPAIGN, {
        refetchQueries: [
            LIST_CAMPAIGNS,
            'ListCampaigns'
        ],
    });


    const handleSubmit = useCallback(
        async (payload: IUpdateCampaignFormData, { reset }) => {
            try {
                const variables: IUpdateCampaignVariables = {
                    input: {
                        name: payload.name,
                        type: payload.type,
                        description: payload.description,
                        start_date: payload.start_date,
                        end_date: payload.end_date,
                        is_active: payload.is_active === 'Sim',
                    },
                    id: payload.id
                }
                await updateCampaign({
                    variables,
                });
                onClose();
                launchToast('Campanha atualizada com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [updateCampaign],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.FormDiv>
                <UpdateCampaignForm onSubmit={handleSubmit} campaign={campaign} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={UpdateCampaignFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    );
};

export default UpdateCampaignModal;