import styled, { css } from 'styled-components';

import colors from '../../shared/utils/constants/colors';

interface ITextProps {
  color?: string;
  size?: number;
  align?: 'center' | 'left' | 'right';
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  margin?: number;
  underline?: boolean;
  weight?: '600' | '400';
  fontFamily?: string;
  whiteSpace?: string;
  ellipsis?: boolean;
  clickable?: boolean;
}

export const TextStyled = styled.p<ITextProps>`
  /* font-weight: ${(props) => (props.weight === '600' ? 'OpenSans_600SemiBold' : 'OpenSans_400Regular')}; */
  font-weight: ${({ weight }: any) => weight || 400};
  font-family: ${({ fontFamily }: any) => fontFamily || 'OpenSans_400Regular'};
  color: ${({ color }: any) => color || colors.gray.dark01};
  font-size: ${({ size = 16 }: any) => size}px;
  text-align: ${({ align = 'center' }: any) => align};
  margin: ${({ margin = 0 }: any) => margin}px;
  line-height: ${({ size = 16 }: any) => size * 1.3}px;
  margin-bottom: ${({ marginBottom = 0 }: any) => marginBottom}px;
  margin-top: ${({ marginTop = 0 }: any) => marginTop}px;
  margin-left: ${({ marginLeft = 0 }: any) => marginLeft}px;
  margin-right: ${({ marginRight = 0 }: any) => marginRight}px;
  text-decoration: ${({ underline }: any) => (underline ? 'underline' : 'none')};
  white-space: ${({ whiteSpace }: any) => (whiteSpace ? whiteSpace : 'normal')};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  
  ${(props) => props.ellipsis && css`
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  `};
`;
