import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../../UserModal/styles';
import { Label } from 'components/Label/styles';
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import Select from 'components/Select';
import TextArea from 'components/TextArea';
import { NewUserObservationFormValidations } from './newUserObservationFormValidations';

export type INewUserObservationPayload = {
    userOrPetId: string,
    observation: string,
    showToCustomer: boolean,
    observationTo: string
};

export type INewCustomerObservationFormProps = {
    onSubmit: (payload: INewUserObservationPayload, options: { reset: () => void }) => Promise<void>;
    userId: string
}

export const newCustomerObservationFormId = 'new-customer-observation-form';

const NewUserObservationForm: React.FC<INewCustomerObservationFormProps> = ({
    onSubmit,
    userId
}) => {

    const [showToCustomer, setShowToCustomer] = useState([] as any)
    const [showToCustomerError, setShowToCustomerError] = useState("");

    const newObservatiionFormRef = useRef<FormHandles>(null);

    const showToCustomerOptions = [{label: "Mostrar", value: true}, {label: "Não mostrar", value: false}]

    useEffect(() => {
        newObservatiionFormRef.current?.setErrors({})
        setShowToCustomer([] as any)
        setShowToCustomerError("")
    }, [userId])

    const resetForm = () => {
        newObservatiionFormRef.current?.setData({
            observation: '',
        });
        setShowToCustomerError("")
        newObservatiionFormRef.current?.setErrors({})
        setShowToCustomer([] as any)
      }

    const validateFields = async (
        payload: INewUserObservationPayload,
        options: { reset: () => void }
    ) => {
        try {
            await NewUserObservationFormValidations.validate({ ...payload}, { abortEarly: false });
            onSubmit({...payload, userOrPetId: userId, observationTo: "customer"}, options);
            resetForm()
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            newObservatiionFormRef.current?.setErrors(errors);
            if(!showToCustomer.label){setShowToCustomerError("Selecione uma opção")}
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form onSubmit={validateFields} ref={newObservatiionFormRef} id={newCustomerObservationFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Exibir para o cliente</Label>
                        <Select
                            name="showToCustomer"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Selecione a ação"
                            options={showToCustomerOptions}
                            value={showToCustomer}
                            error={showToCustomerError}
                            onChange={(e: any) => setShowToCustomer(e)}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Observação</Label>
                        <TextArea
                            containerStyle={{ width: "40rem" }}
                            name="observation"
                            placeholder="Digite a observação"
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default NewUserObservationForm