import styled from 'styled-components';
import colors from '../../shared/utils/constants/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  input:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  height: 4rem;
  appearance: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0.7rem;
  
  background: ${(props) => (props.checked ? colors.primary.default : colors.gray.white)};
  color: ${(props) => (props.checked ? colors.gray.white : colors.gray.dark02)};
  border: 2px solid
    ${(props) => (props.checked ? colors.primary.default : colors.gray.light02)};
  font-weight: ${(props) => (props.checked ? 'bold' : 'normal')};

  font-family: 'Open Sans';
  font-size: 1.6rem;
  place-content: center;

  @media (max-width: 500px) {
    height: 6rem;
  }
`;
