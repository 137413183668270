enum PlatformEnum {
  default = 0,
  admin = 1,
  site = 2,
  mobile = 3,
  landpage = 4,
  job = 5,
  auto_renew = 6,
};

export default PlatformEnum;
