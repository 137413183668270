import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaShippingFast,
  FaHandsHelping,
  FaBoxOpen,
} from "react-icons/fa";

import { ReactComponent as Calendar } from "../../../../assets/svg/sidebar/calendar.svg";
import { ReactComponent as CreditCard } from "../../../../assets/svg/sidebar/creditcard.svg";
import { ReactComponent as Cupom } from "../../../../assets/svg/sidebar/cupom.svg";
import { ReactComponent as File } from "../../../../assets/svg/sidebar/file.svg";
import { ReactComponent as HomeLogo } from "../../../../assets/svg/sidebar/home.svg";
import { ReactComponent as Locker } from "../../../../assets/svg/sidebar/locker.svg";
import { ReactComponent as WhiteArrow } from "../../../../assets/svg/sidebar/whitearrow.svg";

import Text from "../../../Text";
import colors from "../../../../shared/utils/constants/colors";

import * as Styles from "./styles";

interface IOptionProps {
  opened: boolean;
}

type GroupMappings = {
  [group: string]: string[];
};

interface ISubOption {
  nameOption: string;
  text: string;
  path: string;
}

interface IOption {
  nameOption: string;
  icon: any;
  text: string;
  path?: string;
  subOptions?: ISubOption[];
}

const options: IOption[] = [
  { nameOption: "home", icon: <HomeLogo />, text: "Home", path: "" },
  {
    nameOption: "stock",
    icon: <FaBoxOpen color={colors.secondary.default} />,
    text: "Estoque",
    subOptions: [
      { nameOption: "products", text: "Produtos", path: "stock" },
      { nameOption: "moviment", text: "Movimentação", path: "moviment" },
      { nameOption: "recepipts", text: "Notas Fiscais", path: "receipts" },
    ],
  },
  {
    nameOption: "pessoas",
    icon: <Locker />,
    text: "Pessoas/pets",
    subOptions: [
      { nameOption: "users", text: "Usuários", path: "users" },
      { nameOption: "pets", text: "Pets", path: "pets" },
      { nameOption: "Anamnesis", text: "Anamneses", path: "anamnesis" },
    ],
  },
  {
    nameOption: "agenda",
    icon: <Calendar />,
    text: "Agenda",
    subOptions: [
      { nameOption: "appointment", text: "Agendamentos", path: "appointment" },
    ],
  },
  {
    nameOption: "financeiro",
    icon: <CreditCard />,
    text: "Financeiro",
    subOptions: [
      { nameOption: "orders", text: "Pedidos", path: "orders" },
      { nameOption: "plans", text: "Planos", path: "plans" },
      {
        nameOption: "transactions",
        text: "Transações",
        path: "transactions",
      },
      {
        nameOption: "professionals-supplies",
        text: "Insumos para profissional",
        path: "professionals-supplies",
      },
      { nameOption: "orders-errors", text: "Pedidos não finalizados", path: "orders-errors" },
    ],
  },
  {
    nameOption: "promocoes",
    icon: <Cupom />,
    text: "Promoções",
    subOptions: [
      {
        nameOption: "coupon",
        text: "cupons",
        path: "coupon",
      },
      {
        nameOption: "event",
        text: "eventos",
        path: "event",
      },
      {
        nameOption: "campaigns",
        text: "campanhas",
        path: "campaigns",
      }
    ],
  },
  {
    nameOption: "relatorios",
    icon: <File />,
    text: "Relatórios",
    subOptions: [
      { nameOption: "newUsers", text: "Novos usuários", path: "newUsers" },
      {
        nameOption: "lastAppointment",
        text: "Últimos agendamentos",
        path: "lastAppointment",
      },
      {
        nameOption: "appointments-reports",
        text: "Relatório de agendamentos",
        path: "appointments-reports",
      },
      {
        nameOption: "moviments-report",
        text: "Relatório de movimentações",
        path: "moviments-report",
      },
      {
        nameOption: "appointments-financial-report",
        text: "Relatório Financeiro de Agendamentos",
        path: "appointments-financial-reports",
      },
    ],
  },
  {
    nameOption: "routes",
    icon: <FaShippingFast color={colors.secondary.default} />,
    text: "Rotas",
    subOptions: [
      {
        nameOption: "route-dashboard",
        text: "Criar rota",
        path: "route-dashboard",
      },
    ],
  },
  {
    nameOption: "zonas",
    icon: <FaMapMarkerAlt color={colors.secondary.default} />,
    text: "Zonas",
    subOptions: [
      { nameOption: "zonas", text: "Zonas", path: "zonas" },
      { nameOption: "setores", text: "Setores", path: "setores" }
    ],
  },
  {
    nameOption: "partners",
    icon: <FaHandsHelping color={colors.secondary.default} />,
    text: "Parceiros",
    subOptions: [
      { nameOption: "partners", text: "Parcerias", path: "parceiros" },
      { nameOption: "condominios", text: "Condomínios", path: "condominios" },
    ],
  },
];

const groupMappings: GroupMappings = {
  home: ["home"],
  pessoas: ["users", "pets"],
  agenda: ["appointment"],
  financeiro: ["orders", "plans", "transactions"],
  promocoes: ["coupon"],
  relatorios: ["newUsers", "lastAppointment", "appointments-reports"],
  routes: ["route-dashboard"],
  zonas: ["zonas"],
};

const Option: React.FC<IOptionProps> = ({ opened }) => {
  const history = useHistory();
  const [, screen] = history.location.pathname.split("/");
  const [selectedScreen, setSelectedScreen] = useState(screen);
  const [activeSubOption, setActiveSubOption] = useState("");

  const findGroup = (s: string): string => {
    for (const group in groupMappings) {
      if (groupMappings[group].includes(s)) {
        return group;
      }
    }
    return "";
  };

  const [selectedGroup, setSelectedGroup] = useState(findGroup(screen));

  const navigateToOption = (path: string) => {
    setActiveSubOption(path);
    history.push(path);
  };

  useEffect(() => {
    const [, screen_now] = history.location.pathname.split("/");
    if (screen_now !== selectedScreen) {
      setSelectedScreen(screen_now);
      setSelectedGroup(findGroup(screen_now));
    }
  }, [history.location.pathname, selectedScreen]);

  return (
    <>
      {options.map((option: IOption) => (
        <>
          <Styles.Option
            key={option.nameOption}
            opened={opened}
            selectedOption={selectedGroup}
            onClick={() => {
              if (option.subOptions) {
                setSelectedGroup(option.nameOption);
                setActiveSubOption(option.subOptions[0].path);
              } else {
                navigateToOption(option.path as string);
              }
            }}
            nameOption={option.nameOption}
            data-tip={!opened ? option.text : ""}
          >
            {option.icon}
            <Styles.NameOption opened={opened}>
              <Text
                text={option.text}
                color="#00000080"
                fontFamily="Open Sans"
                size={14}
                weight={selectedScreen === option.nameOption ? "600" : "400"}
                marginLeft={20}
              />
              {option.subOptions && <WhiteArrow />}
            </Styles.NameOption>
          </Styles.Option>
          <Styles.ListOption
            opened={opened}
            selectedOption={selectedGroup}
            nameOption={option.nameOption}
          >
            {option.subOptions && selectedGroup === option.nameOption && (
              <>
                {option.subOptions.map((subOption: ISubOption) => (
                  <Styles.Buttom
                    key={subOption.nameOption}
                    style={{
                      background:
                        activeSubOption === subOption.path
                          ? "linear-gradient(0deg, #f6f9fc, #f6f9fc)"
                          : "transparent",
                      borderLeft:
                        activeSubOption === subOption.path
                          ? "2px solid #3c6959"
                          : "none",
                    }}
                  >
                    <Text
                      text={subOption.text}
                      color="#00000080"
                      fontFamily="Open Sans"
                      size={14}
                      weight={
                        activeSubOption === subOption.path ? "600" : "400"
                      }
                      onTextClick={() => navigateToOption(subOption.path)}
                      align="left"
                      marginLeft={35}
                    />
                  </Styles.Buttom>
                ))}
              </>
            )}
          </Styles.ListOption>
        </>
      ))}
    </>
  );
};

export default Option;
