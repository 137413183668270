import styled from "styled-components";
import colors from "../../shared/utils/constants/colors";

export const Alert = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${colors.argon.white};
  border-radius: 5px;
  width: calc(90%-32px);
  padding: 32px;
  margin-top: 1rem;
  margin-left: 16px;
  margin-right: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
