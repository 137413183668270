import React, { useCallback, useEffect, useRef, useState } from "react";

import Text from "../../../components/Text";
import { Form } from "@unform/web";
import InputRef from "../../../components/InputRef";
import Button from "../../../components/Button";
import colors from "../../../shared/utils/constants/colors";
import * as Yup from "yup";

import * as Styles from "./styles";
import { FormHandles } from "@unform/core";
import Modal from "../../../components/Modal";
import Select from "../../../components/Select";
import getValidationErrors from "../../../shared/utils/getValidationErrors";
import formatDate from "../../../shared/utils/formatDate";
import AppointmentStatusEnum from "../../../shared/utils/enums/AppointmentStatusEnum";
import StringOFEnum from "../../../shared/utils/StringOfEnum";
import { getCategories } from "../../../services/api-graphql-calls";
import { Label } from "../../../components/Label/styles";

interface FilterFormData {
  tutor: string;
  pet: string;
  category: string;
  initialDate: string;
  finalDate: string;
  status: string;
}
const FilterModal = ({
  visible,
  onCloseModal,
  applyFilters,
  reciviedFilter,
}: any) => {
  const formRef = useRef<FormHandles>(null);

  const [status, setStatus] = useState([] as any);
  const [categories, setCategories] = useState([] as any);

  const loadCategories = useCallback(async () => {
    const result = (await getCategories()) as any;
    const reciviedCategories = result.categories;
    let categories = [];
    for (let k in reciviedCategories) {
      categories.push({
        value: reciviedCategories[k].id,
        label: reciviedCategories[k].name,
      });
    }

    setCategories(categories);
  }, []);

  useEffect(() => {
    let status = [];
    for (let k in AppointmentStatusEnum) {
      if (typeof AppointmentStatusEnum[k] === "string") {
        status.push({ value: k, label: AppointmentStatusEnum[k] });
      }
    }
    setStatus(status);

    loadCategories();
  }, [loadCategories]);

  useEffect(() => {
    let i;

    i = reciviedFilter.findIndex((r: any) => r.key === "Tutor");
    if (i < 0) formRef.current?.setFieldValue("tutor", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Pet");
    if (i < 0) formRef.current?.setFieldValue("pet", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Status");
    if (i < 0) formRef.current?.setFieldValue("status", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Categoria");
    if (i < 0) formRef.current?.setFieldValue("category", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Data Início");
    if (i < 0) formRef.current?.setFieldValue("initialDate", "");

    i = reciviedFilter.findIndex((r: any) => r.key === "Data Fim");
    if (i < 0) formRef.current?.setFieldValue("finalDate", "");
  }, [reciviedFilter]);

  const handleSubmit = async (data: FilterFormData) => {
    const { tutor, pet, category, initialDate, finalDate, status } = data;
    try {
      formRef.current?.setErrors({});

      if (initialDate !== "" && finalDate === "") {
        formRef.current?.setErrors({ finalDate: "Informar data final" });
        return false;
      }

      if (initialDate === "" && finalDate !== "") {
        formRef.current?.setErrors({ initialDate: "Informar data inicial" });
        return false;
      }

      if (initialDate !== "") {
        const schema = Yup.object().shape({
          initialDate: Yup.date(),
          finalDate: Yup.date().min(
            Yup.ref("initialDate"),
            "Data final deve ser maior que a incial"
          ),
        });
        await schema.validate(data, { abortEarly: false });
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);

      return false;
    }
    let filtersToSend = [];
    if (tutor !== "")
      filtersToSend.push({ key: "Tutor", value: tutor, searchValue: tutor });

    if (pet !== "")
      filtersToSend.push({ key: "Pet", value: pet, searchValue: pet });

    if (initialDate !== "")
      filtersToSend.push({
        key: "Data Início",
        value: formatDate(initialDate),
        searchValue: initialDate,
      });

    if (finalDate !== "")
      filtersToSend.push({
        key: "Data Fim",
        value: formatDate(finalDate),
        searchValue: finalDate,
      });

    if (status !== "") {
      filtersToSend.push({
        key: "Status",
        value: StringOFEnum(AppointmentStatusEnum, parseInt(status)),
        searchValue: status,
      });
    }

    if (category !== "") {
      let cateogrySelected = categories.find(
        (c: { value: string }) => c.value === category
      );

      filtersToSend.push({
        key: "Categoria",
        value: cateogrySelected.label,
        searchValue: category,
      });
    }

    applyFilters(filtersToSend);
    onCloseModal();
  };

  const clearFilters = () => {
    formRef.current?.reset();
    applyFilters([]);
  };

  return (
    <>
      <Modal visible={visible} closeModal={() => onCloseModal()}>
        <Styles.header>
          <Text
            text="Filtrar agendamentos"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={() => onCloseModal()}
          />
        </Styles.header>

        <Styles.line></Styles.line>

        <Styles.FormDiv>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Styles.row>
              <Styles.field>
                <Label>tutor</Label>
                <InputRef
                  placeholder="Digite o nome do tutor"
                  name="tutor"
                  type="text"
                />
              </Styles.field>

              <Styles.field>
                <Label>pet</Label>
                <InputRef
                  placeholder="Digite o nome do pet"
                  name="pet"
                  type="text"
                />
              </Styles.field>

              <Styles.field>
                <Label>categoria</Label>
                <Select
                  name="category"
                  containerStyle={{ width: "33rem" }}
                  placeholder="Categoria"
                  options={categories}
                />
              </Styles.field>
            </Styles.row>

            <Styles.row>
              <Styles.field>
                <Label>data início do agendamento</Label>
                <InputRef name="initialDate" type="date" />
              </Styles.field>

              <Styles.field>
                <Label>data fim do agendamento</Label>
                <InputRef name="finalDate" type="date" />
              </Styles.field>

              <Styles.field>
                <Label>status</Label>
                <Select
                  name="status"
                  containerStyle={{ width: "33rem" }}
                  placeholder="Status"
                  options={status}
                />
              </Styles.field>
            </Styles.row>

            <Styles.rowButton>
              <Styles.WhiteButton onClick={clearFilters}>
                Limpar
              </Styles.WhiteButton>
              <Button
                text="Aplicar"
                type="submit"
                styleContainer={{ minWith: "100px" }}
              />
            </Styles.rowButton>
          </Form>
        </Styles.FormDiv>
      </Modal>
    </>
  );
};
export default FilterModal;
