import React, { useCallback} from 'react';
import * as Styles from '../../constants/styles';
import Button from "components/Button";
import { launchToast } from 'shared/utils/launchToast';
import { ICreateProductFormData } from 'pages/Receipts/constants/types';
import { CREATE_PRODUCT, LIST_PRODUCTS } from 'pages/Stock/graphql/StockQuery';
import { useMutation } from '@apollo/client';
import CreateProductForm, { RegisterProductFormId } from 'pages/Stock/forms/registerProduct/RegisterProductForm';

export type IRegisterProduct = {
    isOpen: boolean;
    onClose: () => void;
};


const RegisterProductTab: React.FC<IRegisterProduct> = ({ isOpen, onClose}) => {

    const [createProduct, { loading }] = useMutation(CREATE_PRODUCT, {
        refetchQueries: [
            LIST_PRODUCTS,
            'products'
        ],
    });

    const handleSubmit = useCallback(
        async (
            payload: ICreateProductFormData,
            { reset }
        ) => {
            try {
                await createProduct({
                    variables: {
                        input: {
                            name: payload.name,
                            brand: payload.brand,
                            category: payload.category,
                            class: payload.class,
                            description: payload.description,
                            comission_percentage: payload.comission_percentage,
                            manufacturer_sku_code: payload.manufacturer_sku_code,
                            maximum_stock: payload.maximum_stock,
                            minimum_stock: payload.minimum_stock,
                            packaging: payload.packaging,
                            stock_unit: payload.stock_unit,
                            quantity: payload.quantity,
                            cost_price: payload.cost_price,
                            selling_price: payload.selling_price
                        }
                    },
                });
                reset();
                onClose();
                launchToast('Produto registrado com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [createProduct],
    );

    return (
        <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}
        >
            <Styles.FormDiv>
                <CreateProductForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={RegisterProductFormId} loading={loading} />
            </Styles.rowButton>
        </div>
    )
}

export default RegisterProductTab