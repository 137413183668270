export const columnAddProductsToReceiptsHeaders = [
    { key: "product", name: "Produto" },
    { key: "stock_unit", name: "Medida" },
    { key: "quantity", name: "Quantidade" },
    { key: "unity_price", name: "Preço de compra" },
    { key: "resale_price", name: "Preço de venda" },
    { key: "actions", name: "ações" },
];

export const columnReceiptsHeaders = [
    { key: "id", name: "Id" },
    { key: "number", name: "Numero" },
    { key: "supplier_id", name: "Fornecedor" },
    { key: "emission_date", name: "Data de Emissão" },
    { key: "actions", name: "ações" },
];