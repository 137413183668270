import React, { FC, useState } from "react";
import {
  Text,
  Select,
  Flex,
  Input,
  Button,
  Divider,
  Grid,
  Box,
  Stack,
} from "@chakra-ui/react";
import { FiCreditCard } from "react-icons/fi";
import { toast } from "react-toastify";
import { updatePartnerPreferedPayment } from "../graphQL";

const paymentsMethods = [
  {
    id: 1,
    name: "CARD",
    description: "Cartão",
  },
  {
    id: 2,
    name: "Pix",
    description: "Pix",
  },
];

interface IPaymentProps {
  partnerId: string;
  cards: any[];
  setCards: any;
}

export const Payment: FC<IPaymentProps> = ({ partnerId, cards, setCards }) => {
  const [payment, setPayment] = useState<string>("");
  const [isNewCard, setIsNewCard] = useState<boolean>(false);
  const [steps, setSteps] = useState<number>(1);
  const [CreditCardHolderInfoInputs, setCreditCardHolderInfoInputs] = useState({
    name: "",
    email: "",
    cpfCnpj: "",
    phone: "",
    mobilePhone: "",
    postalCode: "",
    addressNumber: "",
    addressComplement: "",
  });
  const [creditCard, setCreditCard] = useState({
    number: "",
    holderName: "",
    expiryMonth: "",
    expiryYear: "",
    ccv: "",
  });

  const handleAddCard = async () => {
    try {
      let cardDetails = {
        CreditCardHolderInfoInput: CreditCardHolderInfoInputs,
        creditCard,
      };
      let main = true;
      let input = {
        partnerId,
        payment_method: payment,
        provider: "ASAAS",
        card_input: { cardDetails, main },
      };

      const result = await updatePartnerPreferedPayment(
        input.partnerId,
        input.payment_method,
        input.provider,
        input.card_input
      );
      setCards([...cards, result.cards[0]]);
      toast.success("Cartão cadastrado com sucesso");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao cadastrar cartão");
    }

    setIsNewCard(false);
  };

  return (
    <Flex direction="column" w="100%" h="100%">
      <Flex direction="column" w="100%" h="100%">
        <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
          Forma de pagamento:
        </Text>
        <Select
          placeholder="SELECIONE UMA FORMA DE PAGAMENTO"
          mb="4"
          h={"16"}
          fontSize="1.2rem"
          fontWeight="semibold"
          bg="white"
          border="1px solid #ccc"
          borderRadius="4px"
          _hover={{
            borderColor: "green.600",
          }}
          _focus={{
            borderColor: "green.600",
          }}
          value={payment}
          onChange={(e) => setPayment(e.target.value)}
        >
          {paymentsMethods.map((payment) => (
            <option key={payment.id} value={payment.name}>
              {payment.description.toUpperCase()}
            </option>
          ))}
        </Select>
      </Flex>
      <Divider my="4" />
      {cards.length > 0 && !isNewCard && (
        <Flex direction="column" w="100%" h="100%">
          <Text fontSize="2xl" fontWeight="bold" mb="4" mt="4">
            Forma de pagamento:
          </Text>
          <Stack spacing="4">
            {cards.map((card: any) => (
              <Box
                key={card.id}
                bg="white"
                p="4"
                borderRadius="md"
                boxShadow="md"
                display="flex"
                alignItems="center"
              >
                <Box as={FiCreditCard} boxSize="24px" color="gray.500" />
                <Box ml="4">
                  <Flex alignItems="center">
                    <Text ml="2" fontSize="xl" fontWeight="semibold">
                      {card.first_digits}
                    </Text>
                    <Text ml="2" fontSize="xl" fontWeight="semibold">
                      **** ****
                    </Text>
                    <Text ml="2" fontSize="xl" fontWeight="semibold">
                      {card.last_digits}
                    </Text>
                  </Flex>
                  <Text ml="2" fontSize="xl" fontWeight="semibold">
                    {card.holder}
                  </Text>
                </Box>
              </Box>
            ))}
          </Stack>
        </Flex>
      )}
      {!isNewCard && payment === "CARD" && (
        <Button
          colorScheme="green"
          fontSize="2xl"
          onClick={() => setIsNewCard(true)}
          h={16}
          mt="8"
        >
          Cadastrar novo cartão
        </Button>
      )}
      {!isNewCard && payment !== "CARD" && payment !== "" && (
        <Button
          colorScheme="green"
          fontSize="2xl"
          onClick={() => setIsNewCard(true)}
          h={16}
          mt="4"
        >
          Salvar forma de pagamento
        </Button>
      )}
      {payment === "CARD" && isNewCard && steps === 1 && (
        <>
          <Grid templateColumns="1fr 1fr" gap="4">
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *Nome da empresa:
              </Text>
              <Input
                type="text"
                placeholder="Digite o nome da empresa"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={CreditCardHolderInfoInputs.name}
                onChange={(e) => {
                  setCreditCardHolderInfoInputs({
                    ...CreditCardHolderInfoInputs,
                    name: e.target.value,
                  });
                }}
                isRequired
              />
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *Email:
              </Text>
              <Input
                type="email"
                placeholder="Digite o email"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={CreditCardHolderInfoInputs.email}
                onChange={(e) => {
                  setCreditCardHolderInfoInputs({
                    ...CreditCardHolderInfoInputs,
                    email: e.target.value,
                  });
                }}
                isRequired
              />
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *CPF/CNPJ:
              </Text>
              <Input
                type="text"
                placeholder="Digite o CPF/CNPJ"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={CreditCardHolderInfoInputs.cpfCnpj}
                onChange={(e) => {
                  setCreditCardHolderInfoInputs({
                    ...CreditCardHolderInfoInputs,
                    cpfCnpj: e.target.value,
                  });
                }}
                isRequired
              />
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *CEP:
              </Text>
              <Input
                type="text"
                placeholder="Digite o CEP"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={CreditCardHolderInfoInputs.postalCode}
                onChange={(e) => {
                  setCreditCardHolderInfoInputs({
                    ...CreditCardHolderInfoInputs,
                    postalCode: e.target.value,
                  });
                }}
                isRequired
              />
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *Número:
              </Text>
              <Input
                type="text"
                placeholder="Digite o número"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={CreditCardHolderInfoInputs.addressNumber}
                onChange={(e) => {
                  setCreditCardHolderInfoInputs({
                    ...CreditCardHolderInfoInputs,
                    addressNumber: e.target.value,
                  });
                }}
                isRequired
              />
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *Complemento:
              </Text>
              <Input
                type="text"
                placeholder="Digite o complemento"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={CreditCardHolderInfoInputs.addressComplement}
                onChange={(e) => {
                  setCreditCardHolderInfoInputs({
                    ...CreditCardHolderInfoInputs,
                    addressComplement: e.target.value,
                  });
                }}
                isRequired
              />
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *Telefone:
              </Text>
              <Input
                type="text"
                placeholder="Digite o telefone"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={CreditCardHolderInfoInputs.phone}
                onChange={(e) => {
                  setCreditCardHolderInfoInputs({
                    ...CreditCardHolderInfoInputs,
                    phone: e.target.value,
                  });
                }}
                isRequired
              />
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *Celular:
              </Text>
              <Input
                type="text"
                placeholder="Digite o celular"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={CreditCardHolderInfoInputs.mobilePhone}
                onChange={(e) => {
                  setCreditCardHolderInfoInputs({
                    ...CreditCardHolderInfoInputs,
                    mobilePhone: e.target.value,
                  });
                }}
                isRequired
              />
            </Box>
          </Grid>
          <Button
            bg="green.600"
            color="white"
            fontSize="2xl"
            fontWeight="bold"
            size={"2xl"}
            w="100%"
            h={16}
            mb="4"
            mt={8}
            _hover={{
              bg: "green.700",
            }}
            onClick={() => {
              if (
                CreditCardHolderInfoInputs.cpfCnpj === "" ||
                CreditCardHolderInfoInputs.postalCode === "" ||
                CreditCardHolderInfoInputs.addressNumber === "" ||
                CreditCardHolderInfoInputs.addressComplement === "" ||
                CreditCardHolderInfoInputs.phone === "" ||
                CreditCardHolderInfoInputs.mobilePhone === ""
              ) {
                toast.error("Todos os campos são obrigatórios");
                return;
              }
              setSteps(2);
            }}
          >
            Proxima etapa
          </Button>
        </>
      )}
      {payment === "CARD" && isNewCard && steps === 2 && (
        <Flex direction="column" w="100%" h="100%">
          <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
            *Número do cartão:
          </Text>
          <Input
            type="number"
            placeholder="Digite o número do cartão"
            fontSize={"xl"}
            h={14}
            mb="4"
            bg="white"
            border="1px solid #ccc"
            borderRadius="4px"
            _hover={{
              borderColor: "green.600",
            }}
            _focus={{
              borderColor: "green.600",
            }}
            value={creditCard.number}
            onChange={(e) => {
              setCreditCard({ ...creditCard, number: e.target.value });
            }}
          />
          <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
            *Nome do titular:
          </Text>
          <Input
            type="text"
            placeholder="Digite o nome do titular"
            fontSize={"xl"}
            h={14}
            mb="4"
            bg="white"
            border="1px solid #ccc"
            borderRadius="4px"
            _hover={{
              borderColor: "green.600",
            }}
            _focus={{
              borderColor: "green.600",
            }}
            value={creditCard.holderName}
            onChange={(e) => {
              setCreditCard({ ...creditCard, holderName: e.target.value });
            }}
          />
          <Grid templateColumns="repeat(3, 1fr)" gap={6}>
            <Flex direction="column">
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *Mes de validade:
              </Text>
              <Input
                type="text"
                placeholder="Digite a validade"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={creditCard.expiryMonth}
                onChange={(e) => {
                  setCreditCard({ ...creditCard, expiryMonth: e.target.value });
                }}
              />
            </Flex>
            <Flex direction="column">
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *Ano de validade:
              </Text>
              <Input
                type="text"
                placeholder="Digite a validade"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={creditCard.expiryYear}
                onChange={(e) => {
                  setCreditCard({ ...creditCard, expiryYear: e.target.value });
                }}
              />
            </Flex>
            <Flex direction="column">
              <Text fontSize="2xl" fontWeight="bold" mb="4" mt="6">
                *CCV:
              </Text>
              <Input
                type="number"
                placeholder="Digite o CVV"
                fontSize={"xl"}
                h={14}
                mb="4"
                bg="white"
                border="1px solid #ccc"
                borderRadius="4px"
                _hover={{
                  borderColor: "green.600",
                }}
                _focus={{
                  borderColor: "green.600",
                }}
                value={creditCard.ccv}
                onChange={(e) => {
                  setCreditCard({ ...creditCard, ccv: e.target.value });
                }}
              />
            </Flex>
          </Grid>
          <Divider mt="6" mb="6" />
          <Button
            colorScheme="green"
            size="2xl"
            mt="6"
            height={16}
            onClick={() => {
              if (
                creditCard.number === "" ||
                creditCard.holderName === "" ||
                creditCard.expiryMonth === "" ||
                creditCard.expiryYear === ""
              ) {
                toast.error("Todos os campos são obrigatórios");
                return;
              }
              handleAddCard();
            }}
          >
            Casdastrar cartão
          </Button>
          <Button
            variant={"link"}
            fontSize="2xl"
            fontWeight="bold"
            size={"2xl"}
            w="100%"
            h={16}
            mb="4"
            mt={8}
            onClick={() => setSteps(1)}
          >
            Voltar etapa
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
