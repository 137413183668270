import styled from 'styled-components';

import colors from '../../shared/utils/constants/colors';

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchText = styled.input`
  margin: 0 8px;
  padding: 2px 4px;
  color: ${colors.argon.textColorGray};
  border-radius: 5px;
  border: 1px solid ${colors.argon.textColorLight02};
`;