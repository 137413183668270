import React from "react";
import { ReactComponent as Pix } from "../../../assets/svg/generic/pix.svg";
import Modal from "../../../components/Modal";
import Text from "../../../components/Text";
import StatusTransactionEnum from "../../../shared/enums/StatusTransactionEnum";
import TypePaymentEnum from "../../../shared/enums/TypePaymentEnum";
import colors from "../../../shared/utils/constants/colors";
import { launchToast } from "../../../shared/utils/launchToast";
import ISubscription from "../../../types/ISubscription";
import ITransaction from "../../../types/ITransaction";
import * as Styles from "./styles";

type IPayloadUpdateTransaction = {
  id: string;
  type: number;
};

type IProps = {
  payload: ISubscription;
  onCloseModal: () => void;
  onSubmit: (payload: IPayloadUpdateTransaction) => void;
};

const PlanDetail = ({ payload, onCloseModal, onSubmit }: IProps) => {
  const generateLink = (transaction?: ITransaction) => {
    if (transaction?.type === TypePaymentEnum.pix) {
      const pix = transaction.payload;
      return pix || "";
    }
    return undefined;
  };

  return (
    <>
      <Modal visible={!!payload} closeModal={() => onCloseModal()}>
        <Styles.Header>
          <Text
            text="Mensalidades"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={() => onCloseModal()}
          />
        </Styles.Header>
        <Styles.line></Styles.line>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {payload.payments.map((payment) => (
            <Styles.MonthPayment>
              <Styles.Row>
                <b>
                  <p>Renova dia</p>
                </b>
                <p>{payment.date_to_renew.split("T")[0]}</p>
              </Styles.Row>
              <Styles.Row>
                <b>
                  <p>Dia que foi renovado</p>
                </b>
                <p>{payment.date_renewed?.split("T")[0] || "aguardando"}</p>
              </Styles.Row>
              <Styles.Row>
                <b>
                  <p>Tipo de pagamento</p>
                </b>
                <p>
                  {!!payment.transaction?.type
                    ? TypePaymentEnum[payment.transaction.type]
                    : "sem dado"}
                </p>
              </Styles.Row>
              <Styles.Row>
                <b>
                  <p>Status do pagamento</p>
                </b>
                <p>
                  {!!payment.transaction?.status
                    ? Object.entries(StatusTransactionEnum).find(
                        (item) => item[1] === payment.transaction?.status
                      )?.[0] || "sem dado"
                    : "sem dado"}
                </p>
              </Styles.Row>
              {payment.transaction?.status !== StatusTransactionEnum.paided && (
                <>
                  <Styles.Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 8,
                    }}
                  >
                    {/* <button
                      style={{ marginRight: 8 }}
                      onClick={() =>
                        onSubmit({
                          id: payment.id,
                          type: 1,
                        })
                      }
                    >
                      <FaCreditCard size={18} color={colors.gray.dark02} />
                    </button> */}
                    <button
                      onClick={() =>
                        onSubmit({
                          id: payment.id,
                          type: TypePaymentEnum.pix,
                        })
                      }
                    >
                      <Pix />
                    </button>
                  </Styles.Row>
                  {payment.transaction?.type === TypePaymentEnum.pix && (
                    <button
                      onClick={() => {
                        const link = generateLink(payment.transaction);
                        if (!!link) {
                          navigator.clipboard.writeText(link);
                          launchToast("Link copiado com sucesso", "success");
                        } else {
                          launchToast("Erro ao copiar link", "error");
                        }
                      }}
                    >
                      copiar link
                    </button>
                  )}
                </>
              )}
            </Styles.MonthPayment>
          ))}
        </div>
      </Modal>
    </>
  );
};
export default PlanDetail;
