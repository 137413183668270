import styled from "styled-components";

import colors from "../../../shared/utils/constants/colors";

export const BackgroundPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PanelHeader = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${colors.primary.default};
  color: ${colors.argon.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelItens = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
`;

export const header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const rowButton = styled.div<{ margin?: string }>`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-left: 40%;

  @media (min-width: 650px) {
    flex-direction: row;
    align-items: flex-end;
    width: 30%;
    margin-left: ${(props) => `${props?.margin}%` || "70%"};
  }
`;

export const box = styled.div`
  margin: 24px;
`;
