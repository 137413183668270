import Modal from "components/Modal";
import React, { useCallback } from "react"
import * as Styles from '../constants/styles';
import Text from "components/Text";
import { IconButton } from '@chakra-ui/react';
import { CgClose } from 'react-icons/cg';
import colors from "shared/utils/constants/colors";
import Button from "components/Button";
import { launchToast } from "shared/utils/launchToast";
import FiltersMovimentsForm, { FiltersMovimentsFormId } from "../forms/filtersMoviment/FiltersMovimentsForm";
import { IFilterMovimentsReport } from "pages/report/MovimentsReport/constants/types";
import { APIFiltersMoviments, IFilterMovimentsFormData, MovimentsFilterTranslateEnum } from "../constants/types";

type FiltersMovimentsModalProps = {
    isOpen: boolean
    onClose:  () => void
    handleAddFilter: React.Dispatch<any>
    onFilterSubmit: React.Dispatch<any>
}

const FiltersMovimentsModal = ({
    isOpen,
    onClose,
    handleAddFilter,
    onFilterSubmit
}: FiltersMovimentsModalProps) => {

    const handleSubmit = useCallback(
        async (
            payload: IFilterMovimentsFormData,
            { reset }
        ) => {
            try {
                const activeFilters = [];

                const {...rest } = payload;

                const APIFilters = rest as unknown as APIFiltersMoviments;

                for (const key in payload) {
                    if (payload[key] !== '' && payload[key] !== undefined) {
                        activeFilters.push({ key: MovimentsFilterTranslateEnum[key as keyof APIFiltersMoviments], value: payload[key] });
                    } else {
                        delete APIFilters[key as keyof APIFiltersMoviments];
                    }
                }

                onFilterSubmit(activeFilters);

                handleAddFilter({
                    input: {
                    truckId: payload.truckId,
                    professionalId: payload.professionalId,
                    productId: payload.productId,
                    pagination: {
                        page: 1,
                        limit: 10
                    }
                }});
                reset();
                onClose();
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        }, []
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text="Filtrar Movimentações"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <IconButton
                    aria-label='Fechar modal'
                    icon={<CgClose />}
                    onClick={onClose}
                />
            </Styles.header>
            <Styles.line></Styles.line>
            <Styles.FormDiv>
                <FiltersMovimentsForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button
                    text="Filtrar"
                    type="submit"
                    form={FiltersMovimentsFormId}
                />
            </Styles.rowButton>
        </Modal>
    )
}

export default FiltersMovimentsModal