import { useDisclosure } from "@chakra-ui/react";
import Pagination from "components/Pagination";
import React, {  useEffect, useMemo, useState } from "react";
import * as Styles from "./constants/styles";
import { MOVIMENTS_REPORT, movimentReport} from "./graphql/MovimentsReportQujery";
import Text from "components/Text";
import colors from "shared/utils/constants/colors";
import FiltersMovimentsReportModal from "./modals/FiltersMovimentsReportModal";
import { columnMovimentsReportHeaders } from "./constants/conlumns";
import { useQuery } from "@apollo/client";
import { launchToast } from "shared/utils/launchToast";
import MovimentsReportTable from "./MovimentsReportTable";
import ExcelJS from "exceljs";
import { IMoviemntsReport } from "./constants/types";
import { format, parseISO } from "date-fns";

const MovimentsReport: React.FC = () => {

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filtersMovimentsReport, setFiltersMovimentsReport] =  useState([] as any)
  
  const filterModalControl = useDisclosure();
  
  const { data, loading, refetch } = useQuery(MOVIMENTS_REPORT, {
    variables: {
      input:{
        to: todayDate(),
        from: yesterdayDate(),
        truckId: "",
        professionalId: "",
        productId: null,
        pagination: {
          limit:10,
          page:currentPage
        }
      }
    },
  });

  useEffect(() => {
    try {
      refetch({
        input: {
          ...filtersMovimentsReport, 
          pagination: {page: currentPage, limit: 10}
        },
      });
    } catch (error: any) {
      launchToast(error.message, "error");
    }
  }, [currentPage]);
  
  const handleAddFilter = (filters:Partial<{ input: { to: string; from: string; truckId: string; professionalId: string; productId: null; pagination: { limit: number; page: number; }; }; }>) => {
    setCurrentPage(1);
    refetch(filters);
  };

  const pageInfo = useMemo(() => ({
    isFirstPage: currentPage === 1,
    isLastPage: currentPage === data?.productActionsReport.totalPage,
  }), [currentPage, data?.productActionsReport.totalPage]);

  const normalizeReportData = (data: IMoviemntsReport[]) => {
    const nomalizedData = data.map((moviment: IMoviemntsReport) => ({
      ...moviment,
      createdAt: format(parseISO(moviment.createdAt), "dd/MM/yyyy"),
      professional: moviment.professional || " - ",
      truck: moviment.truck || " - ",
      costPrice: formatarMoney(moviment.costPrice),
      salePrice: formatarMoney(moviment.salePrice),
      totalPrice: formatarMoney(moviment.totalPrice),
      stock_unit: normalizeTextFilter(moviment.stock_unit)
    }))
    return nomalizedData
  }

  function todayDate() {
    const todayDate = new Date();
    const day = String(todayDate.getDate()).padStart(2, '0');
    const month = String(todayDate.getMonth() + 1).padStart(2, '0');
    const year = todayDate.getFullYear();
    const todayDateFormated = `${year}-${month}-${day}`;
    return todayDateFormated;
  }

  function yesterdayDate() {
    const yesterdayDate = new Date();
    const day = String(yesterdayDate.getDate()).padStart(2, '0');
    const month = String(yesterdayDate.getMonth() + 1).padStart(2, '0');
    const year = yesterdayDate.getFullYear();
    const yesterdayDateFormated = `${year}-${month}-${day}`;
    return yesterdayDateFormated;
  }

  const formatarMoney = (valor: number): string => {
    const formatoMoeda = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  
    return formatoMoeda.format(valor / 100)
  };

  const normalizeTextFilter = (text: string) => {
    const normalizationMap: Record<string, string> = {
        "LITER": "Litros",
        "MILLILITRE": "Mililitros",
        "GRAM": "Gramas",
        "KILOGRAM": "Quilogramas",
        "MILIGRAM": "Miligramas",
        "UNITY": "Unidade"
    };
    return normalizationMap[text] || text;
  };

  const handleExportFullData = async () => {

    let excelData: IMoviemntsReport[] = []

    for(  let page = 1; page <= data.productActionsReport.totalPage; page++ ){
      let pageResponse = await movimentReport({...filtersMovimentsReport, pagination: {page: page, limit: 10}})
      excelData = [...excelData, ...pageResponse.productActionsReport.report]
    }

    if (excelData) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('MovimentsReport');
      const columns = columnMovimentsReportHeaders.map(header => ({
        header: header.label,
        key: header.key,
        width: 15,
        style: { alignment: { horizontal: 'left' } as const },
      }));
      
      worksheet.columns = columns;

      worksheet.addRows(normalizeReportData(excelData));

      const excelBuffer = await workbook.xlsx.writeBuffer();
  
      const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(dataBlob);
  
      const a = document.createElement('a');
      a.href = url;
      a.download = 'MovimentsReport.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };


  return (
    <>
      <FiltersMovimentsReportModal
        isOpen={filterModalControl.isOpen}
        onClose={filterModalControl.onClose}
        handleAddFilter={handleAddFilter}
        onFilterSubmit={setFiltersMovimentsReport}
      />
      <Styles.BackgroundPanel>
      <Styles.PanelHeader>
          <Styles.PanelItens>
            <Text
              text="Relatórios de movimentações"
              color={colors.gray.white}
              fontFamily="Open Sans"
              size={20}
              weight="600"
              marginRight={4}
              marginTop={0}
            />
          </Styles.PanelItens>

          <Styles.WhiteButton onClick={filterModalControl.onOpen}>
            Buscar
          </Styles.WhiteButton>
        </Styles.PanelHeader>
        {
          !loading && (
            <>
            <Styles.ContentTable>
              <MovimentsReportTable
                data={data?.productActionsReport?.report}
              />
            </Styles.ContentTable>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                marginBottom: "16px",
              }}
            >
            <Styles.ButtonExport
              onClick={() => handleExportFullData()}
            >
              Exportar dados
            </Styles.ButtonExport>
            <Pagination
              totalPage={data?.productActionsReport.totalPage}
              canPreviousPage={currentPage > 1}
              previousPage={() => {
                !pageInfo.isFirstPage && setCurrentPage(currentPage - 1)
              }}
              nextPage={() => {
                !pageInfo.isLastPage && setCurrentPage(currentPage + 1)
              }}
              canNextPage={currentPage < data?.productActionsReport.totalPage}
              pageIndex={currentPage}
              setPage={(page: number) => setCurrentPage(page)}
            />
            </div>
            </>
          )
        }
      </Styles.BackgroundPanel>
    </>
  );
};

export default MovimentsReport;
