import Text from "components/Text";
import React, { useState } from "react";
import colors from "shared/utils/constants/colors";
import ModalServicesByCategory from "./ModalServicesByCategory";
import * as Styles from "./styles";

type ICategoryCustom = {
  id: string;
  name: string;
  status: boolean;
};

type IProps = {
  region_id?: string;
  categories: ICategoryCustom[];
  onUpdateCategoryStatus: (category: ICategoryCustom) => void;
};

const CategoriesSession = ({
  region_id,
  categories,
  onUpdateCategoryStatus,
}: IProps) => {
  const [selectedCategory, setSelectedCategory] = useState<
    ICategoryCustom | undefined
  >(undefined);

  return (
    <Styles.Container>
      <Text text="Categorias" fontFamily="Open Sans" weight="600" />
      <Styles.CategoriesContainer>
        {categories.map((category) => (
          <Styles.CategoryCard
            key={category.id}
            onClick={() => onUpdateCategoryStatus(category)}
            status={category.status}
          >
            <Text
              text={category.name}
              fontFamily="Open Sans"
              whiteSpace="nowrap"
              color={colors.gray.white}
            />
          </Styles.CategoryCard>
        ))}
      </Styles.CategoriesContainer>
      <Text text="Preços" fontFamily="Open Sans" weight="600" marginTop={24} />
      <Styles.CategoriesContainer>
        {categories
          .filter((category) => category.status)
          .map((category) => (
            <Styles.CategoryCard
              key={category.id}
              onClick={() => setSelectedCategory(category)}
              status={category.status}
            >
              <Text
                text={category.name}
                fontFamily="Open Sans"
                whiteSpace="nowrap"
                color={colors.gray.white}
              />
            </Styles.CategoryCard>
          ))}
      </Styles.CategoriesContainer>
      <ModalServicesByCategory
        region_id={region_id}
        category={selectedCategory}
        onRequestClose={() => setSelectedCategory(undefined)}
      />
    </Styles.Container>
  );
};

export default CategoriesSession;
