import styled from "styled-components";

interface IInputProps {
  sidebar: any;
}

export const ContainerWeb = styled.div`
  max-height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
	min-width: fit-content;
`;

export const Nav = styled.div<{ opened: Boolean }>`
  background: #ffffff;
  width: ${(props) => (props.opened ? `25rem` : `6rem`)};
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => (props.opened ? `flex-end` : `center`)};
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  z-index: 1;

  @media (max-width: 800px) {
    width: ${(props) => (props.opened ? `30rem` : `8rem`)};
  }
`;

export const Header = styled.div<{ opened: Boolean }>`
  width: 100%;
  display: flex;
  // margin-top: 2.4rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.opened ? `1.5rem 2.4rem 0` : `0`)};
`;
export const Content = styled.div`
  padding: 2.4rem 0;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const IconZazuu = styled.div<{ opened: Boolean }>`
  display: ${(props) => (props.opened ? `flex` : `none`)};
`;

export const HamburgerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
`;
export const HamburgerBarVar = styled.div<{ opened?: Boolean }>`
  height: 3px;
  width: ${(props) => (props.opened ? `18px` : `22px`)};
  background: #0d261d;
`;
export const HamburgerBarFix = styled.div`
  height: 3px;
  width: 22px;
  background: #0d261d;
  margin-bottom: 4px;
  margin-top: 4px;
`;

export const NavIcon = styled.div<{ opened?: Boolean }>`
  margin: ${(props) => (props.opened ? `0` : `0 auto`)};
  font-size: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

export const ContainerMobile = styled.div`
  display: none;
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
  background: #8eb7aa;
  padding: 16px;
  @media (max-width: 800px) {
    display: flex;
  }
`;
export const ModalHeaderDiv = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom-width: 1px;
  border-bottom-color: #eaedf0;
  border-bottom-style: solid;
`;
export const CloseButtom = styled.button`
  background: transparent;
  border: none;
`;

export const SearchInputDiv = styled.div`
  width: 90%;
  height: 46px;
  border: 1px solid #e2e2e6;
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  margin-bottom: 20px;
`;
export const SearchInput = styled.input`
  background: transparent;
  font-size: 14px;
  font-family: "OpenSans_400Regular";
  border: none;
  width: 90%;
  height: 100%;
  color: #adb5bd;
`;

export const SpaceBottom = styled.div`
  width: 100%;
  height: 40px;
`;
