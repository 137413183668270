import { Divider } from '@chakra-ui/react';
import { Label } from 'components/Label/styles';
import TextInput from 'components/TextInput';
import { format } from 'date-fns';
import React from 'react';
import { Row } from '../OrdersErrors/styles';
import { IOrderService } from '../OrdersErrors/types';

export default function OrderServiceCard({ service }: {service: IOrderService}) {
  const chunkArray = (arr: string[], size: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, size + i));
    }
    return result;
  };

  const additionalsPerRow = 4;
  const rows = chunkArray(service.additionalsId, additionalsPerRow);

  return (
    <>
    <Label>Serviço</Label>
    <Row>
      <TextInput
        label="Nome"
        name="name"
        value={service.id}
        disabled
      />
      <TextInput
        label="Endereço"
        name="address"
        value={service.addressId}
        disabled
      />
    </Row>
    <Row>
      <TextInput
        label="Data"
        name="date"
        value={format(new Date(service.date), "dd/MM/yyyy")}
        disabled
      />
      <TextInput
        label="Periodo"
        name="period"
        value={service.period}
        disabled
      />
      <TextInput
        label="Pet"
        name="pet"
        value={service.petId}
        disabled
      />
    </Row>
    <Label>Adicionais</Label>
    {rows.map((row, rowIndex) => (
      <Row key={rowIndex}>
        {row.map((additional) => (
          <TextInput
            key={additional}
            name="additional"
            value={additional}
            disabled
          />
        ))}
      </Row>
    ))}
    <Divider marginBottom={8}/>
  </>
  );
}
