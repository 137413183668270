import styled from "styled-components";
import colors from "../../../shared/utils/constants/colors";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const FormDiv = styled.div`
  width: 100%;
`;

export const rowButton = styled.div <{ margin?: string }>`
  display: flex;
  justify-content:space-between;
  margin-top: 70px;

  @media (min-width: 650px) {
    flex-direction: row;
    align-items: flex-end ;
    width: 30%;
    margin-left:${props => `${props?.margin}%` || "70%"};
  }
`;

export const line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.primary.light};
  margin-bottom: 24px;
  position: relative;
  top: -1px;
`;

export const MonthPayment = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  border: 1px solid ${colors.secondary.default};
  border-radius: 8px;
  padding: 8px;
  margin: 16px;

  p {
    margin-right: 8px;
    color: ${colors.gray.dark02};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;