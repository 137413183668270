import React, { useEffect, useRef } from "react";

import Text from "../../../components/Text";
import { Form } from "@unform/web";
import InputRef from "../../../components/InputRef";
import Button from "../../../components/Button";
import colors from "../../../shared/utils/constants/colors";
import * as Yup from "yup";

import * as Styles from "./styles";
import { FormHandles } from "@unform/core";
import Modal from "../../../components/Modal";
import getValidationErrors from "../../../shared/utils/getValidationErrors";
import formatDate from "../../../shared/utils/formatDate";

interface FilterFormData {
  description: string;
  code: string;
  initialDate: string;
  finalDate: string;
}
const FilterModal = ({
  visible,
  onCloseModal,
  applyFilters,
  reciviedFilter,
}: any) => {
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    let index = null;
    index = reciviedFilter.findIndex((f: any) => f.key === "Descrição");
    if (index < 0) formRef.current?.clearField("description");

    index = reciviedFilter.findIndex((f: any) => f.key === "Código");
    if (index < 0) formRef.current?.clearField("code");

    index = reciviedFilter.findIndex((f: any) => f.key === "Data Início");
    if (index < 0) formRef.current?.clearField("initialDate");

    index = reciviedFilter.findIndex((f: any) => f.key === "Data Fim");
    if (index < 0) formRef.current?.clearField("finalDate");
  }, [reciviedFilter]);

  const handleSubmit = async (data: FilterFormData) => {
    const { description, code, initialDate, finalDate } = data;
    try {
      formRef.current?.setErrors({});

      if (initialDate !== "" && finalDate === "") {
        formRef.current?.setErrors({ finalDate: "Informar data final" });
        return false;
      }

      if (initialDate === "" && finalDate !== "") {
        formRef.current?.setErrors({ initialDate: "Informar data inicial" });
        return false;
      }

      if (initialDate !== "") {
        const schema = Yup.object().shape({
          initialDate: Yup.date(),
          finalDate: Yup.date().min(
            Yup.ref("initialDate"),
            "Data final deve ser maior que a incial"
          ),
        });
        await schema.validate(data, { abortEarly: false });
      }
    } catch (error) {
      // @ts-ignore:next-line
      const errors = getValidationErrors(error);

      formRef.current?.setErrors(errors);

      return false;
    }
    let filtersToSend = [];
    if (description !== "")
      filtersToSend.push({
        key: "Descrição",
        value: description,
        searchValue: description,
      });

    if (code !== "")
      filtersToSend.push({ key: "Código", value: code, searchValue: code });

    if (initialDate !== "")
      filtersToSend.push({
        key: "Data Início",
        value: formatDate(initialDate),
        searchValue: initialDate,
      });

    if (finalDate !== "")
      filtersToSend.push({
        key: "Data Fim",
        value: formatDate(finalDate),
        searchValue: finalDate,
      });

    applyFilters(filtersToSend);
    onCloseModal();
  };

  const clearFilters = () => {
    formRef.current?.clearField("description");
    formRef.current?.clearField("code");
    formRef.current?.clearField("initialDate");
    formRef.current?.clearField("finalDate");
    applyFilters([]);
  };

  return (
    <>
      <Modal visible={visible} closeModal={() => onCloseModal()}>
        <Styles.header>
          <Text
            text="Filtrar cupons"
            color={colors.argon.darkBlue}
            fontFamily="Open Sans"
            size={17}
            weight="600"
          />

          <Text
            text="X"
            color={colors.gray.dark01}
            fontFamily="Open Sans"
            size={17}
            weight="600"
            onTextClick={() => onCloseModal()}
          />
        </Styles.header>

        <Styles.line></Styles.line>

        <Styles.FormDiv>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Styles.row>
              <Styles.field>
                <Text
                  text="DESCRICAO"
                  color={colors.argon.textColorDark}
                  fontFamily="Open Sans"
                  size={14}
                  weight="600"
                  marginBottom={5}
                />
                <InputRef
                  placeholder="Digite a descrição"
                  name="description"
                  type="text"
                />
              </Styles.field>

              <Styles.field>
                <Text
                  text="CÓDIGO"
                  color={colors.argon.textColorDark}
                  fontFamily="Open Sans"
                  size={14}
                  weight="600"
                  marginBottom={5}
                />
                <InputRef
                  placeholder="Digite o código"
                  name="code"
                  type="text"
                />
              </Styles.field>
            </Styles.row>

            <Styles.row>
              <Styles.field>
                <Text
                  text="DATA INICIO (CRIADO EM)"
                  color={colors.argon.textColorDark}
                  fontFamily="Open Sans"
                  size={14}
                  weight="600"
                  marginBottom={5}
                />
                <InputRef name="initialDate" type="date" />
              </Styles.field>

              <Styles.field>
                <Text
                  text="DATA FIM (CRIADO EM)"
                  color={colors.argon.textColorDark}
                  fontFamily="Open Sans"
                  size={14}
                  weight="600"
                  marginBottom={5}
                />
                <InputRef name="finalDate" type="date" />
              </Styles.field>
            </Styles.row>

            <Styles.rowButton>
              <Styles.WhiteButton onClick={clearFilters}>
                Limpar
              </Styles.WhiteButton>
              <Button
                text="Aplicar"
                type="submit"
                styleContainer={{ minWith: "100px" }}
              />
            </Styles.rowButton>
          </Form>
        </Styles.FormDiv>
      </Modal>
    </>
  );
};
export default FilterModal;
