import styled from "styled-components";
import colors from "../../../shared/utils/constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
`;

export const ContainerImageInputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

export const ContainerInputsData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 2px dashed ${colors.primary.light};
  border-bottom: 2px dashed ${colors.primary.light};
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  margin-top: 20px;
`;

export const ContainerAnimation = styled.div`
  width: 100%;
  margin-top: -60px;
`;

export const Title = styled.h1`
  color: ${colors.primary.dark};
  font-size: 30px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid ${colors.primary.light};
  padding-bottom: 20px;
`;

export const Input = styled.input`
  display: none;
`;

export const InputText = styled.input`
  padding: 10px 20px;
  border-radius: 10px;
  color: ${colors.primary.dark};
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px solid ${colors.primary.light};
  width: 100%;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  background: ${colors.primary.dark};
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  width: 100%;
  font-size: 14px;

  &:hover {
    opacity: 0.7;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }
`;

export const Img = styled.img`
  max-width: 100px;
  max-height: 100px;
  margin-bottom: 20px;
  padding: 10px;

  border-radius: 10px;
  border: 2px solid ${colors.primary.light};

  &:hover {
    opacity: 0.7;
  }
`;

export const InputData = styled.input`
  padding: 10px 20px;
  border-radius: 10px;
  color: ${colors.primary.dark};
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px solid ${colors.primary.light};
  width: 70%;
`;

export const Button = styled.button`
  background: ${colors.primary.dark};
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  width: 80%;

  &:hover {
    opacity: 0.7;
  }
`;

export const Strong = styled.strong`
  color: ${colors.primary.dark};
  margin-left: 10px;
  margin-right: 10px;
`;
