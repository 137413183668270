import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { loadTypes } from "../Partners/graphQL";
import { createCondominiumDiscount } from "../../services/condominium/graphQL";

interface DiscountFormProps {
  condominiumId: string;
  setIsNewDiscount: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IDiscountType {
  id: string;
  name: string;
}

type DiscountType = "CATEGORY_ID" | "PACK_WEEKLY" | "PACK_BIWEEKLY";

export const DiscountForm: React.FC<DiscountFormProps> = ({
  condominiumId,
  setIsNewDiscount,
}) => {
  const [amount, setAmount] = useState(0);
  const [type, setType] = useState<DiscountType>("" as DiscountType);
  const [typeValue, setTypeValue] = useState<string | null>(null);
  const [categories, setCategories] = useState<IDiscountType[]>([]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (
        amount < 0 ||
        amount > 100 ||
        (type === "CATEGORY_ID" && typeValue === null)
      ) {
        toast.error("Por favor, preencha todos os campos corretamente.");

        return;
      }
      await createCondominiumDiscount({
        condominium_id: condominiumId,
        amount,
        type,
        type_value: typeValue,
      });
      setIsNewDiscount(true);
      toast.success("Desconto adicionado com sucesso!");
    } catch (error) {
      toast.error("Erro ao adicionar desconto.");
    } finally {
      setAmount(0);
      setType("" as DiscountType);
      setTypeValue(null);
    }
  };

  const typesDiscount = async () => {
    const types = await loadTypes();
    setCategories(types.categories);
  };

  useEffect(() => {
    typesDiscount();
  }, []);

  return (
    <Box p={4}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <FormControl>
            <FormLabel fontSize="2xl">
              Porcentagem de desconto (0-100)
            </FormLabel>
            <InputGroup>
              <InputLeftAddon children="%" h={"16"} fontSize="1.2rem" />
              <Input
                type="number"
                placeholder="10, 15, 20..."
                onChange={(e) => setAmount(Number(e.target.value))}
                fontSize="2xl"
                h={16}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel fontSize="2xl">Tipo de desconto</FormLabel>
            <Select
              fontSize="2xl"
              h={16}
              onChange={(e) => setType(e.target.value as DiscountType)}
              fontWeight={"semibold"}
            >
              <option value="">SELECIONE UM TIPO</option>
              <option value="CATEGORY_ID">CATEGORIA</option>
              <option value="PACK_BIWEEKLY">PACOTE QUINZENAL</option>
              <option value="PACK_WEEKLY">PACOTE SEMANAL</option>
            </Select>
          </FormControl>
          <FormControl>
            {type === "CATEGORY_ID" && (
              <Select
                fontSize="2xl"
                h={16}
                onChange={(e) => setTypeValue(e.target.value)}
              >
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name.toUpperCase()}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>
          <Divider my={4} />
          <Button
            type="submit"
            fontSize="2xl"
            h={16}
            colorScheme="green"
            variant="solid"
          >
            Adicionar Desconto
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
