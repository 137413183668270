import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { ErrorResponse, onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from '@apollo/client/link/context';

const errorLink: any = onError(
  ({ graphQLErrors }: ErrorResponse) => {
    let get401 = false;
    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError: any) => {
        if (!get401) {
          get401 = Number(graphQLError.code) === 401;
        }
      });
    }

    if (get401) {
      localStorage.removeItem("@zazuu:admin:user:token");
      localStorage.removeItem("@zazuu:admin:user");
      localStorage.removeItem("@zazuu:admin:customer");
      localStorage.removeItem("@zazuu:cart");

      window.location.href = "/";
    }
  }
);

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("@zazuu:admin:user:token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink, httpLink, errorLink]),
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
});

export default client;
