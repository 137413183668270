import styled from "styled-components";
import colors from "../../shared/utils/constants/colors";

export const PanelHeader = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${colors.primary.default};
  color: ${colors.argon.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PanelItens = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  margin-left: 3%;
`;

export const SeachDisplay = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SearchText = styled.input`
  margin: 0 8px;
  padding: 2px 4px;
  color: ${colors.argon.textColorGray};
  border-radius: 5px;
  border: 1px solid ${colors.argon.textColorLight02};
`;
