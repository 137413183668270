import { gql } from "@apollo/client";
import client from "../../services/api-graphql";
import RefundTypeEnum from "../../shared/enums/RefundTypeEnum";
import IOrder from "../../types/IOrder";

interface IFiltersAppointments {
  pagination?: {
    limit: number;
    page: number;
  };
  orderBy?: {
    created_at: "asc" | "desc";
  }[];
  where: {
    from?: string;
    to?: string;
    ids?: string[];
  };
}

interface ICard {
  id: string,
  last_digits: string,
  brand: string,
  exp_month: string,
  exp_year: string
}

const changePaymentTypeOfOrder = async (
  orderId: string,
  type: number,
  cardId: string | undefined,
): Promise<IOrder> => {
  const results = await client.mutate({
    mutation: gql`
      mutation ChangeOrderTransactionType($type: Float!, $orderId: String!, $cardId: String) {
        changeOrderTransactionType(type: $type, order_id: $orderId, cardId: $cardId) {
          id
          created_at
          platform
          pending_price
          customer {
            user {
              cpf
              name
            }
          }
          transactions {
            id
            type
            amount
            transaction_pagseguro_id
            payload
            status
          }
          status
          appointments {
            id
            service_price
            paid_price
            appointments_items {
              item_price
              paid_price
            }
          }
        }
      }
    `,
    variables: {
      orderId,
      type,
      cardId
    },
  });
  return results.data.changeOrderTransactionType;
};

const refundPendingValues = async (
  orderId: string,
  refundType: number
): Promise<IOrder> => {
  const results = await client.mutate({
    mutation: gql`
      mutation ($refundType: Float!, $orderId: String!) {
        refundPendingValue(refund_type: $refundType, order_id: $orderId) {
          id
          created_at
          platform
          pending_price
          customer {
            user {
              cpf
              name
            }
          }
          transactions {
            type
            amount
            transaction_pagseguro_id
            payload
          }
          status
          appointments {
            id
          }
        }
      }
    `,
    variables: {
      orderId,
      refundType,
    },
  });
  return results.data.refundPendingValue;
};

const createTransactionFromPendingValues = async (
  orderId: string,
  payment_type: number
): Promise<IOrder> => {
  const results = await client.mutate({
    mutation: gql`
      mutation ($paymentType: Float!, $orderId: String!) {
        createTransactionFromPendingValues(
          payment_type: $paymentType
          order_id: $orderId
        ) {
          id
          created_at
          platform
          pending_price
          customer {
            user {
              cpf
              name
            }
          }
          transactions {
            type
            amount
            transaction_pagseguro_id
            payload
            status
          }
          status
          appointments {
            id
          }
        }
      }
    `,
    variables: {
      orderId: orderId,
      paymentType: payment_type,
    },
  });
  return results.data.createTransactionFromPendingValues;
};

const cancelOrder = async (payload: {
  order_id: string;
  refund_type: RefundTypeEnum;
}): Promise<IOrder> => {
  const response = await client.mutate({
    mutation: gql`
      mutation ($refundType: Float!, $orderId: String!) {
        cancelOrder(refund_type: $refundType, order_id: $orderId) {
          id
          created_at
          platform
          pending_price
          customer {
            user {
              cpf
              name
            }
          }
          transactions {
            type
            amount
            transaction_pagseguro_id
            payload
          }
          status
          appointments {
            id
          }
        }
      }
    `,
    variables: {
      orderId: payload.order_id,
      refundType: payload.refund_type,
    },
  });

  return response.data.cancelOrder;
};

const approveOrder = async (orderId: string): Promise<IOrder> => {
  const response = await client.mutate({
    mutation: gql`
      mutation ApproveOrder($orderId: String!) {
        approveOrder(order_id: $orderId) {
          id
          created_at
          platform
          pending_price
          customer {
            user {
              cpf
              name
            }
          }
          transactions {
            type
            amount
            transaction_pagseguro_id
            payload
          }
          status
          appointments {
            id
          }
        }
      }
    `,
    variables: {
      orderId,
    },
  });

  return response.data.approveOrder;
};

const searchOrders = async (
  filters?: IFiltersAppointments
): Promise<{ orders: IOrder[]; totalPages: number }> => {
  const response = await client.query({
    query: gql`
      query Orders($filters: FiltersOrderInputType) {
        orders(filters: $filters) {
          totalPages
          orders {
            id
            created_at
            platform
            pending_price
            paid_price
            total_discount
            sales_order {
              total_price
            }
            customer {
              user {
                cpf
                name
                id
              }
            }
            transactions {
              type
              amount
              transaction_pagseguro_id
              payload
              status
            }
            status
            appointments {
              id
              paid_price
              service_price
              appointments_items {
                paid_price
                item_price
              }
            }
          }
        }
      }
    `,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.orders;
};

const getUserCards = async (
  customerId: string
): Promise <ICard[]>  => {
  const response = await client.query({
    query: gql`
     query GetCardsByUser($customerId: String!) {
      getCardsByCustomer(customerId: $customerId) {
        id
        last_digits
        brand
        exp_month
        exp_year
      }
    }
    `,
    variables: {
      customerId: customerId,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.getCardsByCustomer;
};

export {
  changePaymentTypeOfOrder,
  createTransactionFromPendingValues,
  cancelOrder,
  approveOrder,
  searchOrders,
  refundPendingValues,
  getUserCards
};
