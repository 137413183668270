import React, {  useRef } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import { IRegisterPackagingFormData } from 'pages/Stock/constants/types';
import { RegisterPackagingFormValidationSchema } from './RegisterPackagingValidations';

export type IRegisterPackagingFormProps = {
    onSubmit: (payload: IRegisterPackagingFormData, options: { reset: () => void }) => Promise<void>;
}

export const RegisterPackagingFormId = 'register-packaging-form';

const RegisterPackagingForm: React.FC<IRegisterPackagingFormProps> = ({ onSubmit }) => {

    const registerPackagingFormRef = useRef<FormHandles>(null);

    const validateFields = async (
        payload: IRegisterPackagingFormData,
        options: { reset: () => void }
    ) => {
        try {
            await RegisterPackagingFormValidationSchema.validate(payload, { abortEarly: false });
            onSubmit(payload, options);
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            registerPackagingFormRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };  

    return (
        <>
            <Form onSubmit={validateFields} ref={registerPackagingFormRef} id={RegisterPackagingFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Nome</Label>
                        <InputRef
                            name="name"
                            placeholder="Digite o nome da embalagem"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default RegisterPackagingForm