import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import Text from "components/Text";
import React, { useState } from "react";
import { toast } from "react-toastify";
import colors from "shared/utils/constants/colors";
import processError from "shared/utils/processError";
import ICep from "types/ICep";
import { updateCep } from "./graphQL";
import * as Styles from "./styles";

type IProps = {
  ceps: ICep[];
  onUpdateCepStatus: (cep: ICep) => void;
  handleAddCep: (payload: {
    start_cep: string;
    end_cep: String;
  }) => Promise<void>;
};

const CepsSession = ({ ceps, onUpdateCepStatus, handleAddCep }: IProps) => {
  const [showModalAddCep, setShowModalAddCep] = useState(false);
  const [newCep, setNewCep] = useState({
    start: "",
    end: "",
  });
  const toggleCepStatus = async (cep: ICep) => {
    try {
      const cepUpdated = await updateCep({
        id: cep.id,
        status: !cep.status,
      });
      onUpdateCepStatus(cepUpdated);
    } catch (error) {
      toast.error(processError(error, "GRAPHQL").message);
    }
  };

  const onHandleAddCep = async () => {
    await handleAddCep({
      start_cep: newCep.start,
      end_cep: newCep.end,
    });
    setNewCep({
      start: "",
      end: "",
    });
    setShowModalAddCep(false);
  };

  return (
    <Styles.Container>
      <Text text="Ceps" fontFamily="Open Sans" weight="600" />
      <Styles.CepContainer>
        <Styles.CepCard
          status={true}
          onClick={() => setShowModalAddCep(true)}
          style={{
            background: colors.gray.white,
            color: colors.gray.dark02,
          }}
        >
          Adicionar cep
        </Styles.CepCard>
        {ceps.map((cep) => (
          <Styles.CepCard
            key={cep.id}
            status={cep.status}
            onClick={() => toggleCepStatus(cep)}
          >
            <Text
              text={`${cep.start_cep} - ${cep.end_cep}`}
              fontFamily="Open Sans"
              whiteSpace="nowrap"
              color={colors.gray.white}
            />
          </Styles.CepCard>
        ))}
      </Styles.CepContainer>
      <Modal
        visible={showModalAddCep}
        closeModal={() => setShowModalAddCep(false)}
      >
        <Text text="Adiconar Zona" weight="600" size={24} />
        <Input
          name="cep start"
          placeholder="Cep inicial (04020)"
          value={newCep.start}
          onChange={(e) => setNewCep({ ...newCep, start: e.target.value })}
          containerStyle={{ marginTop: 16 }}
        />
        <Text
          text="*pode ser usado 3 - 8 digitos"
          fontFamily="Open Sans"
          color={colors.suport.alert}
          weight="400"
          size={14}
        />
        <Input
          name="cep end"
          placeholder="Cep final (04052)"
          value={newCep.end}
          onChange={(e) => setNewCep({ ...newCep, end: e.target.value })}
          containerStyle={{ marginTop: 16 }}
        />
        <Text
          text="*pode ser usado 3 - 8 digitos"
          fontFamily="Open Sans"
          color={colors.suport.alert}
          weight="400"
          size={14}
        />
        <Button
          text="Adicionar"
          onClick={() => onHandleAddCep()}
          styleContainer={{ marginTop: 16 }}
        />
      </Modal>
    </Styles.Container>
  );
};

export default CepsSession;
