import { useAuth } from "hooks/Auth";
import React from "react";
import Lottie from "react-lottie";
import { toast } from "react-toastify";
import confetti from "../../assets/confetti.json";
import {
  Container,
  Profile,
  ProfileEmail,
  ProfileImage,
  ProfileName,
} from "./styles";

const Dashboard: React.FC = () => {
  const [isBirthday, setIsBirthday] = React.useState(false);
  // const [appointments, setAppointments] = React.useState([] as any);
  const { user } = useAuth();

  const verifyDateBirthday = () => {
    let day = new Date().getDate();
    let month = new Date().getMonth() + 1;
    let monthFormated = month <= 9 ? "0" + month.toString() : month.toString();
    let dayFormated = day <= 9 ? "0" + day.toString() : day.toString();
    if (user.birthday.split("-")[1] === monthFormated) {
      if (user.birthday.split("-")[2] === dayFormated) {
        setIsBirthday(true);
        toast.success("Zazuu deseja um feliz aniversário!");
        return;
      }
    }
    setIsBirthday(false);
  };

  React.useEffect(() => {
    verifyDateBirthday();
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <Lottie
        options={{
          loop: false,
          animationData: confetti,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={750}
        width={750}
        style={{
          zIndex: 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: isBirthday ? "block" : "none",
        }}
      />
      <Container>
        <Profile>
          <ProfileImage />
          <div>
            <ProfileName>{user?.name}</ProfileName>
            <ProfileEmail>
              <span>
                <strong>{user?.email}</strong>
              </span>
            </ProfileEmail>
          </div>
        </Profile>
        {/* <ContainerContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Header>Ultimo Agendamento</Header>
            {appointments.length > 0 && (
              <StatusAppointment>
                <GrStatusGoodSmall size={20} style={{ marginRight: "5px" }} />
                {AppointmentStatusEnum[appointments[0].status]}
              </StatusAppointment>
            )}
          </div>
          <ContainerAppointments>
            {appointments.length > 0 ? (
              <>
                <Pet>
                  <MdPets
                    size={40}
                    style={{ marginRight: "10px", color: "#CCD500" }}
                  />
                  <div>
                    <NamePet>
                      Nome: {appointments[0].pet.name},{" "}
                      {appointments[0].pet.specie.name}
                    </NamePet>
                    <ServiceAppointment>
                      Serviço: {appointments[0].service.name}
                    </ServiceAppointment>
                  </div>
                </Pet>
                <>
                  {appointments[0].appointments_items.map((item: any) => {
                    return (
                      <div key={item.id}>
                        <span
                          style={{
                            color: "#CCD500",
                            fontWeight: "bold",
                            fontSize: "12px",
                          }}
                        >
                          - {item.item.name}
                        </span>
                      </div>
                    );
                  })}
                </>
                <AddressAppointment>
                  <BiMap size={20} style={{ marginRight: "5px" }} />
                  {appointments[0].address.street},{" "}
                  {appointments[0].address.number} -{" "}
                  {appointments[0].address.neighborhood},{" "}
                  {appointments[0].address.city} -{" "}
                  {appointments[0].address.state}
                </AddressAppointment>
              </>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TiWarningOutline
                  size={30}
                  style={{ color: "#CCD500", marginBottom: "10px" }}
                />
                <span style={{ color: "#CCD500", fontWeight: "bold" }}>
                  Nenhum agendamento encontrado
                </span>
              </div>
            )}
          </ContainerAppointments>
        </ContainerContent> */}
      </Container>
    </>
  );
};
export default Dashboard;
