import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppProvider from "./hooks/index";
import Routes from "./routes";
import GlobalStyle from "./styles/global";
import { ContainerApp } from "./styles/styles";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        <ContainerApp>
          <Routes />
        </ContainerApp>
      </AppProvider>
      <GlobalStyle />
    </BrowserRouter>
  );
};

export default App;
