import React from "react";
import * as S from "./styles";
import Pagination from "../../../../components/Pagination";
import { random } from "lodash";
import { IAppointmentReport } from "../types";

interface IProps {
  data: IAppointmentReport[];
}

const TableRow = ({ appointment }: { appointment: IAppointmentReport }) => {
  return (
    <S.StyledTR key={`appointment-${random(0, 1000000)}`}>
      <S.StyledTD>{appointment.professional.name || "SEM REGISTRO"}</S.StyledTD>
      <S.StyledTD>{appointment.period}</S.StyledTD>
      <S.StyledTD>{appointment.observations}</S.StyledTD>
      <S.StyledTD>{appointment.pet_permanent_observations}</S.StyledTD>
      <S.StyledTD>{appointment.customer_permanent_observations}</S.StyledTD>
      <S.StyledTD>{appointment.deslocation_time}</S.StyledTD>
      <S.StyledTD>{appointment.difference_time}</S.StyledTD>
      <S.StyledTD>{appointment.start_time}</S.StyledTD>
      <S.StyledTD>{appointment.end_time}</S.StyledTD>
      <S.StyledTD>{appointment.real_start_time}</S.StyledTD>
      <S.StyledTD>{appointment.real_end_time}</S.StyledTD>
      <S.StyledTD>{appointment.customer_name}</S.StyledTD>
      <S.StyledTD>{appointment.customer_cpf}</S.StyledTD>
      <S.StyledTD>{appointment.pet_name}</S.StyledTD>
      <S.StyledTD>{appointment.pet_breed}</S.StyledTD>
      <S.StyledTD>{appointment.pet_gender}</S.StyledTD>
      <S.StyledTD>{appointment.pet_specie}</S.StyledTD>
      <S.StyledTD>{appointment.pet_size}</S.StyledTD>
      <S.StyledTD>{appointment.cep}</S.StyledTD>
      <S.StyledTD>{appointment.address_street}</S.StyledTD>
      <S.StyledTD>{appointment.address_neighborhood}</S.StyledTD>
      <S.StyledTD>{appointment.address_number}</S.StyledTD>
      <S.StyledTD>{appointment.service_name}</S.StyledTD>
      <S.StyledTD>{appointment.additionals}</S.StyledTD>
      <S.StyledTD>{appointment.service_full_price}</S.StyledTD>
      <S.StyledTD>{appointment.additionals_full_price}</S.StyledTD>
      <S.StyledTD>{appointment.discount}</S.StyledTD>
      <S.StyledTD>{appointment.coupon_relation}</S.StyledTD>
      <S.StyledTD>{appointment.paided_price}</S.StyledTD>
      <S.StyledTD>{appointment.payment}</S.StyledTD>
      <S.StyledTD>{appointment.observations_admin}</S.StyledTD>
      <S.StyledTD>{appointment.power_supply}</S.StyledTD>
      <S.StyledTD>{appointment.platform}</S.StyledTD>
    </S.StyledTR>
  );
};

const AppointmentsReportTable: React.FC<IProps> = ({
  data,
}) => {
  const renderTableHeader = () => {
    const headers = [
      "PROFISSIONAL",
      "PERÍODO",
      "OBS. AGENDAMENTO",
      "OBS. PET",
      "OBS. CLIENTE",
      "DESL.",
      "TEMPO",
      "INÍCIO",
      "FIM",
      "INÍCIO REAL",
      "FIM REAL",
      "TUTOR",
      "CPF",
      "PET",
      "RAÇA",
      "SEXO",
      "ESPÉCIE",
      "PORTE",
      "CEP",
      "ENDEREÇO",
      "BAIRRO",
      "NUMERO",
      "SERVIÇO",
      "ADICIONAIS",
      "VALOR DO SERVICO",
      "VALOR DO ADICIONAL",
      "DESCONTO",
      "CUPOM",
      "VALOR FINAL",
      "PAGAMENTO",
      "OBSERVAÇÕES",
      "GERADOR - TOMADA",
      "CRIADO PELO",
    ];

    return (
      <S.StyledTHead>
        {headers.map((header) => (
          <S.StyledTH key={header}>{header}</S.StyledTH>
        ))}
      </S.StyledTHead>
    );
  };

  const renderTableBody = () => {
    return (
      <S.StyledTBody>
        {data.map((appointment) => (
          <TableRow
            key={`appointment-${random(0, 1000000)}`}
            appointment={appointment}
          />
        ))}
      </S.StyledTBody>
    );
  };

  return (
    <>
      <S.TableContainer>
        <S.StyledTable id="datatable">
          {renderTableHeader()}
          {renderTableBody()}
        </S.StyledTable>
      </S.TableContainer>
    </>
  );
};

export default AppointmentsReportTable;
