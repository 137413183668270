import { gql } from "@apollo/client";
import client from "../api-graphql";

export const createProduct = async (input: any) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation ($input: CreateProductInputType!) {
        createProduct(input: $input) {
          id
          category {
            name
          }
          brand {
            name
          }
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: "network-only",
  });

  return data;
};

export const getProduct = async (productId: number) => {
  const { data } = await client.query({
    query: gql`
      query ($productId: Float!) {
        product(productId: $productId) {
          id
          description
          manufacturer_sku_code
          name
          brand {
            name
          }
          quantity
          cost_price
          stock_unit
          selling_price
          minimum_stock
          maximum_stock
          comission_percentage
          class
          category {
            name
          }
          packaging {
            name
          }
        }
      }
    `,
    variables: {
      productId,
    },

    fetchPolicy: "network-only",
  });
  return data.product;
};

export const getAllProducts = async (filters: any) => {
  const { data } = await client.query({
    query: gql`
      query {
        products {
          id
          name
          description
          manufacturer_sku_code
          brand {
            name
          }
          quantity
          cost_price
          stock_unit
          selling_price
          minimum_stock
          maximum_stock
          comission_percentage
          class
          category {
            name
          }
        }
      }
    `,
    variables: {
      filters,
    },

    fetchPolicy: "network-only",
  });
  return data.products;
};

export const updateProduct = async (input: any) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation ($input: UpdateProductInputType!) {
        updateProduct(input: $input) {
          id
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: "network-only",
  });

  return data;
};

export const stockAction = async (input: any) => {
  input.map((item: any) => delete item.nameProduct);
  await client.mutate({
    mutation: gql`
      mutation ($input: [StockActionInputType!]!) {
        stockAction(input: $input)
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: "network-only",
  });
};

export const getAllLogs = async () => {
  const { data } = await client.query({
    query: gql`
      query {
        products {
          logs {
            description
            created_at
          }
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return data.products;
};

export const removeProduct = async (productId: number) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation ($productId: Float!) {
        removeProduct(productId: $productId) {
          id
        }
      }
    `,
    variables: {
      productId,
    },
    fetchPolicy: "network-only",
  });

  return data;
};

export const productActionsReport = async (input: any) => {
  if (!input.productId) delete input.productId;
  if (!input.truckId) delete input.truckId;
  if (!input.categoryId) delete input.categoryId;


  const { data } = await client.query({
    query: gql`
      query ($input: ProductActionReportInputType!) {
        productActionsReport(input: $input)
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: "network-only",
  });
  return data.productActionsReport;
};

export const getAllCategories = async () => {
  const { data } = await client.query({
    query: gql`
      query {
        productCategories {
          id
          name
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return data.productCategories;
};

export const getAllBrands = async () => {
  const { data } = await client.query({
    query: gql`
      query {
        brands {
          id
          name
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return data.brands;
};

export const getPackagings = async () => {
  const { data } = await client.query({
    query: gql`
      query {
        packagings {
          id
          name
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return data.packagings;
};

export const getSuppliers = async () => {
  const { data } = await client.query({
    query: gql`
      query {
        suppliers {
          id
          name
        }
      }
    `,
    fetchPolicy: "network-only",
  });
  return data.suppliers;
};
