import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import Text from "../../components/Text";
import colors from "../../shared/utils/constants/colors";
import * as Styles from "./styles";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 300);

  return (
    <Styles.SearchContainer>
      <Text
        text="Pesquisar:"
        color={colors.argon.textColorDark}
        fontFamily="Open Sans"
        size={14}
        weight="400"
        marginRight={4}
        marginTop={0}
      />
      <Styles.SearchText
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Digite uma palavra"
      ></Styles.SearchText>
    </Styles.SearchContainer>
  );
}

export default GlobalFilter;
