enum StatusTransactionEnum {
  paided = 'P',
  waiting = 'W',
  cancelled = 'C',
  changes_waiting_action = 'U',
  refunded = 'R',
  refund_waiting_authorization = 'A',
}

export default StatusTransactionEnum;
