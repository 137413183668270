import Modal from 'components/Modal';
import React, { useCallback } from 'react';
import * as Styles from '../styles';
import Text from 'components/Text';
import colors from 'shared/utils/constants/colors';
import Button from "components/Button";
import { ApolloQueryResult, gql, useMutation } from '@apollo/client';
import { launchToast } from 'shared/utils/launchToast';
import EditBalanceWalletForm, { EditBalanceWalletFormId } from './form/EditBalanceWalletForm';
import { IBalanceCategoryWallet, IEditBalanceWalletInput } from '../types';


export type IEditBalanceWalletModalProps = {
    isOpen: boolean;
    onClose: () => void;
    categoryBalance: IBalanceCategoryWallet;
    walletId: number
    walletRefeth: (variables?: Partial<{
        getUserId: string;
        filters: {};
    }> | undefined) => Promise<ApolloQueryResult<any>>
};

const ALTER_BALANCE = gql`
    mutation AlterBalance($payload: AlterBalanceInputType!) {
        alterBalance(payload: $payload) {
            veterinary
            vacines
            grooming_bath_and_well_being
            products
            all_services
            totalBalance
        }
    }
`;

const EditBalanceWalletModal: React.FC<IEditBalanceWalletModalProps> = ({ isOpen, onClose, categoryBalance, walletId, walletRefeth}) => {

    const [alterBalance, { loading }] = useMutation(ALTER_BALANCE);

    const handleSubmit = useCallback(
        async (
            payload: IEditBalanceWalletInput,
            { reset }
        ) => {
            try {
                await alterBalance({
                    variables: {
                        payload
                    },
                });
                await walletRefeth()
                reset();
                onClose();
                launchToast('Saldo alterado com sucesso!', 'success');
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        },
        [alterBalance, walletRefeth],
    );

    return (
        <Modal visible={isOpen} closeModal={onClose}>
            <Styles.header>
                <Text
                    text={`${categoryBalance.NomalizedCategory}`}
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
            </Styles.header>
            <Styles.FormDiv>
                <EditBalanceWalletForm onSubmit={handleSubmit} categoryBalance={categoryBalance} walletId={walletId} isLoading={loading}/>
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button text="Salvar" type="submit" form={EditBalanceWalletFormId} loading={loading} />
            </Styles.rowButton>
        </Modal>
    )
}

export default EditBalanceWalletModal