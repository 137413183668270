import styled from "styled-components";
import colors from "../../../../shared/utils/constants/colors";

export const Text = styled.span`
  color: ${colors.gray.dark02};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  margin-left: 8px;
`;

export const PaginationButtom = styled.span`
  color: ${colors.gray.dark02};
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  margin-left: 8px;
  cursor: pointer;
`;
