import { gql } from "@apollo/client";
import { ExcludeChar } from "../../shared/utils/excludeChar";
import client from "../api-graphql";
import * as query from "./UserQuery";

export const getAllUsers = async (filters: any): Promise<any[]> => {
  try {
    const response = await client.query({
      query: query.GET_ALL_USERS,
      variables: {
        filters,
      },
      fetchPolicy: "no-cache",
    });
    return response.data.users;
  } catch (error) {
    throw error;
  }
};

export const insertUser = async (
  user?: any,
  roles: any = []
): Promise<any[]> => {
  const {
    birthday,
    cep,
    city,
    cpf,
    email,
    name,
    neighborhood,
    number,
    phone,
    state,
    street,
    surname,
    complement,
  } = user;

  const response = await client.mutate({
    mutation: query.CREATE_USER,
    variables: {
      user: {
        name,
        email,
        first_access: true,
        cpf: ExcludeChar(cpf),
        phone: ExcludeChar(phone),
        birthday,
        address: {
          cep: ExcludeChar(cep),
          number,
          city,
          neighborhood,
          street,
          state,
          surname,
          complement,
        },
      },
      roles,
    },
  });

  return response.data.createUserByAdmin;
};

export const updateUser = async (
  id: string,
  user?: any,
  roles: any = []
): Promise<any[]> => {
  const {
    birthday,
    cpf,
    email,
    name,
    phone,
    cep,
    number,
    city,
    neighborhood,
    street,
    complement,
    surname,
    state,
    status,
  } = user;

  let auxRoles = roles.map((r: any) => r.id);

  const response = await client.mutate({
    mutation: query.UPDATE_USER,
    variables: {
      user: {
        id,
        name,
        email,
        phone: ExcludeChar(phone),
        birthday,
        cpf: ExcludeChar(cpf),
        rolesIds: auxRoles,
        status,
        main_address: {
          cep: ExcludeChar(cep),
          number,
          city,
          neighborhood,
          street,
          complement,
          surname,
          state,
        },
      },
    },
  });

  return response.data.updateUserByAdmin;
};

export const updateVet = async (vet?: any): Promise<any[]> => {
  const { professionalId, crmv, crmv_uf } = vet;

  const response = await client.mutate({
    mutation: query.UPDATE_PROFESSIONAL,
    variables: {
      professionalId,
      professional: {
        crmv,
        crmv_uf,
      },
    },
  });

  return response.data;
};

export const updateGroomer = async (groomer?: any): Promise<any[]> => {
  const {
    professionalId, validity, number, category, cnh_id, truck_id, report_visibility,
  } = groomer;

  const response = await client.mutate({
    mutation: query.UPDATE_PROFESSIONAL,
    variables: {
      professionalId,
      professional: {
        truck_id,
        number,
        validity,
        category,
        cnh_id,
        report_visibility,
      },
    },
  });

  return response.data;
};

export const updateProfession = async (professional?: any): Promise<any[]> => {
  const {
    professionalId, validity, number, category, cnh_id, truck_id, crmv, crmv_uf, outsourced, truck_rent, report_visibility
  } = professional;

  const response = await client.mutate({
    mutation: query.UPDATE_PROFESSIONAL,
    variables: {
      professionalId,
      professional: {
        truck_id,
        number,
        validity,
        category,
        cnh_id,
        crmv,
        crmv_uf,
        outsourced,
        truck_rent,
        report_visibility,
      },
    },
  });

  return response.data;
};

export const insertCustommer = async (custommer?: any): Promise<any[]> => {
  const { userId, meetUs } = custommer;

  const response = await client.mutate({
    mutation: query.CREATE_CUSTOMMER,
    variables: {
      userId,
      meetUs: parseInt(meetUs),
    },
  });

  return response.data;
};

export const updateCustommer = async (custommer?: any): Promise<any[]> => {

  const { userId: customerId, meetUs } = custommer;
  let customer = { meet_us: parseFloat(meetUs) };

  const response = await client.mutate({
    mutation: query.UPDATE_CUSTOMMER,
    variables: {
      customerId,
      customer,
    },
  });

  return response.data;
};

export const insertGroomer = async (grooomer?: any): Promise<any[]> => {
  const { userId, truck: truckId, cnhNumber, category, validity, truck_rent, outsourced } = grooomer;

  const response = await client.mutate({
    mutation: query.CREATE_GROOMER,
    variables: {
      userId,
      truckId,
      cnhNumber,
      category,
      validity,
      truck_rent,
      outsourced
    },
  });

  return response.data;
};

export const inactivetUser = async (
  id: string,
  user: any,
  status: any
): Promise<any[]> => {
  const response = await client.mutate({
    mutation: query.UPDATE_USER,
    variables: {
      user: {
        id,
        status: !!user.status,
      },
    },
  });

  return response.data;
};

export const getNewCustomers = async (filters?: any): Promise<any[]> => {
  const response = await client.query({
    query: gql`
      query ListNewCustomers($filters: FilterNewCustomersInputType) {
        listNewCustomers(filters: $filters) {
          totalPages
          customers {
            id
            ref_code
            user {
              id
              name
              email
              cpf
              phone
              created_at
              address_main_id
            }
          }
        }
      }
    `,
    variables: {
      filters: filters,
    },
  });
  return response.data.listNewCustomers;
};

export const getUserAdress = async (userId: string): Promise<any[]> => {
  const response = await client.query({
    query: gql`
    {
      AddressesCustomerByAdmin${userId ? `(user_id: ${JSON.stringify(userId)})` : ""
      } {
        id
        cep
        street
        number
        neighborhood
        city
        state
        complement
    }
  }
  `,
  });
  return response.data;
};

export const insertCnh = async (cnh?: any): Promise<any[]> => {
  const { validity, category, cnhNumber } = cnh;

  const response = await client.mutate({
    mutation: gql`
      mutation CreateCnhByAdmin(
        $validity: String!
        $category: String!
        $cnhNumber: String!
      ) {
        createCnhByAdmin(
          validity: $validity
          category: $category
          cnh_number: $cnhNumber
        ) {
          id
        }
      }
    `,
    variables: {
      validity,
      category,
      cnhNumber,
    },
  });

  return response.data.createCnhByAdmin;
};
