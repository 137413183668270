import React, { FC } from "react";
import {
  Title,
  ContainerUsers,
  SearchUser,
  AddButton,
  NoDataAlert,
  ContainerUsersList,
  FilterOption,
} from "./styles";
import IUser from "types/IUser";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";
import { BsFillTrashFill } from "react-icons/bs";
import Loading from "components/Loading";
import { createUserPartner, removeUserPartner } from "../graphQL";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { RiUserSearchLine } from "react-icons/ri";

interface IAddPeopleProps {
  usersFromPartner: IUser[];
  partnerId: string;
  setUsersFromPartner: (users: IUser[]) => void;
  notAssociatedUsers: IUser[];
  setSearch: (search: string) => void;
  search: string;
}

export const AddPeople: FC<IAddPeopleProps> = ({
  usersFromPartner,
  partnerId,
  setUsersFromPartner,
  notAssociatedUsers,
  setSearch,
  search,
}) => {
  const [usersSelected, setUsersSelected] = React.useState([] as IUser[]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSearchUserVisible, setIsSearchUserVisible] = React.useState(false);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setSearch("");
      const usersIds = usersSelected.map((user: IUser) => user.id) as string[];
      await createUserPartner(partnerId, usersIds);
      toast.success("Usuários adicionados com sucesso!", {
        autoClose: 2000,
      });
      setUsersFromPartner([...usersFromPartner, ...usersSelected]);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao adicionar usuários!");
    } finally {
      setUsersSelected([] as IUser[]);
      setIsLoading(false);
    }
  };

  const handleRemoveUser = async (user: IUser) => {
    if (!user) toast.error("Erro ao remover usuário!");

    try {
      setIsLoading(true);
      await removeUserPartner(partnerId, user.id);
      toast.success("Usuário removido com sucesso!", {
        autoClose: 2000,
      });
      const newUsersFromPartner = usersFromPartner.filter(
        (userPartner: IUser) => userPartner.id !== user.id
      );
      setUsersFromPartner(newUsersFromPartner);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao remover usuário!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUser = (user: IUser) => {
    const selectedUserId = user.id;
    const selectedUser = notAssociatedUsers.find(
      (user: IUser) => user.id === selectedUserId
    );
    if (selectedUser) {
      setUsersSelected([...usersSelected, selectedUser]);
    }
  };

  const removeUserSelected = (user: IUser) => {
    const newUsersSelected = usersSelected.filter((userSelected: IUser) => {
      return userSelected.id !== user.id;
    });
    setUsersSelected(newUsersSelected);
  };

  return (
    <>
      <div
        style={{
          width: "800px",
          height: "500px",
        }}
        onClick={() => setIsSearchUserVisible(false)}
      >
        {isLoading && <Loading />}
        <Box mt="20px">
          <Box width="100%">
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon
                  as={RiUserSearchLine}
                  fontSize={20}
                  mt={"1.5rem"}
                  ml={"1.5rem"}
                />
              </InputLeftElement>
              <Input
                id="partnerPercentagePaid"
                placeholder="Pesquisar usuário"
                type="text"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsSearchUserVisible(true);
                }}
                _focus={{
                  border: "1px solid #CBD5E0",
                  boxShadow: "0 0 6px rgba(30, 200, 96, 0.4)",
                }}
                width="100%"
                h={"16"}
                fontSize="1.5rem"
                pl="40px"
                pr="10px"
              />
            </InputGroup>
            <Box
              display={isSearchUserVisible ? "block" : "none"}
              boxShadow={"0 0 6px rgba(30, 200, 96, 0.4)"}
              position="absolute"
              width="35%"
              zIndex="1"
              bg="white"
              rounded="md"
              h={notAssociatedUsers.length > 5 ? "200px" : "80px"}
              mt="1rem"
              overflowY="auto"
            >
              {notAssociatedUsers.length > 0 ? (
                notAssociatedUsers.map((user: IUser) => {
                  return (
                    <FilterOption
                      key={user.id}
                      onClick={() => handleAddUser(user)}
                    >
                      <AiOutlineUserAdd
                        size={20}
                        style={{ marginRight: "0.5rem" }}
                      />
                      {user.name}
                    </FilterOption>
                  );
                })
              ) : (
                <FilterOption>
                  <FiAlertTriangle
                    size={20}
                    style={{ marginRight: "0.5rem" }}
                  />
                  Nenhum usuário encontrado
                </FilterOption>
              )}
            </Box>
          </Box>
          {usersSelected.length > 0 && (
            <>
              <Title style={{ fontSize: "1.5rem", marginTop: "1.5rem" }}>
                Usuários aguardando para serem adicionados
              </Title>
              <ContainerUsersList style={{ height: "100px" }}>
                {usersSelected.map((user: IUser) => {
                  return (
                    <ContainerUsers key={user.id}>
                      <p
                        style={{
                          cursor: "pointer",
                          color: "#ccd500",
                          fontWeight: "bold",
                        }}
                      >
                        {user.name}
                      </p>
                      <BsFillTrashFill
                        size={20}
                        style={{ cursor: "pointer", marginRight: "1rem" }}
                        onClick={() => removeUserSelected(user)}
                      />
                    </ContainerUsers>
                  );
                })}
              </ContainerUsersList>
            </>
          )}
          {usersFromPartner.length > 0 ? (
            <>
              <Title style={{ fontSize: "1.5rem", marginTop: "1.5rem" }}>
                Usuários já adicionados
              </Title>
              <ContainerUsersList>
                {usersFromPartner.map((userPartner: IUser) => {
                  return (
                    <ContainerUsers key={userPartner.id}>
                      <p
                        style={{
                          cursor: "pointer",
                          color: "#ccd500",
                          fontWeight: "bold",
                        }}
                      >
                        {userPartner.name}
                      </p>
                      <BsFillTrashFill
                        size={20}
                        style={{ cursor: "pointer", marginRight: "1rem" }}
                        onClick={() => handleRemoveUser(userPartner)}
                      />
                    </ContainerUsers>
                  );
                })}
              </ContainerUsersList>
            </>
          ) : (
            <NoDataAlert>
              <FiAlertTriangle size={25} />
              <p>Nenhum usuário adicionado a esse parceiro ainda!</p>
            </NoDataAlert>
          )}
        </Box>
      </div>
      {usersSelected.length > 0 && (
        <Button
          width={"100%"}
          height={16}
          colorScheme="green"
          variant={"solid"}
          onClick={handleSubmit}
          leftIcon={<AiOutlineUserAdd size={20} />}
          fontSize="1.5rem"
        >
          Adicionar
        </Button>
      )}
    </>
  );
};
