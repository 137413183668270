import colors from "shared/utils/constants/colors";
import styled from "styled-components";

export const PanelItens = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
`;

export const BackgroundPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
`;

export const PanelHeader = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${colors.primary.default};
  color: ${colors.argon.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WhiteButton = styled.button`
  margin: 5%;
  background-color: ${colors.argon.white};
  border: 2px ${colors.argon.default} solid;
  border-radius: 5px;
  width: 100px;
  font-family: Open Sans;
  color: ${colors.argon.default};
  height: 40px;
`;

export const Container = styled.div`
  flex: 1;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #d7e5e0, #d9e6e1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  z-index: 2;
  background: ${colors.argon.textColorLight02};
  border-radius: 6px;
  top: 30%;
  box-shadow: 0 0 32px #8898aa26;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 33px;
  padding-right: 33px;
  width: 30%;
  height: 100vh;
`;

export const FormDiv = styled.div`
  width: 100%;
`;

export const KeepConnectedDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 16px;
  margin-bottom: 26px;
`;

export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${colors.argon.white};
  box-shadow: 0 1px 3px ${colors.argon.textColorLight};
  cursor: pointer;
`;

export const header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`;

export const row = styled.div`
  @media (min-width: 650px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-left: 14px;
    margin-bottom: 24px;
  }
`;

export const rowButton = styled.div <{ margin?: string }>`
display: flex;
justify-content:space-between;
margin-top: 24px;

@media (min-width: 650px) {
  flex-direction: row;
  align-items: flex-end ;
  width: 30%;
  margin-left:${props => `${props?.margin}%` || "70%"};
}
`;

export const field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-right: 20px;
  margin-top: 20px;
`;

export const line = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.primary.light};
  margin-bottom: 24px;
  position: relative;
  top: -1px;
`


export const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: transparent;
  border-radius: 4px;

  margin-top: 2rem;

  height: 47px;

  background: ${colors.argon.secondaryButton};

  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  min-width: 120px;
  padding: 10px;

  color: ${colors.gray.white};
  transition: background 0.2s;
  box-shadow: 0 6px 8px ${colors.argon.textColorGray};

  :hover {
    transform: scale(1.05);
    opacity: 0.8;
  }
`;

export const fieldRadio = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  p {
    font-size: 14px;
    width: 250px;
    margin: 0;
  }
`;

export const ContentTable = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center
`;

export const ButtonExport = styled.button`
  color:  ${colors.gray.white};;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: #669d8c;
  margin: 0 16px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 8px ${colors.argon.textColorGray};
  }
`;

export const BaseBoardPagination = styled.div`
  display: "flex";
  flex-direction: "row";
  justify-content: "flex-start";
  align-items: "center";
  width: "100%";
  margin-bottom: "16px",
                  
`