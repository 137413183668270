export type ICreateReceiptsFormData = {
    number: number;
    emission_date: string;
    supplier_id: string;
    quantity: number;
    reason: string
    products: IProductReceipts[]
};

export type IReceipt = {
    id: number
    number:  number;
    emission_date: string;
    supplier: ISupplierReceipt;
    products: IProductReceipts[]
}

export type ISupplierReceipt = {
    name: string
    id: string
}

export type IProductReceipts = {
    name: string;
    id: number;
    quantity: number;
    unity_price: string;
    resale_price: string;
    cost_price: string
    selling_price: string
    stock_unit: string
};

export type IListProductReceipts = {
    product: IProductReceipts,
    id: number;
    name: string
    stock_unit: string,
    quantity: number,
    unity_price: number,
    resale_price: number,
}

export type ICreateProductFormData = {
    brand: string,
    description: string;
    stock_unit: string
    class: string,
    quantity: number,
    manufacturer_sku_code: number,
    minimum_stock: number,
    maximum_stock: number,
    cost_price: number,
    selling_price: number,
    comission_percentage: number;
    category: string;
    packaging: string;
    name: string;
}

export type IUpdateProductFormData = {
    productId: number
    brand: string,
    description: string;
    stock_unit: string
    class: string,
    quantity: number,
    manufacturer_sku_code: number,
    minimum_stock: number,
    maximum_stock: number,
    cost_price: number,
    selling_price: number,
    comission_percentage: number;
    category: string;
    packaging: string;
    name: string;
}

export type IFilterReceiptsFormData = {
    cnpj?: string;
    endDate?: string;
    number?: number 
    startDate?: string;
    supplier?: string
    [key: string]: any
}

export type APIFiltersReceipts = {
    cnpj?: string;
    number?: number
    startDate?: string;
    supplier?: string
    endDate?: string;
}

export enum ReceiptsFilterTranslateEnum {
    cnpj = 'CNPJ',
    endDate = 'Ante de',
    startDate = 'Depois de',
    supplier = 'Fornecedor',
    number = 'Numero'
}