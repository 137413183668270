import IGraphQLError from "../../types/IGraphQLError";

function processError(error: any, type: 'GRAPHQL' | 'REST', default_message?: string) {
  let name = 'Erro';
  let message = default_message || 'Erro desconhecido, contate o suporte';
  if (!error?.graphQLErrors[0] && !error?.response?.data) {
    return {
      name: name,
      message: message
    }
  }

  switch (type) {
    case 'GRAPHQL':
      const erroGRAPHQL = error as IGraphQLError;
      const payloadErro = erroGRAPHQL.graphQLErrors[0];
      name = payloadErro.name;
      message = payloadErro.message;
      break;
    case 'REST':
      const erroREST = error.response.data;
      name = 'Erro';
      message = erroREST.message;
      break;
    default:
      break;
  }

  return {
    name: name,
    message: message
  }
}

export default processError;
