import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import {  IProductReceipts } from 'pages/Receipts/constants/types';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import Select from 'components/Select';
import { FormHandles } from '@unform/core';
import { AddProductToReceiptsValidationSchema } from './AddProductToReceiptsFormValidations';
import { launchToast } from 'shared/utils/launchToast';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { IProductMoviment } from 'pages/Moviment/constants/types';
import { listOptionProducts } from 'pages/Moviment/graphql/MovimentQuery';

export type IAddProductsToReceiptsFormProps = {
    onSubmit: (product: IProductReceipts)  => void;
}

export const AddProductToReceiptFormId = 'add-producto-to-receipt-form';

const AddProductToReceiptForm: React.FC<IAddProductsToReceiptsFormProps> = ({
    onSubmit ,
}) => {

    const [searchProductInput, setSearchProductInput] = useState('')
    const [productOptions, setProductOptions] = useState([] as any)
    const [selectedProduct, setSelectedProduct] = useState([] as any) 
    const [productError, setProductError] = useState("");
    const [stockUnity, setstockUnity] = useState([] as any) 
    const [unityPrice, setUnityPrice] = useState("");
    const [resalePrice, setResalePrice] = useState("");

    const addProductToReceiptFormRef = useRef<FormHandles>(null);
 
    useEffect(() => {
          setUnityPrice("R$ 0,00")
          setResalePrice("R$ 0,00")
  }, [])

  useEffect(() => {
    let timeoutProduct: NodeJS.Timeout;
    const fatchProducts = async () => {
        const productList = await listOptionProducts(searchProductInput)
        setProductOptions(productList);
    }
    const delayedFetchData = () => {
        clearTimeout(timeoutProduct);
        timeoutProduct = setTimeout(fatchProducts, 400);
      };
  
      delayedFetchData();
  
      return () => clearTimeout(timeoutProduct);
  }, [searchProductInput]);

    useEffect(() => {
      addProductToReceiptFormRef.current?.setErrors({});
      setstockUnity(normalizeStockUnit(selectedProduct.stock_unit))
      if(selectedProduct.name){setProductError("")}
  }, [selectedProduct]);

  const normalizeStockUnit = (StockUnit: string) => {
    const normalizationMap: Record<string, string> = {
      "LITER": "Litros",
      "MILLILITRE": "Mililitros",
      "GRAM": "Gramas",
      "KILOGRAM": "Quilogramas",
      "MILIGRAM": "Miligramas",
      "UNITY": "Unidade"
    };
    return normalizationMap[StockUnit] || StockUnit;
  };

  const priceMask = (e: any) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(numericValue) / 100);
    return formattedValue
  }

  const priceUnmask = (value: string) => {
    const formattedValue = value?.replace('R$', '').replace(/\./g, '').replace(',', '');;
    const numberFloat = parseFloat(formattedValue);
    return numberFloat
  }

  const resetForm = () => {
    addProductToReceiptFormRef.current?.setData({
      stock_unit: '',
      quantity: '',
      product: ''
    });
    setSearchProductInput("")
    setSelectedProduct([]as any)
    setUnityPrice("R$ 0,00")
    setResalePrice("R$ 0,00")
  }

    const handleSubmit = async (
        payload: IProductReceipts,
    ) => {
        try {
            await AddProductToReceiptsValidationSchema.validate({ ...payload, quantity:  Number(payload.quantity), product_id: selectedProduct.id, unity_price: priceUnmask(unityPrice), resale_price: priceUnmask(resalePrice)}, { abortEarly: false });
            onSubmit({ ...payload, name: selectedProduct.name, quantity: Number(payload.quantity), id: selectedProduct.id, unity_price: unityPrice, resale_price: resalePrice});
            resetForm()
        } catch (error) {
            if(error instanceof ValidationError){
              const errors = getValidationErrors(error as ValidationError);
              addProductToReceiptFormRef.current?.setErrors(errors);
              if(!selectedProduct.name){setProductError("Selecione um produto")}
              launchToast("Verifique o preenchimento dos dados", "error");
            } else{
              launchToast("Produto já existe na nota fiscal", "error");
            }
        }
    };

    return (
        <>
          <Form 
            onSubmit={handleSubmit} ref={addProductToReceiptFormRef} id={AddProductToReceiptFormId}
          >
            <Styles.row>
              <Styles.field>
                <Label>Produtos</Label>
                <Select
                  name="product"
                  options={productOptions?.map((product:  IProductMoviment) => ({
                  ...product,
                  label: product.name
                  })) as { value: string; label: string; }[]}
                  onInputChange={setSearchProductInput}
                  error={productError}
                  placeholder="Busque um produto"
                  onChange={(e) => setSelectedProduct(e)}
                />
              </Styles.field>
            </Styles.row>
            <Styles.row>
              <Styles.field>
                <Label>Unidade de Medida</Label>
                <InputRef
                  name="stock_unit"
                  placeholder="Selecione um Produto"
                  containerStyle={{ width: "33rem" }}
                  value={stockUnity}
                  disabled
                />
              </Styles.field>
              <Styles.field>
                <Label>Quantidade</Label>
                <InputRef
                  type='number'
                  name="quantity"
                  placeholder="Digite a quantidade"
                  containerStyle={{ width: "33rem" }}
                />
              </Styles.field>
            </Styles.row>
            <Styles.row>
              <Styles.field>
                <Label>Valor de Compra</Label>
                <InputRef
                  name="unity_price"
                  placeholder="Digite o valor"
                  containerStyle={{ width: "33rem" }}
                  value={unityPrice}
                  onChange={(e: any) => setUnityPrice(priceMask(e))}
                />
              </Styles.field>
              <Styles.field>
                <Label>Valor de Venda</Label>
                <InputRef
                  name="resale_price"
                  placeholder="Digite o valor"
                  containerStyle={{ width: "33rem" }}
                  value={resalePrice}
                  onChange={(e: any) => setResalePrice(priceMask(e))}
                />
              </Styles.field>
            </Styles.row>
          </Form >
        </>
    )
}

export default AddProductToReceiptForm