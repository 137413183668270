import Modal from 'components/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import * as Styles from './styles';
import Button from "components/Button";
import { gql, useQuery } from '@apollo/client';
import { walletBalanceColumns, walletLogsColumns } from './conlumns';
import Table from "components/Table";
import { ReactComponent as EditButton } from "../../../assets/svg/generic/edit.svg";
import IUser from 'types/IUser';
import EditBalanceWalletModal from './EditBalanceWalletModal';
import { useDisclosure } from '@chakra-ui/react';
import { IBalanceCategoryWallet, IWalletLog } from './types';
import { Divider, Heading, Stack } from "@chakra-ui/react";
import Loading from "../../../components/Loading";

export type IWalletModalProps = {
    isOpen: boolean;
    onClose: () => void;
    user: IUser
};

export const GET_WALLET = gql`
query GetUserBalance($getUserId: String!, $filters: WalletLogFilter!) {
  getUser(id: $getUserId) {
    getUserBalance {
      veterinary
      vacines
      grooming_bath_and_well_being
      products
      all_services
      totalBalance
      id
    }
    getWalletLogs(filters: $filters) {
      id
      alteredBy
      alteredReason
      categories
      amount
      type
      date
    }
  }
}
`;

const WalletModal: React.FC<IWalletModalProps> = ({ isOpen, onClose, user }) => {
    const [walletBalance, setWalletBalance] = useState<IBalanceCategoryWallet[]>([])
    const [walletLogs, setWalletLogs] = useState<IWalletLog[]>([])
    const [categoryBalance, setCategoryBalance] = useState<IBalanceCategoryWallet | null>(null)

    
    const editBalanceWalletControl = useDisclosure();

    const { data, loading: isWalletLoading, refetch } = useQuery(GET_WALLET, {
        variables: {
          getUserId: user.id,
          filters: {}
        }
      });
    useEffect(() => {
        let balance: IBalanceCategoryWallet[] = []
        let logs: IWalletLog[] = []
        if(data){
          Object.entries(data.getUser.getUserBalance).filter(([chave]) => chave !== "__typename" && chave !== "id" ).map(([key, value]) => {
            balance.push({NomalizedCategory: normalizeString(key), amount: (`R$ ${(Number(value)/100).toFixed(2)}`).replace('.', ','), category: key})
          })
          logs = [...data.getUser.getWalletLogs]
        }
        setWalletBalance(balance.reverse())
        setWalletLogs(logs)
    }, [data])

    const normalizeString = (category: string) => {
        const normalizationMap: Record<string, string> = {
            "veterinary": "Veterinário",
            "vacines": "Vacina",
            "grooming_bath_and_well_being": "Banho & Tosa",
            "products": "Produto",
            "all_services": "Todos os serviços",
            "totalBalance": "Total",
            "add": "Adicionado",
            "remove": "Retirado",
            "bathAndGrooming": "Banho & Tosa",
            "allServices": "Todos os serviços"
        };
        return normalizationMap[category] || category;
    };

    const normalizeDate =(isoString: string) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
    }

    const priceMask = (amount: string) => {
        const amountValue = amount.toString()
        const numericValue = amountValue.replace(/\D/g, '');
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(Number(numericValue) / 100);
        return formattedValue
    }

    const handleEditBallanceWallet= useCallback((category: IBalanceCategoryWallet) => {
        setCategoryBalance(category)
        editBalanceWalletControl.onOpen()
    },
        []
    );

    return (
        <>
           { isWalletLoading ? <Loading/> : <Modal visible={isOpen} closeModal={onClose}>
                {categoryBalance && <EditBalanceWalletModal
                    isOpen={editBalanceWalletControl.isOpen}
                    onClose={editBalanceWalletControl.onClose}
                    categoryBalance={categoryBalance}
                    walletId={data.getUser.getUserBalance.id}
                    walletRefeth={refetch}
                />}
                <Stack mt={4} width="100%" alignItems="center">
                <Heading fontSize={"16px"} fontFamily={"Open Sans"}>Carteira</Heading>
                    <Table
                        headers={walletBalanceColumns}
                        data={walletBalance.map((balance) => ({
                            service: balance.NomalizedCategory,
                            amount: balance.amount,
                            actions: ( balance.category !== 'totalBalance' ? [{
                                name: "adicionar",
                                icon: <EditButton />,
                                action: () => handleEditBallanceWallet(balance),
                            }] : []),
                        }))}
                        firstLine
                    />
                </Stack>
                {data && data.getUser.getWalletLogs.length > 0 && <>
                    <Divider m={12} />
                    <Stack width="100%" alignItems="center">
                        <Heading fontSize={"16px"} fontFamily={"Open Sans"}>Transações</Heading>
                        <Table
                            headers={walletLogsColumns}
                            data={walletLogs.map((log) => ({
                                alteredBy: log.alteredBy,
                                alteredReason: log.alteredReason,
                                categories: log.categories.map((category: string) => normalizeString(category)).join(', '),
                                amount: priceMask(log.amount),
                                type: normalizeString(log.type),
                                date: normalizeDate(log.date),
                            }))}
                        />
                    </Stack>
                </>
                }
                <Styles.walletRowButton>
                    <Button text="Fechar" onClick={onClose} />
                </Styles.walletRowButton>
            </Modal>}
        </>
    )
}

export default WalletModal