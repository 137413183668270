import React, { useEffect, useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import { ISectorCepsCategories, IUpdateCepFormData } from 'pages/Ceps/constants/types';
import { UpdateCepFormValidationSchema } from './updateCepFormValidations copy';

export type IUpdateCepFormProps = {
    onSubmit: (payload: IUpdateCepFormData, options: { reset: () => void }) => Promise<void>,
    section: ISectorCepsCategories
    cep: ISectorCepsCategories
}

export const UpdateCepFormId = 'update-cep-form';

const UpdateCepForm: React.FC<IUpdateCepFormProps> = ({
    onSubmit,
    section,
    cep
}) => {

    const updateCepFormRef = useRef<FormHandles>(null);

    useEffect(() => {
        updateCepFormRef.current?.setErrors({});
        updateCepFormRef.current?.setData({
            start_cep: cep.start_cep,
            end_cep: cep.end_cep,
        });
    }, [cep]);

    const validateFields = async (
        payload: IUpdateCepFormData,
        options: { reset: () => void }
    ) => {
        try {
            verifyInterval(payload.start_cep, payload.end_cep, section.start_cep, section.end_cep)
            await UpdateCepFormValidationSchema.validate({ start_cep: `${payload.start_cep}`, end_cep: `${payload.end_cep}`}, { abortEarly: false });
            onSubmit({ start_cep: `${payload.start_cep}`, end_cep: `${payload.end_cep}`, regionId: section.region_id}, options);
        } catch (error: any) {
            if(error.message.startsWith('Cep inicial e cep final devem estar entre')) {
                launchToast(`${error.message}`, "error");
            } else {
                const errors = getValidationErrors(error as ValidationError);
                updateCepFormRef.current?.setErrors(errors);
                launchToast("Verifique o preenchimento dos dados", "error");
            }
        }
    };

    const verifyInterval = (start_cep: string, end_cep: string, start_section: string, end_section: string): void => {
        const startSectionToCompare = Number(start_section) * 100
        const endSectionToCompare = ((Number(end_section) + 1) * 100) - 1
        const startCepToCompare = Number(start_cep)
        const endCepToCompare = Number(end_cep)

        if(startCepToCompare < startSectionToCompare || startCepToCompare > endSectionToCompare || endCepToCompare < startSectionToCompare || endCepToCompare > endSectionToCompare){
            throw new Error(`Cep inicial e cep final devem estar entre ${start_section}00 e ${end_section}99`)
        }
    }

    return (
        <>
            <Form onSubmit={validateFields} ref={updateCepFormRef} id={UpdateCepFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>{`Cep inicia`}</Label>
                        <InputRef
                            name="start_cep"
                            type='number'
                            placeholder="Complemento cep inicial"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>{`Cep Final`}</Label>
                        <InputRef
                            name="end_cep"
                            type='number'
                            placeholder="Complemento cep final"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default UpdateCepForm