import { gql } from "@apollo/client";
import client from "../../../services/api-graphql";
import PeriodsEnum from "../../../shared/utils/enums/PeriodsEnum";
import IAppointment from "../../../types/IAppointment";

export type IPayloadUpdateAppointment = {
  id: string;
  period: string;
  date: string;
  address_id: string;
  observations?: string;
};

const updateAppointment = async (
  payload: IPayloadUpdateAppointment
): Promise<IAppointment> => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation ($appointment: AppointmentUpdateInputType!) {
          updateAppointment(appointment: $appointment) {
            id
            date
            period
            created_at
            service_price
            platform
            price_fix
            price_fix_reason
            paid_price
            status
            observations
            service_id
            service {
              id
              name
              category {
                id
                name
              }
              groups {
                id
                name
                items {
                  id
                  name
                }
              }
            }
            pet {
              name
              weight
              specie {
                id
                name
              }
              breed {
                name
                size
              }
            }
            customer_id
            customer {
              user {
                id
                name
              }
            }
            order {
              id
              increase_by_admin
              increase_reason
              discount_by_admin
              pack_id
              transactions {
                type
              }
            }
            address {
              id
              cep
              state
              city
              neighborhood
              street
              number
            }
            appointments_items {
              item_price
              paid_price
              item {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        appointment: payload,
      },
    });
    return result.data.updateAppointment;
  } catch (error) {
    throw error;
  }
};

const getServicesByCategory = async (category_id: string) => {
  const result = await client.query({
    query: gql`
      query ($filters: FiltersServiceInputType) {
        services(filters: $filters) {
          id
          name
        }
      }
    `,
    variables: {
      filters: {
        where: {
          category_id: category_id,
        },
      },
    },
  });

  return result;
};

const getItemsById = async (ids: string[]) => {
  const result = await client.query({
    query: gql`
      query ($ids: [String!]) {
        items(ids: $ids) {
          id
          name
          prices {
            id
            price
            size
            specie_id
          }
        }
      }
    `,
    variables: {
      ids: ids,
    },
  });

  return result;
};

const updateAppointmentAdditionals = async ({
  appointment_id,
  additionals_ids,
}: {
  appointment_id: string;
  additionals_ids: string[];
}) => {
  const results = await client.mutate({
    mutation: gql`
      mutation ($additionalsIds: [String!]!, $appointmentId: String!) {
        updateAppointmentAdditionals(
          additionals_ids: $additionalsIds
          appointment_id: $appointmentId
        ) {
          id
          date
          period
          created_at
          service_price
          paid_price
          price_fix
          price_fix_reason
          status
          observations
          service_id
          pack_frequency
          service {
            id
            name
            category {
              id
              name
            }
            groups {
              id
              name
              items {
                id
                name
              }
            }
          }
          pet {
            name
            weight
            specie {
              id
              name
            }
            breed {
              name
              size
            }
          }
          customer_id
          customer {
            user {
              id
              name
            }
          }
          order {
            id
            increase_by_admin
            increase_reason
            discount_by_admin
            pack_id
            transactions {
              type
            }
          }
          address {
            id
            cep
            state
            city
            neighborhood
            street
            number
          }
          appointments_items {
            item_price
            paid_price
            item {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      appointmentId: appointment_id,
      additionalsIds: additionals_ids,
    },
  });

  return results.data.updateAppointmentAdditionals;
};

const updateAppointmentPriceFix = async ({
  appointment_id,
  price,
  reason,
}: {
  appointment_id: string;
  price: number;
  reason: string;
}) => {
  const results = await client.mutate({
    mutation: gql`
      mutation ($reason: String!, $price: Float!, $appointmentId: String!) {
        fixPriceAppointment(
          reason: $reason
          price: $price
          appointment_id: $appointmentId
        ) {
          id
          date
          period
          created_at
          service_price
          price_fix
          price_fix_reason
          paid_price
          status
          observations
          service_id
          service {
            id
            name
            category {
              id
              name
            }
            groups {
              id
              name
              items {
                id
                name
              }
            }
          }
          pet {
            name
            weight
            specie {
              id
              name
            }
            breed {
              name
              size
            }
          }
          customer_id
          customer {
            user {
              id
              name
            }
          }
          order {
            id
            increase_by_admin
            increase_reason
            discount_by_admin
            pack_id
            transactions {
              type
            }
          }
          address {
            id
            cep
            state
            city
            neighborhood
            street
            number
          }
          appointments_items {
            item_price
            paid_price
            item {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      appointmentId: appointment_id,
      reason: reason,
      price: price,
    },
  });
  return results.data.fixPriceAppointment;
};

const finishAppointmentByAdmin = async (
  appointmentId: string
): Promise<IAppointment> => {
  const results = await client.mutate({
    mutation: gql`
      mutation ($appointmentId: String!) {
        finishAppointmentByFinancialDepartment(appointmentId: $appointmentId) {
          id
          date
          period
          created_at
          service_price
          price_fix
          price_fix_reason
          paid_price
          status
          observations
          service_id
          service {
            id
            name
            category {
              id
              name
            }
            groups {
              id
              name
              items {
                id
                name
              }
            }
          }
          pet {
            name
            weight
            specie {
              id
              name
            }
            breed {
              name
              size
            }
          }
          customer_id
          customer {
            user {
              id
              name
            }
          }
          order {
            id
            increase_by_admin
            increase_reason
            discount_by_admin
            pack_id
            platform
            transactions {
              type
            }
          }
          address {
            id
            cep
            state
            city
            neighborhood
            street
            number
          }
          appointments_items {
            item_price
            paid_price
            item {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      appointmentId: appointmentId,
    },
  });
  return results.data.finishAppointmentByFinancialDepartment;
};

export {
  updateAppointment,
  getServicesByCategory,
  getItemsById,
  updateAppointmentAdditionals,
  updateAppointmentPriceFix,
  finishAppointmentByAdmin,
};
