import React, { FC, useState } from "react";
import { loadPartners } from "./graphQL";
import IPartner from "types/IPartner";

import { MdSimCardAlert } from "react-icons/md";
import { Alert } from "./styles";
import { PanelHeader } from "components/PanelHeader";
import { Search } from "../../components/Search";
import { ItemPartner } from "./ItemPartner";
import { PartnerModal } from "./PartnerModal";
import { NewPartnerModal } from "./NewPartnerModal";
import { toast } from "react-toastify";

import Modal from "components/Modal";
import Loading from "components/Loading";
import { Box, Divider, Flex, Grid, Heading } from "@chakra-ui/react";

export const Partners: FC = () => {
  const [search, setSearch] = useState("" as string);
  const [isModalNewPartnerVisible, setIsModalNewPartnerVisible] =
    useState(false);
  const [isModalPartnerVisible, setIsModalPartnerVisible] = useState(false);
  const [idPartner, setIdPartner] = useState("" as string);
  const [dataPartner, setDataPartner] = useState({} as IPartner[]);
  const [loading, setLoading] = useState(false);

  const partnersLoad = async () => {
    try {
      setLoading(true);
      const results = await loadPartners();
      setDataPartner(results);
    } catch (error) {
      console.log(error);
      toast.error("Erro entre em contato com o suporte!");
    } finally {
      setLoading(false);
    }
  };

  const filtered = search
    ? dataPartner.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase().trim())
      )
    : dataPartner;

  React.useEffect(() => {
    partnersLoad();
  }, []);

  return (
    <>
      {loading && <Loading />}
      {isModalNewPartnerVisible && (
        <Modal
          visible={true}
          closeModal={() => {
            setIsModalNewPartnerVisible(false);
            partnersLoad();
          }}
          children={
            <NewPartnerModal
              onClose={() => {
                setIsModalNewPartnerVisible(false);
                partnersLoad();
              }}
            />
          }
        />
      )}
      {isModalPartnerVisible && (
        <Modal
          visible={true}
          closeModal={() => {
            setIsModalPartnerVisible(false);
            partnersLoad();
          }}
          children={
            <PartnerModal
              id={idPartner}
              onClose={() => {
                setIsModalPartnerVisible(false);
                partnersLoad();
              }}
            />
          }
        />
      )}

      <Flex flexDir={"column"} w={"100%"}>
        <PanelHeader
          title="Parceiros"
          isFilterModalVisible={false}
          isNew={true}
          onClick={() => setIsModalNewPartnerVisible(true)}
        />
        <Search
          setSearch={setSearch}
          wordSearch={search}
          placeholder="Pesquisar por nome do parceiro"
        />
        {filtered.length === 0 ||
        filtered.length === undefined ||
        filtered === undefined ? (
          <Alert>
            <MdSimCardAlert size={25} style={{ marginRight: 8 }} />
            Nenhum parceiro encontrado
          </Alert>
        ) : null}
        {filtered.length > 0 && (
          <>
            <Heading
              size="lg"
              color="gray.500"
              fontWeight="normal"
              px={8}
              mt={6}
            >
              Parcerias cadastradas ({filtered.length})
              <Divider my={4} />
            </Heading>
          </>
        )}
        <Box h={"500px"} overflowY={"auto"} p={8}>
          {filtered.length > 0 && (
            <Grid
              templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
              gap={2}
            >
              {filtered.map((item) => (
                <ItemPartner
                  key={item.id}
                  partner={item}
                  onClick={() => {
                    setIdPartner(item.id);
                    setIsModalPartnerVisible(true);
                  }}
                  partnersLoad={partnersLoad}
                />
              ))}
            </Grid>
          )}
        </Box>
      </Flex>
    </>
  );
};
