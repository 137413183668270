const colors = {
  primary: {
    default: '#8EB7AA',
    light: '#D7E5E0',
    dark: '#518071',
    background: '#EDF4FA', // old system
    disabled: '#DEE2E6',
  },
  secondary: {
    default: '#CCD500',
    dark: '#585C00',
  },
  gray: {
    black: '#000000',
    white: '#FFFFFF',
    dark01: '#4C4C4C',
    dark02: '#7E7E81',
    dark03: '#BFBFC0',
    light01: '#C9D0D8',
    light02: '#EAEDF0',
    light03: '#F9FAFB',
  },
  suport: {
    alert: '#E8505B',
    blue: '#3B78ED',
    green: '#8EB7AA',
    warning: '#FF8F39',
    sucess: '#2DCE89'
  },
  argon: {
    primaryAlerts: '#D7E5E0',
    textColorLight02: '#EAEDF0',
    default: '#8EB7AA',
    defaultDarken: '#669D8C',
    textColorDark: '#212529',
    textColorGray: '#8898AA',
    textColorLight: '#ADB5BD',
    white: '#FFFFFF',
    darkBlue: '#32325D',
    dropFIlter: '#04261B',
    secondaryButton: '#FA3A0E',
  }
};

export default colors;
