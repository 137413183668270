export const headers = [
  {
    key: "created_at",
    name: "criado",
  },
  {
    key: "name",
    name: "nome",
  },
  {
    key: "code",
    name: "código",
  },
  {
    key: "type",
    name: "tipo",
  },
  {
    key: "starts_in",
    name: "início",
  },
  {
    key: "ends_in",
    name: "término",
  },
  {
    key: "campaign",
    name: "campanha",
  },
  {
    key: "status",
    name: "Status",
  },
  {
    key: "amount",
    name: "Valor",
  },
  {
    key: "actions",
    name: "Ações",
  },
];
