import Modal from "components/Modal";
import React, { useCallback } from "react"
import * as Styles from '../../Receipts/constants/styles';
import Text from "components/Text";
import { IconButton } from '@chakra-ui/react';
import colors from "shared/utils/constants/colors";
import Button from "components/Button";
import { launchToast } from "shared/utils/launchToast";
import { APIFiltersStock, IFilterStockFormData, StockFilterTranslateEnum } from "../constants/types";
import { CgClose } from "react-icons/cg";
import FiltersStockForm, { FiltersStockFormId } from "../forms/filterStock/FiltersStockForm";

type FiltersStockModalProps = {
    isOpen: boolean
    onClose:  () => void
    handleAddFilter: React.Dispatch<any>
    onFilterSubmit: React.Dispatch<any>
}

const FiltersStockModal = ({
    isOpen,
    onClose,
    handleAddFilter,
    onFilterSubmit
}: FiltersStockModalProps) => {

    const handleSubmit = useCallback(
        async (
            payload: IFilterStockFormData,
            { reset }
        ) => {
            try {
                const activeFilters = [];

                const {...rest } = payload;

                const APIFilters = rest as unknown as APIFiltersStock;

                for (const key in payload) {
                    if (payload[key] !== '' && payload[key] !== undefined) {
                        activeFilters.push({ key: StockFilterTranslateEnum[key as keyof APIFiltersStock], value: payload[key] });
                    } else {
                        delete APIFilters[key as keyof APIFiltersStock];
                    }
                }

                onFilterSubmit(activeFilters);

                handleAddFilter({
                    filters: {
                        manufacturer_sku_code: payload.manufacturer_sku_code,
                        pagination: {
                            page: 1,
                            limit: 10
                        }
                    }
                });
                reset();
                onClose();
            } catch (error: any) {
                launchToast(error.message, "error");
            }
        }, []
    );

    return (
        <Modal visible={isOpen} closeModal={onClose} >
            <Styles.header>
                <Text
                    text="Filtrar Produtos"
                    color={colors.argon.darkBlue}
                    fontFamily="Open Sans"
                    size={17}
                    weight="600"
                />
                <IconButton
                    aria-label='Fechar modal'
                    icon={<CgClose />}
                    onClick={onClose}
                />
            </Styles.header>
            <Styles.line></Styles.line>
            <Styles.FormDiv     
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
            }}>
            <FiltersStockForm onSubmit={handleSubmit} />
            </Styles.FormDiv>
            <Styles.rowButton>
                <Button text="Cancelar" onClick={onClose} />
                <Button
                    text="Filtrar"
                    type="submit"
                    form={FiltersStockFormId}
                />
            </Styles.rowButton>
        </Modal>
    )
}

export default FiltersStockModal