import * as Yup from 'yup';

export const AppointmentFiltersFormValidationSchema = Yup.object().shape({
  start_date: Yup.date().typeError('A data inicial é obrigatória'),
  end_date: Yup.date()
    .min(
      Yup.ref("start_date"),
      "Data final deve ser maior que a incial"
    )
    .typeError('A data final é obrigatória'),
  categories: Yup.array(Yup.string()).nullable(),
  status: Yup.array(Yup.number()).nullable(),
  tutor_name: Yup.string().nullable(),
});

export const AppointmentFiltersFormId = 'appointment-filters';
