import Modal from "components/Modal";
import Text from "components/Text";
import React, { useEffect, useState } from "react";
import colors from "shared/utils/constants/colors";
import IService from "types/IService";
import { getServicesByCategory } from "./graphQL";
import ModalServiceDetails from "./ModalServiceDetails";
import * as Styles from "./styles";

type IProps = {
  region_id?: string;
  category?: {
    id: string;
    name: string;
  };
  onRequestClose: () => void;
};

const ModalServicesByCategory = ({
  region_id,
  category,
  onRequestClose,
}: IProps) => {
  const [services, setServices] = useState<IService[]>([]);
  const [selectedService, setSelectedService] = useState<IService | undefined>(
    undefined
  );

  useEffect(() => {
    if (!!category) {
      getServicesByCategory(category.id).then((data) => setServices(data));
    }
  }, [category]);

  return (
    <Modal visible={!!category} closeModal={() => onRequestClose()}>
      <Styles.Container>
        <Text text="Serviços" fontFamily="Open Sans" weight="600" />
        <Styles.ServicesContainer>
          {services.map((service) => (
            <Styles.ServiceCard
              key={service.id}
              onClick={() => setSelectedService(service)}
            >
              <Text
                text={service.name}
                fontFamily="Open Sans"
                whiteSpace="nowrap"
                color={colors.gray.white}
              />
            </Styles.ServiceCard>
          ))}
        </Styles.ServicesContainer>
      </Styles.Container>
      <ModalServiceDetails
        serviceBasic={selectedService}
        region_id={region_id}
        category={category}
        onRequestClose={() => setSelectedService(undefined)}
      />
    </Modal>
  );
};

export default ModalServicesByCategory;
