import React, { useState } from "react";
import ICondominium from "types/ICondominium";
import { loadCondominiums } from "../../services/condominium/graphQL";
import {
  Box,
  Container,
  Grid,
  Flex,
  Text,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { ModalCreate } from "./ModalCreate";
import { PanelHeader } from "components/PanelHeader";
import { CondominiumCard } from "./CondominiumCard";
import { Search } from "components/Search";
import { RiErrorWarningLine } from "react-icons/ri";
import MainContainer from "components/MainContainer";

export const Condominiums = () => {
  const [condominios, setCondominios] = useState<ICondominium[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [isUpdateInDiscount, setUpIsUpdateInDiscount] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    cep: "",
    city: "",
    neighborhood: "",
    street: "",
    end_number: 0,
    start_number: 0,
    state: "",
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormValues({
      name: "",
      cep: "",
      city: "",
      neighborhood: "",
      street: "",
      end_number: 0,
      start_number: 0,
      state: "",
    });
  };

  const condominiumsLoad = async () => {
    try {
      const results = await loadCondominiums();
      setCondominios(results);
    } catch (error) {
      console.log(error);
    }
  };

  const filtered = search
    ? condominios.filter((c) =>
        c.name.toLowerCase().includes(search.toLowerCase().trim())
      )
    : condominios;

  React.useEffect(() => {
    condominiumsLoad();
  }, [isUpdateInDiscount]);

  return (
    <MainContainer>
      <PanelHeader
        title="Parceiros"
        isFilterModalVisible={false}
        isNew={true}
        onClick={openModal}
      />
      <Search
        setSearch={setSearch}
        wordSearch={search}
        placeholder="Pesquisar por nome do condomínio"
      />
      <ModalCreate
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        setCondominios={setCondominios}
        formValues={formValues}
        setFormValues={setFormValues}
      />
      {filtered.length > 0 && (
        <>
          <Heading size="lg" color="gray.500" fontWeight="normal" px={8} mt={6}>
            Condomínios cadastrados ({filtered.length})
            <Divider my={4} />
          </Heading>
        </>
      )}
      <Box h={"500px"} overflowY={"auto"} p={8}>
        {filtered.length > 0 ? (
          <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={3}>
            {filtered.map((condominio, index) => (
              <CondominiumCard
                key={index}
                condominium={condominio}
                condominiums={condominios}
                setCondominiums={setCondominios}
                setUpIsUpdateInDiscount={setUpIsUpdateInDiscount}
              />
            ))}
          </Grid>
        ) : (
          <Flex align="center" h="100%" flexDirection="column" color="gray.500">
            <RiErrorWarningLine size={48} />
            <Text mt={4} fontSize="xl" fontWeight="bold">
              Nenhum registro encontrado.
            </Text>
          </Flex>
        )}
      </Box>
    </MainContainer>
  );
};
