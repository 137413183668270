import { FormHandles } from "@unform/core";
import cepPromise from "cep-promise";
import { launchToast } from "./launchToast";

export const cepAutoComplete = async (formRef: FormHandles | null) => {
  try {
    const address = await cepPromise(formRef?.getFieldValue("cep"));

    formRef?.setFieldValue('city', address.city);
    formRef?.setFieldValue('state', address.state);
    formRef?.setFieldValue('street', address.street);
    formRef?.setFieldValue('neighborhood', address.neighborhood);
    formRef?.setFieldValue('service', address.service)
  } catch (error) {
    launchToast("Houve erro na solicitação do cep", "error");
  }
};

