import styled from "styled-components";
import colors from "../../../shared/utils/constants/colors";

export const Container = styled.div`

`;

export const GroupContainer = styled.div`
  display: flex;
  background-color: ${colors.gray.light02};
  margin-right: 16px;
  flex-direction: column;
  text-decoration-skip-ink: auto;
  width: 332px;
  border-radius: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary.default};
  padding: 16px 8px;
  margin-bottom: 16px;
  color: ${colors.gray.white};
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  position: relative;
  width: 332px;
`;

export const Alert = styled.button<{ pending: boolean; loading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.pending ? colors.suport.warning : colors.secondary.default};
  width: 16px;
  height: 16px;
  position: absolute;
  right: 16px;
  border-radius: 8px;

  cursor: ${props => props.pending ? 'pointer' : 'default'};

  animation-name: example;
  animation-duration: ${props => props.loading ? '0s' : "2s"};

  transition: all 0.6s;

  @keyframes example {
    from {background-color: ${colors.suport.warning};}
    to {background-color: ${colors.secondary.default};}
  }
`;