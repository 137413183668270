import React, {
  InputHTMLAttributes, useCallback, useRef, useState
} from 'react';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: any;
  error?: string;
  onclickIcon?: () => void;
  onBlurExecute?: (value: string) => void;
}

const Input: React.FC<InputProps> = ({
  name,
  containerStyle,
  icon: Icon,
  error,
  onclickIcon,
  onBlurExecute,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback((): void => {
    setIsFocus(false);

    if (onBlurExecute) {
      onBlurExecute(inputRef.current?.value || '');
    }

    setIsFilled(!!inputRef.current?.value);
  }, [onBlurExecute]);
  
  return (
      <Container
        style={containerStyle}
        hasIcon={!!Icon}
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={disabled ? disabled : false}
      >
        {Icon && <Icon />}

        <input
          name={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          disabled={disabled}
          {...rest}
        />
      </Container>
  );
};

export default Input;
