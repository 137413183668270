import React from "react";
import * as Styles from "./styles";
import animation from "../../assets/animation.json";

import Lottie from "react-lottie";

function Loading() {
  return (
    <Styles.Panel>
      <Styles.ItemContainer>
        <Lottie
          height={400}
          width={400}
          options={{
            autoplay: true,
            animationData: animation,
          }}
        />
      </Styles.ItemContainer>
    </Styles.Panel>
  );
}

export default Loading;
