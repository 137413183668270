import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Option = styled.div<{
  opened: boolean;
  nameOption: string;
  selectedOption: string;
}>`
  padding-left: ${(props) => (props.opened ? `2.4rem` : `0`)};
  padding-right: ${(props) => (props.opened ? `2.4rem` : `0`)};
  width: ${(props) => (props.opened ? `100%` : `4.5rem`)};
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.opened ? `flex-start` : `center`)};
  flex-direction: row;
  border-radius: ${(props) => (props.opened ? `25px` : `10rem`)};

  transition: all 0.3s ease;

  cursor: pointer;
  background: ${(props) =>
    props.nameOption === props.selectedOption
      ? `#3c6959`
      : `#FFFFFF` && props.nameOption === props.selectedOption
      ? `#3c6959`
      : `#FFFFFF`};
  div {
    p {
      color: ${(props) =>
        props.nameOption === props.selectedOption ? `#FFFFFF` : `#000`};
    }
  }

  &:hover {
    background: #3c6959;
    svg {
      transform: ${(props) =>
        props.nameOption === props.selectedOption
          ? `scale(1.0);`
          : `scale(1.2);`};
    }
    div {
      p {
        color: #fff;
      }
    }
  }
  & + div {
    margin-top: 1rem;
  }
  @media (max-width: 800px) {
    & + div {
      margin-top: 2rem;
    }
  }
`;
export const NameOption = styled.div<{ opened: Boolean }>`
  display: ${(props) => (props.opened ? `flex` : `none`)};
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const SubOption = styled.div<{
  opened: boolean;
  nameOption: string;
  selectedOption: string;
}>`
  height: 100%;
  background: #f2f2f2;
  display: flex;
  flex-direction: colunm;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${(props) => (props.opened ? `90%` : `4.5rem`)};

  padding-left: ${(props) => (props.opened ? `2.4rem` : `0`)};
  padding-right: ${(props) => (props.opened ? `2.4rem` : `0`)};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.opened ? `flex-start` : `center`)};
  flex-direction: row;
  cursor: pointer;
  border-radius: ${(props) => (props.opened ? `25px` : `10rem`)};
  transition: all 0.2s ease-in-out;

  div {
    p {
      color: ${(props) =>
        props.nameOption === props.selectedOption ? `#FFFFFF` : `#00000060`};
    }
  }
  &:hover {
    background: #3c6959;
    div {
      p {
        color: #fff;
      }
    }
  }
  & + div {
    margin-top: 1rem;
  }
`;

export const ListOption = styled.div<{
  opened: boolean;
  nameOption: string;
  selectedOption: string;
}>`
  display: ${(props) =>
    props.nameOption === props.selectedOption && props.opened === true
      ? `flex`
      : `none`};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  & + div {
    margin-top: 1rem;
  }
  p {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  border-radius: 4px;
  transition: all 0.2s ease;
`;
export const Buttom = styled.button`
  width: 100%;

  &:hover {
    p {
      color: #3c6959;
    }
  }
`;
