import { Form } from "@unform/web";
import { IFilterCamapaignFormData } from "pages/Campaigns/constants/types";
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Styles from '../../constants/styles';
import { Label } from "components/Label/styles";
import Select from "components/Select";
import { IFilterReceiptsFormData } from "pages/Receipts/constants/types";

import { ICustomerToAnamneses, IFilterAnamnesisFormData, IProfessionalToAnamneses } from "pages/Anamnesis/constants/types";
import { listOptionCustomer, listOptionProfessional } from "pages/Anamnesis/graphql/AnamnesesQuery";

export type IFilterAnamnesisFormProps = {
    onSubmit: (payload: IFilterAnamnesisFormData, { reset }: any) => void;
}

export const FilterAnamnesisFormId = 'filter-anamnesis-form';


const FilterAnamnesisForm = ({ onSubmit }: IFilterAnamnesisFormProps) => {
    const anamnesisFiltersFormRef = useRef<FormHandles>(null);

    const [searchCustomerInput, setSearchCustomerInput] = useState('')
    const [customerOptions, setCustomerOptions] = useState([] as any)
    const [selectedCustomer, setSelectedCustomer] = useState([] as any)
    const [searchProfessionalInput, setSearchProfessionalInput] = useState('')
    const [professionalOptions, setProfessionalOptions] = useState([] as any)
    const [selectedProfessional, setSelectedProfessional] = useState([] as any)

    useEffect(() => {
        let timeoutCustomer: NodeJS.Timeout;
        const fatchCustomers = async () => {
            const customerList = await listOptionCustomer(searchCustomerInput)
            setCustomerOptions(customerList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutCustomer);
            timeoutCustomer = setTimeout(fatchCustomers, 200);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutCustomer);
    }, [searchCustomerInput]);

    useEffect(() => {
        let timeoutProfessional: NodeJS.Timeout;
        const fatchProfessionals = async () => {
            const professionalList = await listOptionProfessional(searchProfessionalInput)
            setProfessionalOptions(professionalList);
        }
        const delayedFetchData = () => {
            clearTimeout(timeoutProfessional);
            timeoutProfessional = setTimeout(fatchProfessionals, 200);
          };
      
          delayedFetchData();
      
          return () => clearTimeout(timeoutProfessional);
    }, [searchProfessionalInput]);
 

    const validateFields = async (
        payload: IFilterReceiptsFormData,
        options: { reset: () => void }
    ) => {
        onSubmit({
            professional_id: selectedProfessional,
            customer_id: selectedCustomer,
        }, options);
        setSelectedCustomer([] as any)
        setSelectedProfessional([] as any)
    };

    return (
        <Form onSubmit={validateFields} ref={anamnesisFiltersFormRef} id={FilterAnamnesisFormId} >
            <Styles.row>
                <Styles.field>
                    <Label>Tutor</Label>
                    <Select
                        name="customer"
                        options={customerOptions?.map((customer:  ICustomerToAnamneses) => ({
                        ...customer,
                        label: customer.name,
                        value: customer.id
                        })) as { value: string; label: string; }[]}
                        onInputChange={setSearchCustomerInput}
                        placeholder="Busque por nome"
                        onChange={(e) => setSelectedCustomer(e)}
                        value={selectedCustomer}
                        />
                </Styles.field>
                <Styles.field>
                    <Label>Profissional</Label>
                    <Select
                        name="professional"
                        options={professionalOptions?.map((professional:  IProfessionalToAnamneses) => ({
                        ...professional,
                        label: professional?.user.name,
                        value: professional.id
                        })) as { value: string; label: string; }[]}
                        onInputChange={setSearchProfessionalInput}
                        placeholder="Busque por nome"
                        onChange={(e) => setSelectedProfessional(e)}
                        value={selectedProfessional}
                        />
                </Styles.field>
            </Styles.row>
        </Form>
    )
}

export default FilterAnamnesisForm