import ReactSelect from 'react-select';
import styled from 'styled-components';
import colors from '../../shared/utils/constants/colors';

export const ReactSelectStyled = styled(ReactSelect)`
  color: ${colors.gray.dark01};
  box-shadow: 0px 1px 3px rgb(50 50 93 / 15%);
  border-color: transparent;
  min-width: 33rem;
  font-family: Open Sans;
  font-size: 2rem;


  .react-select__option, .error__option {
    background-color: ${colors.gray.white};

    :hover {
      background-color: ${colors.gray.light02};
    }
  }

  .react-select__option--is-selected, .error__option--is-selected {
    color: ${colors.gray.dark01};
    background-color: ${colors.primary.light}
  }

  .react-select__control {
    height: 5.7rem;
    border-radius: 4px;
    background-color: ${colors.gray.white};
    border:none;

    @media (max-width: 500px) {
      height: 6rem;
    }
  }
  
  .react-select__control--is-disabled  {
    height: 5.7rem;
    border-radius: 4px;
    background-color: ${colors.primary.disabled};
    color: ${colors.argon.textColorGray};
    border:none;

    @media (max-width: 500px) {
      height: 6rem;
    }
  }

  .error__control {
    height: 5.7rem;
    border-radius: 4px;
    background-color: ${colors.gray.white};
    border:red 1px solid;

    @media (max-width: 500px) {
      height: 6rem;
    }
  }

  .react-select__control--is-focused, .error__control--is-focused {
    box-shadow: 0 0 0 1px ${colors.gray.light01};
    border: transparent;

    :hover {
      border-color: transparent;
    }
  }

  .react-select__control--menu-is-open, .error__control--menu-is-open {
    box-shadow: 0 0 0 1px ${colors.gray.light01};
    border: transparent;

    :hover {
      border-color: transparent;
    }
  }
`;
