import React from "react";
import colors from "../../shared/utils/constants/colors";
import * as Styles from "./styles";
import Text from "../../components/Text/index";
import { MdDeleteForever } from "react-icons/md";

export interface FilterProps {
  key: string;
  value: string;
  searchValue: String;
}

const normalizeValueFilter = (roleLabel: string) => {
  const normalizationMap: Record<string, string> = {
    "DONATE": "Doação",
    "false": "Não",
    "true": "Sim"
  };
  return normalizationMap[roleLabel] || roleLabel;
};

const FilterList = ({
  filters,
  removeFilter,
}: {
  filters: any[];
  removeFilter: any;
}) => {
  const remove = (key: string) => {
    removeFilter((key));
  };

  return (
    <Styles.FilterConatiner>
      {filters.map((f: FilterProps, index) => {
        const { key, value } = f;
        if (key !== "created") {
          let text = `${key} : ${normalizeValueFilter(value)}`;

          return (
            <Styles.FilterDrop key={String(index)}>
              <Text
                text={text}
                color={colors.argon.white}
                fontFamily="Open Sans"
                size={12}
                weight="600"
              />
              <MdDeleteForever
                size={20}
                color={colors.suport.alert}
                onClick={() => remove(key)}
              />
            </Styles.FilterDrop>
          );
        } else {
          return <div key={index}></div>;
        }
      })}
    </Styles.FilterConatiner>
  );
};

export default FilterList;
