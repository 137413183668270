import React, { useRef, useState } from 'react';
import * as Styles from '../../constants/styles';
import { Label } from 'components/Label/styles';
import InputRef from "components/InputRef";
import { Form } from "@unform/web";
import { FormHandles } from '@unform/core';
import { ICreateCampaignFormData } from 'pages/Campaigns/constants/types';
import { CreateCampaignFormValidationSchema } from './CreateFormValidations';
import getValidationErrors from 'shared/utils/getValidationErrors';
import { ValidationError } from 'yup';
import { launchToast } from 'shared/utils/launchToast';
import Select from 'components/Select';
import TextArea from 'components/TextArea';

export type ICreateCampaignFormProps = {
    onSubmit: (payload: ICreateCampaignFormData, options: { reset: () => void }) => Promise<void>;
}

export const CreateCampaignFormId = 'create-campaign-form';

const CreateCamaipaignForm: React.FC<ICreateCampaignFormProps> = ({
    onSubmit,
}) => {

    const [selectType, setSelectType] = useState([] as any)

    const createCampaignFormRef = useRef<FormHandles>(null);

    const selectTypeOptions = [{ label: 'Doação' }]

    const normalizeLabel = (roleLabel: string) => {
        const normalizationMap: Record<string, string> = {
            "Doação": "DONATE",
        };
        return normalizationMap[roleLabel] || roleLabel;
    };

    const normalizeDateCampaign = (date: string) => {
        const originalDate = new Date(date);
        const newDate = new Date(originalDate.getTime() + 3 * 60 * 60 * 1000);
    
     return newDate.toISOString();
    }

    const validateFields = async (
        payload: ICreateCampaignFormData,
        options: { reset: () => void }
    ) => {
        try {
            await CreateCampaignFormValidationSchema.validate({ ...payload, type: normalizeLabel(selectType.label) }, { abortEarly: false });
            onSubmit({ ...payload, start_date: normalizeDateCampaign(payload.start_date), end_date: normalizeDateCampaign(payload.end_date), type: normalizeLabel(selectType.label) }, options);
        } catch (error) {
            const errors = getValidationErrors(error as ValidationError);
            createCampaignFormRef.current?.setErrors(errors);
            launchToast("Verifique o preenchimento dos dados", "error");
        }
    };

    return (
        <>
            <Form onSubmit={validateFields} ref={createCampaignFormRef} id={CreateCampaignFormId}>
                <Styles.row>
                    <Styles.field>
                        <Label>Nome</Label>
                        <InputRef
                            name="name"
                            placeholder="Digite o nome do Campanha"
                            containerStyle={{ width: "33rem" }}
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Modelo</Label>
                        <Select
                            name="type"
                            containerStyle={{ width: "33rem" }}
                            placeholder="Modelo da campanha"
                            options={selectTypeOptions}
                            value={selectType}
                            onChange={(e: any) => setSelectType(e)}
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Data de início</Label>
                        <InputRef
                            containerStyle={{ width: "33rem" }}
                            name="start_date"
                            type="date"
                        />
                    </Styles.field>
                    <Styles.field>
                        <Label>Data do término </Label>
                        <InputRef
                            containerStyle={{ width: "33rem" }}
                            name="end_date"
                            type="date"
                        />
                    </Styles.field>
                </Styles.row>
                <Styles.row>
                    <Styles.field>
                        <Label>Descrição</Label>
                        <TextArea
                            containerStyle={{ width: "40rem" }}
                            name="description"
                            placeholder="Descrição da campanha"
                        />
                    </Styles.field>
                </Styles.row>
            </Form >
        </>
    )
}

export default CreateCamaipaignForm