import { gql } from "@apollo/client";
import client from "../../services/api-graphql";
import ITransaction from "../../types/ITransaction";

interface IFiltersTransactions {
  pagination?: {
    limit: number;
    page: number;
  };
  orderBy?: {
    created_at: "asc" | "desc";
  }[],
  where: {
    type?: number;
    customer_name?: string;
    created_at?: string;
    wordSearch?: string;
  };
}

interface IResponseTransactions {
  data: ITransaction[];
  totalPages: number;
}

const searchTransactions = async (filters?: IFiltersTransactions): Promise<IResponseTransactions> => {
  const response = await client.query({
    query: gql`
      query($filters: FiltersTransactionsInputType) {
        transactions(filters: $filters) {
          totalPages
          data {
            id
            status
            created_at
            type
            amount
            order {
              id
              customer {
                user {
                  name
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      filters: filters,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.transactions;
};

const allowRefundAwaitingConfirmationFromAdmin = async (transaction_id: string): Promise<ITransaction> => {
  const response = await client.query({
    query: gql`
      mutation($transactionId: String!) {
        allowRefundAwaitingConfirmationFromAdmin(transaction_id: $transactionId) {
          id
        }
      }
    `,
    variables: {
      transactionId: transaction_id,
    },
    fetchPolicy: "no-cache",
  });
  return response.data.allowRefundAwaitingConfirmationFromAdmin;
};

export {
  searchTransactions,
  allowRefundAwaitingConfirmationFromAdmin
};
