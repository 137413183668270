import { createGlobalStyle } from "styled-components";
import colors from "../shared/utils/constants/colors";
import okicon from "../assets/okicon.png";
import okiconblack from "../assets/okiconblack.png";

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  @media(max-width: 1080px){
    html {
      font-size: 50%;
    }
  }

  @media(max-width: 900px){
    html {
      font-size: 45%;
    }
  }

  @media(max-width: 550px){
    html {
      font-size: 40%;
    }
  }

  body {
    background: ${colors.primary.background};
    color: red;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    -webkit-tap-highlight-color: transparent;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .Toastify__toast--error {
    background-color: #F75676;
  }

  .Toastify__toast--warning {
    background-color: #FC7C5F;
  }

  .Toastify__toast--success {
    background-color: #4FD69C;
  }

  .Toastify__toast--info {
    background-color: #37D5F2;
    color: #000000;
  }

  .Toastify__toast--default {
    background-color: #518071;
    color: #FFFFFF;
  }

  .Toastify__progress-bar--default {
    background-color: #518071 !important;
  }

  .Toastify__toast::before {
    content: url(${okicon});
    margin: 10px 30px 10px 10px;
  }

  .Toastify__toast--info::before {
    content: url(${okiconblack});
    margin: 10px 30px 10px 10px;
  }

  .Toastify__close-button--info > svg {
    fill: #000000;
  }


  &::-webkit-scrollbar {
    width: 5px;               /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #eaedf0;        /* color of the tracking area */
    margin: 1px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccd500;    /* color of the scroll thumb */
    border-radius: 6px;       /* roundness of the scroll thumb */
  }
`;
