import * as Yup from 'yup';

export const CreateCepSectionFormValidationSchema = Yup.object().shape({
    startCep: Yup.string()
        .required('Cep inicial obrigatório')
        .length(3, 'Cep inicial deve ter 3 caracteres'),
    endCep: Yup.string()
        .required('Cep final obrigatório')
        .length(3, 'Cep final deve ter 3 caracteres'),
    regionId: Yup.string()
        .required('Região obrigatória')
});
