import { useField } from '@unform/core';
import React, {
  CSSProperties,
  InputHTMLAttributes, useCallback, useEffect,
  useRef, useState
} from 'react';

import Text from '../../components/Text';

import { Container, Field, Label } from './styles';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  containerStyle?: object;
  icon?: any;
  onclickIcon?: () => void;
  onBlurExecute?: (value: string) => void;
  label?: string;
  labelStyle?: CSSProperties;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  labelStyle,
  containerStyle,
  icon: Icon,
  onclickIcon,
  onBlurExecute,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleInputBlur = useCallback((): void => {
    setIsFocus(false);

    if (onBlurExecute) {
      onBlurExecute(inputRef.current?.value || '');
    }

    setIsFilled(!!inputRef.current?.value);
  }, [onBlurExecute]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Field>
      {label && (
        <Label style={labelStyle} htmlFor={name}>
          {label}
        </Label>
      )}
      <Container
        style={containerStyle}
        hasIcon={!!Icon}
        isErrored={!!error}
        isFocused={isFocused}
        isFilled={isFilled}
        isDisabled={disabled ? disabled : false}
      >
        {Icon && <Icon />}

        <input
          id={name}
          name={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          disabled={disabled}
          defaultValue={defaultValue}
          {...rest}
        />
      </Container>
      {error && (
        <Text
          text={error}
          color="#FF6340"
          fontFamily="Open Sans"
          align="left"
          weight="400"
          size={12}
          marginBottom={16}
        />
      )}
    </Field>
  );
};

export default TextInput;
