import axios from 'axios';
import { launchToast } from '../shared/utils/launchToast';

const paymentApi = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_API,
});

paymentApi.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('@zazuu:admin:user:token');
      if (token?.length) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject((error.response) || 'Unauthorized Access'),
  );

  paymentApi.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user'); 
        localStorage.removeItem('customer');  
        localStorage.removeItem('cart');  
  
        window.location.href = '/';
      }
      return Promise.reject((error.response) || 'Something went wrong');
    }
  );

export default paymentApi;