import colors from "shared/utils/constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, #d7e5e0, #d9e6e1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const rowButton = styled.div <{ margin?: string }>`
display: flex;
justify-content: space-around;
margin-top: 24px;


@media (min-width: 650px) {
  flex-direction: row;
  align-items: flex-end ;
  width: 400px;
  margin-left:${props => `${props?.margin}%` || "70%"};
}
`;