import React, { FC, ChangeEvent, useState, useEffect } from "react";
import { inputFields } from "./utils/inputFilds";
import { createCondominium } from "../../services/condominium/graphQL";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Box,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { RiCloseFill, RiCheckFill } from "react-icons/ri";
import ICondominium from "types/ICondominium";

interface IProps {
  isModalOpen: boolean;
  closeModal: () => void;
  setCondominios: (condominios: any) => void;
  formValues: {
    name: string;
    cep: string;
    city: string;
    neighborhood: string;
    street: string;
    state: string;
    start_number: number;
    end_number: number;
    [key: string]: string | number;
  };
  setFormValues: (formValues: any) => void;
}

interface IValidationErrors {
  [key: string]: boolean;
}

export const ModalCreate: FC<IProps> = ({
  isModalOpen,
  closeModal,
  setCondominios,
  formValues,
  setFormValues,
}) => {
  const [validationErrors, setValidationErrors] = useState<IValidationErrors>(
    {}
  );
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const errorMessages: { [key in keyof typeof formValues]: string } = {
    name: "Campo de nome é obrigatório",
    cep: "Campo de CEP é obrigatório",
    city: "Campo de cidade é obrigatório",
    neighborhood: "Campo de bairro é obrigatório",
    street: "Campo de rua é obrigatório",
    state: "Campo de estado é obrigatório",
    start_number: "Campo de número inicial é obrigatório",
    end_number: "Campo de número final é obrigatório",
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevFormValues: { [key: string]: string }) => ({
      ...prevFormValues,
      [name]: value,
    }));
    setValidationErrors((prevValidationErrors) => ({
      ...prevValidationErrors,
      [name]: false,
    }));
  };

  useEffect(() => {
    if (hasSubmitted) {
      setValidationErrors((prevValidationErrors) => {
        const errors: IValidationErrors = {};

        inputFields.forEach((field) => {
          const { key } = field;
          errors[key] = !formValues[key];
        });

        return { ...prevValidationErrors, ...errors };
      });
    }
  }, [formValues, hasSubmitted]);

  const isFormValid = () => {
    const requiredFields = inputFields.filter((field) => !field.optional);

    return requiredFields.every((field) => {
      const { key } = field;
      const value = formValues[key];
      return typeof value === "string" && value.trim() !== "";
    });
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      const dataSubmit = {
        name: formValues.name,
        cep: formValues.cep,
        city: formValues.city,
        neighborhood: formValues.neighborhood,
        street: formValues.street,
        state: formValues.state,
        start_number: Number(formValues.start_number),
        end_number: Number(formValues.end_number),
      } as ICondominium;

      try {
        const response = await createCondominium(dataSubmit);
        setCondominios((prevCondominios: any) => [
          ...prevCondominios,
          response.createCondominium,
        ]);
        toast.success("Condomínio criado com sucesso!");
        setHasSubmitted(false);
      } catch (error) {
        toast.error(
          "Ocorreu um erro ao criar o condomínio. Por favor, tente novamente mais tarde."
        );
        console.error("Erro ao criar condomínio:", error);
      }
    } else {
      setHasSubmitted(true);
      toast.error("Por favor, preencha todos os campos corretamente.");
    }
  };

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent maxW="900px" maxH="900px">
          <ModalHeader fontSize="3xl" borderBottomWidth="1px" fontWeight="bold">
            Adicionar Condomínio
          </ModalHeader>
          <ModalCloseButton _focus={{ outline: "none" }} fontSize="md" />
          <ModalBody padding="2rem" fontSize={"3xl"}>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              {inputFields.map((field) => (
                <FormControl key={field.key}>
                  <FormLabel fontSize="2xl">{field.label}</FormLabel>
                  <Input
                    name={field.key}
                    value={formValues[field.key]}
                    onChange={handleInputChange}
                    h="3rem"
                    fontSize="2xl"
                    placeholder={field.placeholder}
                    type={field.type}
                  />
                  {hasSubmitted && validationErrors[field.key] && (
                    <Box fontSize="sm" color="red" mt={1}>
                      {errorMessages[field.key]}
                    </Box>
                  )}
                </FormControl>
              ))}
            </Grid>
          </ModalBody>
          <ModalFooter borderTopWidth="1px">
            <Button
              onClick={closeModal}
              h="4rem"
              fontSize="2xl"
              w="100px"
              mr={5}
              leftIcon={<RiCloseFill />}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                handleSubmit();
                if (isFormValid()) {
                  closeModal();
                }
              }}
              disabled={!isFormValid()}
              h="4rem"
              fontSize="2xl"
              w="100px"
              leftIcon={<RiCheckFill />}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
